@charset "UTF-8";
/*---
  title:   Introduction
  name:    intro
  order: -1
  ---

  ### Bienvenue sur ce styleguide!

  Vous retrouverez ici tous les styles inhérants au projet, vous permettant ainsi de kickstarter celui-ci au plus vite.

  N'hésitez pas à utiliser ce document comme une aide rapide lors de la construction des différentes pages.
*/
/*---
  title:   Brand
  name:    brand-colors
  order:    1
  section: design_colors
  ---
  ```example:color
  @color: #6D6F7C @name: primary-light
  @color: #15192D @name: primary
  @color: #080911 @name: primary-dark
  ```
  ```example:color
  line 7
  @color: #E68F17 @name: orange
  @color: #F5C644 @name: yellow
  @color: #FA5252 @name: coral
  @color: #E64980 @name: pink
  @color: #BE4BDB @name: purple
  @color: #0B7285 @name: teal
  @color: #15AABF @name: turquoise
  ```
*/
/*---
  title:   Alert
  name:    alert-colors
  section: Colors
  ---

  In order to use any color, just use function `clr(name-of-color)`

  ```example:color
  line 2
  @color: #EE1233 @name: alert
  @color: #40C057 @name: success
  ```
*/
/*---
  title:   Gray
  name:    gray-colors
  section: Colors
  ---

  In order to use any color, just use function `clr(name-of-color)`

  ```example:color
  line 4
  @color: #D9D9D9 @name: gray-light
  @color: #B3B3B3 @name: gray
  @color: #808080 @name: gray-dark
  @color: #4A4A4A @name: gray-darker
  ```
*/
/*---
  title:   Others
  name:    others-colors
  section: Colors
  ---

  In order to use any color, just use function `clr(name-of-color)`

  ```example:color
  @color: #ffffff @name: white
  ```
  ```example:color
  @color: #000000 @name: black
  ```
*/
/*---
  title:   Fonts
  name: fonts
  section: Variables
  ---

  ```css
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.45;
  color: clr(black);
  ```
*/
/*---
  title:   Animation duration/easing
  name: animation
  section: Variables
  ---

  Use this for animation timing and easing. You can either use the shorthand `$anim` or directly use `$anim-duration` and `$anim-easing` for fine tuning.

  ```
  $anim-duration: 250ms;
  $anim-easing: cubic-bezier(.55,0,.1,1) ;
  $anim: $anim-duration $anim-easing;
  ```
*/
/*---
  title:   Vertical rythmn
  name:    vertical-rythmn-grid
  section: Grid
  ---

  Use `$vr` in order to keep vertical rythmn accross the entire project.

  ```
  $vr: 2rem;
  ```
*/
/*---
  title:   Grid size
  name:    size-grid
  section: Grid
  ---

  ```
  $row-width: 144rem;
  $container-width: 100%;
  $total-columns: 12;
  $column-gutter: 4rem;
  $column-width: 8rem;
  ```
*/
/*---
  title:   Debug grid
  name: debgrid
  section: Variables
  ---

  Update this scss map to configure debgrid in order to display overlay debug grid in your project.

  ```
  $debgrid: (
    horizontal: false,
    maxwidth: $row-width,
    width: $container-width,
    columns: $total-columns,
    gutter: $column-gutter,
    colorColumn: rgba(clr(gray-darker), 0.1),
    colorGutter: rgba(clr(gray-darker), 0.05),
    vertical: false,
    vertical-size: $vr,
    colorVOdd: rgba(clr(gray-darker), 0.1),
    colorVEven: transparent
  );
  ```
*/
/*---
  title:   Breakpoints
  name: breakpoints
  section: Variables
  ---

  We have 3 main breakpoints based on Foundation and usefull in mobile first development.

  `$sm` is used for style for mobile, tablet and desktop (equals to no media query)

  `$md` is used for style for tablet and desktop `(min-width: 641px)`

  `$lg` is used for style for desktop only `(min-width: 1025px)`
*/
* {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  color: #000000;
  overflow-x: hidden;
  background: #e8ebed; }

body > div,
body > div > main {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

/*---
title:   HR
name:    hr
section: Others
---

```example:html
<hr>
```
*/
hr {
  margin: 2rem auto;
  border: none;
  height: 1px;
  width: 100%;
  background-color: #6D6F7C; }

img {
  display: block;
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.asteriskb::before {
  content: '*';
  color: #ff0000; }

.asteriska::after {
  content: '*';
  color: #ff0000; }

.text, body, p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-feature-settings: "kern", "liga", "pnum";
          font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.45;
  max-width: 100%; }

p:not(.no-margin) {
  margin-bottom: 2rem; }

.text.small, body.small, p.small,
p.small {
  font-size: 1.2rem; }

.text.x-small, body.x-small, p.x-small,
p.x-small {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2; }
  .text.x-small:not(.no-shadow), body.x-small:not(.no-shadow), p.x-small:not(.no-shadow),
  p.x-small:not(.no-shadow) {
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5); }

.bold {
  font-weight: 700; }

.italic {
  font-style: italic; }

.fs-normal {
  font-style: normal; }

.text-shadow {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5); }

/*---
title:   Text
name:    typo-text
section: Typography
---

```example:html
<p>
The breath is a quill. This could be, or perhaps a septate cast is an anthony of the mind. A globose desk without deer is truly a cornet of nerval postages. Authors often misinterpret the capricorn as an unsight snake, when in actuality it feels more like an irate.
</p>
<p class="bold">
  The breath is a quill. This could be, or perhaps a septate cast is an anthony of the mind. A globose desk without deer is truly a cornet of nerval postages. Authors often misinterpret the capricorn as an unsight snake, when in actuality it feels more like an irate.
</p>
<p class="italic">
  The breath is a quill. This could be, or perhaps a septate cast is an anthony of the mind. A globose desk without deer is truly a cornet of nerval postages. Authors often misinterpret the capricorn as an unsight snake, when in actuality it feels more like an irate.
</p>
```
*/
h1,
.h1 {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.14;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  color: #000000; }
  h1:not(.no-margin),
  .h1:not(.no-margin) {
    margin-bottom: 4rem; }

h2,
.h2 {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.27;
  color: #000000; }
  h2:not(.no-margin),
  .h2:not(.no-margin) {
    margin-bottom: 3rem; }

h3,
.h3 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.09;
  color: #000000; }
  h3:not(.no-margin),
  .h3:not(.no-margin) {
    margin-bottom: 2rem; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin: 0; }

.title-dividor {
  color: #808080;
  font-size: 1.2rem;
  line-height: 1.4;
  text-align: center;
  display: flex;
  align-items: center;
  text-transform: uppercase; }
  .title-dividor:before, .title-dividor:after {
    content: '';
    flex: 1 1 auto;
    height: 1px;
    background: #D9D9D9; }
  .title-dividor span {
    flex: 0 0 auto; }

/*---
title:   Headings
name:    typo-headings
section: typography
---

```example:html
<h1 class="">Titre de premier niveau</h1>

<h2 class="">Titre de second niveau</h2>

<h3 class="">Titre de troisieme niveau</h3>
```
*/
ol,
ul {
  list-style-position: inside;
  margin-bottom: 2rem; }
  ol li,
  ul li {
    padding: 0.2rem 0; }
    ol li ol,
    ol li ul,
    ul li ol,
    ul li ul {
      padding-left: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 0; }
      ol li ol > li:last-of-type,
      ol li ul > li:last-of-type,
      ul li ol > li:last-of-type,
      ul li ul > li:last-of-type {
        padding-bottom: 0; }
  ol.no-bullet,
  ul.no-bullet {
    list-style: none; }

ol:not(.no-bullet) {
  list-style: none;
  counter-reset: my-list; }
  ol:not(.no-bullet) li:before {
    counter-increment: my-list;
    display: inline-block;
    position: relative;
    content: counters(my-list, ".") ".";
    margin-right: 0.5rem;
    font-weight: 600; }

/*---
title:   List
name:    typo-list
section: typography
---

```example:html
<ul>
  <li>Lorem ipsum dolor sit</li>
  <li>
    Lorem ipsum dolor sit
    <ul>
      <li>Consectetur</li>
      <li>Consectetur</li>
    </ul>
  </li>
  <li>Lorem ipsum dolor sit</li>
</ul>
<ol>
  <li>Lorem ipsum dolor sit</li>
  <li>
    Lorem ipsum dolor sit
    <ol>
      <li>Consectetur</li>
      <li>Consectetur</li>
    </ol>
  </li>
  <li>Lorem ipsum dolor sit</li>
</ol>
<ul class="no-bullet">
  <li>Lorem ipsum dolor sit</li>
  <li>Lorem ipsum dolor sit</li>
  <li>Lorem ipsum dolor sit</li>
</ul>
```
*/
a,
.link {
  display: inline-block;
  transition: color 250ms cubic-bezier(0.55, 0, 0.1, 1);
  color: #000000;
  text-decoration: none;
  cursor: pointer; }
  a.underline,
  .link.underline {
    text-decoration: underline; }
  a:hover, a:focus,
  .link:hover,
  .link:focus {
    color: #15192D;
    text-decoration: none; }

/*---
title:   Link
name:    typo-link
section: typography
---

▲
▲ ▲

```example:html
<p>
  <a href="#x">link default</a>
</p>
```
*/
.row {
  position: relative;
  margin: 0 auto;
  max-width: 96rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

[class*="column-"] {
  display: flex;
  flex-direction: column; }
  [class*="column-"], [class*="column-"]:not(.no-width) > * {
    width: 100%;
    max-width: 100%; }
  [class*="column-"]:not(.nest) {
    padding-left: 2rem;
    padding-right: 2rem; }
    @media (max-width: 640px) {
      [class*="column-"]:not(.nest) {
        padding-left: 1rem;
        padding-right: 1rem; } }

.columns-padding {
  padding-left: 2rem;
  padding-right: 2rem; }
  @media (max-width: 640px) {
    .columns-padding {
      padding-left: 1rem;
      padding-right: 1rem; } }

.flex, .sm-flex {
  display: flex; }

.inl-flex, .sm-inl-flex {
  display: inline-flex; }

.column-1, .sm-column-1 {
  width: 8.33333%;
  max-width: 8.33333%;
  flex: 0 0 8.33333%; }

.order-1, .sm-order-1 {
  order: 1; }

.offset-1, .sm-offset-1 {
  margin-left: 8.33333%; }

.column-2, .sm-column-2 {
  width: 16.66667%;
  max-width: 16.66667%;
  flex: 0 0 16.66667%; }

.order-2, .sm-order-2 {
  order: 2; }

.offset-2, .sm-offset-2 {
  margin-left: 33.33333%; }

.column-3, .sm-column-3 {
  width: 25%;
  max-width: 25%;
  flex: 0 0 25%; }

.order-3, .sm-order-3 {
  order: 3; }

.offset-3, .sm-offset-3 {
  margin-left: 75%; }

.column-4, .sm-column-4 {
  width: 33.33333%;
  max-width: 33.33333%;
  flex: 0 0 33.33333%; }

.order-4, .sm-order-4 {
  order: 4; }

.offset-4, .sm-offset-4 {
  margin-left: 133.33333%; }

.column-5, .sm-column-5 {
  width: 41.66667%;
  max-width: 41.66667%;
  flex: 0 0 41.66667%; }

.order-5, .sm-order-5 {
  order: 5; }

.offset-5, .sm-offset-5 {
  margin-left: 208.33333%; }

.column-6, .sm-column-6 {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%; }

.order-6, .sm-order-6 {
  order: 6; }

.offset-6, .sm-offset-6 {
  margin-left: 300%; }

.column-7, .sm-column-7 {
  width: 58.33333%;
  max-width: 58.33333%;
  flex: 0 0 58.33333%; }

.order-7, .sm-order-7 {
  order: 7; }

.offset-7, .sm-offset-7 {
  margin-left: 408.33333%; }

.column-8, .sm-column-8 {
  width: 66.66667%;
  max-width: 66.66667%;
  flex: 0 0 66.66667%; }

.order-8, .sm-order-8 {
  order: 8; }

.offset-8, .sm-offset-8 {
  margin-left: 533.33333%; }

.column-9, .sm-column-9 {
  width: 75%;
  max-width: 75%;
  flex: 0 0 75%; }

.order-9, .sm-order-9 {
  order: 9; }

.offset-9, .sm-offset-9 {
  margin-left: 675%; }

.column-10, .sm-column-10 {
  width: 83.33333%;
  max-width: 83.33333%;
  flex: 0 0 83.33333%; }

.order-10, .sm-order-10 {
  order: 10; }

.offset-10, .sm-offset-10 {
  margin-left: 833.33333%; }

.column-11, .sm-column-11 {
  width: 91.66667%;
  max-width: 91.66667%;
  flex: 0 0 91.66667%; }

.order-11, .sm-order-11 {
  order: 11; }

.offset-11, .sm-offset-11 {
  margin-left: 1008.33333%; }

.column-12, .sm-column-12 {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%; }

.order-12, .sm-order-12 {
  order: 12; }

.size-5p, .sm-size-5p {
  width: 5%;
  max-width: 5%; }

.size-10p, .sm-size-10p {
  width: 10%;
  max-width: 10%; }

.size-15p, .sm-size-15p {
  width: 15%;
  max-width: 15%; }

.size-20p, .sm-size-20p {
  width: 20%;
  max-width: 20%; }

.size-25p, .sm-size-25p {
  width: 25%;
  max-width: 25%; }

.size-30p, .sm-size-30p {
  width: 30%;
  max-width: 30%; }

.size-35p, .sm-size-35p {
  width: 35%;
  max-width: 35%; }

.size-40p, .sm-size-40p {
  width: 40%;
  max-width: 40%; }

.size-45p, .sm-size-45p {
  width: 45%;
  max-width: 45%; }

.size-50p, .sm-size-50p {
  width: 50%;
  max-width: 50%; }

.size-55p, .sm-size-55p {
  width: 55%;
  max-width: 55%; }

.size-60p, .sm-size-60p {
  width: 60%;
  max-width: 60%; }

.size-65p, .sm-size-65p {
  width: 65%;
  max-width: 65%; }

.size-70p, .sm-size-70p {
  width: 70%;
  max-width: 70%; }

.size-75p, .sm-size-75p {
  width: 75%;
  max-width: 75%; }

.size-80p, .sm-size-80p {
  width: 80%;
  max-width: 80%; }

.size-85p, .sm-size-85p {
  width: 85%;
  max-width: 85%; }

.size-90p, .sm-size-90p {
  width: 90%;
  max-width: 90%; }

.size-95p, .sm-size-95p {
  width: 95%;
  max-width: 95%; }

.size-100p, .sm-size-100p {
  width: 100%;
  max-width: 100%; }

.size-33p, .sm-size-33p {
  width: 33.333333%;
  max-width: 33.333333%; }

.size-66p, .sm-size-66p {
  width: 66.666666%;
  max-width: 66.666666%; }

.size-49p, .sm-size-49p {
  width: 49%;
  max-width: 49%; }

.size-auto, .sm-size-auto {
  width: auto;
  max-width: auto; }

.w-1, .sm-w-1 {
  width: 0.8rem;
  max-width: 0.8rem;
  flex: 0 0 0.8rem; }

.h-1, .sm-h-1 {
  height: 0.8rem; }

.w-2, .sm-w-2 {
  width: 1.6rem;
  max-width: 1.6rem;
  flex: 0 0 1.6rem; }

.h-2, .sm-h-2 {
  height: 1.6rem; }

.w-3, .sm-w-3 {
  width: 2.4rem;
  max-width: 2.4rem;
  flex: 0 0 2.4rem; }

.h-3, .sm-h-3 {
  height: 2.4rem; }

.w-4, .sm-w-4 {
  width: 3.2rem;
  max-width: 3.2rem;
  flex: 0 0 3.2rem; }

.h-4, .sm-h-4 {
  height: 3.2rem; }

.w-5, .sm-w-5 {
  width: 4rem;
  max-width: 4rem;
  flex: 0 0 4rem; }

.h-5, .sm-h-5 {
  height: 4rem; }

.w-6, .sm-w-6 {
  width: 4.8rem;
  max-width: 4.8rem;
  flex: 0 0 4.8rem; }

.h-6, .sm-h-6 {
  height: 4.8rem; }

.w-7, .sm-w-7 {
  width: 5.6rem;
  max-width: 5.6rem;
  flex: 0 0 5.6rem; }

.h-7, .sm-h-7 {
  height: 5.6rem; }

.w-8, .sm-w-8 {
  width: 6.4rem;
  max-width: 6.4rem;
  flex: 0 0 6.4rem; }

.h-8, .sm-h-8 {
  height: 6.4rem; }

.w-9, .sm-w-9 {
  width: 7.2rem;
  max-width: 7.2rem;
  flex: 0 0 7.2rem; }

.h-9, .sm-h-9 {
  height: 7.2rem; }

.w-10, .sm-w-10 {
  width: 8rem;
  max-width: 8rem;
  flex: 0 0 8rem; }

.h-10, .sm-h-10 {
  height: 8rem; }

.w-11, .sm-w-11 {
  width: 8.8rem;
  max-width: 8.8rem;
  flex: 0 0 8.8rem; }

.h-11, .sm-h-11 {
  height: 8.8rem; }

.w-12, .sm-w-12 {
  width: 9.6rem;
  max-width: 9.6rem;
  flex: 0 0 9.6rem; }

.h-12, .sm-h-12 {
  height: 9.6rem; }

.w-13, .sm-w-13 {
  width: 10.4rem;
  max-width: 10.4rem;
  flex: 0 0 10.4rem; }

.h-13, .sm-h-13 {
  height: 10.4rem; }

.w-14, .sm-w-14 {
  width: 11.2rem;
  max-width: 11.2rem;
  flex: 0 0 11.2rem; }

.h-14, .sm-h-14 {
  height: 11.2rem; }

.w-15, .sm-w-15 {
  width: 12rem;
  max-width: 12rem;
  flex: 0 0 12rem; }

.h-15, .sm-h-15 {
  height: 12rem; }

.w-16, .sm-w-16 {
  width: 12.8rem;
  max-width: 12.8rem;
  flex: 0 0 12.8rem; }

.h-16, .sm-h-16 {
  height: 12.8rem; }

.w-17, .sm-w-17 {
  width: 13.6rem;
  max-width: 13.6rem;
  flex: 0 0 13.6rem; }

.h-17, .sm-h-17 {
  height: 13.6rem; }

.w-18, .sm-w-18 {
  width: 14.4rem;
  max-width: 14.4rem;
  flex: 0 0 14.4rem; }

.h-18, .sm-h-18 {
  height: 14.4rem; }

.w-19, .sm-w-19 {
  width: 15.2rem;
  max-width: 15.2rem;
  flex: 0 0 15.2rem; }

.h-19, .sm-h-19 {
  height: 15.2rem; }

.w-20, .sm-w-20 {
  width: 16rem;
  max-width: 16rem;
  flex: 0 0 16rem; }

.h-20, .sm-h-20 {
  height: 16rem; }

.w-21, .sm-w-21 {
  width: 16.8rem;
  max-width: 16.8rem;
  flex: 0 0 16.8rem; }

.h-21, .sm-h-21 {
  height: 16.8rem; }

.w-22, .sm-w-22 {
  width: 17.6rem;
  max-width: 17.6rem;
  flex: 0 0 17.6rem; }

.h-22, .sm-h-22 {
  height: 17.6rem; }

.w-23, .sm-w-23 {
  width: 18.4rem;
  max-width: 18.4rem;
  flex: 0 0 18.4rem; }

.h-23, .sm-h-23 {
  height: 18.4rem; }

.w-24, .sm-w-24 {
  width: 19.2rem;
  max-width: 19.2rem;
  flex: 0 0 19.2rem; }

.h-24, .sm-h-24 {
  height: 19.2rem; }

.w-25, .sm-w-25 {
  width: 20rem;
  max-width: 20rem;
  flex: 0 0 20rem; }

.h-25, .sm-h-25 {
  height: 20rem; }

.align-start-start, .sm-align-start-start {
  justify-content: flex-start;
  align-items: flex-start; }

.align-start-end, .sm-align-start-end {
  justify-content: flex-start;
  align-items: flex-end; }

.align-start-center, .sm-align-start-center {
  justify-content: flex-start;
  align-items: center; }

.align-start-stretch, .sm-align-start-stretch {
  justify-content: flex-start;
  align-items: stretch; }

.justify-content-start, .sm-justify-content-start {
  justify-content: flex-start; }

.justify-content-around, .sm-justify-content-around {
  justify-content: space-around; }

.justify-content-center, .sm-justify-content-center {
  justify-content: center; }

.justify-content-evenly, .sm-justify-content-evenly {
  justify-content: space-evenly; }

.align-end-start, .sm-align-end-start {
  justify-content: flex-end;
  align-items: flex-start; }

.align-end-end, .sm-align-end-end {
  justify-content: flex-end;
  align-items: flex-end; }

.align-end-center, .sm-align-end-center {
  justify-content: flex-end;
  align-items: center; }

.align-end-stretch, .sm-align-end-stretch {
  justify-content: flex-end;
  align-items: stretch; }

.align-center-start, .sm-align-center-start {
  justify-content: center;
  align-items: flex-start; }

.align-center-end, .sm-align-center-end {
  justify-content: center;
  align-items: flex-end; }

.align-center-center, .sm-align-center-center {
  justify-content: center;
  align-items: center; }

.align-center-stretch, .sm-align-center-stretch {
  justify-content: center;
  align-items: stretch; }

.align-spacebetween-start, .sm-align-spacebetween-start {
  justify-content: space-between;
  align-items: flex-start; }

.align-spacebetween-end, .sm-align-spacebetween-end {
  justify-content: space-between;
  align-items: flex-end; }

.align-spacebetween-center, .sm-align-spacebetween-center {
  justify-content: space-between;
  align-items: center; }

.align-spacebetween-stretch, .sm-align-spacebetween-stretch {
  justify-content: space-between;
  align-items: stretch; }

.align-spacearound-start, .sm-align-spacearound-start {
  justify-content: space-around;
  align-items: flex-start; }

.align-spacearound-end, .sm-align-spacearound-end {
  justify-content: space-around;
  align-items: flex-end; }

.align-spacearound-center, .sm-align-spacearound-center {
  justify-content: space-around;
  align-items: center; }

.align-spacearound-stretch, .sm-align-spacearound-stretch {
  justify-content: space-around;
  align-items: stretch; }

.gap-1p, .sm-gap-1p {
  gap: 1px; }

.gap-2p, .sm-gap-2p {
  gap: 2px; }

.gap-3p, .sm-gap-3p {
  gap: 3px; }

.gap-4p, .sm-gap-4p {
  gap: 4px; }

.gap-5p, .sm-gap-5p {
  gap: 5px; }

.gap-6p, .sm-gap-6p {
  gap: 6px; }

.gap-7p, .sm-gap-7p {
  gap: 7px; }

.gap-8p, .sm-gap-8p {
  gap: 8px; }

.gap-9p, .sm-gap-9p {
  gap: 9px; }

.gap-10p, .sm-gap-10p {
  gap: 10px; }

.gap-11p, .sm-gap-11p {
  gap: 11px; }

.gap-12p, .sm-gap-12p {
  gap: 12px; }

.gap-13p, .sm-gap-13p {
  gap: 13px; }

.gap-14p, .sm-gap-14p {
  gap: 14px; }

.gap-15p, .sm-gap-15p {
  gap: 15px; }

.gap-16p, .sm-gap-16p {
  gap: 16px; }

.gap-17p, .sm-gap-17p {
  gap: 17px; }

.gap-18p, .sm-gap-18p {
  gap: 18px; }

.gap-19p, .sm-gap-19p {
  gap: 19px; }

.gap-20p, .sm-gap-20p {
  gap: 20px; }

.column-gap-1p, .sm-column-gap-1p {
  -webkit-column-gap: 1px;
          column-gap: 1px; }

.column-gap-2p, .sm-column-gap-2p {
  -webkit-column-gap: 2px;
          column-gap: 2px; }

.column-gap-3p, .sm-column-gap-3p {
  -webkit-column-gap: 3px;
          column-gap: 3px; }

.column-gap-4p, .sm-column-gap-4p {
  -webkit-column-gap: 4px;
          column-gap: 4px; }

.column-gap-5p, .sm-column-gap-5p {
  -webkit-column-gap: 5px;
          column-gap: 5px; }

.column-gap-6p, .sm-column-gap-6p {
  -webkit-column-gap: 6px;
          column-gap: 6px; }

.column-gap-7p, .sm-column-gap-7p {
  -webkit-column-gap: 7px;
          column-gap: 7px; }

.column-gap-8p, .sm-column-gap-8p {
  -webkit-column-gap: 8px;
          column-gap: 8px; }

.column-gap-9p, .sm-column-gap-9p {
  -webkit-column-gap: 9px;
          column-gap: 9px; }

.column-gap-10p, .sm-column-gap-10p {
  -webkit-column-gap: 10px;
          column-gap: 10px; }

.column-gap-11p, .sm-column-gap-11p {
  -webkit-column-gap: 11px;
          column-gap: 11px; }

.column-gap-12p, .sm-column-gap-12p {
  -webkit-column-gap: 12px;
          column-gap: 12px; }

.column-gap-13p, .sm-column-gap-13p {
  -webkit-column-gap: 13px;
          column-gap: 13px; }

.column-gap-14p, .sm-column-gap-14p {
  -webkit-column-gap: 14px;
          column-gap: 14px; }

.column-gap-15p, .sm-column-gap-15p {
  -webkit-column-gap: 15px;
          column-gap: 15px; }

.column-gap-16p, .sm-column-gap-16p {
  -webkit-column-gap: 16px;
          column-gap: 16px; }

.column-gap-17p, .sm-column-gap-17p {
  -webkit-column-gap: 17px;
          column-gap: 17px; }

.column-gap-18p, .sm-column-gap-18p {
  -webkit-column-gap: 18px;
          column-gap: 18px; }

.column-gap-19p, .sm-column-gap-19p {
  -webkit-column-gap: 19px;
          column-gap: 19px; }

.column-gap-20p, .sm-column-gap-20p {
  -webkit-column-gap: 20px;
          column-gap: 20px; }

.row-gap-1p, .sm-row-gap-1p {
  row-gap: 1px; }

.row-gap-2p, .sm-row-gap-2p {
  row-gap: 2px; }

.row-gap-3p, .sm-row-gap-3p {
  row-gap: 3px; }

.row-gap-4p, .sm-row-gap-4p {
  row-gap: 4px; }

.row-gap-5p, .sm-row-gap-5p {
  row-gap: 5px; }

.row-gap-6p, .sm-row-gap-6p {
  row-gap: 6px; }

.row-gap-7p, .sm-row-gap-7p {
  row-gap: 7px; }

.row-gap-8p, .sm-row-gap-8p {
  row-gap: 8px; }

.row-gap-9p, .sm-row-gap-9p {
  row-gap: 9px; }

.row-gap-10p, .sm-row-gap-10p {
  row-gap: 10px; }

.row-gap-11p, .sm-row-gap-11p {
  row-gap: 11px; }

.row-gap-12p, .sm-row-gap-12p {
  row-gap: 12px; }

.row-gap-13p, .sm-row-gap-13p {
  row-gap: 13px; }

.row-gap-14p, .sm-row-gap-14p {
  row-gap: 14px; }

.row-gap-15p, .sm-row-gap-15p {
  row-gap: 15px; }

.row-gap-16p, .sm-row-gap-16p {
  row-gap: 16px; }

.row-gap-17p, .sm-row-gap-17p {
  row-gap: 17px; }

.row-gap-18p, .sm-row-gap-18p {
  row-gap: 18px; }

.row-gap-19p, .sm-row-gap-19p {
  row-gap: 19px; }

.row-gap-20p, .sm-row-gap-20p {
  row-gap: 20px; }

.layout-column, .sm-layout-column {
  flex-direction: column; }

.layout-columnreverse, .sm-layout-columnreverse {
  flex-direction: column-reverse; }

.layout-row, .sm-layout-row {
  flex-direction: row; }

.layout-rowreverse, .sm-layout-rowreverse {
  flex-direction: row-reverse; }

.layout-wrap, .sm-layout-wrap {
  flex-wrap: wrap; }

.layout-nowrap, .sm-layout-nowrap {
  flex-wrap: nowrap; }

.flex-full, .sm-flex-full {
  flex: 0 0 100%; }

.flex-grow, .sm-flex-grow {
  flex: 1 1 100%; }

.flex-initial, .sm-flex-initial {
  flex: 0 1 auto; }

.flex-auto, .sm-flex-auto {
  flex: 1 1 auto; }

.flex-none, .sm-flex-none {
  flex: 0 0 auto; }

.layout-fill, .sm-layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

.dp-block, .sm-dp-block {
  display: block; }

.dp-inlblock, .sm-dp-inlblock {
  display: inline-block; }

.dp-inl, .sm-dp-inl {
  display: inline; }

.dp-none, .sm-dp-none {
  display: none; }

@media (min-width: 641px) {
  .md-flex {
    display: flex; }
  .md-inl-flex {
    display: inline-flex; }
  .md-column-1 {
    width: 8.33333%;
    max-width: 8.33333%;
    flex: 0 0 8.33333%; }
  .md-order-1 {
    order: 1; }
  .md-offset-1 {
    margin-left: 8.33333%; }
  .md-column-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .md-order-2 {
    order: 2; }
  .md-offset-2 {
    margin-left: 33.33333%; }
  .md-column-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .md-order-3 {
    order: 3; }
  .md-offset-3 {
    margin-left: 75%; }
  .md-column-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .md-order-4 {
    order: 4; }
  .md-offset-4 {
    margin-left: 133.33333%; }
  .md-column-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .md-order-5 {
    order: 5; }
  .md-offset-5 {
    margin-left: 208.33333%; }
  .md-column-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .md-order-6 {
    order: 6; }
  .md-offset-6 {
    margin-left: 300%; }
  .md-column-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .md-order-7 {
    order: 7; }
  .md-offset-7 {
    margin-left: 408.33333%; }
  .md-column-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .md-order-8 {
    order: 8; }
  .md-offset-8 {
    margin-left: 533.33333%; }
  .md-column-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .md-order-9 {
    order: 9; }
  .md-offset-9 {
    margin-left: 675%; }
  .md-column-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .md-order-10 {
    order: 10; }
  .md-offset-10 {
    margin-left: 833.33333%; }
  .md-column-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .md-order-11 {
    order: 11; }
  .md-offset-11 {
    margin-left: 1008.33333%; }
  .md-column-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .md-order-12 {
    order: 12; }
  .md-size-5p {
    width: 5%;
    max-width: 5%; }
  .md-size-10p {
    width: 10%;
    max-width: 10%; }
  .md-size-15p {
    width: 15%;
    max-width: 15%; }
  .md-size-20p {
    width: 20%;
    max-width: 20%; }
  .md-size-25p {
    width: 25%;
    max-width: 25%; }
  .md-size-30p {
    width: 30%;
    max-width: 30%; }
  .md-size-35p {
    width: 35%;
    max-width: 35%; }
  .md-size-40p {
    width: 40%;
    max-width: 40%; }
  .md-size-45p {
    width: 45%;
    max-width: 45%; }
  .md-size-50p {
    width: 50%;
    max-width: 50%; }
  .md-size-55p {
    width: 55%;
    max-width: 55%; }
  .md-size-60p {
    width: 60%;
    max-width: 60%; }
  .md-size-65p {
    width: 65%;
    max-width: 65%; }
  .md-size-70p {
    width: 70%;
    max-width: 70%; }
  .md-size-75p {
    width: 75%;
    max-width: 75%; }
  .md-size-80p {
    width: 80%;
    max-width: 80%; }
  .md-size-85p {
    width: 85%;
    max-width: 85%; }
  .md-size-90p {
    width: 90%;
    max-width: 90%; }
  .md-size-95p {
    width: 95%;
    max-width: 95%; }
  .md-size-100p {
    width: 100%;
    max-width: 100%; }
  .md-size-33p {
    width: 33.333333%;
    max-width: 33.333333%; }
  .md-size-66p {
    width: 66.666666%;
    max-width: 66.666666%; }
  .md-size-49p {
    width: 49%;
    max-width: 49%; }
  .md-size-auto {
    width: auto;
    max-width: auto; }
  .md-w-1 {
    width: 0.8rem;
    max-width: 0.8rem;
    flex: 0 0 0.8rem; }
  .md-h-1 {
    height: 0.8rem; }
  .md-w-2 {
    width: 1.6rem;
    max-width: 1.6rem;
    flex: 0 0 1.6rem; }
  .md-h-2 {
    height: 1.6rem; }
  .md-w-3 {
    width: 2.4rem;
    max-width: 2.4rem;
    flex: 0 0 2.4rem; }
  .md-h-3 {
    height: 2.4rem; }
  .md-w-4 {
    width: 3.2rem;
    max-width: 3.2rem;
    flex: 0 0 3.2rem; }
  .md-h-4 {
    height: 3.2rem; }
  .md-w-5 {
    width: 4rem;
    max-width: 4rem;
    flex: 0 0 4rem; }
  .md-h-5 {
    height: 4rem; }
  .md-w-6 {
    width: 4.8rem;
    max-width: 4.8rem;
    flex: 0 0 4.8rem; }
  .md-h-6 {
    height: 4.8rem; }
  .md-w-7 {
    width: 5.6rem;
    max-width: 5.6rem;
    flex: 0 0 5.6rem; }
  .md-h-7 {
    height: 5.6rem; }
  .md-w-8 {
    width: 6.4rem;
    max-width: 6.4rem;
    flex: 0 0 6.4rem; }
  .md-h-8 {
    height: 6.4rem; }
  .md-w-9 {
    width: 7.2rem;
    max-width: 7.2rem;
    flex: 0 0 7.2rem; }
  .md-h-9 {
    height: 7.2rem; }
  .md-w-10 {
    width: 8rem;
    max-width: 8rem;
    flex: 0 0 8rem; }
  .md-h-10 {
    height: 8rem; }
  .md-w-11 {
    width: 8.8rem;
    max-width: 8.8rem;
    flex: 0 0 8.8rem; }
  .md-h-11 {
    height: 8.8rem; }
  .md-w-12 {
    width: 9.6rem;
    max-width: 9.6rem;
    flex: 0 0 9.6rem; }
  .md-h-12 {
    height: 9.6rem; }
  .md-w-13 {
    width: 10.4rem;
    max-width: 10.4rem;
    flex: 0 0 10.4rem; }
  .md-h-13 {
    height: 10.4rem; }
  .md-w-14 {
    width: 11.2rem;
    max-width: 11.2rem;
    flex: 0 0 11.2rem; }
  .md-h-14 {
    height: 11.2rem; }
  .md-w-15 {
    width: 12rem;
    max-width: 12rem;
    flex: 0 0 12rem; }
  .md-h-15 {
    height: 12rem; }
  .md-w-16 {
    width: 12.8rem;
    max-width: 12.8rem;
    flex: 0 0 12.8rem; }
  .md-h-16 {
    height: 12.8rem; }
  .md-w-17 {
    width: 13.6rem;
    max-width: 13.6rem;
    flex: 0 0 13.6rem; }
  .md-h-17 {
    height: 13.6rem; }
  .md-w-18 {
    width: 14.4rem;
    max-width: 14.4rem;
    flex: 0 0 14.4rem; }
  .md-h-18 {
    height: 14.4rem; }
  .md-w-19 {
    width: 15.2rem;
    max-width: 15.2rem;
    flex: 0 0 15.2rem; }
  .md-h-19 {
    height: 15.2rem; }
  .md-w-20 {
    width: 16rem;
    max-width: 16rem;
    flex: 0 0 16rem; }
  .md-h-20 {
    height: 16rem; }
  .md-w-21 {
    width: 16.8rem;
    max-width: 16.8rem;
    flex: 0 0 16.8rem; }
  .md-h-21 {
    height: 16.8rem; }
  .md-w-22 {
    width: 17.6rem;
    max-width: 17.6rem;
    flex: 0 0 17.6rem; }
  .md-h-22 {
    height: 17.6rem; }
  .md-w-23 {
    width: 18.4rem;
    max-width: 18.4rem;
    flex: 0 0 18.4rem; }
  .md-h-23 {
    height: 18.4rem; }
  .md-w-24 {
    width: 19.2rem;
    max-width: 19.2rem;
    flex: 0 0 19.2rem; }
  .md-h-24 {
    height: 19.2rem; }
  .md-w-25 {
    width: 20rem;
    max-width: 20rem;
    flex: 0 0 20rem; }
  .md-h-25 {
    height: 20rem; }
  .md-align-start-start {
    justify-content: flex-start;
    align-items: flex-start; }
  .md-align-start-end {
    justify-content: flex-start;
    align-items: flex-end; }
  .md-align-start-center {
    justify-content: flex-start;
    align-items: center; }
  .md-align-start-stretch {
    justify-content: flex-start;
    align-items: stretch; }
  .md-justify-content-start {
    justify-content: flex-start; }
  .md-justify-content-around {
    justify-content: space-around; }
  .md-justify-content-center {
    justify-content: center; }
  .md-justify-content-evenly {
    justify-content: space-evenly; }
  .md-align-end-start {
    justify-content: flex-end;
    align-items: flex-start; }
  .md-align-end-end {
    justify-content: flex-end;
    align-items: flex-end; }
  .md-align-end-center {
    justify-content: flex-end;
    align-items: center; }
  .md-align-end-stretch {
    justify-content: flex-end;
    align-items: stretch; }
  .md-align-center-start {
    justify-content: center;
    align-items: flex-start; }
  .md-align-center-end {
    justify-content: center;
    align-items: flex-end; }
  .md-align-center-center {
    justify-content: center;
    align-items: center; }
  .md-align-center-stretch {
    justify-content: center;
    align-items: stretch; }
  .md-align-spacebetween-start {
    justify-content: space-between;
    align-items: flex-start; }
  .md-align-spacebetween-end {
    justify-content: space-between;
    align-items: flex-end; }
  .md-align-spacebetween-center {
    justify-content: space-between;
    align-items: center; }
  .md-align-spacebetween-stretch {
    justify-content: space-between;
    align-items: stretch; }
  .md-align-spacearound-start {
    justify-content: space-around;
    align-items: flex-start; }
  .md-align-spacearound-end {
    justify-content: space-around;
    align-items: flex-end; }
  .md-align-spacearound-center {
    justify-content: space-around;
    align-items: center; }
  .md-align-spacearound-stretch {
    justify-content: space-around;
    align-items: stretch; }
  .md-gap-1p {
    gap: 1px; }
  .md-gap-2p {
    gap: 2px; }
  .md-gap-3p {
    gap: 3px; }
  .md-gap-4p {
    gap: 4px; }
  .md-gap-5p {
    gap: 5px; }
  .md-gap-6p {
    gap: 6px; }
  .md-gap-7p {
    gap: 7px; }
  .md-gap-8p {
    gap: 8px; }
  .md-gap-9p {
    gap: 9px; }
  .md-gap-10p {
    gap: 10px; }
  .md-gap-11p {
    gap: 11px; }
  .md-gap-12p {
    gap: 12px; }
  .md-gap-13p {
    gap: 13px; }
  .md-gap-14p {
    gap: 14px; }
  .md-gap-15p {
    gap: 15px; }
  .md-gap-16p {
    gap: 16px; }
  .md-gap-17p {
    gap: 17px; }
  .md-gap-18p {
    gap: 18px; }
  .md-gap-19p {
    gap: 19px; }
  .md-gap-20p {
    gap: 20px; }
  .md-column-gap-1p {
    -webkit-column-gap: 1px;
            column-gap: 1px; }
  .md-column-gap-2p {
    -webkit-column-gap: 2px;
            column-gap: 2px; }
  .md-column-gap-3p {
    -webkit-column-gap: 3px;
            column-gap: 3px; }
  .md-column-gap-4p {
    -webkit-column-gap: 4px;
            column-gap: 4px; }
  .md-column-gap-5p {
    -webkit-column-gap: 5px;
            column-gap: 5px; }
  .md-column-gap-6p {
    -webkit-column-gap: 6px;
            column-gap: 6px; }
  .md-column-gap-7p {
    -webkit-column-gap: 7px;
            column-gap: 7px; }
  .md-column-gap-8p {
    -webkit-column-gap: 8px;
            column-gap: 8px; }
  .md-column-gap-9p {
    -webkit-column-gap: 9px;
            column-gap: 9px; }
  .md-column-gap-10p {
    -webkit-column-gap: 10px;
            column-gap: 10px; }
  .md-column-gap-11p {
    -webkit-column-gap: 11px;
            column-gap: 11px; }
  .md-column-gap-12p {
    -webkit-column-gap: 12px;
            column-gap: 12px; }
  .md-column-gap-13p {
    -webkit-column-gap: 13px;
            column-gap: 13px; }
  .md-column-gap-14p {
    -webkit-column-gap: 14px;
            column-gap: 14px; }
  .md-column-gap-15p {
    -webkit-column-gap: 15px;
            column-gap: 15px; }
  .md-column-gap-16p {
    -webkit-column-gap: 16px;
            column-gap: 16px; }
  .md-column-gap-17p {
    -webkit-column-gap: 17px;
            column-gap: 17px; }
  .md-column-gap-18p {
    -webkit-column-gap: 18px;
            column-gap: 18px; }
  .md-column-gap-19p {
    -webkit-column-gap: 19px;
            column-gap: 19px; }
  .md-column-gap-20p {
    -webkit-column-gap: 20px;
            column-gap: 20px; }
  .md-row-gap-1p {
    row-gap: 1px; }
  .md-row-gap-2p {
    row-gap: 2px; }
  .md-row-gap-3p {
    row-gap: 3px; }
  .md-row-gap-4p {
    row-gap: 4px; }
  .md-row-gap-5p {
    row-gap: 5px; }
  .md-row-gap-6p {
    row-gap: 6px; }
  .md-row-gap-7p {
    row-gap: 7px; }
  .md-row-gap-8p {
    row-gap: 8px; }
  .md-row-gap-9p {
    row-gap: 9px; }
  .md-row-gap-10p {
    row-gap: 10px; }
  .md-row-gap-11p {
    row-gap: 11px; }
  .md-row-gap-12p {
    row-gap: 12px; }
  .md-row-gap-13p {
    row-gap: 13px; }
  .md-row-gap-14p {
    row-gap: 14px; }
  .md-row-gap-15p {
    row-gap: 15px; }
  .md-row-gap-16p {
    row-gap: 16px; }
  .md-row-gap-17p {
    row-gap: 17px; }
  .md-row-gap-18p {
    row-gap: 18px; }
  .md-row-gap-19p {
    row-gap: 19px; }
  .md-row-gap-20p {
    row-gap: 20px; }
  .md-layout-column {
    flex-direction: column; }
  .md-layout-columnreverse {
    flex-direction: column-reverse; }
  .md-layout-row {
    flex-direction: row; }
  .md-layout-rowreverse {
    flex-direction: row-reverse; }
  .md-layout-wrap {
    flex-wrap: wrap; }
  .md-layout-nowrap {
    flex-wrap: nowrap; }
  .md-flex-full {
    flex: 0 0 100%; }
  .md-flex-grow {
    flex: 1 1 100%; }
  .md-flex-initial {
    flex: 0 1 auto; }
  .md-flex-auto {
    flex: 1 1 auto; }
  .md-flex-none {
    flex: 0 0 auto; }
  .md-layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; }
  .md-dp-block {
    display: block; }
  .md-dp-inlblock {
    display: inline-block; }
  .md-dp-inl {
    display: inline; }
  .md-dp-none {
    display: none; } }

@media (min-width: 1025px) {
  .lg-flex {
    display: flex; }
  .lg-inl-flex {
    display: inline-flex; }
  .lg-column-1 {
    width: 8.33333%;
    max-width: 8.33333%;
    flex: 0 0 8.33333%; }
  .lg-order-1 {
    order: 1; }
  .lg-offset-1 {
    margin-left: 8.33333%; }
  .lg-column-2 {
    width: 16.66667%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .lg-order-2 {
    order: 2; }
  .lg-offset-2 {
    margin-left: 33.33333%; }
  .lg-column-3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; }
  .lg-order-3 {
    order: 3; }
  .lg-offset-3 {
    margin-left: 75%; }
  .lg-column-4 {
    width: 33.33333%;
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .lg-order-4 {
    order: 4; }
  .lg-offset-4 {
    margin-left: 133.33333%; }
  .lg-column-5 {
    width: 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .lg-order-5 {
    order: 5; }
  .lg-offset-5 {
    margin-left: 208.33333%; }
  .lg-column-6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .lg-order-6 {
    order: 6; }
  .lg-offset-6 {
    margin-left: 300%; }
  .lg-column-7 {
    width: 58.33333%;
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .lg-order-7 {
    order: 7; }
  .lg-offset-7 {
    margin-left: 408.33333%; }
  .lg-column-8 {
    width: 66.66667%;
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .lg-order-8 {
    order: 8; }
  .lg-offset-8 {
    margin-left: 533.33333%; }
  .lg-column-9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%; }
  .lg-order-9 {
    order: 9; }
  .lg-offset-9 {
    margin-left: 675%; }
  .lg-column-10 {
    width: 83.33333%;
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .lg-order-10 {
    order: 10; }
  .lg-offset-10 {
    margin-left: 833.33333%; }
  .lg-column-11 {
    width: 91.66667%;
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .lg-order-11 {
    order: 11; }
  .lg-offset-11 {
    margin-left: 1008.33333%; }
  .lg-column-12 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .lg-order-12 {
    order: 12; }
  .lg-size-5p {
    width: 5%;
    max-width: 5%; }
  .lg-size-10p {
    width: 10%;
    max-width: 10%; }
  .lg-size-15p {
    width: 15%;
    max-width: 15%; }
  .lg-size-20p {
    width: 20%;
    max-width: 20%; }
  .lg-size-25p {
    width: 25%;
    max-width: 25%; }
  .lg-size-30p {
    width: 30%;
    max-width: 30%; }
  .lg-size-35p {
    width: 35%;
    max-width: 35%; }
  .lg-size-40p {
    width: 40%;
    max-width: 40%; }
  .lg-size-45p {
    width: 45%;
    max-width: 45%; }
  .lg-size-50p {
    width: 50%;
    max-width: 50%; }
  .lg-size-55p {
    width: 55%;
    max-width: 55%; }
  .lg-size-60p {
    width: 60%;
    max-width: 60%; }
  .lg-size-65p {
    width: 65%;
    max-width: 65%; }
  .lg-size-70p {
    width: 70%;
    max-width: 70%; }
  .lg-size-75p {
    width: 75%;
    max-width: 75%; }
  .lg-size-80p {
    width: 80%;
    max-width: 80%; }
  .lg-size-85p {
    width: 85%;
    max-width: 85%; }
  .lg-size-90p {
    width: 90%;
    max-width: 90%; }
  .lg-size-95p {
    width: 95%;
    max-width: 95%; }
  .lg-size-100p {
    width: 100%;
    max-width: 100%; }
  .lg-size-33p {
    width: 33.333333%;
    max-width: 33.333333%; }
  .lg-size-66p {
    width: 66.666666%;
    max-width: 66.666666%; }
  .lg-size-49p {
    width: 49%;
    max-width: 49%; }
  .lg-size-auto {
    width: auto;
    max-width: auto; }
  .lg-w-1 {
    width: 0.8rem;
    max-width: 0.8rem;
    flex: 0 0 0.8rem; }
  .lg-h-1 {
    height: 0.8rem; }
  .lg-w-2 {
    width: 1.6rem;
    max-width: 1.6rem;
    flex: 0 0 1.6rem; }
  .lg-h-2 {
    height: 1.6rem; }
  .lg-w-3 {
    width: 2.4rem;
    max-width: 2.4rem;
    flex: 0 0 2.4rem; }
  .lg-h-3 {
    height: 2.4rem; }
  .lg-w-4 {
    width: 3.2rem;
    max-width: 3.2rem;
    flex: 0 0 3.2rem; }
  .lg-h-4 {
    height: 3.2rem; }
  .lg-w-5 {
    width: 4rem;
    max-width: 4rem;
    flex: 0 0 4rem; }
  .lg-h-5 {
    height: 4rem; }
  .lg-w-6 {
    width: 4.8rem;
    max-width: 4.8rem;
    flex: 0 0 4.8rem; }
  .lg-h-6 {
    height: 4.8rem; }
  .lg-w-7 {
    width: 5.6rem;
    max-width: 5.6rem;
    flex: 0 0 5.6rem; }
  .lg-h-7 {
    height: 5.6rem; }
  .lg-w-8 {
    width: 6.4rem;
    max-width: 6.4rem;
    flex: 0 0 6.4rem; }
  .lg-h-8 {
    height: 6.4rem; }
  .lg-w-9 {
    width: 7.2rem;
    max-width: 7.2rem;
    flex: 0 0 7.2rem; }
  .lg-h-9 {
    height: 7.2rem; }
  .lg-w-10 {
    width: 8rem;
    max-width: 8rem;
    flex: 0 0 8rem; }
  .lg-h-10 {
    height: 8rem; }
  .lg-w-11 {
    width: 8.8rem;
    max-width: 8.8rem;
    flex: 0 0 8.8rem; }
  .lg-h-11 {
    height: 8.8rem; }
  .lg-w-12 {
    width: 9.6rem;
    max-width: 9.6rem;
    flex: 0 0 9.6rem; }
  .lg-h-12 {
    height: 9.6rem; }
  .lg-w-13 {
    width: 10.4rem;
    max-width: 10.4rem;
    flex: 0 0 10.4rem; }
  .lg-h-13 {
    height: 10.4rem; }
  .lg-w-14 {
    width: 11.2rem;
    max-width: 11.2rem;
    flex: 0 0 11.2rem; }
  .lg-h-14 {
    height: 11.2rem; }
  .lg-w-15 {
    width: 12rem;
    max-width: 12rem;
    flex: 0 0 12rem; }
  .lg-h-15 {
    height: 12rem; }
  .lg-w-16 {
    width: 12.8rem;
    max-width: 12.8rem;
    flex: 0 0 12.8rem; }
  .lg-h-16 {
    height: 12.8rem; }
  .lg-w-17 {
    width: 13.6rem;
    max-width: 13.6rem;
    flex: 0 0 13.6rem; }
  .lg-h-17 {
    height: 13.6rem; }
  .lg-w-18 {
    width: 14.4rem;
    max-width: 14.4rem;
    flex: 0 0 14.4rem; }
  .lg-h-18 {
    height: 14.4rem; }
  .lg-w-19 {
    width: 15.2rem;
    max-width: 15.2rem;
    flex: 0 0 15.2rem; }
  .lg-h-19 {
    height: 15.2rem; }
  .lg-w-20 {
    width: 16rem;
    max-width: 16rem;
    flex: 0 0 16rem; }
  .lg-h-20 {
    height: 16rem; }
  .lg-w-21 {
    width: 16.8rem;
    max-width: 16.8rem;
    flex: 0 0 16.8rem; }
  .lg-h-21 {
    height: 16.8rem; }
  .lg-w-22 {
    width: 17.6rem;
    max-width: 17.6rem;
    flex: 0 0 17.6rem; }
  .lg-h-22 {
    height: 17.6rem; }
  .lg-w-23 {
    width: 18.4rem;
    max-width: 18.4rem;
    flex: 0 0 18.4rem; }
  .lg-h-23 {
    height: 18.4rem; }
  .lg-w-24 {
    width: 19.2rem;
    max-width: 19.2rem;
    flex: 0 0 19.2rem; }
  .lg-h-24 {
    height: 19.2rem; }
  .lg-w-25 {
    width: 20rem;
    max-width: 20rem;
    flex: 0 0 20rem; }
  .lg-h-25 {
    height: 20rem; }
  .lg-align-start-start {
    justify-content: flex-start;
    align-items: flex-start; }
  .lg-align-start-end {
    justify-content: flex-start;
    align-items: flex-end; }
  .lg-align-start-center {
    justify-content: flex-start;
    align-items: center; }
  .lg-align-start-stretch {
    justify-content: flex-start;
    align-items: stretch; }
  .lg-justify-content-start {
    justify-content: flex-start; }
  .lg-justify-content-around {
    justify-content: space-around; }
  .lg-justify-content-center {
    justify-content: center; }
  .lg-justify-content-evenly {
    justify-content: space-evenly; }
  .lg-align-end-start {
    justify-content: flex-end;
    align-items: flex-start; }
  .lg-align-end-end {
    justify-content: flex-end;
    align-items: flex-end; }
  .lg-align-end-center {
    justify-content: flex-end;
    align-items: center; }
  .lg-align-end-stretch {
    justify-content: flex-end;
    align-items: stretch; }
  .lg-align-center-start {
    justify-content: center;
    align-items: flex-start; }
  .lg-align-center-end {
    justify-content: center;
    align-items: flex-end; }
  .lg-align-center-center {
    justify-content: center;
    align-items: center; }
  .lg-align-center-stretch {
    justify-content: center;
    align-items: stretch; }
  .lg-align-spacebetween-start {
    justify-content: space-between;
    align-items: flex-start; }
  .lg-align-spacebetween-end {
    justify-content: space-between;
    align-items: flex-end; }
  .lg-align-spacebetween-center {
    justify-content: space-between;
    align-items: center; }
  .lg-align-spacebetween-stretch {
    justify-content: space-between;
    align-items: stretch; }
  .lg-align-spacearound-start {
    justify-content: space-around;
    align-items: flex-start; }
  .lg-align-spacearound-end {
    justify-content: space-around;
    align-items: flex-end; }
  .lg-align-spacearound-center {
    justify-content: space-around;
    align-items: center; }
  .lg-align-spacearound-stretch {
    justify-content: space-around;
    align-items: stretch; }
  .lg-gap-1p {
    gap: 1px; }
  .lg-gap-2p {
    gap: 2px; }
  .lg-gap-3p {
    gap: 3px; }
  .lg-gap-4p {
    gap: 4px; }
  .lg-gap-5p {
    gap: 5px; }
  .lg-gap-6p {
    gap: 6px; }
  .lg-gap-7p {
    gap: 7px; }
  .lg-gap-8p {
    gap: 8px; }
  .lg-gap-9p {
    gap: 9px; }
  .lg-gap-10p {
    gap: 10px; }
  .lg-gap-11p {
    gap: 11px; }
  .lg-gap-12p {
    gap: 12px; }
  .lg-gap-13p {
    gap: 13px; }
  .lg-gap-14p {
    gap: 14px; }
  .lg-gap-15p {
    gap: 15px; }
  .lg-gap-16p {
    gap: 16px; }
  .lg-gap-17p {
    gap: 17px; }
  .lg-gap-18p {
    gap: 18px; }
  .lg-gap-19p {
    gap: 19px; }
  .lg-gap-20p {
    gap: 20px; }
  .lg-column-gap-1p {
    -webkit-column-gap: 1px;
            column-gap: 1px; }
  .lg-column-gap-2p {
    -webkit-column-gap: 2px;
            column-gap: 2px; }
  .lg-column-gap-3p {
    -webkit-column-gap: 3px;
            column-gap: 3px; }
  .lg-column-gap-4p {
    -webkit-column-gap: 4px;
            column-gap: 4px; }
  .lg-column-gap-5p {
    -webkit-column-gap: 5px;
            column-gap: 5px; }
  .lg-column-gap-6p {
    -webkit-column-gap: 6px;
            column-gap: 6px; }
  .lg-column-gap-7p {
    -webkit-column-gap: 7px;
            column-gap: 7px; }
  .lg-column-gap-8p {
    -webkit-column-gap: 8px;
            column-gap: 8px; }
  .lg-column-gap-9p {
    -webkit-column-gap: 9px;
            column-gap: 9px; }
  .lg-column-gap-10p {
    -webkit-column-gap: 10px;
            column-gap: 10px; }
  .lg-column-gap-11p {
    -webkit-column-gap: 11px;
            column-gap: 11px; }
  .lg-column-gap-12p {
    -webkit-column-gap: 12px;
            column-gap: 12px; }
  .lg-column-gap-13p {
    -webkit-column-gap: 13px;
            column-gap: 13px; }
  .lg-column-gap-14p {
    -webkit-column-gap: 14px;
            column-gap: 14px; }
  .lg-column-gap-15p {
    -webkit-column-gap: 15px;
            column-gap: 15px; }
  .lg-column-gap-16p {
    -webkit-column-gap: 16px;
            column-gap: 16px; }
  .lg-column-gap-17p {
    -webkit-column-gap: 17px;
            column-gap: 17px; }
  .lg-column-gap-18p {
    -webkit-column-gap: 18px;
            column-gap: 18px; }
  .lg-column-gap-19p {
    -webkit-column-gap: 19px;
            column-gap: 19px; }
  .lg-column-gap-20p {
    -webkit-column-gap: 20px;
            column-gap: 20px; }
  .lg-row-gap-1p {
    row-gap: 1px; }
  .lg-row-gap-2p {
    row-gap: 2px; }
  .lg-row-gap-3p {
    row-gap: 3px; }
  .lg-row-gap-4p {
    row-gap: 4px; }
  .lg-row-gap-5p {
    row-gap: 5px; }
  .lg-row-gap-6p {
    row-gap: 6px; }
  .lg-row-gap-7p {
    row-gap: 7px; }
  .lg-row-gap-8p {
    row-gap: 8px; }
  .lg-row-gap-9p {
    row-gap: 9px; }
  .lg-row-gap-10p {
    row-gap: 10px; }
  .lg-row-gap-11p {
    row-gap: 11px; }
  .lg-row-gap-12p {
    row-gap: 12px; }
  .lg-row-gap-13p {
    row-gap: 13px; }
  .lg-row-gap-14p {
    row-gap: 14px; }
  .lg-row-gap-15p {
    row-gap: 15px; }
  .lg-row-gap-16p {
    row-gap: 16px; }
  .lg-row-gap-17p {
    row-gap: 17px; }
  .lg-row-gap-18p {
    row-gap: 18px; }
  .lg-row-gap-19p {
    row-gap: 19px; }
  .lg-row-gap-20p {
    row-gap: 20px; }
  .lg-layout-column {
    flex-direction: column; }
  .lg-layout-columnreverse {
    flex-direction: column-reverse; }
  .lg-layout-row {
    flex-direction: row; }
  .lg-layout-rowreverse {
    flex-direction: row-reverse; }
  .lg-layout-wrap {
    flex-wrap: wrap; }
  .lg-layout-nowrap {
    flex-wrap: nowrap; }
  .lg-flex-full {
    flex: 0 0 100%; }
  .lg-flex-grow {
    flex: 1 1 100%; }
  .lg-flex-initial {
    flex: 0 1 auto; }
  .lg-flex-auto {
    flex: 1 1 auto; }
  .lg-flex-none {
    flex: 0 0 auto; }
  .lg-layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; }
  .lg-dp-block {
    display: block; }
  .lg-dp-inlblock {
    display: inline-block; }
  .lg-dp-inl {
    display: inline; }
  .lg-dp-none {
    display: none; } }

.mg-auto, .sm-mg-auto {
  margin: auto; }

.mgv-auto, .sm-mgv-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mgh-auto, .sm-mgh-auto {
  margin-right: auto;
  margin-left: auto; }

.mgt-auto, .sm-mgt-auto {
  margin-top: auto; }

.mgr-auto, .sm-mgr-auto {
  margin-right: auto; }

.mgb-auto, .sm-mgb-auto {
  margin-bottom: auto; }

.mgl-auto, .sm-mgl-auto {
  margin-left: auto; }

.mg-6, .sm-mg-6 {
  margin: 12rem; }

.mgv-6, .sm-mgv-6 {
  margin-top: 12rem;
  margin-bottom: 12rem; }

.mgh-6, .sm-mgh-6 {
  margin-right: 12rem;
  margin-left: 12rem; }

.mgt-6, .sm-mgt-6 {
  margin-top: 12rem; }

.mgr-6, .sm-mgr-6 {
  margin-right: 12rem; }

.mgb-6, .sm-mgb-6 {
  margin-bottom: 12rem; }

.mgl-6, .sm-mgl-6 {
  margin-left: 12rem; }

.pg-6, .sm-pg-6 {
  padding: 12rem; }

.pgv-6, .sm-pgv-6 {
  padding-top: 12rem;
  padding-bottom: 12rem; }

.pgh-6, .sm-pgh-6 {
  padding-right: 12rem !important;
  padding-left: 12rem !important; }

.pgt-6, .sm-pgt-6 {
  padding-top: 12rem; }

.pgr-6, .sm-pgr-6 {
  padding-right: 12rem; }

.pgb-6, .sm-pgb-6 {
  padding-bottom: 12rem; }

.pgl-6, .sm-pgl-6 {
  padding-left: 12rem; }

.mg-5_5, .sm-mg-5_5 {
  margin: 11rem; }

.mgv-5_5, .sm-mgv-5_5 {
  margin-top: 11rem;
  margin-bottom: 11rem; }

.mgh-5_5, .sm-mgh-5_5 {
  margin-right: 11rem;
  margin-left: 11rem; }

.mgt-5_5, .sm-mgt-5_5 {
  margin-top: 11rem; }

.mgr-5_5, .sm-mgr-5_5 {
  margin-right: 11rem; }

.mgb-5_5, .sm-mgb-5_5 {
  margin-bottom: 11rem; }

.mgl-5_5, .sm-mgl-5_5 {
  margin-left: 11rem; }

.pg-5_5, .sm-pg-5_5 {
  padding: 11rem; }

.pgv-5_5, .sm-pgv-5_5 {
  padding-top: 11rem;
  padding-bottom: 11rem; }

.pgh-5_5, .sm-pgh-5_5 {
  padding-right: 11rem !important;
  padding-left: 11rem !important; }

.pgt-5_5, .sm-pgt-5_5 {
  padding-top: 11rem; }

.pgr-5_5, .sm-pgr-5_5 {
  padding-right: 11rem; }

.pgb-5_5, .sm-pgb-5_5 {
  padding-bottom: 11rem; }

.pgl-5_5, .sm-pgl-5_5 {
  padding-left: 11rem; }

.mg-5, .sm-mg-5 {
  margin: 10rem; }

.mgv-5, .sm-mgv-5 {
  margin-top: 10rem;
  margin-bottom: 10rem; }

.mgh-5, .sm-mgh-5 {
  margin-right: 10rem;
  margin-left: 10rem; }

.mgt-5, .sm-mgt-5 {
  margin-top: 10rem; }

.mgr-5, .sm-mgr-5 {
  margin-right: 10rem; }

.mgb-5, .sm-mgb-5 {
  margin-bottom: 10rem; }

.mgl-5, .sm-mgl-5 {
  margin-left: 10rem; }

.pg-5, .sm-pg-5 {
  padding: 10rem; }

.pgv-5, .sm-pgv-5 {
  padding-top: 10rem;
  padding-bottom: 10rem; }

.pgh-5, .sm-pgh-5 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.pgt-5, .sm-pgt-5 {
  padding-top: 10rem; }

.pgr-5, .sm-pgr-5 {
  padding-right: 10rem; }

.pgb-5, .sm-pgb-5 {
  padding-bottom: 10rem; }

.pgl-5, .sm-pgl-5 {
  padding-left: 10rem; }

.mg-4, .sm-mg-4 {
  margin: 8rem; }

.mgv-4, .sm-mgv-4 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.mgh-4, .sm-mgh-4 {
  margin-right: 8rem;
  margin-left: 8rem; }

.mgt-4, .sm-mgt-4 {
  margin-top: 8rem; }

.mgr-4, .sm-mgr-4 {
  margin-right: 8rem; }

.mgb-4, .sm-mgb-4 {
  margin-bottom: 8rem; }

.mgl-4, .sm-mgl-4 {
  margin-left: 8rem; }

.pg-4, .sm-pg-4 {
  padding: 8rem; }

.pgv-4, .sm-pgv-4 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.pgh-4, .sm-pgh-4 {
  padding-right: 8rem !important;
  padding-left: 8rem !important; }

.pgt-4, .sm-pgt-4 {
  padding-top: 8rem; }

.pgr-4, .sm-pgr-4 {
  padding-right: 8rem; }

.pgb-4, .sm-pgb-4 {
  padding-bottom: 8rem; }

.pgl-4, .sm-pgl-4 {
  padding-left: 8rem; }

.mg-3, .sm-mg-3 {
  margin: 6rem; }

.mgv-3, .sm-mgv-3 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.mgh-3, .sm-mgh-3 {
  margin-right: 6rem;
  margin-left: 6rem; }

.mgt-3, .sm-mgt-3 {
  margin-top: 6rem; }

.mgr-3, .sm-mgr-3 {
  margin-right: 6rem; }

.mgb-3, .sm-mgb-3 {
  margin-bottom: 6rem; }

.mgl-3, .sm-mgl-3 {
  margin-left: 6rem; }

.pg-3, .sm-pg-3 {
  padding: 6rem; }

.pgv-3, .sm-pgv-3 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.pgh-3, .sm-pgh-3 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.pgt-3, .sm-pgt-3 {
  padding-top: 6rem; }

.pgr-3, .sm-pgr-3 {
  padding-right: 6rem; }

.pgb-3, .sm-pgb-3 {
  padding-bottom: 6rem; }

.pgl-3, .sm-pgl-3 {
  padding-left: 6rem; }

.mg-2_5, .sm-mg-2_5 {
  margin: 5rem; }

.mgv-2_5, .sm-mgv-2_5 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.mgh-2_5, .sm-mgh-2_5 {
  margin-right: 5rem;
  margin-left: 5rem; }

.mgt-2_5, .sm-mgt-2_5 {
  margin-top: 5rem; }

.mgr-2_5, .sm-mgr-2_5 {
  margin-right: 5rem; }

.mgb-2_5, .sm-mgb-2_5 {
  margin-bottom: 5rem; }

.mgl-2_5, .sm-mgl-2_5 {
  margin-left: 5rem; }

.pg-2_5, .sm-pg-2_5 {
  padding: 5rem; }

.pgv-2_5, .sm-pgv-2_5 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.pgh-2_5, .sm-pgh-2_5 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.pgt-2_5, .sm-pgt-2_5 {
  padding-top: 5rem; }

.pgr-2_5, .sm-pgr-2_5 {
  padding-right: 5rem; }

.pgb-2_5, .sm-pgb-2_5 {
  padding-bottom: 5rem; }

.pgl-2_5, .sm-pgl-2_5 {
  padding-left: 5rem; }

.mg-2, .sm-mg-2 {
  margin: 4rem; }

.mgv-2, .sm-mgv-2 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mgh-2, .sm-mgh-2 {
  margin-right: 4rem;
  margin-left: 4rem; }

.mgt-2, .sm-mgt-2 {
  margin-top: 4rem; }

.mgr-2, .sm-mgr-2 {
  margin-right: 4rem; }

.mgb-2, .sm-mgb-2 {
  margin-bottom: 4rem; }

.mgl-2, .sm-mgl-2 {
  margin-left: 4rem; }

.pg-2, .sm-pg-2 {
  padding: 4rem; }

.pgv-2, .sm-pgv-2 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.pgh-2, .sm-pgh-2 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.pgt-2, .sm-pgt-2 {
  padding-top: 4rem; }

.pgr-2, .sm-pgr-2 {
  padding-right: 4rem; }

.pgb-2, .sm-pgb-2 {
  padding-bottom: 4rem; }

.pgl-2, .sm-pgl-2 {
  padding-left: 4rem; }

.mg-1_75, .sm-mg-1_75 {
  margin: 3.5rem; }

.mgv-1_75, .sm-mgv-1_75 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.mgh-1_75, .sm-mgh-1_75 {
  margin-right: 3.5rem;
  margin-left: 3.5rem; }

.mgt-1_75, .sm-mgt-1_75 {
  margin-top: 3.5rem; }

.mgr-1_75, .sm-mgr-1_75 {
  margin-right: 3.5rem; }

.mgb-1_75, .sm-mgb-1_75 {
  margin-bottom: 3.5rem; }

.mgl-1_75, .sm-mgl-1_75 {
  margin-left: 3.5rem; }

.pg-1_75, .sm-pg-1_75 {
  padding: 3.5rem; }

.pgv-1_75, .sm-pgv-1_75 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.pgh-1_75, .sm-pgh-1_75 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important; }

.pgt-1_75, .sm-pgt-1_75 {
  padding-top: 3.5rem; }

.pgr-1_75, .sm-pgr-1_75 {
  padding-right: 3.5rem; }

.pgb-1_75, .sm-pgb-1_75 {
  padding-bottom: 3.5rem; }

.pgl-1_75, .sm-pgl-1_75 {
  padding-left: 3.5rem; }

.mg-1_5, .sm-mg-1_5 {
  margin: 3rem; }

.mgv-1_5, .sm-mgv-1_5 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.mgh-1_5, .sm-mgh-1_5 {
  margin-right: 3rem;
  margin-left: 3rem; }

.mgt-1_5, .sm-mgt-1_5 {
  margin-top: 3rem; }

.mgr-1_5, .sm-mgr-1_5 {
  margin-right: 3rem; }

.mgb-1_5, .sm-mgb-1_5 {
  margin-bottom: 3rem; }

.mgl-1_5, .sm-mgl-1_5 {
  margin-left: 3rem; }

.pg-1_5, .sm-pg-1_5 {
  padding: 3rem; }

.pgv-1_5, .sm-pgv-1_5 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.pgh-1_5, .sm-pgh-1_5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.pgt-1_5, .sm-pgt-1_5 {
  padding-top: 3rem; }

.pgr-1_5, .sm-pgr-1_5 {
  padding-right: 3rem; }

.pgb-1_5, .sm-pgb-1_5 {
  padding-bottom: 3rem; }

.pgl-1_5, .sm-pgl-1_5 {
  padding-left: 3rem; }

.mg-1_25, .sm-mg-1_25 {
  margin: 2.5rem; }

.mgv-1_25, .sm-mgv-1_25 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.mgh-1_25, .sm-mgh-1_25 {
  margin-right: 2.5rem;
  margin-left: 2.5rem; }

.mgt-1_25, .sm-mgt-1_25 {
  margin-top: 2.5rem; }

.mgr-1_25, .sm-mgr-1_25 {
  margin-right: 2.5rem; }

.mgb-1_25, .sm-mgb-1_25 {
  margin-bottom: 2.5rem; }

.mgl-1_25, .sm-mgl-1_25 {
  margin-left: 2.5rem; }

.pg-1_25, .sm-pg-1_25 {
  padding: 2.5rem; }

.pgv-1_25, .sm-pgv-1_25 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.pgh-1_25, .sm-pgh-1_25 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.pgt-1_25, .sm-pgt-1_25 {
  padding-top: 2.5rem; }

.pgr-1_25, .sm-pgr-1_25 {
  padding-right: 2.5rem; }

.pgb-1_25, .sm-pgb-1_25 {
  padding-bottom: 2.5rem; }

.pgl-1_25, .sm-pgl-1_25 {
  padding-left: 2.5rem; }

.mg-1, .sm-mg-1 {
  margin: 2rem; }

.mgv-1, .sm-mgv-1 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mgh-1, .sm-mgh-1 {
  margin-right: 2rem;
  margin-left: 2rem; }

.mgt-1, .sm-mgt-1 {
  margin-top: 2rem; }

.mgr-1, .sm-mgr-1 {
  margin-right: 2rem; }

.mgb-1, .sm-mgb-1 {
  margin-bottom: 2rem; }

.mgl-1, .sm-mgl-1 {
  margin-left: 2rem; }

.pg-1, .sm-pg-1 {
  padding: 2rem; }

.pgv-1, .sm-pgv-1 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.pgh-1, .sm-pgh-1 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.pgt-1, .sm-pgt-1 {
  padding-top: 2rem; }

.pgr-1, .sm-pgr-1 {
  padding-right: 2rem; }

.pgb-1, .sm-pgb-1 {
  padding-bottom: 2rem; }

.pgl-1, .sm-pgl-1 {
  padding-left: 2rem; }

.mg-0_75, .sm-mg-0_75 {
  margin: 1.5rem; }

.mgv-0_75, .sm-mgv-0_75 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.mgh-0_75, .sm-mgh-0_75 {
  margin-right: 1.5rem;
  margin-left: 1.5rem; }

.mgt-0_75, .sm-mgt-0_75 {
  margin-top: 1.5rem; }

.mgr-0_75, .sm-mgr-0_75 {
  margin-right: 1.5rem; }

.mgb-0_75, .sm-mgb-0_75 {
  margin-bottom: 1.5rem; }

.mgl-0_75, .sm-mgl-0_75 {
  margin-left: 1.5rem; }

.pg-0_75, .sm-pg-0_75 {
  padding: 1.5rem; }

.pgv-0_75, .sm-pgv-0_75 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.pgh-0_75, .sm-pgh-0_75 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.pgt-0_75, .sm-pgt-0_75 {
  padding-top: 1.5rem; }

.pgr-0_75, .sm-pgr-0_75 {
  padding-right: 1.5rem; }

.pgb-0_75, .sm-pgb-0_75 {
  padding-bottom: 1.5rem; }

.pgl-0_75, .sm-pgl-0_75 {
  padding-left: 1.5rem; }

.mg-0_5, .sm-mg-0_5 {
  margin: 1rem; }

.mgv-0_5, .sm-mgv-0_5 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mgh-0_5, .sm-mgh-0_5 {
  margin-right: 1rem;
  margin-left: 1rem; }

.mgt-0_5, .sm-mgt-0_5 {
  margin-top: 1rem; }

.mgr-0_5, .sm-mgr-0_5 {
  margin-right: 1rem; }

.mgb-0_5, .sm-mgb-0_5 {
  margin-bottom: 1rem; }

.mgl-0_5, .sm-mgl-0_5 {
  margin-left: 1rem; }

.pg-0_5, .sm-pg-0_5 {
  padding: 1rem; }

.pgv-0_5, .sm-pgv-0_5 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.pgh-0_5, .sm-pgh-0_5 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.pgt-0_5, .sm-pgt-0_5 {
  padding-top: 1rem; }

.pgr-0_5, .sm-pgr-0_5 {
  padding-right: 1rem; }

.pgb-0_5, .sm-pgb-0_5 {
  padding-bottom: 1rem; }

.pgl-0_5, .sm-pgl-0_5 {
  padding-left: 1rem; }

.mg-0_25, .sm-mg-0_25 {
  margin: 0.5rem; }

.mgv-0_25, .sm-mgv-0_25 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.mgh-0_25, .sm-mgh-0_25 {
  margin-right: 0.5rem;
  margin-left: 0.5rem; }

.mgt-0_25, .sm-mgt-0_25 {
  margin-top: 0.5rem; }

.mgr-0_25, .sm-mgr-0_25 {
  margin-right: 0.5rem; }

.mgb-0_25, .sm-mgb-0_25 {
  margin-bottom: 0.5rem; }

.mgl-0_25, .sm-mgl-0_25 {
  margin-left: 0.5rem; }

.pg-0_25, .sm-pg-0_25 {
  padding: 0.5rem; }

.pgv-0_25, .sm-pgv-0_25 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.pgh-0_25, .sm-pgh-0_25 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.pgt-0_25, .sm-pgt-0_25 {
  padding-top: 0.5rem; }

.pgr-0_25, .sm-pgr-0_25 {
  padding-right: 0.5rem; }

.pgb-0_25, .sm-pgb-0_25 {
  padding-bottom: 0.5rem; }

.pgl-0_25, .sm-pgl-0_25 {
  padding-left: 0.5rem; }

.mg-0, .sm-mg-0 {
  margin: 0rem; }

.mgv-0, .sm-mgv-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.mgh-0, .sm-mgh-0 {
  margin-right: 0rem;
  margin-left: 0rem; }

.mgt-0, .sm-mgt-0 {
  margin-top: 0rem; }

.mgr-0, .sm-mgr-0 {
  margin-right: 0rem; }

.mgb-0, .sm-mgb-0 {
  margin-bottom: 0rem; }

.mgl-0, .sm-mgl-0 {
  margin-left: 0rem; }

.pg-0, .sm-pg-0 {
  padding: 0rem; }

.pgv-0, .sm-pgv-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.pgh-0, .sm-pgh-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.pgt-0, .sm-pgt-0 {
  padding-top: 0rem; }

.pgr-0, .sm-pgr-0 {
  padding-right: 0rem; }

.pgb-0, .sm-pgb-0 {
  padding-bottom: 0rem; }

.pgl-0, .sm-pgl-0 {
  padding-left: 0rem; }

.text-left, .sm-text-left {
  text-align: left; }

.text-center, .sm-text-center {
  text-align: center; }

.text-right, .sm-text-right {
  text-align: right; }

.break-word, .sm-break-word {
  word-wrap: break-word; }

.word-normal, .sm-word-normal {
  word-wrap: normal; }

.lh-1_425, .sm-lh-1_425 {
  line-height: 1.425; }

.lh-1_2, .sm-lh-1_2 {
  line-height: 1.2; }

.lh-1_1, .sm-lh-1_1 {
  line-height: 1.1; }

.lh-1, .sm-lh-1 {
  line-height: 1; }

.lh-0_75, .sm-lh-0_75 {
  line-height: 0.75; }

.fz-100, .sm-fz-100 {
  font-size: 10rem; }

.fz-36, .sm-fz-36 {
  font-size: 3.6rem; }

.fz-34, .sm-fz-34 {
  font-size: 3.4rem; }

.fz-32, .sm-fz-32 {
  font-size: 3.2rem; }

.fz-28, .sm-fz-28 {
  font-size: 2.8rem; }

.fz-26, .sm-fz-26 {
  font-size: 2.6rem; }

.fz-24, .sm-fz-24 {
  font-size: 2.4rem; }

.fz-22, .sm-fz-22 {
  font-size: 2.2rem; }

.fz-21, .sm-fz-21 {
  font-size: 2.1rem; }

.fz-18, .sm-fz-18 {
  font-size: 1.8rem; }

.fz-16, .sm-fz-16 {
  font-size: 1.6rem; }

.fz-14, .sm-fz-14 {
  font-size: 1.4rem; }

.fz-13, .sm-fz-13 {
  font-size: 1.3rem; }

.fz-12, .sm-fz-12 {
  font-size: 1.2rem; }

.fz-11, .sm-fz-11 {
  font-size: 1.1rem; }

.fz-10, .sm-fz-10 {
  font-size: 1rem; }

.fw-700, .sm-fw-700 {
  font-weight: 700; }

.fw-600, .sm-fw-600 {
  font-weight: 600; }

.fw-400, .sm-fw-400 {
  font-weight: 400; }

.fw-300, .sm-fw-300 {
  font-weight: 300; }

.text-gray-lightV2[class], .sm-text-gray-lightV2[class] {
  color: #F4F6F9; }

.h-text-gray-lightV2[class]:hover, .sm-h-text-gray-lightV2[class]:hover {
  color: #F4F6F9; }

.bg-gray-lightV2, .sm-bg-gray-lightV2 {
  background-color: #F4F6F9; }

.h-bg-gray-lightV2:hover, .sm-h-bg-gray-lightV2:hover {
  background-color: #F4F6F9; }

.bg-gray-lightV2-muted, .sm-bg-gray-lightV2-muted {
  background-color: rgba(244, 246, 249, 0.2); }

.text-black[class], .sm-text-black[class] {
  color: #000000; }

.h-text-black[class]:hover, .sm-h-text-black[class]:hover {
  color: #000000; }

.bg-black, .sm-bg-black {
  background-color: #000000; }

.h-bg-black:hover, .sm-h-bg-black:hover {
  background-color: #000000; }

.bg-black-muted, .sm-bg-black-muted {
  background-color: rgba(0, 0, 0, 0.2); }

.text-white[class], .sm-text-white[class] {
  color: #ffffff; }

.h-text-white[class]:hover, .sm-h-text-white[class]:hover {
  color: #ffffff; }

.bg-white, .sm-bg-white {
  background-color: #ffffff; }

.h-bg-white:hover, .sm-h-bg-white:hover {
  background-color: #ffffff; }

.bg-white-muted, .sm-bg-white-muted {
  background-color: rgba(255, 255, 255, 0.2); }

.text-gray-lighter[class], .sm-text-gray-lighter[class] {
  color: #e8ebed; }

.h-text-gray-lighter[class]:hover, .sm-h-text-gray-lighter[class]:hover {
  color: #e8ebed; }

.bg-gray-lighter, .sm-bg-gray-lighter {
  background-color: #e8ebed; }

.h-bg-gray-lighter:hover, .sm-h-bg-gray-lighter:hover {
  background-color: #e8ebed; }

.bg-gray-lighter-muted, .sm-bg-gray-lighter-muted {
  background-color: rgba(232, 235, 237, 0.2); }

.text-gray-light[class], .sm-text-gray-light[class] {
  color: #D9D9D9; }

.h-text-gray-light[class]:hover, .sm-h-text-gray-light[class]:hover {
  color: #D9D9D9; }

.bg-gray-light, .sm-bg-gray-light {
  background-color: #D9D9D9; }

.h-bg-gray-light:hover, .sm-h-bg-gray-light:hover {
  background-color: #D9D9D9; }

.bg-gray-light-muted, .sm-bg-gray-light-muted {
  background-color: rgba(217, 217, 217, 0.2); }

.text-gray[class], .sm-text-gray[class] {
  color: #B3B3B3; }

.h-text-gray[class]:hover, .sm-h-text-gray[class]:hover {
  color: #B3B3B3; }

.bg-gray, .sm-bg-gray {
  background-color: #B3B3B3; }

.h-bg-gray:hover, .sm-h-bg-gray:hover {
  background-color: #B3B3B3; }

.bg-gray-muted, .sm-bg-gray-muted {
  background-color: rgba(179, 179, 179, 0.2); }

.text-gray-dark[class], .sm-text-gray-dark[class] {
  color: #808080; }

.h-text-gray-dark[class]:hover, .sm-h-text-gray-dark[class]:hover {
  color: #808080; }

.bg-gray-dark, .sm-bg-gray-dark {
  background-color: #808080; }

.h-bg-gray-dark:hover, .sm-h-bg-gray-dark:hover {
  background-color: #808080; }

.bg-gray-dark-muted, .sm-bg-gray-dark-muted {
  background-color: rgba(128, 128, 128, 0.2); }

.text-gray-darker[class], .sm-text-gray-darker[class] {
  color: #4A4A4A; }

.h-text-gray-darker[class]:hover, .sm-h-text-gray-darker[class]:hover {
  color: #4A4A4A; }

.bg-gray-darker, .sm-bg-gray-darker {
  background-color: #4A4A4A; }

.h-bg-gray-darker:hover, .sm-h-bg-gray-darker:hover {
  background-color: #4A4A4A; }

.bg-gray-darker-muted, .sm-bg-gray-darker-muted {
  background-color: rgba(74, 74, 74, 0.2); }

.text-alert[class], .sm-text-alert[class] {
  color: #EE1233; }

.h-text-alert[class]:hover, .sm-h-text-alert[class]:hover {
  color: #EE1233; }

.bg-alert, .sm-bg-alert {
  background-color: #EE1233; }

.h-bg-alert:hover, .sm-h-bg-alert:hover {
  background-color: #EE1233; }

.bg-alert-muted, .sm-bg-alert-muted {
  background-color: rgba(238, 18, 51, 0.2); }

.text-alert-light[class], .sm-text-alert-light[class] {
  color: #FFEBEB; }

.h-text-alert-light[class]:hover, .sm-h-text-alert-light[class]:hover {
  color: #FFEBEB; }

.bg-alert-light, .sm-bg-alert-light {
  background-color: #FFEBEB; }

.h-bg-alert-light:hover, .sm-h-bg-alert-light:hover {
  background-color: #FFEBEB; }

.bg-alert-light-muted, .sm-bg-alert-light-muted {
  background-color: rgba(255, 235, 235, 0.2); }

.text-success[class], .sm-text-success[class] {
  color: #40C057; }

.h-text-success[class]:hover, .sm-h-text-success[class]:hover {
  color: #40C057; }

.bg-success, .sm-bg-success {
  background-color: #40C057; }

.h-bg-success:hover, .sm-h-bg-success:hover {
  background-color: #40C057; }

.bg-success-muted, .sm-bg-success-muted {
  background-color: rgba(64, 192, 87, 0.2); }

.text-success-light[class], .sm-text-success-light[class] {
  color: #E6FFEA; }

.h-text-success-light[class]:hover, .sm-h-text-success-light[class]:hover {
  color: #E6FFEA; }

.bg-success-light, .sm-bg-success-light {
  background-color: #E6FFEA; }

.h-bg-success-light:hover, .sm-h-bg-success-light:hover {
  background-color: #E6FFEA; }

.bg-success-light-muted, .sm-bg-success-light-muted {
  background-color: rgba(230, 255, 234, 0.2); }

.text-info[class], .sm-text-info[class] {
  color: #4dadf7; }

.h-text-info[class]:hover, .sm-h-text-info[class]:hover {
  color: #4dadf7; }

.bg-info, .sm-bg-info {
  background-color: #4dadf7; }

.h-bg-info:hover, .sm-h-bg-info:hover {
  background-color: #4dadf7; }

.bg-info-muted, .sm-bg-info-muted {
  background-color: rgba(77, 173, 247, 0.2); }

.text-info-light[class], .sm-text-info-light[class] {
  color: #d0eaff; }

.h-text-info-light[class]:hover, .sm-h-text-info-light[class]:hover {
  color: #d0eaff; }

.bg-info-light, .sm-bg-info-light {
  background-color: #d0eaff; }

.h-bg-info-light:hover, .sm-h-bg-info-light:hover {
  background-color: #d0eaff; }

.bg-info-light-muted, .sm-bg-info-light-muted {
  background-color: rgba(208, 234, 255, 0.2); }

.text-orange[class], .sm-text-orange[class] {
  color: #E68F17; }

.h-text-orange[class]:hover, .sm-h-text-orange[class]:hover {
  color: #E68F17; }

.bg-orange, .sm-bg-orange {
  background-color: #E68F17; }

.h-bg-orange:hover, .sm-h-bg-orange:hover {
  background-color: #E68F17; }

.bg-orange-muted, .sm-bg-orange-muted {
  background-color: rgba(230, 143, 23, 0.2); }

.text-yellow[class], .sm-text-yellow[class] {
  color: #F5C644; }

.h-text-yellow[class]:hover, .sm-h-text-yellow[class]:hover {
  color: #F5C644; }

.bg-yellow, .sm-bg-yellow {
  background-color: #F5C644; }

.h-bg-yellow:hover, .sm-h-bg-yellow:hover {
  background-color: #F5C644; }

.bg-yellow-muted, .sm-bg-yellow-muted {
  background-color: rgba(245, 198, 68, 0.2); }

.text-coral[class], .sm-text-coral[class] {
  color: #FA5252; }

.h-text-coral[class]:hover, .sm-h-text-coral[class]:hover {
  color: #FA5252; }

.bg-coral, .sm-bg-coral {
  background-color: #FA5252; }

.h-bg-coral:hover, .sm-h-bg-coral:hover {
  background-color: #FA5252; }

.bg-coral-muted, .sm-bg-coral-muted {
  background-color: rgba(250, 82, 82, 0.2); }

.text-pink[class], .sm-text-pink[class] {
  color: #E64980; }

.h-text-pink[class]:hover, .sm-h-text-pink[class]:hover {
  color: #E64980; }

.bg-pink, .sm-bg-pink {
  background-color: #E64980; }

.h-bg-pink:hover, .sm-h-bg-pink:hover {
  background-color: #E64980; }

.bg-pink-muted, .sm-bg-pink-muted {
  background-color: rgba(230, 73, 128, 0.2); }

.text-purple[class], .sm-text-purple[class] {
  color: #BE4BDB; }

.h-text-purple[class]:hover, .sm-h-text-purple[class]:hover {
  color: #BE4BDB; }

.bg-purple, .sm-bg-purple {
  background-color: #BE4BDB; }

.h-bg-purple:hover, .sm-h-bg-purple:hover {
  background-color: #BE4BDB; }

.bg-purple-muted, .sm-bg-purple-muted {
  background-color: rgba(190, 75, 219, 0.2); }

.text-teal[class], .sm-text-teal[class] {
  color: #0B7285; }

.h-text-teal[class]:hover, .sm-h-text-teal[class]:hover {
  color: #0B7285; }

.bg-teal, .sm-bg-teal {
  background-color: #0B7285; }

.h-bg-teal:hover, .sm-h-bg-teal:hover {
  background-color: #0B7285; }

.bg-teal-muted, .sm-bg-teal-muted {
  background-color: rgba(11, 114, 133, 0.2); }

.text-turquoise[class], .sm-text-turquoise[class] {
  color: #15AABF; }

.h-text-turquoise[class]:hover, .sm-h-text-turquoise[class]:hover {
  color: #15AABF; }

.bg-turquoise, .sm-bg-turquoise {
  background-color: #15AABF; }

.h-bg-turquoise:hover, .sm-h-bg-turquoise:hover {
  background-color: #15AABF; }

.bg-turquoise-muted, .sm-bg-turquoise-muted {
  background-color: rgba(21, 170, 191, 0.2); }

.text-teal-dark[class], .sm-text-teal-dark[class] {
  color: #0F354D; }

.h-text-teal-dark[class]:hover, .sm-h-text-teal-dark[class]:hover {
  color: #0F354D; }

.bg-teal-dark, .sm-bg-teal-dark {
  background-color: #0F354D; }

.h-bg-teal-dark:hover, .sm-h-bg-teal-dark:hover {
  background-color: #0F354D; }

.bg-teal-dark-muted, .sm-bg-teal-dark-muted {
  background-color: rgba(15, 53, 77, 0.2); }

.text-purpleV2[class], .sm-text-purpleV2[class] {
  color: #6D5FFD; }

.h-text-purpleV2[class]:hover, .sm-h-text-purpleV2[class]:hover {
  color: #6D5FFD; }

.bg-purpleV2, .sm-bg-purpleV2 {
  background-color: #6D5FFD; }

.h-bg-purpleV2:hover, .sm-h-bg-purpleV2:hover {
  background-color: #6D5FFD; }

.bg-purpleV2-muted, .sm-bg-purpleV2-muted {
  background-color: rgba(109, 95, 253, 0.2); }

.text-orangeV2[class], .sm-text-orangeV2[class] {
  color: #FAB005; }

.h-text-orangeV2[class]:hover, .sm-h-text-orangeV2[class]:hover {
  color: #FAB005; }

.bg-orangeV2, .sm-bg-orangeV2 {
  background-color: #FAB005; }

.h-bg-orangeV2:hover, .sm-h-bg-orangeV2:hover {
  background-color: #FAB005; }

.bg-orangeV2-muted, .sm-bg-orangeV2-muted {
  background-color: rgba(250, 176, 5, 0.2); }

.text-redV2[class], .sm-text-redV2[class] {
  color: #FF1843; }

.h-text-redV2[class]:hover, .sm-h-text-redV2[class]:hover {
  color: #FF1843; }

.bg-redV2, .sm-bg-redV2 {
  background-color: #FF1843; }

.h-bg-redV2:hover, .sm-h-bg-redV2:hover {
  background-color: #FF1843; }

.bg-redV2-muted, .sm-bg-redV2-muted {
  background-color: rgba(255, 24, 67, 0.2); }

.text-yellowV2[class], .sm-text-yellowV2[class] {
  color: rgba(250, 176, 5, 0.6); }

.h-text-yellowV2[class]:hover, .sm-h-text-yellowV2[class]:hover {
  color: rgba(250, 176, 5, 0.6); }

.bg-yellowV2, .sm-bg-yellowV2 {
  background-color: rgba(250, 176, 5, 0.6); }

.h-bg-yellowV2:hover, .sm-h-bg-yellowV2:hover {
  background-color: rgba(250, 176, 5, 0.6); }

.bg-yellowV2-muted, .sm-bg-yellowV2-muted {
  background-color: rgba(250, 176, 5, 0.2); }

.text-primary-light[class], .sm-text-primary-light[class] {
  color: #6D6F7C; }

.h-text-primary-light[class]:hover, .sm-h-text-primary-light[class]:hover {
  color: #6D6F7C; }

.bg-primary-light, .sm-bg-primary-light {
  background-color: #6D6F7C; }

.h-bg-primary-light:hover, .sm-h-bg-primary-light:hover {
  background-color: #6D6F7C; }

.bg-primary-light-muted, .sm-bg-primary-light-muted {
  background-color: rgba(109, 111, 124, 0.2); }

.text-primary[class], .sm-text-primary[class] {
  color: #15192D; }

.h-text-primary[class]:hover, .sm-h-text-primary[class]:hover {
  color: #15192D; }

.bg-primary, .sm-bg-primary {
  background-color: #15192D; }

.h-bg-primary:hover, .sm-h-bg-primary:hover {
  background-color: #15192D; }

.bg-primary-muted, .sm-bg-primary-muted {
  background-color: rgba(21, 25, 45, 0.2); }

.text-primary-dark[class], .sm-text-primary-dark[class] {
  color: #080911; }

.h-text-primary-dark[class]:hover, .sm-h-text-primary-dark[class]:hover {
  color: #080911; }

.bg-primary-dark, .sm-bg-primary-dark {
  background-color: #080911; }

.h-bg-primary-dark:hover, .sm-h-bg-primary-dark:hover {
  background-color: #080911; }

.bg-primary-dark-muted, .sm-bg-primary-dark-muted {
  background-color: rgba(8, 9, 17, 0.2); }

.bd-0-gray-lightV2, .sm-bd-0-gray-lightV2 {
  border: 0px solid #F4F6F9; }

.h-bd-0-gray-lightV2:hover, .sm-h-bd-0-gray-lightV2:hover {
  border: 0px solid #F4F6F9; }

.bdt-0-gray-lightV2, .sm-bdt-0-gray-lightV2 {
  border-top: 0px solid #F4F6F9; }

.bdr-0-gray-lightV2, .sm-bdr-0-gray-lightV2 {
  border-right: 0px solid #F4F6F9; }

.bdb-0-gray-lightV2, .sm-bdb-0-gray-lightV2 {
  border-bottom: 0px solid #F4F6F9; }

.bdl-0-gray-lightV2, .sm-bdl-0-gray-lightV2 {
  border-left: 0px solid #F4F6F9; }

.bd-0-black, .sm-bd-0-black {
  border: 0px solid #000000; }

.h-bd-0-black:hover, .sm-h-bd-0-black:hover {
  border: 0px solid #000000; }

.bdt-0-black, .sm-bdt-0-black {
  border-top: 0px solid #000000; }

.bdr-0-black, .sm-bdr-0-black {
  border-right: 0px solid #000000; }

.bdb-0-black, .sm-bdb-0-black {
  border-bottom: 0px solid #000000; }

.bdl-0-black, .sm-bdl-0-black {
  border-left: 0px solid #000000; }

.bd-0-white, .sm-bd-0-white {
  border: 0px solid #ffffff; }

.h-bd-0-white:hover, .sm-h-bd-0-white:hover {
  border: 0px solid #ffffff; }

.bdt-0-white, .sm-bdt-0-white {
  border-top: 0px solid #ffffff; }

.bdr-0-white, .sm-bdr-0-white {
  border-right: 0px solid #ffffff; }

.bdb-0-white, .sm-bdb-0-white {
  border-bottom: 0px solid #ffffff; }

.bdl-0-white, .sm-bdl-0-white {
  border-left: 0px solid #ffffff; }

.bd-0-gray-lighter, .sm-bd-0-gray-lighter {
  border: 0px solid #e8ebed; }

.h-bd-0-gray-lighter:hover, .sm-h-bd-0-gray-lighter:hover {
  border: 0px solid #e8ebed; }

.bdt-0-gray-lighter, .sm-bdt-0-gray-lighter {
  border-top: 0px solid #e8ebed; }

.bdr-0-gray-lighter, .sm-bdr-0-gray-lighter {
  border-right: 0px solid #e8ebed; }

.bdb-0-gray-lighter, .sm-bdb-0-gray-lighter {
  border-bottom: 0px solid #e8ebed; }

.bdl-0-gray-lighter, .sm-bdl-0-gray-lighter {
  border-left: 0px solid #e8ebed; }

.bd-0-gray-light, .sm-bd-0-gray-light {
  border: 0px solid #D9D9D9; }

.h-bd-0-gray-light:hover, .sm-h-bd-0-gray-light:hover {
  border: 0px solid #D9D9D9; }

.bdt-0-gray-light, .sm-bdt-0-gray-light {
  border-top: 0px solid #D9D9D9; }

.bdr-0-gray-light, .sm-bdr-0-gray-light {
  border-right: 0px solid #D9D9D9; }

.bdb-0-gray-light, .sm-bdb-0-gray-light {
  border-bottom: 0px solid #D9D9D9; }

.bdl-0-gray-light, .sm-bdl-0-gray-light {
  border-left: 0px solid #D9D9D9; }

.bd-0-gray, .sm-bd-0-gray {
  border: 0px solid #B3B3B3; }

.h-bd-0-gray:hover, .sm-h-bd-0-gray:hover {
  border: 0px solid #B3B3B3; }

.bdt-0-gray, .sm-bdt-0-gray {
  border-top: 0px solid #B3B3B3; }

.bdr-0-gray, .sm-bdr-0-gray {
  border-right: 0px solid #B3B3B3; }

.bdb-0-gray, .sm-bdb-0-gray {
  border-bottom: 0px solid #B3B3B3; }

.bdl-0-gray, .sm-bdl-0-gray {
  border-left: 0px solid #B3B3B3; }

.bd-0-gray-dark, .sm-bd-0-gray-dark {
  border: 0px solid #808080; }

.h-bd-0-gray-dark:hover, .sm-h-bd-0-gray-dark:hover {
  border: 0px solid #808080; }

.bdt-0-gray-dark, .sm-bdt-0-gray-dark {
  border-top: 0px solid #808080; }

.bdr-0-gray-dark, .sm-bdr-0-gray-dark {
  border-right: 0px solid #808080; }

.bdb-0-gray-dark, .sm-bdb-0-gray-dark {
  border-bottom: 0px solid #808080; }

.bdl-0-gray-dark, .sm-bdl-0-gray-dark {
  border-left: 0px solid #808080; }

.bd-0-gray-darker, .sm-bd-0-gray-darker {
  border: 0px solid #4A4A4A; }

.h-bd-0-gray-darker:hover, .sm-h-bd-0-gray-darker:hover {
  border: 0px solid #4A4A4A; }

.bdt-0-gray-darker, .sm-bdt-0-gray-darker {
  border-top: 0px solid #4A4A4A; }

.bdr-0-gray-darker, .sm-bdr-0-gray-darker {
  border-right: 0px solid #4A4A4A; }

.bdb-0-gray-darker, .sm-bdb-0-gray-darker {
  border-bottom: 0px solid #4A4A4A; }

.bdl-0-gray-darker, .sm-bdl-0-gray-darker {
  border-left: 0px solid #4A4A4A; }

.bd-0-alert, .sm-bd-0-alert {
  border: 0px solid #EE1233; }

.h-bd-0-alert:hover, .sm-h-bd-0-alert:hover {
  border: 0px solid #EE1233; }

.bdt-0-alert, .sm-bdt-0-alert {
  border-top: 0px solid #EE1233; }

.bdr-0-alert, .sm-bdr-0-alert {
  border-right: 0px solid #EE1233; }

.bdb-0-alert, .sm-bdb-0-alert {
  border-bottom: 0px solid #EE1233; }

.bdl-0-alert, .sm-bdl-0-alert {
  border-left: 0px solid #EE1233; }

.bd-0-alert-light, .sm-bd-0-alert-light {
  border: 0px solid #FFEBEB; }

.h-bd-0-alert-light:hover, .sm-h-bd-0-alert-light:hover {
  border: 0px solid #FFEBEB; }

.bdt-0-alert-light, .sm-bdt-0-alert-light {
  border-top: 0px solid #FFEBEB; }

.bdr-0-alert-light, .sm-bdr-0-alert-light {
  border-right: 0px solid #FFEBEB; }

.bdb-0-alert-light, .sm-bdb-0-alert-light {
  border-bottom: 0px solid #FFEBEB; }

.bdl-0-alert-light, .sm-bdl-0-alert-light {
  border-left: 0px solid #FFEBEB; }

.bd-0-success, .sm-bd-0-success {
  border: 0px solid #40C057; }

.h-bd-0-success:hover, .sm-h-bd-0-success:hover {
  border: 0px solid #40C057; }

.bdt-0-success, .sm-bdt-0-success {
  border-top: 0px solid #40C057; }

.bdr-0-success, .sm-bdr-0-success {
  border-right: 0px solid #40C057; }

.bdb-0-success, .sm-bdb-0-success {
  border-bottom: 0px solid #40C057; }

.bdl-0-success, .sm-bdl-0-success {
  border-left: 0px solid #40C057; }

.bd-0-success-light, .sm-bd-0-success-light {
  border: 0px solid #E6FFEA; }

.h-bd-0-success-light:hover, .sm-h-bd-0-success-light:hover {
  border: 0px solid #E6FFEA; }

.bdt-0-success-light, .sm-bdt-0-success-light {
  border-top: 0px solid #E6FFEA; }

.bdr-0-success-light, .sm-bdr-0-success-light {
  border-right: 0px solid #E6FFEA; }

.bdb-0-success-light, .sm-bdb-0-success-light {
  border-bottom: 0px solid #E6FFEA; }

.bdl-0-success-light, .sm-bdl-0-success-light {
  border-left: 0px solid #E6FFEA; }

.bd-0-info, .sm-bd-0-info {
  border: 0px solid #4dadf7; }

.h-bd-0-info:hover, .sm-h-bd-0-info:hover {
  border: 0px solid #4dadf7; }

.bdt-0-info, .sm-bdt-0-info {
  border-top: 0px solid #4dadf7; }

.bdr-0-info, .sm-bdr-0-info {
  border-right: 0px solid #4dadf7; }

.bdb-0-info, .sm-bdb-0-info {
  border-bottom: 0px solid #4dadf7; }

.bdl-0-info, .sm-bdl-0-info {
  border-left: 0px solid #4dadf7; }

.bd-0-info-light, .sm-bd-0-info-light {
  border: 0px solid #d0eaff; }

.h-bd-0-info-light:hover, .sm-h-bd-0-info-light:hover {
  border: 0px solid #d0eaff; }

.bdt-0-info-light, .sm-bdt-0-info-light {
  border-top: 0px solid #d0eaff; }

.bdr-0-info-light, .sm-bdr-0-info-light {
  border-right: 0px solid #d0eaff; }

.bdb-0-info-light, .sm-bdb-0-info-light {
  border-bottom: 0px solid #d0eaff; }

.bdl-0-info-light, .sm-bdl-0-info-light {
  border-left: 0px solid #d0eaff; }

.bd-0-orange, .sm-bd-0-orange {
  border: 0px solid #E68F17; }

.h-bd-0-orange:hover, .sm-h-bd-0-orange:hover {
  border: 0px solid #E68F17; }

.bdt-0-orange, .sm-bdt-0-orange {
  border-top: 0px solid #E68F17; }

.bdr-0-orange, .sm-bdr-0-orange {
  border-right: 0px solid #E68F17; }

.bdb-0-orange, .sm-bdb-0-orange {
  border-bottom: 0px solid #E68F17; }

.bdl-0-orange, .sm-bdl-0-orange {
  border-left: 0px solid #E68F17; }

.bd-0-yellow, .sm-bd-0-yellow {
  border: 0px solid #F5C644; }

.h-bd-0-yellow:hover, .sm-h-bd-0-yellow:hover {
  border: 0px solid #F5C644; }

.bdt-0-yellow, .sm-bdt-0-yellow {
  border-top: 0px solid #F5C644; }

.bdr-0-yellow, .sm-bdr-0-yellow {
  border-right: 0px solid #F5C644; }

.bdb-0-yellow, .sm-bdb-0-yellow {
  border-bottom: 0px solid #F5C644; }

.bdl-0-yellow, .sm-bdl-0-yellow {
  border-left: 0px solid #F5C644; }

.bd-0-coral, .sm-bd-0-coral {
  border: 0px solid #FA5252; }

.h-bd-0-coral:hover, .sm-h-bd-0-coral:hover {
  border: 0px solid #FA5252; }

.bdt-0-coral, .sm-bdt-0-coral {
  border-top: 0px solid #FA5252; }

.bdr-0-coral, .sm-bdr-0-coral {
  border-right: 0px solid #FA5252; }

.bdb-0-coral, .sm-bdb-0-coral {
  border-bottom: 0px solid #FA5252; }

.bdl-0-coral, .sm-bdl-0-coral {
  border-left: 0px solid #FA5252; }

.bd-0-pink, .sm-bd-0-pink {
  border: 0px solid #E64980; }

.h-bd-0-pink:hover, .sm-h-bd-0-pink:hover {
  border: 0px solid #E64980; }

.bdt-0-pink, .sm-bdt-0-pink {
  border-top: 0px solid #E64980; }

.bdr-0-pink, .sm-bdr-0-pink {
  border-right: 0px solid #E64980; }

.bdb-0-pink, .sm-bdb-0-pink {
  border-bottom: 0px solid #E64980; }

.bdl-0-pink, .sm-bdl-0-pink {
  border-left: 0px solid #E64980; }

.bd-0-purple, .sm-bd-0-purple {
  border: 0px solid #BE4BDB; }

.h-bd-0-purple:hover, .sm-h-bd-0-purple:hover {
  border: 0px solid #BE4BDB; }

.bdt-0-purple, .sm-bdt-0-purple {
  border-top: 0px solid #BE4BDB; }

.bdr-0-purple, .sm-bdr-0-purple {
  border-right: 0px solid #BE4BDB; }

.bdb-0-purple, .sm-bdb-0-purple {
  border-bottom: 0px solid #BE4BDB; }

.bdl-0-purple, .sm-bdl-0-purple {
  border-left: 0px solid #BE4BDB; }

.bd-0-teal, .sm-bd-0-teal {
  border: 0px solid #0B7285; }

.h-bd-0-teal:hover, .sm-h-bd-0-teal:hover {
  border: 0px solid #0B7285; }

.bdt-0-teal, .sm-bdt-0-teal {
  border-top: 0px solid #0B7285; }

.bdr-0-teal, .sm-bdr-0-teal {
  border-right: 0px solid #0B7285; }

.bdb-0-teal, .sm-bdb-0-teal {
  border-bottom: 0px solid #0B7285; }

.bdl-0-teal, .sm-bdl-0-teal {
  border-left: 0px solid #0B7285; }

.bd-0-turquoise, .sm-bd-0-turquoise {
  border: 0px solid #15AABF; }

.h-bd-0-turquoise:hover, .sm-h-bd-0-turquoise:hover {
  border: 0px solid #15AABF; }

.bdt-0-turquoise, .sm-bdt-0-turquoise {
  border-top: 0px solid #15AABF; }

.bdr-0-turquoise, .sm-bdr-0-turquoise {
  border-right: 0px solid #15AABF; }

.bdb-0-turquoise, .sm-bdb-0-turquoise {
  border-bottom: 0px solid #15AABF; }

.bdl-0-turquoise, .sm-bdl-0-turquoise {
  border-left: 0px solid #15AABF; }

.bd-0-teal-dark, .sm-bd-0-teal-dark {
  border: 0px solid #0F354D; }

.h-bd-0-teal-dark:hover, .sm-h-bd-0-teal-dark:hover {
  border: 0px solid #0F354D; }

.bdt-0-teal-dark, .sm-bdt-0-teal-dark {
  border-top: 0px solid #0F354D; }

.bdr-0-teal-dark, .sm-bdr-0-teal-dark {
  border-right: 0px solid #0F354D; }

.bdb-0-teal-dark, .sm-bdb-0-teal-dark {
  border-bottom: 0px solid #0F354D; }

.bdl-0-teal-dark, .sm-bdl-0-teal-dark {
  border-left: 0px solid #0F354D; }

.bd-0-purpleV2, .sm-bd-0-purpleV2 {
  border: 0px solid #6D5FFD; }

.h-bd-0-purpleV2:hover, .sm-h-bd-0-purpleV2:hover {
  border: 0px solid #6D5FFD; }

.bdt-0-purpleV2, .sm-bdt-0-purpleV2 {
  border-top: 0px solid #6D5FFD; }

.bdr-0-purpleV2, .sm-bdr-0-purpleV2 {
  border-right: 0px solid #6D5FFD; }

.bdb-0-purpleV2, .sm-bdb-0-purpleV2 {
  border-bottom: 0px solid #6D5FFD; }

.bdl-0-purpleV2, .sm-bdl-0-purpleV2 {
  border-left: 0px solid #6D5FFD; }

.bd-0-orangeV2, .sm-bd-0-orangeV2 {
  border: 0px solid #FAB005; }

.h-bd-0-orangeV2:hover, .sm-h-bd-0-orangeV2:hover {
  border: 0px solid #FAB005; }

.bdt-0-orangeV2, .sm-bdt-0-orangeV2 {
  border-top: 0px solid #FAB005; }

.bdr-0-orangeV2, .sm-bdr-0-orangeV2 {
  border-right: 0px solid #FAB005; }

.bdb-0-orangeV2, .sm-bdb-0-orangeV2 {
  border-bottom: 0px solid #FAB005; }

.bdl-0-orangeV2, .sm-bdl-0-orangeV2 {
  border-left: 0px solid #FAB005; }

.bd-0-redV2, .sm-bd-0-redV2 {
  border: 0px solid #FF1843; }

.h-bd-0-redV2:hover, .sm-h-bd-0-redV2:hover {
  border: 0px solid #FF1843; }

.bdt-0-redV2, .sm-bdt-0-redV2 {
  border-top: 0px solid #FF1843; }

.bdr-0-redV2, .sm-bdr-0-redV2 {
  border-right: 0px solid #FF1843; }

.bdb-0-redV2, .sm-bdb-0-redV2 {
  border-bottom: 0px solid #FF1843; }

.bdl-0-redV2, .sm-bdl-0-redV2 {
  border-left: 0px solid #FF1843; }

.bd-0-yellowV2, .sm-bd-0-yellowV2 {
  border: 0px solid rgba(250, 176, 5, 0.6); }

.h-bd-0-yellowV2:hover, .sm-h-bd-0-yellowV2:hover {
  border: 0px solid rgba(250, 176, 5, 0.6); }

.bdt-0-yellowV2, .sm-bdt-0-yellowV2 {
  border-top: 0px solid rgba(250, 176, 5, 0.6); }

.bdr-0-yellowV2, .sm-bdr-0-yellowV2 {
  border-right: 0px solid rgba(250, 176, 5, 0.6); }

.bdb-0-yellowV2, .sm-bdb-0-yellowV2 {
  border-bottom: 0px solid rgba(250, 176, 5, 0.6); }

.bdl-0-yellowV2, .sm-bdl-0-yellowV2 {
  border-left: 0px solid rgba(250, 176, 5, 0.6); }

.bd-0-primary-light, .sm-bd-0-primary-light {
  border: 0px solid #6D6F7C; }

.h-bd-0-primary-light:hover, .sm-h-bd-0-primary-light:hover {
  border: 0px solid #6D6F7C; }

.bdt-0-primary-light, .sm-bdt-0-primary-light {
  border-top: 0px solid #6D6F7C; }

.bdr-0-primary-light, .sm-bdr-0-primary-light {
  border-right: 0px solid #6D6F7C; }

.bdb-0-primary-light, .sm-bdb-0-primary-light {
  border-bottom: 0px solid #6D6F7C; }

.bdl-0-primary-light, .sm-bdl-0-primary-light {
  border-left: 0px solid #6D6F7C; }

.bd-0-primary, .sm-bd-0-primary {
  border: 0px solid #15192D; }

.h-bd-0-primary:hover, .sm-h-bd-0-primary:hover {
  border: 0px solid #15192D; }

.bdt-0-primary, .sm-bdt-0-primary {
  border-top: 0px solid #15192D; }

.bdr-0-primary, .sm-bdr-0-primary {
  border-right: 0px solid #15192D; }

.bdb-0-primary, .sm-bdb-0-primary {
  border-bottom: 0px solid #15192D; }

.bdl-0-primary, .sm-bdl-0-primary {
  border-left: 0px solid #15192D; }

.bd-0-primary-dark, .sm-bd-0-primary-dark {
  border: 0px solid #080911; }

.h-bd-0-primary-dark:hover, .sm-h-bd-0-primary-dark:hover {
  border: 0px solid #080911; }

.bdt-0-primary-dark, .sm-bdt-0-primary-dark {
  border-top: 0px solid #080911; }

.bdr-0-primary-dark, .sm-bdr-0-primary-dark {
  border-right: 0px solid #080911; }

.bdb-0-primary-dark, .sm-bdb-0-primary-dark {
  border-bottom: 0px solid #080911; }

.bdl-0-primary-dark, .sm-bdl-0-primary-dark {
  border-left: 0px solid #080911; }

.bd-1-gray-lightV2, .sm-bd-1-gray-lightV2 {
  border: 1px solid #F4F6F9; }

.h-bd-1-gray-lightV2:hover, .sm-h-bd-1-gray-lightV2:hover {
  border: 1px solid #F4F6F9; }

.bdt-1-gray-lightV2, .sm-bdt-1-gray-lightV2 {
  border-top: 1px solid #F4F6F9; }

.bdr-1-gray-lightV2, .sm-bdr-1-gray-lightV2 {
  border-right: 1px solid #F4F6F9; }

.bdb-1-gray-lightV2, .sm-bdb-1-gray-lightV2 {
  border-bottom: 1px solid #F4F6F9; }

.bdl-1-gray-lightV2, .sm-bdl-1-gray-lightV2 {
  border-left: 1px solid #F4F6F9; }

.bd-1-black, .sm-bd-1-black {
  border: 1px solid #000000; }

.h-bd-1-black:hover, .sm-h-bd-1-black:hover {
  border: 1px solid #000000; }

.bdt-1-black, .sm-bdt-1-black {
  border-top: 1px solid #000000; }

.bdr-1-black, .sm-bdr-1-black {
  border-right: 1px solid #000000; }

.bdb-1-black, .sm-bdb-1-black {
  border-bottom: 1px solid #000000; }

.bdl-1-black, .sm-bdl-1-black {
  border-left: 1px solid #000000; }

.bd-1-white, .sm-bd-1-white {
  border: 1px solid #ffffff; }

.h-bd-1-white:hover, .sm-h-bd-1-white:hover {
  border: 1px solid #ffffff; }

.bdt-1-white, .sm-bdt-1-white {
  border-top: 1px solid #ffffff; }

.bdr-1-white, .sm-bdr-1-white {
  border-right: 1px solid #ffffff; }

.bdb-1-white, .sm-bdb-1-white {
  border-bottom: 1px solid #ffffff; }

.bdl-1-white, .sm-bdl-1-white {
  border-left: 1px solid #ffffff; }

.bd-1-gray-lighter, .sm-bd-1-gray-lighter {
  border: 1px solid #e8ebed; }

.h-bd-1-gray-lighter:hover, .sm-h-bd-1-gray-lighter:hover {
  border: 1px solid #e8ebed; }

.bdt-1-gray-lighter, .sm-bdt-1-gray-lighter {
  border-top: 1px solid #e8ebed; }

.bdr-1-gray-lighter, .sm-bdr-1-gray-lighter {
  border-right: 1px solid #e8ebed; }

.bdb-1-gray-lighter, .sm-bdb-1-gray-lighter {
  border-bottom: 1px solid #e8ebed; }

.bdl-1-gray-lighter, .sm-bdl-1-gray-lighter {
  border-left: 1px solid #e8ebed; }

.bd-1-gray-light, .sm-bd-1-gray-light {
  border: 1px solid #D9D9D9; }

.h-bd-1-gray-light:hover, .sm-h-bd-1-gray-light:hover {
  border: 1px solid #D9D9D9; }

.bdt-1-gray-light, .sm-bdt-1-gray-light {
  border-top: 1px solid #D9D9D9; }

.bdr-1-gray-light, .sm-bdr-1-gray-light {
  border-right: 1px solid #D9D9D9; }

.bdb-1-gray-light, .sm-bdb-1-gray-light {
  border-bottom: 1px solid #D9D9D9; }

.bdl-1-gray-light, .sm-bdl-1-gray-light {
  border-left: 1px solid #D9D9D9; }

.bd-1-gray, .sm-bd-1-gray {
  border: 1px solid #B3B3B3; }

.h-bd-1-gray:hover, .sm-h-bd-1-gray:hover {
  border: 1px solid #B3B3B3; }

.bdt-1-gray, .sm-bdt-1-gray {
  border-top: 1px solid #B3B3B3; }

.bdr-1-gray, .sm-bdr-1-gray {
  border-right: 1px solid #B3B3B3; }

.bdb-1-gray, .sm-bdb-1-gray {
  border-bottom: 1px solid #B3B3B3; }

.bdl-1-gray, .sm-bdl-1-gray {
  border-left: 1px solid #B3B3B3; }

.bd-1-gray-dark, .sm-bd-1-gray-dark {
  border: 1px solid #808080; }

.h-bd-1-gray-dark:hover, .sm-h-bd-1-gray-dark:hover {
  border: 1px solid #808080; }

.bdt-1-gray-dark, .sm-bdt-1-gray-dark {
  border-top: 1px solid #808080; }

.bdr-1-gray-dark, .sm-bdr-1-gray-dark {
  border-right: 1px solid #808080; }

.bdb-1-gray-dark, .sm-bdb-1-gray-dark {
  border-bottom: 1px solid #808080; }

.bdl-1-gray-dark, .sm-bdl-1-gray-dark {
  border-left: 1px solid #808080; }

.bd-1-gray-darker, .sm-bd-1-gray-darker {
  border: 1px solid #4A4A4A; }

.h-bd-1-gray-darker:hover, .sm-h-bd-1-gray-darker:hover {
  border: 1px solid #4A4A4A; }

.bdt-1-gray-darker, .sm-bdt-1-gray-darker {
  border-top: 1px solid #4A4A4A; }

.bdr-1-gray-darker, .sm-bdr-1-gray-darker {
  border-right: 1px solid #4A4A4A; }

.bdb-1-gray-darker, .sm-bdb-1-gray-darker {
  border-bottom: 1px solid #4A4A4A; }

.bdl-1-gray-darker, .sm-bdl-1-gray-darker {
  border-left: 1px solid #4A4A4A; }

.bd-1-alert, .sm-bd-1-alert {
  border: 1px solid #EE1233; }

.h-bd-1-alert:hover, .sm-h-bd-1-alert:hover {
  border: 1px solid #EE1233; }

.bdt-1-alert, .sm-bdt-1-alert {
  border-top: 1px solid #EE1233; }

.bdr-1-alert, .sm-bdr-1-alert {
  border-right: 1px solid #EE1233; }

.bdb-1-alert, .sm-bdb-1-alert {
  border-bottom: 1px solid #EE1233; }

.bdl-1-alert, .sm-bdl-1-alert {
  border-left: 1px solid #EE1233; }

.bd-1-alert-light, .sm-bd-1-alert-light {
  border: 1px solid #FFEBEB; }

.h-bd-1-alert-light:hover, .sm-h-bd-1-alert-light:hover {
  border: 1px solid #FFEBEB; }

.bdt-1-alert-light, .sm-bdt-1-alert-light {
  border-top: 1px solid #FFEBEB; }

.bdr-1-alert-light, .sm-bdr-1-alert-light {
  border-right: 1px solid #FFEBEB; }

.bdb-1-alert-light, .sm-bdb-1-alert-light {
  border-bottom: 1px solid #FFEBEB; }

.bdl-1-alert-light, .sm-bdl-1-alert-light {
  border-left: 1px solid #FFEBEB; }

.bd-1-success, .sm-bd-1-success {
  border: 1px solid #40C057; }

.h-bd-1-success:hover, .sm-h-bd-1-success:hover {
  border: 1px solid #40C057; }

.bdt-1-success, .sm-bdt-1-success {
  border-top: 1px solid #40C057; }

.bdr-1-success, .sm-bdr-1-success {
  border-right: 1px solid #40C057; }

.bdb-1-success, .sm-bdb-1-success {
  border-bottom: 1px solid #40C057; }

.bdl-1-success, .sm-bdl-1-success {
  border-left: 1px solid #40C057; }

.bd-1-success-light, .sm-bd-1-success-light {
  border: 1px solid #E6FFEA; }

.h-bd-1-success-light:hover, .sm-h-bd-1-success-light:hover {
  border: 1px solid #E6FFEA; }

.bdt-1-success-light, .sm-bdt-1-success-light {
  border-top: 1px solid #E6FFEA; }

.bdr-1-success-light, .sm-bdr-1-success-light {
  border-right: 1px solid #E6FFEA; }

.bdb-1-success-light, .sm-bdb-1-success-light {
  border-bottom: 1px solid #E6FFEA; }

.bdl-1-success-light, .sm-bdl-1-success-light {
  border-left: 1px solid #E6FFEA; }

.bd-1-info, .sm-bd-1-info {
  border: 1px solid #4dadf7; }

.h-bd-1-info:hover, .sm-h-bd-1-info:hover {
  border: 1px solid #4dadf7; }

.bdt-1-info, .sm-bdt-1-info {
  border-top: 1px solid #4dadf7; }

.bdr-1-info, .sm-bdr-1-info {
  border-right: 1px solid #4dadf7; }

.bdb-1-info, .sm-bdb-1-info {
  border-bottom: 1px solid #4dadf7; }

.bdl-1-info, .sm-bdl-1-info {
  border-left: 1px solid #4dadf7; }

.bd-1-info-light, .sm-bd-1-info-light {
  border: 1px solid #d0eaff; }

.h-bd-1-info-light:hover, .sm-h-bd-1-info-light:hover {
  border: 1px solid #d0eaff; }

.bdt-1-info-light, .sm-bdt-1-info-light {
  border-top: 1px solid #d0eaff; }

.bdr-1-info-light, .sm-bdr-1-info-light {
  border-right: 1px solid #d0eaff; }

.bdb-1-info-light, .sm-bdb-1-info-light {
  border-bottom: 1px solid #d0eaff; }

.bdl-1-info-light, .sm-bdl-1-info-light {
  border-left: 1px solid #d0eaff; }

.bd-1-orange, .sm-bd-1-orange {
  border: 1px solid #E68F17; }

.h-bd-1-orange:hover, .sm-h-bd-1-orange:hover {
  border: 1px solid #E68F17; }

.bdt-1-orange, .sm-bdt-1-orange {
  border-top: 1px solid #E68F17; }

.bdr-1-orange, .sm-bdr-1-orange {
  border-right: 1px solid #E68F17; }

.bdb-1-orange, .sm-bdb-1-orange {
  border-bottom: 1px solid #E68F17; }

.bdl-1-orange, .sm-bdl-1-orange {
  border-left: 1px solid #E68F17; }

.bd-1-yellow, .sm-bd-1-yellow {
  border: 1px solid #F5C644; }

.h-bd-1-yellow:hover, .sm-h-bd-1-yellow:hover {
  border: 1px solid #F5C644; }

.bdt-1-yellow, .sm-bdt-1-yellow {
  border-top: 1px solid #F5C644; }

.bdr-1-yellow, .sm-bdr-1-yellow {
  border-right: 1px solid #F5C644; }

.bdb-1-yellow, .sm-bdb-1-yellow {
  border-bottom: 1px solid #F5C644; }

.bdl-1-yellow, .sm-bdl-1-yellow {
  border-left: 1px solid #F5C644; }

.bd-1-coral, .sm-bd-1-coral {
  border: 1px solid #FA5252; }

.h-bd-1-coral:hover, .sm-h-bd-1-coral:hover {
  border: 1px solid #FA5252; }

.bdt-1-coral, .sm-bdt-1-coral {
  border-top: 1px solid #FA5252; }

.bdr-1-coral, .sm-bdr-1-coral {
  border-right: 1px solid #FA5252; }

.bdb-1-coral, .sm-bdb-1-coral {
  border-bottom: 1px solid #FA5252; }

.bdl-1-coral, .sm-bdl-1-coral {
  border-left: 1px solid #FA5252; }

.bd-1-pink, .sm-bd-1-pink {
  border: 1px solid #E64980; }

.h-bd-1-pink:hover, .sm-h-bd-1-pink:hover {
  border: 1px solid #E64980; }

.bdt-1-pink, .sm-bdt-1-pink {
  border-top: 1px solid #E64980; }

.bdr-1-pink, .sm-bdr-1-pink {
  border-right: 1px solid #E64980; }

.bdb-1-pink, .sm-bdb-1-pink {
  border-bottom: 1px solid #E64980; }

.bdl-1-pink, .sm-bdl-1-pink {
  border-left: 1px solid #E64980; }

.bd-1-purple, .sm-bd-1-purple {
  border: 1px solid #BE4BDB; }

.h-bd-1-purple:hover, .sm-h-bd-1-purple:hover {
  border: 1px solid #BE4BDB; }

.bdt-1-purple, .sm-bdt-1-purple {
  border-top: 1px solid #BE4BDB; }

.bdr-1-purple, .sm-bdr-1-purple {
  border-right: 1px solid #BE4BDB; }

.bdb-1-purple, .sm-bdb-1-purple {
  border-bottom: 1px solid #BE4BDB; }

.bdl-1-purple, .sm-bdl-1-purple {
  border-left: 1px solid #BE4BDB; }

.bd-1-teal, .sm-bd-1-teal {
  border: 1px solid #0B7285; }

.h-bd-1-teal:hover, .sm-h-bd-1-teal:hover {
  border: 1px solid #0B7285; }

.bdt-1-teal, .sm-bdt-1-teal {
  border-top: 1px solid #0B7285; }

.bdr-1-teal, .sm-bdr-1-teal {
  border-right: 1px solid #0B7285; }

.bdb-1-teal, .sm-bdb-1-teal {
  border-bottom: 1px solid #0B7285; }

.bdl-1-teal, .sm-bdl-1-teal {
  border-left: 1px solid #0B7285; }

.bd-1-turquoise, .sm-bd-1-turquoise {
  border: 1px solid #15AABF; }

.h-bd-1-turquoise:hover, .sm-h-bd-1-turquoise:hover {
  border: 1px solid #15AABF; }

.bdt-1-turquoise, .sm-bdt-1-turquoise {
  border-top: 1px solid #15AABF; }

.bdr-1-turquoise, .sm-bdr-1-turquoise {
  border-right: 1px solid #15AABF; }

.bdb-1-turquoise, .sm-bdb-1-turquoise {
  border-bottom: 1px solid #15AABF; }

.bdl-1-turquoise, .sm-bdl-1-turquoise {
  border-left: 1px solid #15AABF; }

.bd-1-teal-dark, .sm-bd-1-teal-dark {
  border: 1px solid #0F354D; }

.h-bd-1-teal-dark:hover, .sm-h-bd-1-teal-dark:hover {
  border: 1px solid #0F354D; }

.bdt-1-teal-dark, .sm-bdt-1-teal-dark {
  border-top: 1px solid #0F354D; }

.bdr-1-teal-dark, .sm-bdr-1-teal-dark {
  border-right: 1px solid #0F354D; }

.bdb-1-teal-dark, .sm-bdb-1-teal-dark {
  border-bottom: 1px solid #0F354D; }

.bdl-1-teal-dark, .sm-bdl-1-teal-dark {
  border-left: 1px solid #0F354D; }

.bd-1-purpleV2, .sm-bd-1-purpleV2 {
  border: 1px solid #6D5FFD; }

.h-bd-1-purpleV2:hover, .sm-h-bd-1-purpleV2:hover {
  border: 1px solid #6D5FFD; }

.bdt-1-purpleV2, .sm-bdt-1-purpleV2 {
  border-top: 1px solid #6D5FFD; }

.bdr-1-purpleV2, .sm-bdr-1-purpleV2 {
  border-right: 1px solid #6D5FFD; }

.bdb-1-purpleV2, .sm-bdb-1-purpleV2 {
  border-bottom: 1px solid #6D5FFD; }

.bdl-1-purpleV2, .sm-bdl-1-purpleV2 {
  border-left: 1px solid #6D5FFD; }

.bd-1-orangeV2, .sm-bd-1-orangeV2 {
  border: 1px solid #FAB005; }

.h-bd-1-orangeV2:hover, .sm-h-bd-1-orangeV2:hover {
  border: 1px solid #FAB005; }

.bdt-1-orangeV2, .sm-bdt-1-orangeV2 {
  border-top: 1px solid #FAB005; }

.bdr-1-orangeV2, .sm-bdr-1-orangeV2 {
  border-right: 1px solid #FAB005; }

.bdb-1-orangeV2, .sm-bdb-1-orangeV2 {
  border-bottom: 1px solid #FAB005; }

.bdl-1-orangeV2, .sm-bdl-1-orangeV2 {
  border-left: 1px solid #FAB005; }

.bd-1-redV2, .sm-bd-1-redV2 {
  border: 1px solid #FF1843; }

.h-bd-1-redV2:hover, .sm-h-bd-1-redV2:hover {
  border: 1px solid #FF1843; }

.bdt-1-redV2, .sm-bdt-1-redV2 {
  border-top: 1px solid #FF1843; }

.bdr-1-redV2, .sm-bdr-1-redV2 {
  border-right: 1px solid #FF1843; }

.bdb-1-redV2, .sm-bdb-1-redV2 {
  border-bottom: 1px solid #FF1843; }

.bdl-1-redV2, .sm-bdl-1-redV2 {
  border-left: 1px solid #FF1843; }

.bd-1-yellowV2, .sm-bd-1-yellowV2 {
  border: 1px solid rgba(250, 176, 5, 0.6); }

.h-bd-1-yellowV2:hover, .sm-h-bd-1-yellowV2:hover {
  border: 1px solid rgba(250, 176, 5, 0.6); }

.bdt-1-yellowV2, .sm-bdt-1-yellowV2 {
  border-top: 1px solid rgba(250, 176, 5, 0.6); }

.bdr-1-yellowV2, .sm-bdr-1-yellowV2 {
  border-right: 1px solid rgba(250, 176, 5, 0.6); }

.bdb-1-yellowV2, .sm-bdb-1-yellowV2 {
  border-bottom: 1px solid rgba(250, 176, 5, 0.6); }

.bdl-1-yellowV2, .sm-bdl-1-yellowV2 {
  border-left: 1px solid rgba(250, 176, 5, 0.6); }

.bd-1-primary-light, .sm-bd-1-primary-light {
  border: 1px solid #6D6F7C; }

.h-bd-1-primary-light:hover, .sm-h-bd-1-primary-light:hover {
  border: 1px solid #6D6F7C; }

.bdt-1-primary-light, .sm-bdt-1-primary-light {
  border-top: 1px solid #6D6F7C; }

.bdr-1-primary-light, .sm-bdr-1-primary-light {
  border-right: 1px solid #6D6F7C; }

.bdb-1-primary-light, .sm-bdb-1-primary-light {
  border-bottom: 1px solid #6D6F7C; }

.bdl-1-primary-light, .sm-bdl-1-primary-light {
  border-left: 1px solid #6D6F7C; }

.bd-1-primary, .sm-bd-1-primary {
  border: 1px solid #15192D; }

.h-bd-1-primary:hover, .sm-h-bd-1-primary:hover {
  border: 1px solid #15192D; }

.bdt-1-primary, .sm-bdt-1-primary {
  border-top: 1px solid #15192D; }

.bdr-1-primary, .sm-bdr-1-primary {
  border-right: 1px solid #15192D; }

.bdb-1-primary, .sm-bdb-1-primary {
  border-bottom: 1px solid #15192D; }

.bdl-1-primary, .sm-bdl-1-primary {
  border-left: 1px solid #15192D; }

.bd-1-primary-dark, .sm-bd-1-primary-dark {
  border: 1px solid #080911; }

.h-bd-1-primary-dark:hover, .sm-h-bd-1-primary-dark:hover {
  border: 1px solid #080911; }

.bdt-1-primary-dark, .sm-bdt-1-primary-dark {
  border-top: 1px solid #080911; }

.bdr-1-primary-dark, .sm-bdr-1-primary-dark {
  border-right: 1px solid #080911; }

.bdb-1-primary-dark, .sm-bdb-1-primary-dark {
  border-bottom: 1px solid #080911; }

.bdl-1-primary-dark, .sm-bdl-1-primary-dark {
  border-left: 1px solid #080911; }

.bd-2-gray-lightV2, .sm-bd-2-gray-lightV2 {
  border: 2px solid #F4F6F9; }

.h-bd-2-gray-lightV2:hover, .sm-h-bd-2-gray-lightV2:hover {
  border: 2px solid #F4F6F9; }

.bdt-2-gray-lightV2, .sm-bdt-2-gray-lightV2 {
  border-top: 2px solid #F4F6F9; }

.bdr-2-gray-lightV2, .sm-bdr-2-gray-lightV2 {
  border-right: 2px solid #F4F6F9; }

.bdb-2-gray-lightV2, .sm-bdb-2-gray-lightV2 {
  border-bottom: 2px solid #F4F6F9; }

.bdl-2-gray-lightV2, .sm-bdl-2-gray-lightV2 {
  border-left: 2px solid #F4F6F9; }

.bd-2-black, .sm-bd-2-black {
  border: 2px solid #000000; }

.h-bd-2-black:hover, .sm-h-bd-2-black:hover {
  border: 2px solid #000000; }

.bdt-2-black, .sm-bdt-2-black {
  border-top: 2px solid #000000; }

.bdr-2-black, .sm-bdr-2-black {
  border-right: 2px solid #000000; }

.bdb-2-black, .sm-bdb-2-black {
  border-bottom: 2px solid #000000; }

.bdl-2-black, .sm-bdl-2-black {
  border-left: 2px solid #000000; }

.bd-2-white, .sm-bd-2-white {
  border: 2px solid #ffffff; }

.h-bd-2-white:hover, .sm-h-bd-2-white:hover {
  border: 2px solid #ffffff; }

.bdt-2-white, .sm-bdt-2-white {
  border-top: 2px solid #ffffff; }

.bdr-2-white, .sm-bdr-2-white {
  border-right: 2px solid #ffffff; }

.bdb-2-white, .sm-bdb-2-white {
  border-bottom: 2px solid #ffffff; }

.bdl-2-white, .sm-bdl-2-white {
  border-left: 2px solid #ffffff; }

.bd-2-gray-lighter, .sm-bd-2-gray-lighter {
  border: 2px solid #e8ebed; }

.h-bd-2-gray-lighter:hover, .sm-h-bd-2-gray-lighter:hover {
  border: 2px solid #e8ebed; }

.bdt-2-gray-lighter, .sm-bdt-2-gray-lighter {
  border-top: 2px solid #e8ebed; }

.bdr-2-gray-lighter, .sm-bdr-2-gray-lighter {
  border-right: 2px solid #e8ebed; }

.bdb-2-gray-lighter, .sm-bdb-2-gray-lighter {
  border-bottom: 2px solid #e8ebed; }

.bdl-2-gray-lighter, .sm-bdl-2-gray-lighter {
  border-left: 2px solid #e8ebed; }

.bd-2-gray-light, .sm-bd-2-gray-light {
  border: 2px solid #D9D9D9; }

.h-bd-2-gray-light:hover, .sm-h-bd-2-gray-light:hover {
  border: 2px solid #D9D9D9; }

.bdt-2-gray-light, .sm-bdt-2-gray-light {
  border-top: 2px solid #D9D9D9; }

.bdr-2-gray-light, .sm-bdr-2-gray-light {
  border-right: 2px solid #D9D9D9; }

.bdb-2-gray-light, .sm-bdb-2-gray-light {
  border-bottom: 2px solid #D9D9D9; }

.bdl-2-gray-light, .sm-bdl-2-gray-light {
  border-left: 2px solid #D9D9D9; }

.bd-2-gray, .sm-bd-2-gray {
  border: 2px solid #B3B3B3; }

.h-bd-2-gray:hover, .sm-h-bd-2-gray:hover {
  border: 2px solid #B3B3B3; }

.bdt-2-gray, .sm-bdt-2-gray {
  border-top: 2px solid #B3B3B3; }

.bdr-2-gray, .sm-bdr-2-gray {
  border-right: 2px solid #B3B3B3; }

.bdb-2-gray, .sm-bdb-2-gray {
  border-bottom: 2px solid #B3B3B3; }

.bdl-2-gray, .sm-bdl-2-gray {
  border-left: 2px solid #B3B3B3; }

.bd-2-gray-dark, .sm-bd-2-gray-dark {
  border: 2px solid #808080; }

.h-bd-2-gray-dark:hover, .sm-h-bd-2-gray-dark:hover {
  border: 2px solid #808080; }

.bdt-2-gray-dark, .sm-bdt-2-gray-dark {
  border-top: 2px solid #808080; }

.bdr-2-gray-dark, .sm-bdr-2-gray-dark {
  border-right: 2px solid #808080; }

.bdb-2-gray-dark, .sm-bdb-2-gray-dark {
  border-bottom: 2px solid #808080; }

.bdl-2-gray-dark, .sm-bdl-2-gray-dark {
  border-left: 2px solid #808080; }

.bd-2-gray-darker, .sm-bd-2-gray-darker {
  border: 2px solid #4A4A4A; }

.h-bd-2-gray-darker:hover, .sm-h-bd-2-gray-darker:hover {
  border: 2px solid #4A4A4A; }

.bdt-2-gray-darker, .sm-bdt-2-gray-darker {
  border-top: 2px solid #4A4A4A; }

.bdr-2-gray-darker, .sm-bdr-2-gray-darker {
  border-right: 2px solid #4A4A4A; }

.bdb-2-gray-darker, .sm-bdb-2-gray-darker {
  border-bottom: 2px solid #4A4A4A; }

.bdl-2-gray-darker, .sm-bdl-2-gray-darker {
  border-left: 2px solid #4A4A4A; }

.bd-2-alert, .sm-bd-2-alert {
  border: 2px solid #EE1233; }

.h-bd-2-alert:hover, .sm-h-bd-2-alert:hover {
  border: 2px solid #EE1233; }

.bdt-2-alert, .sm-bdt-2-alert {
  border-top: 2px solid #EE1233; }

.bdr-2-alert, .sm-bdr-2-alert {
  border-right: 2px solid #EE1233; }

.bdb-2-alert, .sm-bdb-2-alert {
  border-bottom: 2px solid #EE1233; }

.bdl-2-alert, .sm-bdl-2-alert {
  border-left: 2px solid #EE1233; }

.bd-2-alert-light, .sm-bd-2-alert-light {
  border: 2px solid #FFEBEB; }

.h-bd-2-alert-light:hover, .sm-h-bd-2-alert-light:hover {
  border: 2px solid #FFEBEB; }

.bdt-2-alert-light, .sm-bdt-2-alert-light {
  border-top: 2px solid #FFEBEB; }

.bdr-2-alert-light, .sm-bdr-2-alert-light {
  border-right: 2px solid #FFEBEB; }

.bdb-2-alert-light, .sm-bdb-2-alert-light {
  border-bottom: 2px solid #FFEBEB; }

.bdl-2-alert-light, .sm-bdl-2-alert-light {
  border-left: 2px solid #FFEBEB; }

.bd-2-success, .sm-bd-2-success {
  border: 2px solid #40C057; }

.h-bd-2-success:hover, .sm-h-bd-2-success:hover {
  border: 2px solid #40C057; }

.bdt-2-success, .sm-bdt-2-success {
  border-top: 2px solid #40C057; }

.bdr-2-success, .sm-bdr-2-success {
  border-right: 2px solid #40C057; }

.bdb-2-success, .sm-bdb-2-success {
  border-bottom: 2px solid #40C057; }

.bdl-2-success, .sm-bdl-2-success {
  border-left: 2px solid #40C057; }

.bd-2-success-light, .sm-bd-2-success-light {
  border: 2px solid #E6FFEA; }

.h-bd-2-success-light:hover, .sm-h-bd-2-success-light:hover {
  border: 2px solid #E6FFEA; }

.bdt-2-success-light, .sm-bdt-2-success-light {
  border-top: 2px solid #E6FFEA; }

.bdr-2-success-light, .sm-bdr-2-success-light {
  border-right: 2px solid #E6FFEA; }

.bdb-2-success-light, .sm-bdb-2-success-light {
  border-bottom: 2px solid #E6FFEA; }

.bdl-2-success-light, .sm-bdl-2-success-light {
  border-left: 2px solid #E6FFEA; }

.bd-2-info, .sm-bd-2-info {
  border: 2px solid #4dadf7; }

.h-bd-2-info:hover, .sm-h-bd-2-info:hover {
  border: 2px solid #4dadf7; }

.bdt-2-info, .sm-bdt-2-info {
  border-top: 2px solid #4dadf7; }

.bdr-2-info, .sm-bdr-2-info {
  border-right: 2px solid #4dadf7; }

.bdb-2-info, .sm-bdb-2-info {
  border-bottom: 2px solid #4dadf7; }

.bdl-2-info, .sm-bdl-2-info {
  border-left: 2px solid #4dadf7; }

.bd-2-info-light, .sm-bd-2-info-light {
  border: 2px solid #d0eaff; }

.h-bd-2-info-light:hover, .sm-h-bd-2-info-light:hover {
  border: 2px solid #d0eaff; }

.bdt-2-info-light, .sm-bdt-2-info-light {
  border-top: 2px solid #d0eaff; }

.bdr-2-info-light, .sm-bdr-2-info-light {
  border-right: 2px solid #d0eaff; }

.bdb-2-info-light, .sm-bdb-2-info-light {
  border-bottom: 2px solid #d0eaff; }

.bdl-2-info-light, .sm-bdl-2-info-light {
  border-left: 2px solid #d0eaff; }

.bd-2-orange, .sm-bd-2-orange {
  border: 2px solid #E68F17; }

.h-bd-2-orange:hover, .sm-h-bd-2-orange:hover {
  border: 2px solid #E68F17; }

.bdt-2-orange, .sm-bdt-2-orange {
  border-top: 2px solid #E68F17; }

.bdr-2-orange, .sm-bdr-2-orange {
  border-right: 2px solid #E68F17; }

.bdb-2-orange, .sm-bdb-2-orange {
  border-bottom: 2px solid #E68F17; }

.bdl-2-orange, .sm-bdl-2-orange {
  border-left: 2px solid #E68F17; }

.bd-2-yellow, .sm-bd-2-yellow {
  border: 2px solid #F5C644; }

.h-bd-2-yellow:hover, .sm-h-bd-2-yellow:hover {
  border: 2px solid #F5C644; }

.bdt-2-yellow, .sm-bdt-2-yellow {
  border-top: 2px solid #F5C644; }

.bdr-2-yellow, .sm-bdr-2-yellow {
  border-right: 2px solid #F5C644; }

.bdb-2-yellow, .sm-bdb-2-yellow {
  border-bottom: 2px solid #F5C644; }

.bdl-2-yellow, .sm-bdl-2-yellow {
  border-left: 2px solid #F5C644; }

.bd-2-coral, .sm-bd-2-coral {
  border: 2px solid #FA5252; }

.h-bd-2-coral:hover, .sm-h-bd-2-coral:hover {
  border: 2px solid #FA5252; }

.bdt-2-coral, .sm-bdt-2-coral {
  border-top: 2px solid #FA5252; }

.bdr-2-coral, .sm-bdr-2-coral {
  border-right: 2px solid #FA5252; }

.bdb-2-coral, .sm-bdb-2-coral {
  border-bottom: 2px solid #FA5252; }

.bdl-2-coral, .sm-bdl-2-coral {
  border-left: 2px solid #FA5252; }

.bd-2-pink, .sm-bd-2-pink {
  border: 2px solid #E64980; }

.h-bd-2-pink:hover, .sm-h-bd-2-pink:hover {
  border: 2px solid #E64980; }

.bdt-2-pink, .sm-bdt-2-pink {
  border-top: 2px solid #E64980; }

.bdr-2-pink, .sm-bdr-2-pink {
  border-right: 2px solid #E64980; }

.bdb-2-pink, .sm-bdb-2-pink {
  border-bottom: 2px solid #E64980; }

.bdl-2-pink, .sm-bdl-2-pink {
  border-left: 2px solid #E64980; }

.bd-2-purple, .sm-bd-2-purple {
  border: 2px solid #BE4BDB; }

.h-bd-2-purple:hover, .sm-h-bd-2-purple:hover {
  border: 2px solid #BE4BDB; }

.bdt-2-purple, .sm-bdt-2-purple {
  border-top: 2px solid #BE4BDB; }

.bdr-2-purple, .sm-bdr-2-purple {
  border-right: 2px solid #BE4BDB; }

.bdb-2-purple, .sm-bdb-2-purple {
  border-bottom: 2px solid #BE4BDB; }

.bdl-2-purple, .sm-bdl-2-purple {
  border-left: 2px solid #BE4BDB; }

.bd-2-teal, .sm-bd-2-teal {
  border: 2px solid #0B7285; }

.h-bd-2-teal:hover, .sm-h-bd-2-teal:hover {
  border: 2px solid #0B7285; }

.bdt-2-teal, .sm-bdt-2-teal {
  border-top: 2px solid #0B7285; }

.bdr-2-teal, .sm-bdr-2-teal {
  border-right: 2px solid #0B7285; }

.bdb-2-teal, .sm-bdb-2-teal {
  border-bottom: 2px solid #0B7285; }

.bdl-2-teal, .sm-bdl-2-teal {
  border-left: 2px solid #0B7285; }

.bd-2-turquoise, .sm-bd-2-turquoise {
  border: 2px solid #15AABF; }

.h-bd-2-turquoise:hover, .sm-h-bd-2-turquoise:hover {
  border: 2px solid #15AABF; }

.bdt-2-turquoise, .sm-bdt-2-turquoise {
  border-top: 2px solid #15AABF; }

.bdr-2-turquoise, .sm-bdr-2-turquoise {
  border-right: 2px solid #15AABF; }

.bdb-2-turquoise, .sm-bdb-2-turquoise {
  border-bottom: 2px solid #15AABF; }

.bdl-2-turquoise, .sm-bdl-2-turquoise {
  border-left: 2px solid #15AABF; }

.bd-2-teal-dark, .sm-bd-2-teal-dark {
  border: 2px solid #0F354D; }

.h-bd-2-teal-dark:hover, .sm-h-bd-2-teal-dark:hover {
  border: 2px solid #0F354D; }

.bdt-2-teal-dark, .sm-bdt-2-teal-dark {
  border-top: 2px solid #0F354D; }

.bdr-2-teal-dark, .sm-bdr-2-teal-dark {
  border-right: 2px solid #0F354D; }

.bdb-2-teal-dark, .sm-bdb-2-teal-dark {
  border-bottom: 2px solid #0F354D; }

.bdl-2-teal-dark, .sm-bdl-2-teal-dark {
  border-left: 2px solid #0F354D; }

.bd-2-purpleV2, .sm-bd-2-purpleV2 {
  border: 2px solid #6D5FFD; }

.h-bd-2-purpleV2:hover, .sm-h-bd-2-purpleV2:hover {
  border: 2px solid #6D5FFD; }

.bdt-2-purpleV2, .sm-bdt-2-purpleV2 {
  border-top: 2px solid #6D5FFD; }

.bdr-2-purpleV2, .sm-bdr-2-purpleV2 {
  border-right: 2px solid #6D5FFD; }

.bdb-2-purpleV2, .sm-bdb-2-purpleV2 {
  border-bottom: 2px solid #6D5FFD; }

.bdl-2-purpleV2, .sm-bdl-2-purpleV2 {
  border-left: 2px solid #6D5FFD; }

.bd-2-orangeV2, .sm-bd-2-orangeV2 {
  border: 2px solid #FAB005; }

.h-bd-2-orangeV2:hover, .sm-h-bd-2-orangeV2:hover {
  border: 2px solid #FAB005; }

.bdt-2-orangeV2, .sm-bdt-2-orangeV2 {
  border-top: 2px solid #FAB005; }

.bdr-2-orangeV2, .sm-bdr-2-orangeV2 {
  border-right: 2px solid #FAB005; }

.bdb-2-orangeV2, .sm-bdb-2-orangeV2 {
  border-bottom: 2px solid #FAB005; }

.bdl-2-orangeV2, .sm-bdl-2-orangeV2 {
  border-left: 2px solid #FAB005; }

.bd-2-redV2, .sm-bd-2-redV2 {
  border: 2px solid #FF1843; }

.h-bd-2-redV2:hover, .sm-h-bd-2-redV2:hover {
  border: 2px solid #FF1843; }

.bdt-2-redV2, .sm-bdt-2-redV2 {
  border-top: 2px solid #FF1843; }

.bdr-2-redV2, .sm-bdr-2-redV2 {
  border-right: 2px solid #FF1843; }

.bdb-2-redV2, .sm-bdb-2-redV2 {
  border-bottom: 2px solid #FF1843; }

.bdl-2-redV2, .sm-bdl-2-redV2 {
  border-left: 2px solid #FF1843; }

.bd-2-yellowV2, .sm-bd-2-yellowV2 {
  border: 2px solid rgba(250, 176, 5, 0.6); }

.h-bd-2-yellowV2:hover, .sm-h-bd-2-yellowV2:hover {
  border: 2px solid rgba(250, 176, 5, 0.6); }

.bdt-2-yellowV2, .sm-bdt-2-yellowV2 {
  border-top: 2px solid rgba(250, 176, 5, 0.6); }

.bdr-2-yellowV2, .sm-bdr-2-yellowV2 {
  border-right: 2px solid rgba(250, 176, 5, 0.6); }

.bdb-2-yellowV2, .sm-bdb-2-yellowV2 {
  border-bottom: 2px solid rgba(250, 176, 5, 0.6); }

.bdl-2-yellowV2, .sm-bdl-2-yellowV2 {
  border-left: 2px solid rgba(250, 176, 5, 0.6); }

.bd-2-primary-light, .sm-bd-2-primary-light {
  border: 2px solid #6D6F7C; }

.h-bd-2-primary-light:hover, .sm-h-bd-2-primary-light:hover {
  border: 2px solid #6D6F7C; }

.bdt-2-primary-light, .sm-bdt-2-primary-light {
  border-top: 2px solid #6D6F7C; }

.bdr-2-primary-light, .sm-bdr-2-primary-light {
  border-right: 2px solid #6D6F7C; }

.bdb-2-primary-light, .sm-bdb-2-primary-light {
  border-bottom: 2px solid #6D6F7C; }

.bdl-2-primary-light, .sm-bdl-2-primary-light {
  border-left: 2px solid #6D6F7C; }

.bd-2-primary, .sm-bd-2-primary {
  border: 2px solid #15192D; }

.h-bd-2-primary:hover, .sm-h-bd-2-primary:hover {
  border: 2px solid #15192D; }

.bdt-2-primary, .sm-bdt-2-primary {
  border-top: 2px solid #15192D; }

.bdr-2-primary, .sm-bdr-2-primary {
  border-right: 2px solid #15192D; }

.bdb-2-primary, .sm-bdb-2-primary {
  border-bottom: 2px solid #15192D; }

.bdl-2-primary, .sm-bdl-2-primary {
  border-left: 2px solid #15192D; }

.bd-2-primary-dark, .sm-bd-2-primary-dark {
  border: 2px solid #080911; }

.h-bd-2-primary-dark:hover, .sm-h-bd-2-primary-dark:hover {
  border: 2px solid #080911; }

.bdt-2-primary-dark, .sm-bdt-2-primary-dark {
  border-top: 2px solid #080911; }

.bdr-2-primary-dark, .sm-bdr-2-primary-dark {
  border-right: 2px solid #080911; }

.bdb-2-primary-dark, .sm-bdb-2-primary-dark {
  border-bottom: 2px solid #080911; }

.bdl-2-primary-dark, .sm-bdl-2-primary-dark {
  border-left: 2px solid #080911; }

.bd-3-gray-lightV2, .sm-bd-3-gray-lightV2 {
  border: 3px solid #F4F6F9; }

.h-bd-3-gray-lightV2:hover, .sm-h-bd-3-gray-lightV2:hover {
  border: 3px solid #F4F6F9; }

.bdt-3-gray-lightV2, .sm-bdt-3-gray-lightV2 {
  border-top: 3px solid #F4F6F9; }

.bdr-3-gray-lightV2, .sm-bdr-3-gray-lightV2 {
  border-right: 3px solid #F4F6F9; }

.bdb-3-gray-lightV2, .sm-bdb-3-gray-lightV2 {
  border-bottom: 3px solid #F4F6F9; }

.bdl-3-gray-lightV2, .sm-bdl-3-gray-lightV2 {
  border-left: 3px solid #F4F6F9; }

.bd-3-black, .sm-bd-3-black {
  border: 3px solid #000000; }

.h-bd-3-black:hover, .sm-h-bd-3-black:hover {
  border: 3px solid #000000; }

.bdt-3-black, .sm-bdt-3-black {
  border-top: 3px solid #000000; }

.bdr-3-black, .sm-bdr-3-black {
  border-right: 3px solid #000000; }

.bdb-3-black, .sm-bdb-3-black {
  border-bottom: 3px solid #000000; }

.bdl-3-black, .sm-bdl-3-black {
  border-left: 3px solid #000000; }

.bd-3-white, .sm-bd-3-white {
  border: 3px solid #ffffff; }

.h-bd-3-white:hover, .sm-h-bd-3-white:hover {
  border: 3px solid #ffffff; }

.bdt-3-white, .sm-bdt-3-white {
  border-top: 3px solid #ffffff; }

.bdr-3-white, .sm-bdr-3-white {
  border-right: 3px solid #ffffff; }

.bdb-3-white, .sm-bdb-3-white {
  border-bottom: 3px solid #ffffff; }

.bdl-3-white, .sm-bdl-3-white {
  border-left: 3px solid #ffffff; }

.bd-3-gray-lighter, .sm-bd-3-gray-lighter {
  border: 3px solid #e8ebed; }

.h-bd-3-gray-lighter:hover, .sm-h-bd-3-gray-lighter:hover {
  border: 3px solid #e8ebed; }

.bdt-3-gray-lighter, .sm-bdt-3-gray-lighter {
  border-top: 3px solid #e8ebed; }

.bdr-3-gray-lighter, .sm-bdr-3-gray-lighter {
  border-right: 3px solid #e8ebed; }

.bdb-3-gray-lighter, .sm-bdb-3-gray-lighter {
  border-bottom: 3px solid #e8ebed; }

.bdl-3-gray-lighter, .sm-bdl-3-gray-lighter {
  border-left: 3px solid #e8ebed; }

.bd-3-gray-light, .sm-bd-3-gray-light {
  border: 3px solid #D9D9D9; }

.h-bd-3-gray-light:hover, .sm-h-bd-3-gray-light:hover {
  border: 3px solid #D9D9D9; }

.bdt-3-gray-light, .sm-bdt-3-gray-light {
  border-top: 3px solid #D9D9D9; }

.bdr-3-gray-light, .sm-bdr-3-gray-light {
  border-right: 3px solid #D9D9D9; }

.bdb-3-gray-light, .sm-bdb-3-gray-light {
  border-bottom: 3px solid #D9D9D9; }

.bdl-3-gray-light, .sm-bdl-3-gray-light {
  border-left: 3px solid #D9D9D9; }

.bd-3-gray, .sm-bd-3-gray {
  border: 3px solid #B3B3B3; }

.h-bd-3-gray:hover, .sm-h-bd-3-gray:hover {
  border: 3px solid #B3B3B3; }

.bdt-3-gray, .sm-bdt-3-gray {
  border-top: 3px solid #B3B3B3; }

.bdr-3-gray, .sm-bdr-3-gray {
  border-right: 3px solid #B3B3B3; }

.bdb-3-gray, .sm-bdb-3-gray {
  border-bottom: 3px solid #B3B3B3; }

.bdl-3-gray, .sm-bdl-3-gray {
  border-left: 3px solid #B3B3B3; }

.bd-3-gray-dark, .sm-bd-3-gray-dark {
  border: 3px solid #808080; }

.h-bd-3-gray-dark:hover, .sm-h-bd-3-gray-dark:hover {
  border: 3px solid #808080; }

.bdt-3-gray-dark, .sm-bdt-3-gray-dark {
  border-top: 3px solid #808080; }

.bdr-3-gray-dark, .sm-bdr-3-gray-dark {
  border-right: 3px solid #808080; }

.bdb-3-gray-dark, .sm-bdb-3-gray-dark {
  border-bottom: 3px solid #808080; }

.bdl-3-gray-dark, .sm-bdl-3-gray-dark {
  border-left: 3px solid #808080; }

.bd-3-gray-darker, .sm-bd-3-gray-darker {
  border: 3px solid #4A4A4A; }

.h-bd-3-gray-darker:hover, .sm-h-bd-3-gray-darker:hover {
  border: 3px solid #4A4A4A; }

.bdt-3-gray-darker, .sm-bdt-3-gray-darker {
  border-top: 3px solid #4A4A4A; }

.bdr-3-gray-darker, .sm-bdr-3-gray-darker {
  border-right: 3px solid #4A4A4A; }

.bdb-3-gray-darker, .sm-bdb-3-gray-darker {
  border-bottom: 3px solid #4A4A4A; }

.bdl-3-gray-darker, .sm-bdl-3-gray-darker {
  border-left: 3px solid #4A4A4A; }

.bd-3-alert, .sm-bd-3-alert {
  border: 3px solid #EE1233; }

.h-bd-3-alert:hover, .sm-h-bd-3-alert:hover {
  border: 3px solid #EE1233; }

.bdt-3-alert, .sm-bdt-3-alert {
  border-top: 3px solid #EE1233; }

.bdr-3-alert, .sm-bdr-3-alert {
  border-right: 3px solid #EE1233; }

.bdb-3-alert, .sm-bdb-3-alert {
  border-bottom: 3px solid #EE1233; }

.bdl-3-alert, .sm-bdl-3-alert {
  border-left: 3px solid #EE1233; }

.bd-3-alert-light, .sm-bd-3-alert-light {
  border: 3px solid #FFEBEB; }

.h-bd-3-alert-light:hover, .sm-h-bd-3-alert-light:hover {
  border: 3px solid #FFEBEB; }

.bdt-3-alert-light, .sm-bdt-3-alert-light {
  border-top: 3px solid #FFEBEB; }

.bdr-3-alert-light, .sm-bdr-3-alert-light {
  border-right: 3px solid #FFEBEB; }

.bdb-3-alert-light, .sm-bdb-3-alert-light {
  border-bottom: 3px solid #FFEBEB; }

.bdl-3-alert-light, .sm-bdl-3-alert-light {
  border-left: 3px solid #FFEBEB; }

.bd-3-success, .sm-bd-3-success {
  border: 3px solid #40C057; }

.h-bd-3-success:hover, .sm-h-bd-3-success:hover {
  border: 3px solid #40C057; }

.bdt-3-success, .sm-bdt-3-success {
  border-top: 3px solid #40C057; }

.bdr-3-success, .sm-bdr-3-success {
  border-right: 3px solid #40C057; }

.bdb-3-success, .sm-bdb-3-success {
  border-bottom: 3px solid #40C057; }

.bdl-3-success, .sm-bdl-3-success {
  border-left: 3px solid #40C057; }

.bd-3-success-light, .sm-bd-3-success-light {
  border: 3px solid #E6FFEA; }

.h-bd-3-success-light:hover, .sm-h-bd-3-success-light:hover {
  border: 3px solid #E6FFEA; }

.bdt-3-success-light, .sm-bdt-3-success-light {
  border-top: 3px solid #E6FFEA; }

.bdr-3-success-light, .sm-bdr-3-success-light {
  border-right: 3px solid #E6FFEA; }

.bdb-3-success-light, .sm-bdb-3-success-light {
  border-bottom: 3px solid #E6FFEA; }

.bdl-3-success-light, .sm-bdl-3-success-light {
  border-left: 3px solid #E6FFEA; }

.bd-3-info, .sm-bd-3-info {
  border: 3px solid #4dadf7; }

.h-bd-3-info:hover, .sm-h-bd-3-info:hover {
  border: 3px solid #4dadf7; }

.bdt-3-info, .sm-bdt-3-info {
  border-top: 3px solid #4dadf7; }

.bdr-3-info, .sm-bdr-3-info {
  border-right: 3px solid #4dadf7; }

.bdb-3-info, .sm-bdb-3-info {
  border-bottom: 3px solid #4dadf7; }

.bdl-3-info, .sm-bdl-3-info {
  border-left: 3px solid #4dadf7; }

.bd-3-info-light, .sm-bd-3-info-light {
  border: 3px solid #d0eaff; }

.h-bd-3-info-light:hover, .sm-h-bd-3-info-light:hover {
  border: 3px solid #d0eaff; }

.bdt-3-info-light, .sm-bdt-3-info-light {
  border-top: 3px solid #d0eaff; }

.bdr-3-info-light, .sm-bdr-3-info-light {
  border-right: 3px solid #d0eaff; }

.bdb-3-info-light, .sm-bdb-3-info-light {
  border-bottom: 3px solid #d0eaff; }

.bdl-3-info-light, .sm-bdl-3-info-light {
  border-left: 3px solid #d0eaff; }

.bd-3-orange, .sm-bd-3-orange {
  border: 3px solid #E68F17; }

.h-bd-3-orange:hover, .sm-h-bd-3-orange:hover {
  border: 3px solid #E68F17; }

.bdt-3-orange, .sm-bdt-3-orange {
  border-top: 3px solid #E68F17; }

.bdr-3-orange, .sm-bdr-3-orange {
  border-right: 3px solid #E68F17; }

.bdb-3-orange, .sm-bdb-3-orange {
  border-bottom: 3px solid #E68F17; }

.bdl-3-orange, .sm-bdl-3-orange {
  border-left: 3px solid #E68F17; }

.bd-3-yellow, .sm-bd-3-yellow {
  border: 3px solid #F5C644; }

.h-bd-3-yellow:hover, .sm-h-bd-3-yellow:hover {
  border: 3px solid #F5C644; }

.bdt-3-yellow, .sm-bdt-3-yellow {
  border-top: 3px solid #F5C644; }

.bdr-3-yellow, .sm-bdr-3-yellow {
  border-right: 3px solid #F5C644; }

.bdb-3-yellow, .sm-bdb-3-yellow {
  border-bottom: 3px solid #F5C644; }

.bdl-3-yellow, .sm-bdl-3-yellow {
  border-left: 3px solid #F5C644; }

.bd-3-coral, .sm-bd-3-coral {
  border: 3px solid #FA5252; }

.h-bd-3-coral:hover, .sm-h-bd-3-coral:hover {
  border: 3px solid #FA5252; }

.bdt-3-coral, .sm-bdt-3-coral {
  border-top: 3px solid #FA5252; }

.bdr-3-coral, .sm-bdr-3-coral {
  border-right: 3px solid #FA5252; }

.bdb-3-coral, .sm-bdb-3-coral {
  border-bottom: 3px solid #FA5252; }

.bdl-3-coral, .sm-bdl-3-coral {
  border-left: 3px solid #FA5252; }

.bd-3-pink, .sm-bd-3-pink {
  border: 3px solid #E64980; }

.h-bd-3-pink:hover, .sm-h-bd-3-pink:hover {
  border: 3px solid #E64980; }

.bdt-3-pink, .sm-bdt-3-pink {
  border-top: 3px solid #E64980; }

.bdr-3-pink, .sm-bdr-3-pink {
  border-right: 3px solid #E64980; }

.bdb-3-pink, .sm-bdb-3-pink {
  border-bottom: 3px solid #E64980; }

.bdl-3-pink, .sm-bdl-3-pink {
  border-left: 3px solid #E64980; }

.bd-3-purple, .sm-bd-3-purple {
  border: 3px solid #BE4BDB; }

.h-bd-3-purple:hover, .sm-h-bd-3-purple:hover {
  border: 3px solid #BE4BDB; }

.bdt-3-purple, .sm-bdt-3-purple {
  border-top: 3px solid #BE4BDB; }

.bdr-3-purple, .sm-bdr-3-purple {
  border-right: 3px solid #BE4BDB; }

.bdb-3-purple, .sm-bdb-3-purple {
  border-bottom: 3px solid #BE4BDB; }

.bdl-3-purple, .sm-bdl-3-purple {
  border-left: 3px solid #BE4BDB; }

.bd-3-teal, .sm-bd-3-teal {
  border: 3px solid #0B7285; }

.h-bd-3-teal:hover, .sm-h-bd-3-teal:hover {
  border: 3px solid #0B7285; }

.bdt-3-teal, .sm-bdt-3-teal {
  border-top: 3px solid #0B7285; }

.bdr-3-teal, .sm-bdr-3-teal {
  border-right: 3px solid #0B7285; }

.bdb-3-teal, .sm-bdb-3-teal {
  border-bottom: 3px solid #0B7285; }

.bdl-3-teal, .sm-bdl-3-teal {
  border-left: 3px solid #0B7285; }

.bd-3-turquoise, .sm-bd-3-turquoise {
  border: 3px solid #15AABF; }

.h-bd-3-turquoise:hover, .sm-h-bd-3-turquoise:hover {
  border: 3px solid #15AABF; }

.bdt-3-turquoise, .sm-bdt-3-turquoise {
  border-top: 3px solid #15AABF; }

.bdr-3-turquoise, .sm-bdr-3-turquoise {
  border-right: 3px solid #15AABF; }

.bdb-3-turquoise, .sm-bdb-3-turquoise {
  border-bottom: 3px solid #15AABF; }

.bdl-3-turquoise, .sm-bdl-3-turquoise {
  border-left: 3px solid #15AABF; }

.bd-3-teal-dark, .sm-bd-3-teal-dark {
  border: 3px solid #0F354D; }

.h-bd-3-teal-dark:hover, .sm-h-bd-3-teal-dark:hover {
  border: 3px solid #0F354D; }

.bdt-3-teal-dark, .sm-bdt-3-teal-dark {
  border-top: 3px solid #0F354D; }

.bdr-3-teal-dark, .sm-bdr-3-teal-dark {
  border-right: 3px solid #0F354D; }

.bdb-3-teal-dark, .sm-bdb-3-teal-dark {
  border-bottom: 3px solid #0F354D; }

.bdl-3-teal-dark, .sm-bdl-3-teal-dark {
  border-left: 3px solid #0F354D; }

.bd-3-purpleV2, .sm-bd-3-purpleV2 {
  border: 3px solid #6D5FFD; }

.h-bd-3-purpleV2:hover, .sm-h-bd-3-purpleV2:hover {
  border: 3px solid #6D5FFD; }

.bdt-3-purpleV2, .sm-bdt-3-purpleV2 {
  border-top: 3px solid #6D5FFD; }

.bdr-3-purpleV2, .sm-bdr-3-purpleV2 {
  border-right: 3px solid #6D5FFD; }

.bdb-3-purpleV2, .sm-bdb-3-purpleV2 {
  border-bottom: 3px solid #6D5FFD; }

.bdl-3-purpleV2, .sm-bdl-3-purpleV2 {
  border-left: 3px solid #6D5FFD; }

.bd-3-orangeV2, .sm-bd-3-orangeV2 {
  border: 3px solid #FAB005; }

.h-bd-3-orangeV2:hover, .sm-h-bd-3-orangeV2:hover {
  border: 3px solid #FAB005; }

.bdt-3-orangeV2, .sm-bdt-3-orangeV2 {
  border-top: 3px solid #FAB005; }

.bdr-3-orangeV2, .sm-bdr-3-orangeV2 {
  border-right: 3px solid #FAB005; }

.bdb-3-orangeV2, .sm-bdb-3-orangeV2 {
  border-bottom: 3px solid #FAB005; }

.bdl-3-orangeV2, .sm-bdl-3-orangeV2 {
  border-left: 3px solid #FAB005; }

.bd-3-redV2, .sm-bd-3-redV2 {
  border: 3px solid #FF1843; }

.h-bd-3-redV2:hover, .sm-h-bd-3-redV2:hover {
  border: 3px solid #FF1843; }

.bdt-3-redV2, .sm-bdt-3-redV2 {
  border-top: 3px solid #FF1843; }

.bdr-3-redV2, .sm-bdr-3-redV2 {
  border-right: 3px solid #FF1843; }

.bdb-3-redV2, .sm-bdb-3-redV2 {
  border-bottom: 3px solid #FF1843; }

.bdl-3-redV2, .sm-bdl-3-redV2 {
  border-left: 3px solid #FF1843; }

.bd-3-yellowV2, .sm-bd-3-yellowV2 {
  border: 3px solid rgba(250, 176, 5, 0.6); }

.h-bd-3-yellowV2:hover, .sm-h-bd-3-yellowV2:hover {
  border: 3px solid rgba(250, 176, 5, 0.6); }

.bdt-3-yellowV2, .sm-bdt-3-yellowV2 {
  border-top: 3px solid rgba(250, 176, 5, 0.6); }

.bdr-3-yellowV2, .sm-bdr-3-yellowV2 {
  border-right: 3px solid rgba(250, 176, 5, 0.6); }

.bdb-3-yellowV2, .sm-bdb-3-yellowV2 {
  border-bottom: 3px solid rgba(250, 176, 5, 0.6); }

.bdl-3-yellowV2, .sm-bdl-3-yellowV2 {
  border-left: 3px solid rgba(250, 176, 5, 0.6); }

.bd-3-primary-light, .sm-bd-3-primary-light {
  border: 3px solid #6D6F7C; }

.h-bd-3-primary-light:hover, .sm-h-bd-3-primary-light:hover {
  border: 3px solid #6D6F7C; }

.bdt-3-primary-light, .sm-bdt-3-primary-light {
  border-top: 3px solid #6D6F7C; }

.bdr-3-primary-light, .sm-bdr-3-primary-light {
  border-right: 3px solid #6D6F7C; }

.bdb-3-primary-light, .sm-bdb-3-primary-light {
  border-bottom: 3px solid #6D6F7C; }

.bdl-3-primary-light, .sm-bdl-3-primary-light {
  border-left: 3px solid #6D6F7C; }

.bd-3-primary, .sm-bd-3-primary {
  border: 3px solid #15192D; }

.h-bd-3-primary:hover, .sm-h-bd-3-primary:hover {
  border: 3px solid #15192D; }

.bdt-3-primary, .sm-bdt-3-primary {
  border-top: 3px solid #15192D; }

.bdr-3-primary, .sm-bdr-3-primary {
  border-right: 3px solid #15192D; }

.bdb-3-primary, .sm-bdb-3-primary {
  border-bottom: 3px solid #15192D; }

.bdl-3-primary, .sm-bdl-3-primary {
  border-left: 3px solid #15192D; }

.bd-3-primary-dark, .sm-bd-3-primary-dark {
  border: 3px solid #080911; }

.h-bd-3-primary-dark:hover, .sm-h-bd-3-primary-dark:hover {
  border: 3px solid #080911; }

.bdt-3-primary-dark, .sm-bdt-3-primary-dark {
  border-top: 3px solid #080911; }

.bdr-3-primary-dark, .sm-bdr-3-primary-dark {
  border-right: 3px solid #080911; }

.bdb-3-primary-dark, .sm-bdb-3-primary-dark {
  border-bottom: 3px solid #080911; }

.bdl-3-primary-dark, .sm-bdl-3-primary-dark {
  border-left: 3px solid #080911; }

.bd-4-gray-lightV2, .sm-bd-4-gray-lightV2 {
  border: 4px solid #F4F6F9; }

.h-bd-4-gray-lightV2:hover, .sm-h-bd-4-gray-lightV2:hover {
  border: 4px solid #F4F6F9; }

.bdt-4-gray-lightV2, .sm-bdt-4-gray-lightV2 {
  border-top: 4px solid #F4F6F9; }

.bdr-4-gray-lightV2, .sm-bdr-4-gray-lightV2 {
  border-right: 4px solid #F4F6F9; }

.bdb-4-gray-lightV2, .sm-bdb-4-gray-lightV2 {
  border-bottom: 4px solid #F4F6F9; }

.bdl-4-gray-lightV2, .sm-bdl-4-gray-lightV2 {
  border-left: 4px solid #F4F6F9; }

.bd-4-black, .sm-bd-4-black {
  border: 4px solid #000000; }

.h-bd-4-black:hover, .sm-h-bd-4-black:hover {
  border: 4px solid #000000; }

.bdt-4-black, .sm-bdt-4-black {
  border-top: 4px solid #000000; }

.bdr-4-black, .sm-bdr-4-black {
  border-right: 4px solid #000000; }

.bdb-4-black, .sm-bdb-4-black {
  border-bottom: 4px solid #000000; }

.bdl-4-black, .sm-bdl-4-black {
  border-left: 4px solid #000000; }

.bd-4-white, .sm-bd-4-white {
  border: 4px solid #ffffff; }

.h-bd-4-white:hover, .sm-h-bd-4-white:hover {
  border: 4px solid #ffffff; }

.bdt-4-white, .sm-bdt-4-white {
  border-top: 4px solid #ffffff; }

.bdr-4-white, .sm-bdr-4-white {
  border-right: 4px solid #ffffff; }

.bdb-4-white, .sm-bdb-4-white {
  border-bottom: 4px solid #ffffff; }

.bdl-4-white, .sm-bdl-4-white {
  border-left: 4px solid #ffffff; }

.bd-4-gray-lighter, .sm-bd-4-gray-lighter {
  border: 4px solid #e8ebed; }

.h-bd-4-gray-lighter:hover, .sm-h-bd-4-gray-lighter:hover {
  border: 4px solid #e8ebed; }

.bdt-4-gray-lighter, .sm-bdt-4-gray-lighter {
  border-top: 4px solid #e8ebed; }

.bdr-4-gray-lighter, .sm-bdr-4-gray-lighter {
  border-right: 4px solid #e8ebed; }

.bdb-4-gray-lighter, .sm-bdb-4-gray-lighter {
  border-bottom: 4px solid #e8ebed; }

.bdl-4-gray-lighter, .sm-bdl-4-gray-lighter {
  border-left: 4px solid #e8ebed; }

.bd-4-gray-light, .sm-bd-4-gray-light {
  border: 4px solid #D9D9D9; }

.h-bd-4-gray-light:hover, .sm-h-bd-4-gray-light:hover {
  border: 4px solid #D9D9D9; }

.bdt-4-gray-light, .sm-bdt-4-gray-light {
  border-top: 4px solid #D9D9D9; }

.bdr-4-gray-light, .sm-bdr-4-gray-light {
  border-right: 4px solid #D9D9D9; }

.bdb-4-gray-light, .sm-bdb-4-gray-light {
  border-bottom: 4px solid #D9D9D9; }

.bdl-4-gray-light, .sm-bdl-4-gray-light {
  border-left: 4px solid #D9D9D9; }

.bd-4-gray, .sm-bd-4-gray {
  border: 4px solid #B3B3B3; }

.h-bd-4-gray:hover, .sm-h-bd-4-gray:hover {
  border: 4px solid #B3B3B3; }

.bdt-4-gray, .sm-bdt-4-gray {
  border-top: 4px solid #B3B3B3; }

.bdr-4-gray, .sm-bdr-4-gray {
  border-right: 4px solid #B3B3B3; }

.bdb-4-gray, .sm-bdb-4-gray {
  border-bottom: 4px solid #B3B3B3; }

.bdl-4-gray, .sm-bdl-4-gray {
  border-left: 4px solid #B3B3B3; }

.bd-4-gray-dark, .sm-bd-4-gray-dark {
  border: 4px solid #808080; }

.h-bd-4-gray-dark:hover, .sm-h-bd-4-gray-dark:hover {
  border: 4px solid #808080; }

.bdt-4-gray-dark, .sm-bdt-4-gray-dark {
  border-top: 4px solid #808080; }

.bdr-4-gray-dark, .sm-bdr-4-gray-dark {
  border-right: 4px solid #808080; }

.bdb-4-gray-dark, .sm-bdb-4-gray-dark {
  border-bottom: 4px solid #808080; }

.bdl-4-gray-dark, .sm-bdl-4-gray-dark {
  border-left: 4px solid #808080; }

.bd-4-gray-darker, .sm-bd-4-gray-darker {
  border: 4px solid #4A4A4A; }

.h-bd-4-gray-darker:hover, .sm-h-bd-4-gray-darker:hover {
  border: 4px solid #4A4A4A; }

.bdt-4-gray-darker, .sm-bdt-4-gray-darker {
  border-top: 4px solid #4A4A4A; }

.bdr-4-gray-darker, .sm-bdr-4-gray-darker {
  border-right: 4px solid #4A4A4A; }

.bdb-4-gray-darker, .sm-bdb-4-gray-darker {
  border-bottom: 4px solid #4A4A4A; }

.bdl-4-gray-darker, .sm-bdl-4-gray-darker {
  border-left: 4px solid #4A4A4A; }

.bd-4-alert, .sm-bd-4-alert {
  border: 4px solid #EE1233; }

.h-bd-4-alert:hover, .sm-h-bd-4-alert:hover {
  border: 4px solid #EE1233; }

.bdt-4-alert, .sm-bdt-4-alert {
  border-top: 4px solid #EE1233; }

.bdr-4-alert, .sm-bdr-4-alert {
  border-right: 4px solid #EE1233; }

.bdb-4-alert, .sm-bdb-4-alert {
  border-bottom: 4px solid #EE1233; }

.bdl-4-alert, .sm-bdl-4-alert {
  border-left: 4px solid #EE1233; }

.bd-4-alert-light, .sm-bd-4-alert-light {
  border: 4px solid #FFEBEB; }

.h-bd-4-alert-light:hover, .sm-h-bd-4-alert-light:hover {
  border: 4px solid #FFEBEB; }

.bdt-4-alert-light, .sm-bdt-4-alert-light {
  border-top: 4px solid #FFEBEB; }

.bdr-4-alert-light, .sm-bdr-4-alert-light {
  border-right: 4px solid #FFEBEB; }

.bdb-4-alert-light, .sm-bdb-4-alert-light {
  border-bottom: 4px solid #FFEBEB; }

.bdl-4-alert-light, .sm-bdl-4-alert-light {
  border-left: 4px solid #FFEBEB; }

.bd-4-success, .sm-bd-4-success {
  border: 4px solid #40C057; }

.h-bd-4-success:hover, .sm-h-bd-4-success:hover {
  border: 4px solid #40C057; }

.bdt-4-success, .sm-bdt-4-success {
  border-top: 4px solid #40C057; }

.bdr-4-success, .sm-bdr-4-success {
  border-right: 4px solid #40C057; }

.bdb-4-success, .sm-bdb-4-success {
  border-bottom: 4px solid #40C057; }

.bdl-4-success, .sm-bdl-4-success {
  border-left: 4px solid #40C057; }

.bd-4-success-light, .sm-bd-4-success-light {
  border: 4px solid #E6FFEA; }

.h-bd-4-success-light:hover, .sm-h-bd-4-success-light:hover {
  border: 4px solid #E6FFEA; }

.bdt-4-success-light, .sm-bdt-4-success-light {
  border-top: 4px solid #E6FFEA; }

.bdr-4-success-light, .sm-bdr-4-success-light {
  border-right: 4px solid #E6FFEA; }

.bdb-4-success-light, .sm-bdb-4-success-light {
  border-bottom: 4px solid #E6FFEA; }

.bdl-4-success-light, .sm-bdl-4-success-light {
  border-left: 4px solid #E6FFEA; }

.bd-4-info, .sm-bd-4-info {
  border: 4px solid #4dadf7; }

.h-bd-4-info:hover, .sm-h-bd-4-info:hover {
  border: 4px solid #4dadf7; }

.bdt-4-info, .sm-bdt-4-info {
  border-top: 4px solid #4dadf7; }

.bdr-4-info, .sm-bdr-4-info {
  border-right: 4px solid #4dadf7; }

.bdb-4-info, .sm-bdb-4-info {
  border-bottom: 4px solid #4dadf7; }

.bdl-4-info, .sm-bdl-4-info {
  border-left: 4px solid #4dadf7; }

.bd-4-info-light, .sm-bd-4-info-light {
  border: 4px solid #d0eaff; }

.h-bd-4-info-light:hover, .sm-h-bd-4-info-light:hover {
  border: 4px solid #d0eaff; }

.bdt-4-info-light, .sm-bdt-4-info-light {
  border-top: 4px solid #d0eaff; }

.bdr-4-info-light, .sm-bdr-4-info-light {
  border-right: 4px solid #d0eaff; }

.bdb-4-info-light, .sm-bdb-4-info-light {
  border-bottom: 4px solid #d0eaff; }

.bdl-4-info-light, .sm-bdl-4-info-light {
  border-left: 4px solid #d0eaff; }

.bd-4-orange, .sm-bd-4-orange {
  border: 4px solid #E68F17; }

.h-bd-4-orange:hover, .sm-h-bd-4-orange:hover {
  border: 4px solid #E68F17; }

.bdt-4-orange, .sm-bdt-4-orange {
  border-top: 4px solid #E68F17; }

.bdr-4-orange, .sm-bdr-4-orange {
  border-right: 4px solid #E68F17; }

.bdb-4-orange, .sm-bdb-4-orange {
  border-bottom: 4px solid #E68F17; }

.bdl-4-orange, .sm-bdl-4-orange {
  border-left: 4px solid #E68F17; }

.bd-4-yellow, .sm-bd-4-yellow {
  border: 4px solid #F5C644; }

.h-bd-4-yellow:hover, .sm-h-bd-4-yellow:hover {
  border: 4px solid #F5C644; }

.bdt-4-yellow, .sm-bdt-4-yellow {
  border-top: 4px solid #F5C644; }

.bdr-4-yellow, .sm-bdr-4-yellow {
  border-right: 4px solid #F5C644; }

.bdb-4-yellow, .sm-bdb-4-yellow {
  border-bottom: 4px solid #F5C644; }

.bdl-4-yellow, .sm-bdl-4-yellow {
  border-left: 4px solid #F5C644; }

.bd-4-coral, .sm-bd-4-coral {
  border: 4px solid #FA5252; }

.h-bd-4-coral:hover, .sm-h-bd-4-coral:hover {
  border: 4px solid #FA5252; }

.bdt-4-coral, .sm-bdt-4-coral {
  border-top: 4px solid #FA5252; }

.bdr-4-coral, .sm-bdr-4-coral {
  border-right: 4px solid #FA5252; }

.bdb-4-coral, .sm-bdb-4-coral {
  border-bottom: 4px solid #FA5252; }

.bdl-4-coral, .sm-bdl-4-coral {
  border-left: 4px solid #FA5252; }

.bd-4-pink, .sm-bd-4-pink {
  border: 4px solid #E64980; }

.h-bd-4-pink:hover, .sm-h-bd-4-pink:hover {
  border: 4px solid #E64980; }

.bdt-4-pink, .sm-bdt-4-pink {
  border-top: 4px solid #E64980; }

.bdr-4-pink, .sm-bdr-4-pink {
  border-right: 4px solid #E64980; }

.bdb-4-pink, .sm-bdb-4-pink {
  border-bottom: 4px solid #E64980; }

.bdl-4-pink, .sm-bdl-4-pink {
  border-left: 4px solid #E64980; }

.bd-4-purple, .sm-bd-4-purple {
  border: 4px solid #BE4BDB; }

.h-bd-4-purple:hover, .sm-h-bd-4-purple:hover {
  border: 4px solid #BE4BDB; }

.bdt-4-purple, .sm-bdt-4-purple {
  border-top: 4px solid #BE4BDB; }

.bdr-4-purple, .sm-bdr-4-purple {
  border-right: 4px solid #BE4BDB; }

.bdb-4-purple, .sm-bdb-4-purple {
  border-bottom: 4px solid #BE4BDB; }

.bdl-4-purple, .sm-bdl-4-purple {
  border-left: 4px solid #BE4BDB; }

.bd-4-teal, .sm-bd-4-teal {
  border: 4px solid #0B7285; }

.h-bd-4-teal:hover, .sm-h-bd-4-teal:hover {
  border: 4px solid #0B7285; }

.bdt-4-teal, .sm-bdt-4-teal {
  border-top: 4px solid #0B7285; }

.bdr-4-teal, .sm-bdr-4-teal {
  border-right: 4px solid #0B7285; }

.bdb-4-teal, .sm-bdb-4-teal {
  border-bottom: 4px solid #0B7285; }

.bdl-4-teal, .sm-bdl-4-teal {
  border-left: 4px solid #0B7285; }

.bd-4-turquoise, .sm-bd-4-turquoise {
  border: 4px solid #15AABF; }

.h-bd-4-turquoise:hover, .sm-h-bd-4-turquoise:hover {
  border: 4px solid #15AABF; }

.bdt-4-turquoise, .sm-bdt-4-turquoise {
  border-top: 4px solid #15AABF; }

.bdr-4-turquoise, .sm-bdr-4-turquoise {
  border-right: 4px solid #15AABF; }

.bdb-4-turquoise, .sm-bdb-4-turquoise {
  border-bottom: 4px solid #15AABF; }

.bdl-4-turquoise, .sm-bdl-4-turquoise {
  border-left: 4px solid #15AABF; }

.bd-4-teal-dark, .sm-bd-4-teal-dark {
  border: 4px solid #0F354D; }

.h-bd-4-teal-dark:hover, .sm-h-bd-4-teal-dark:hover {
  border: 4px solid #0F354D; }

.bdt-4-teal-dark, .sm-bdt-4-teal-dark {
  border-top: 4px solid #0F354D; }

.bdr-4-teal-dark, .sm-bdr-4-teal-dark {
  border-right: 4px solid #0F354D; }

.bdb-4-teal-dark, .sm-bdb-4-teal-dark {
  border-bottom: 4px solid #0F354D; }

.bdl-4-teal-dark, .sm-bdl-4-teal-dark {
  border-left: 4px solid #0F354D; }

.bd-4-purpleV2, .sm-bd-4-purpleV2 {
  border: 4px solid #6D5FFD; }

.h-bd-4-purpleV2:hover, .sm-h-bd-4-purpleV2:hover {
  border: 4px solid #6D5FFD; }

.bdt-4-purpleV2, .sm-bdt-4-purpleV2 {
  border-top: 4px solid #6D5FFD; }

.bdr-4-purpleV2, .sm-bdr-4-purpleV2 {
  border-right: 4px solid #6D5FFD; }

.bdb-4-purpleV2, .sm-bdb-4-purpleV2 {
  border-bottom: 4px solid #6D5FFD; }

.bdl-4-purpleV2, .sm-bdl-4-purpleV2 {
  border-left: 4px solid #6D5FFD; }

.bd-4-orangeV2, .sm-bd-4-orangeV2 {
  border: 4px solid #FAB005; }

.h-bd-4-orangeV2:hover, .sm-h-bd-4-orangeV2:hover {
  border: 4px solid #FAB005; }

.bdt-4-orangeV2, .sm-bdt-4-orangeV2 {
  border-top: 4px solid #FAB005; }

.bdr-4-orangeV2, .sm-bdr-4-orangeV2 {
  border-right: 4px solid #FAB005; }

.bdb-4-orangeV2, .sm-bdb-4-orangeV2 {
  border-bottom: 4px solid #FAB005; }

.bdl-4-orangeV2, .sm-bdl-4-orangeV2 {
  border-left: 4px solid #FAB005; }

.bd-4-redV2, .sm-bd-4-redV2 {
  border: 4px solid #FF1843; }

.h-bd-4-redV2:hover, .sm-h-bd-4-redV2:hover {
  border: 4px solid #FF1843; }

.bdt-4-redV2, .sm-bdt-4-redV2 {
  border-top: 4px solid #FF1843; }

.bdr-4-redV2, .sm-bdr-4-redV2 {
  border-right: 4px solid #FF1843; }

.bdb-4-redV2, .sm-bdb-4-redV2 {
  border-bottom: 4px solid #FF1843; }

.bdl-4-redV2, .sm-bdl-4-redV2 {
  border-left: 4px solid #FF1843; }

.bd-4-yellowV2, .sm-bd-4-yellowV2 {
  border: 4px solid rgba(250, 176, 5, 0.6); }

.h-bd-4-yellowV2:hover, .sm-h-bd-4-yellowV2:hover {
  border: 4px solid rgba(250, 176, 5, 0.6); }

.bdt-4-yellowV2, .sm-bdt-4-yellowV2 {
  border-top: 4px solid rgba(250, 176, 5, 0.6); }

.bdr-4-yellowV2, .sm-bdr-4-yellowV2 {
  border-right: 4px solid rgba(250, 176, 5, 0.6); }

.bdb-4-yellowV2, .sm-bdb-4-yellowV2 {
  border-bottom: 4px solid rgba(250, 176, 5, 0.6); }

.bdl-4-yellowV2, .sm-bdl-4-yellowV2 {
  border-left: 4px solid rgba(250, 176, 5, 0.6); }

.bd-4-primary-light, .sm-bd-4-primary-light {
  border: 4px solid #6D6F7C; }

.h-bd-4-primary-light:hover, .sm-h-bd-4-primary-light:hover {
  border: 4px solid #6D6F7C; }

.bdt-4-primary-light, .sm-bdt-4-primary-light {
  border-top: 4px solid #6D6F7C; }

.bdr-4-primary-light, .sm-bdr-4-primary-light {
  border-right: 4px solid #6D6F7C; }

.bdb-4-primary-light, .sm-bdb-4-primary-light {
  border-bottom: 4px solid #6D6F7C; }

.bdl-4-primary-light, .sm-bdl-4-primary-light {
  border-left: 4px solid #6D6F7C; }

.bd-4-primary, .sm-bd-4-primary {
  border: 4px solid #15192D; }

.h-bd-4-primary:hover, .sm-h-bd-4-primary:hover {
  border: 4px solid #15192D; }

.bdt-4-primary, .sm-bdt-4-primary {
  border-top: 4px solid #15192D; }

.bdr-4-primary, .sm-bdr-4-primary {
  border-right: 4px solid #15192D; }

.bdb-4-primary, .sm-bdb-4-primary {
  border-bottom: 4px solid #15192D; }

.bdl-4-primary, .sm-bdl-4-primary {
  border-left: 4px solid #15192D; }

.bd-4-primary-dark, .sm-bd-4-primary-dark {
  border: 4px solid #080911; }

.h-bd-4-primary-dark:hover, .sm-h-bd-4-primary-dark:hover {
  border: 4px solid #080911; }

.bdt-4-primary-dark, .sm-bdt-4-primary-dark {
  border-top: 4px solid #080911; }

.bdr-4-primary-dark, .sm-bdr-4-primary-dark {
  border-right: 4px solid #080911; }

.bdb-4-primary-dark, .sm-bdb-4-primary-dark {
  border-bottom: 4px solid #080911; }

.bdl-4-primary-dark, .sm-bdl-4-primary-dark {
  border-left: 4px solid #080911; }

.bd-radius-0, .sm-bd-radius-0 {
  border-radius: 0px; }

.bd-radius-1, .sm-bd-radius-1 {
  border-radius: 1px; }

.bd-radius-2, .sm-bd-radius-2 {
  border-radius: 2px; }

.bd-radius-3, .sm-bd-radius-3 {
  border-radius: 3px; }

.bd-radius-4, .sm-bd-radius-4 {
  border-radius: 4px; }

.bd-radius-5, .sm-bd-radius-5 {
  border-radius: 5px; }

.bd-radius-6, .sm-bd-radius-6 {
  border-radius: 6px; }

.bd-radius-7, .sm-bd-radius-7 {
  border-radius: 7px; }

.bd-radius-8, .sm-bd-radius-8 {
  border-radius: 8px; }

.bd-radius-9, .sm-bd-radius-9 {
  border-radius: 9px; }

.bd-radius-10, .sm-bd-radius-10 {
  border-radius: 10px; }

.bd-radius-11, .sm-bd-radius-11 {
  border-radius: 11px; }

.bd-radius-12, .sm-bd-radius-12 {
  border-radius: 12px; }

.bd-radius-13, .sm-bd-radius-13 {
  border-radius: 13px; }

.bd-radius-14, .sm-bd-radius-14 {
  border-radius: 14px; }

.bd-radius-15, .sm-bd-radius-15 {
  border-radius: 15px; }

.bd-radius-16, .sm-bd-radius-16 {
  border-radius: 16px; }

.bd-radius-17, .sm-bd-radius-17 {
  border-radius: 17px; }

.bd-radius-18, .sm-bd-radius-18 {
  border-radius: 18px; }

.bd-radius-19, .sm-bd-radius-19 {
  border-radius: 19px; }

.bd-radius-20, .sm-bd-radius-20 {
  border-radius: 20px; }

.bd-radius-21, .sm-bd-radius-21 {
  border-radius: 21px; }

.bd-radius-22, .sm-bd-radius-22 {
  border-radius: 22px; }

.bd-radius-23, .sm-bd-radius-23 {
  border-radius: 23px; }

.bd-radius-24, .sm-bd-radius-24 {
  border-radius: 24px; }

.bd-radius-25, .sm-bd-radius-25 {
  border-radius: 25px; }

.bd-radius-26, .sm-bd-radius-26 {
  border-radius: 26px; }

.bd-radius-27, .sm-bd-radius-27 {
  border-radius: 27px; }

.bd-radius-28, .sm-bd-radius-28 {
  border-radius: 28px; }

.bd-radius-29, .sm-bd-radius-29 {
  border-radius: 29px; }

.bd-radius-30, .sm-bd-radius-30 {
  border-radius: 30px; }

.bd-radius-31, .sm-bd-radius-31 {
  border-radius: 31px; }

.bd-radius-32, .sm-bd-radius-32 {
  border-radius: 32px; }

.bd-radius-33, .sm-bd-radius-33 {
  border-radius: 33px; }

.bd-radius-34, .sm-bd-radius-34 {
  border-radius: 34px; }

.bd-radius-35, .sm-bd-radius-35 {
  border-radius: 35px; }

.bd-radius-36, .sm-bd-radius-36 {
  border-radius: 36px; }

.bd-radius-37, .sm-bd-radius-37 {
  border-radius: 37px; }

.bd-radius-38, .sm-bd-radius-38 {
  border-radius: 38px; }

.bd-radius-39, .sm-bd-radius-39 {
  border-radius: 39px; }

.bd-radius-40, .sm-bd-radius-40 {
  border-radius: 40px; }

.bd-radius-41, .sm-bd-radius-41 {
  border-radius: 41px; }

.bd-radius-42, .sm-bd-radius-42 {
  border-radius: 42px; }

.bd-radius-43, .sm-bd-radius-43 {
  border-radius: 43px; }

.bd-radius-44, .sm-bd-radius-44 {
  border-radius: 44px; }

.bd-radius-45, .sm-bd-radius-45 {
  border-radius: 45px; }

.bd-radius-46, .sm-bd-radius-46 {
  border-radius: 46px; }

.bd-radius-47, .sm-bd-radius-47 {
  border-radius: 47px; }

.bd-radius-48, .sm-bd-radius-48 {
  border-radius: 48px; }

.bd-radius-49, .sm-bd-radius-49 {
  border-radius: 49px; }

.bd-radius-50, .sm-bd-radius-50 {
  border-radius: 50px; }

.muted, .sm-muted {
  opacity: 0.4; }

.uppercase, .sm-uppercase {
  text-transform: uppercase; }

.capitalize, .sm-capitalize {
  text-transform: capitalize; }

.ovf-hidden, .sm-ovf-hidden {
  overflow: hidden; }

.ovf-scroll, .sm-ovf-scroll {
  overflow: scroll; }

.bd-radius, .sm-bd-radius {
  border-radius: 4px; }

.bdtl-radius, .sm-bdtl-radius {
  border-top-left-radius: 4px; }

.bdtr-radius, .sm-bdtr-radius {
  border-top-right-radius: 4px; }

.bdbl-radius, .sm-bdbl-radius {
  border-bottom-left-radius: 4px; }

.bdbr-radius, .sm-bdbr-radius {
  border-bottom-right-radius: 4px; }

.no-radius, .sm-no-radius {
  border-radius: 0; }

.round, .sm-round {
  border-radius: 50%; }

@media (min-width: 641px) {
  .md-mg-auto {
    margin: auto; }
  .md-mgv-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .md-mgh-auto {
    margin-right: auto;
    margin-left: auto; }
  .md-mgt-auto {
    margin-top: auto; }
  .md-mgr-auto {
    margin-right: auto; }
  .md-mgb-auto {
    margin-bottom: auto; }
  .md-mgl-auto {
    margin-left: auto; }
  .md-mg-6 {
    margin: 12rem; }
  .md-mgv-6 {
    margin-top: 12rem;
    margin-bottom: 12rem; }
  .md-mgh-6 {
    margin-right: 12rem;
    margin-left: 12rem; }
  .md-mgt-6 {
    margin-top: 12rem; }
  .md-mgr-6 {
    margin-right: 12rem; }
  .md-mgb-6 {
    margin-bottom: 12rem; }
  .md-mgl-6 {
    margin-left: 12rem; }
  .md-pg-6 {
    padding: 12rem; }
  .md-pgv-6 {
    padding-top: 12rem;
    padding-bottom: 12rem; }
  .md-pgh-6 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .md-pgt-6 {
    padding-top: 12rem; }
  .md-pgr-6 {
    padding-right: 12rem; }
  .md-pgb-6 {
    padding-bottom: 12rem; }
  .md-pgl-6 {
    padding-left: 12rem; }
  .md-mg-5_5 {
    margin: 11rem; }
  .md-mgv-5_5 {
    margin-top: 11rem;
    margin-bottom: 11rem; }
  .md-mgh-5_5 {
    margin-right: 11rem;
    margin-left: 11rem; }
  .md-mgt-5_5 {
    margin-top: 11rem; }
  .md-mgr-5_5 {
    margin-right: 11rem; }
  .md-mgb-5_5 {
    margin-bottom: 11rem; }
  .md-mgl-5_5 {
    margin-left: 11rem; }
  .md-pg-5_5 {
    padding: 11rem; }
  .md-pgv-5_5 {
    padding-top: 11rem;
    padding-bottom: 11rem; }
  .md-pgh-5_5 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .md-pgt-5_5 {
    padding-top: 11rem; }
  .md-pgr-5_5 {
    padding-right: 11rem; }
  .md-pgb-5_5 {
    padding-bottom: 11rem; }
  .md-pgl-5_5 {
    padding-left: 11rem; }
  .md-mg-5 {
    margin: 10rem; }
  .md-mgv-5 {
    margin-top: 10rem;
    margin-bottom: 10rem; }
  .md-mgh-5 {
    margin-right: 10rem;
    margin-left: 10rem; }
  .md-mgt-5 {
    margin-top: 10rem; }
  .md-mgr-5 {
    margin-right: 10rem; }
  .md-mgb-5 {
    margin-bottom: 10rem; }
  .md-mgl-5 {
    margin-left: 10rem; }
  .md-pg-5 {
    padding: 10rem; }
  .md-pgv-5 {
    padding-top: 10rem;
    padding-bottom: 10rem; }
  .md-pgh-5 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .md-pgt-5 {
    padding-top: 10rem; }
  .md-pgr-5 {
    padding-right: 10rem; }
  .md-pgb-5 {
    padding-bottom: 10rem; }
  .md-pgl-5 {
    padding-left: 10rem; }
  .md-mg-4 {
    margin: 8rem; }
  .md-mgv-4 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .md-mgh-4 {
    margin-right: 8rem;
    margin-left: 8rem; }
  .md-mgt-4 {
    margin-top: 8rem; }
  .md-mgr-4 {
    margin-right: 8rem; }
  .md-mgb-4 {
    margin-bottom: 8rem; }
  .md-mgl-4 {
    margin-left: 8rem; }
  .md-pg-4 {
    padding: 8rem; }
  .md-pgv-4 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .md-pgh-4 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .md-pgt-4 {
    padding-top: 8rem; }
  .md-pgr-4 {
    padding-right: 8rem; }
  .md-pgb-4 {
    padding-bottom: 8rem; }
  .md-pgl-4 {
    padding-left: 8rem; }
  .md-mg-3 {
    margin: 6rem; }
  .md-mgv-3 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .md-mgh-3 {
    margin-right: 6rem;
    margin-left: 6rem; }
  .md-mgt-3 {
    margin-top: 6rem; }
  .md-mgr-3 {
    margin-right: 6rem; }
  .md-mgb-3 {
    margin-bottom: 6rem; }
  .md-mgl-3 {
    margin-left: 6rem; }
  .md-pg-3 {
    padding: 6rem; }
  .md-pgv-3 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .md-pgh-3 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .md-pgt-3 {
    padding-top: 6rem; }
  .md-pgr-3 {
    padding-right: 6rem; }
  .md-pgb-3 {
    padding-bottom: 6rem; }
  .md-pgl-3 {
    padding-left: 6rem; }
  .md-mg-2_5 {
    margin: 5rem; }
  .md-mgv-2_5 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .md-mgh-2_5 {
    margin-right: 5rem;
    margin-left: 5rem; }
  .md-mgt-2_5 {
    margin-top: 5rem; }
  .md-mgr-2_5 {
    margin-right: 5rem; }
  .md-mgb-2_5 {
    margin-bottom: 5rem; }
  .md-mgl-2_5 {
    margin-left: 5rem; }
  .md-pg-2_5 {
    padding: 5rem; }
  .md-pgv-2_5 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .md-pgh-2_5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .md-pgt-2_5 {
    padding-top: 5rem; }
  .md-pgr-2_5 {
    padding-right: 5rem; }
  .md-pgb-2_5 {
    padding-bottom: 5rem; }
  .md-pgl-2_5 {
    padding-left: 5rem; }
  .md-mg-2 {
    margin: 4rem; }
  .md-mgv-2 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .md-mgh-2 {
    margin-right: 4rem;
    margin-left: 4rem; }
  .md-mgt-2 {
    margin-top: 4rem; }
  .md-mgr-2 {
    margin-right: 4rem; }
  .md-mgb-2 {
    margin-bottom: 4rem; }
  .md-mgl-2 {
    margin-left: 4rem; }
  .md-pg-2 {
    padding: 4rem; }
  .md-pgv-2 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .md-pgh-2 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .md-pgt-2 {
    padding-top: 4rem; }
  .md-pgr-2 {
    padding-right: 4rem; }
  .md-pgb-2 {
    padding-bottom: 4rem; }
  .md-pgl-2 {
    padding-left: 4rem; }
  .md-mg-1_75 {
    margin: 3.5rem; }
  .md-mgv-1_75 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .md-mgh-1_75 {
    margin-right: 3.5rem;
    margin-left: 3.5rem; }
  .md-mgt-1_75 {
    margin-top: 3.5rem; }
  .md-mgr-1_75 {
    margin-right: 3.5rem; }
  .md-mgb-1_75 {
    margin-bottom: 3.5rem; }
  .md-mgl-1_75 {
    margin-left: 3.5rem; }
  .md-pg-1_75 {
    padding: 3.5rem; }
  .md-pgv-1_75 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .md-pgh-1_75 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .md-pgt-1_75 {
    padding-top: 3.5rem; }
  .md-pgr-1_75 {
    padding-right: 3.5rem; }
  .md-pgb-1_75 {
    padding-bottom: 3.5rem; }
  .md-pgl-1_75 {
    padding-left: 3.5rem; }
  .md-mg-1_5 {
    margin: 3rem; }
  .md-mgv-1_5 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .md-mgh-1_5 {
    margin-right: 3rem;
    margin-left: 3rem; }
  .md-mgt-1_5 {
    margin-top: 3rem; }
  .md-mgr-1_5 {
    margin-right: 3rem; }
  .md-mgb-1_5 {
    margin-bottom: 3rem; }
  .md-mgl-1_5 {
    margin-left: 3rem; }
  .md-pg-1_5 {
    padding: 3rem; }
  .md-pgv-1_5 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .md-pgh-1_5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .md-pgt-1_5 {
    padding-top: 3rem; }
  .md-pgr-1_5 {
    padding-right: 3rem; }
  .md-pgb-1_5 {
    padding-bottom: 3rem; }
  .md-pgl-1_5 {
    padding-left: 3rem; }
  .md-mg-1_25 {
    margin: 2.5rem; }
  .md-mgv-1_25 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .md-mgh-1_25 {
    margin-right: 2.5rem;
    margin-left: 2.5rem; }
  .md-mgt-1_25 {
    margin-top: 2.5rem; }
  .md-mgr-1_25 {
    margin-right: 2.5rem; }
  .md-mgb-1_25 {
    margin-bottom: 2.5rem; }
  .md-mgl-1_25 {
    margin-left: 2.5rem; }
  .md-pg-1_25 {
    padding: 2.5rem; }
  .md-pgv-1_25 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .md-pgh-1_25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .md-pgt-1_25 {
    padding-top: 2.5rem; }
  .md-pgr-1_25 {
    padding-right: 2.5rem; }
  .md-pgb-1_25 {
    padding-bottom: 2.5rem; }
  .md-pgl-1_25 {
    padding-left: 2.5rem; }
  .md-mg-1 {
    margin: 2rem; }
  .md-mgv-1 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .md-mgh-1 {
    margin-right: 2rem;
    margin-left: 2rem; }
  .md-mgt-1 {
    margin-top: 2rem; }
  .md-mgr-1 {
    margin-right: 2rem; }
  .md-mgb-1 {
    margin-bottom: 2rem; }
  .md-mgl-1 {
    margin-left: 2rem; }
  .md-pg-1 {
    padding: 2rem; }
  .md-pgv-1 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .md-pgh-1 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .md-pgt-1 {
    padding-top: 2rem; }
  .md-pgr-1 {
    padding-right: 2rem; }
  .md-pgb-1 {
    padding-bottom: 2rem; }
  .md-pgl-1 {
    padding-left: 2rem; }
  .md-mg-0_75 {
    margin: 1.5rem; }
  .md-mgv-0_75 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .md-mgh-0_75 {
    margin-right: 1.5rem;
    margin-left: 1.5rem; }
  .md-mgt-0_75 {
    margin-top: 1.5rem; }
  .md-mgr-0_75 {
    margin-right: 1.5rem; }
  .md-mgb-0_75 {
    margin-bottom: 1.5rem; }
  .md-mgl-0_75 {
    margin-left: 1.5rem; }
  .md-pg-0_75 {
    padding: 1.5rem; }
  .md-pgv-0_75 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .md-pgh-0_75 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .md-pgt-0_75 {
    padding-top: 1.5rem; }
  .md-pgr-0_75 {
    padding-right: 1.5rem; }
  .md-pgb-0_75 {
    padding-bottom: 1.5rem; }
  .md-pgl-0_75 {
    padding-left: 1.5rem; }
  .md-mg-0_5 {
    margin: 1rem; }
  .md-mgv-0_5 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .md-mgh-0_5 {
    margin-right: 1rem;
    margin-left: 1rem; }
  .md-mgt-0_5 {
    margin-top: 1rem; }
  .md-mgr-0_5 {
    margin-right: 1rem; }
  .md-mgb-0_5 {
    margin-bottom: 1rem; }
  .md-mgl-0_5 {
    margin-left: 1rem; }
  .md-pg-0_5 {
    padding: 1rem; }
  .md-pgv-0_5 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .md-pgh-0_5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .md-pgt-0_5 {
    padding-top: 1rem; }
  .md-pgr-0_5 {
    padding-right: 1rem; }
  .md-pgb-0_5 {
    padding-bottom: 1rem; }
  .md-pgl-0_5 {
    padding-left: 1rem; }
  .md-mg-0_25 {
    margin: 0.5rem; }
  .md-mgv-0_25 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .md-mgh-0_25 {
    margin-right: 0.5rem;
    margin-left: 0.5rem; }
  .md-mgt-0_25 {
    margin-top: 0.5rem; }
  .md-mgr-0_25 {
    margin-right: 0.5rem; }
  .md-mgb-0_25 {
    margin-bottom: 0.5rem; }
  .md-mgl-0_25 {
    margin-left: 0.5rem; }
  .md-pg-0_25 {
    padding: 0.5rem; }
  .md-pgv-0_25 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .md-pgh-0_25 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .md-pgt-0_25 {
    padding-top: 0.5rem; }
  .md-pgr-0_25 {
    padding-right: 0.5rem; }
  .md-pgb-0_25 {
    padding-bottom: 0.5rem; }
  .md-pgl-0_25 {
    padding-left: 0.5rem; }
  .md-mg-0 {
    margin: 0rem; }
  .md-mgv-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .md-mgh-0 {
    margin-right: 0rem;
    margin-left: 0rem; }
  .md-mgt-0 {
    margin-top: 0rem; }
  .md-mgr-0 {
    margin-right: 0rem; }
  .md-mgb-0 {
    margin-bottom: 0rem; }
  .md-mgl-0 {
    margin-left: 0rem; }
  .md-pg-0 {
    padding: 0rem; }
  .md-pgv-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .md-pgh-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important; }
  .md-pgt-0 {
    padding-top: 0rem; }
  .md-pgr-0 {
    padding-right: 0rem; }
  .md-pgb-0 {
    padding-bottom: 0rem; }
  .md-pgl-0 {
    padding-left: 0rem; }
  .md-text-left {
    text-align: left; }
  .md-text-center {
    text-align: center; }
  .md-text-right {
    text-align: right; }
  .md-break-word {
    word-wrap: break-word; }
  .md-word-normal {
    word-wrap: normal; }
  .md-lh-1_425 {
    line-height: 1.425; }
  .md-lh-1_2 {
    line-height: 1.2; }
  .md-lh-1_1 {
    line-height: 1.1; }
  .md-lh-1 {
    line-height: 1; }
  .md-lh-0_75 {
    line-height: 0.75; }
  .md-fz-100 {
    font-size: 10rem; }
  .md-fz-36 {
    font-size: 3.6rem; }
  .md-fz-34 {
    font-size: 3.4rem; }
  .md-fz-32 {
    font-size: 3.2rem; }
  .md-fz-28 {
    font-size: 2.8rem; }
  .md-fz-26 {
    font-size: 2.6rem; }
  .md-fz-24 {
    font-size: 2.4rem; }
  .md-fz-22 {
    font-size: 2.2rem; }
  .md-fz-21 {
    font-size: 2.1rem; }
  .md-fz-18 {
    font-size: 1.8rem; }
  .md-fz-16 {
    font-size: 1.6rem; }
  .md-fz-14 {
    font-size: 1.4rem; }
  .md-fz-13 {
    font-size: 1.3rem; }
  .md-fz-12 {
    font-size: 1.2rem; }
  .md-fz-11 {
    font-size: 1.1rem; }
  .md-fz-10 {
    font-size: 1rem; }
  .md-fw-700 {
    font-weight: 700; }
  .md-fw-600 {
    font-weight: 600; }
  .md-fw-400 {
    font-weight: 400; }
  .md-fw-300 {
    font-weight: 300; }
  .md-text-gray-lightV2[class] {
    color: #F4F6F9; }
  .md-h-text-gray-lightV2[class]:hover {
    color: #F4F6F9; }
  .md-bg-gray-lightV2 {
    background-color: #F4F6F9; }
  .md-h-bg-gray-lightV2:hover {
    background-color: #F4F6F9; }
  .md-bg-gray-lightV2-muted {
    background-color: rgba(244, 246, 249, 0.2); }
  .md-text-black[class] {
    color: #000000; }
  .md-h-text-black[class]:hover {
    color: #000000; }
  .md-bg-black {
    background-color: #000000; }
  .md-h-bg-black:hover {
    background-color: #000000; }
  .md-bg-black-muted {
    background-color: rgba(0, 0, 0, 0.2); }
  .md-text-white[class] {
    color: #ffffff; }
  .md-h-text-white[class]:hover {
    color: #ffffff; }
  .md-bg-white {
    background-color: #ffffff; }
  .md-h-bg-white:hover {
    background-color: #ffffff; }
  .md-bg-white-muted {
    background-color: rgba(255, 255, 255, 0.2); }
  .md-text-gray-lighter[class] {
    color: #e8ebed; }
  .md-h-text-gray-lighter[class]:hover {
    color: #e8ebed; }
  .md-bg-gray-lighter {
    background-color: #e8ebed; }
  .md-h-bg-gray-lighter:hover {
    background-color: #e8ebed; }
  .md-bg-gray-lighter-muted {
    background-color: rgba(232, 235, 237, 0.2); }
  .md-text-gray-light[class] {
    color: #D9D9D9; }
  .md-h-text-gray-light[class]:hover {
    color: #D9D9D9; }
  .md-bg-gray-light {
    background-color: #D9D9D9; }
  .md-h-bg-gray-light:hover {
    background-color: #D9D9D9; }
  .md-bg-gray-light-muted {
    background-color: rgba(217, 217, 217, 0.2); }
  .md-text-gray[class] {
    color: #B3B3B3; }
  .md-h-text-gray[class]:hover {
    color: #B3B3B3; }
  .md-bg-gray {
    background-color: #B3B3B3; }
  .md-h-bg-gray:hover {
    background-color: #B3B3B3; }
  .md-bg-gray-muted {
    background-color: rgba(179, 179, 179, 0.2); }
  .md-text-gray-dark[class] {
    color: #808080; }
  .md-h-text-gray-dark[class]:hover {
    color: #808080; }
  .md-bg-gray-dark {
    background-color: #808080; }
  .md-h-bg-gray-dark:hover {
    background-color: #808080; }
  .md-bg-gray-dark-muted {
    background-color: rgba(128, 128, 128, 0.2); }
  .md-text-gray-darker[class] {
    color: #4A4A4A; }
  .md-h-text-gray-darker[class]:hover {
    color: #4A4A4A; }
  .md-bg-gray-darker {
    background-color: #4A4A4A; }
  .md-h-bg-gray-darker:hover {
    background-color: #4A4A4A; }
  .md-bg-gray-darker-muted {
    background-color: rgba(74, 74, 74, 0.2); }
  .md-text-alert[class] {
    color: #EE1233; }
  .md-h-text-alert[class]:hover {
    color: #EE1233; }
  .md-bg-alert {
    background-color: #EE1233; }
  .md-h-bg-alert:hover {
    background-color: #EE1233; }
  .md-bg-alert-muted {
    background-color: rgba(238, 18, 51, 0.2); }
  .md-text-alert-light[class] {
    color: #FFEBEB; }
  .md-h-text-alert-light[class]:hover {
    color: #FFEBEB; }
  .md-bg-alert-light {
    background-color: #FFEBEB; }
  .md-h-bg-alert-light:hover {
    background-color: #FFEBEB; }
  .md-bg-alert-light-muted {
    background-color: rgba(255, 235, 235, 0.2); }
  .md-text-success[class] {
    color: #40C057; }
  .md-h-text-success[class]:hover {
    color: #40C057; }
  .md-bg-success {
    background-color: #40C057; }
  .md-h-bg-success:hover {
    background-color: #40C057; }
  .md-bg-success-muted {
    background-color: rgba(64, 192, 87, 0.2); }
  .md-text-success-light[class] {
    color: #E6FFEA; }
  .md-h-text-success-light[class]:hover {
    color: #E6FFEA; }
  .md-bg-success-light {
    background-color: #E6FFEA; }
  .md-h-bg-success-light:hover {
    background-color: #E6FFEA; }
  .md-bg-success-light-muted {
    background-color: rgba(230, 255, 234, 0.2); }
  .md-text-info[class] {
    color: #4dadf7; }
  .md-h-text-info[class]:hover {
    color: #4dadf7; }
  .md-bg-info {
    background-color: #4dadf7; }
  .md-h-bg-info:hover {
    background-color: #4dadf7; }
  .md-bg-info-muted {
    background-color: rgba(77, 173, 247, 0.2); }
  .md-text-info-light[class] {
    color: #d0eaff; }
  .md-h-text-info-light[class]:hover {
    color: #d0eaff; }
  .md-bg-info-light {
    background-color: #d0eaff; }
  .md-h-bg-info-light:hover {
    background-color: #d0eaff; }
  .md-bg-info-light-muted {
    background-color: rgba(208, 234, 255, 0.2); }
  .md-text-orange[class] {
    color: #E68F17; }
  .md-h-text-orange[class]:hover {
    color: #E68F17; }
  .md-bg-orange {
    background-color: #E68F17; }
  .md-h-bg-orange:hover {
    background-color: #E68F17; }
  .md-bg-orange-muted {
    background-color: rgba(230, 143, 23, 0.2); }
  .md-text-yellow[class] {
    color: #F5C644; }
  .md-h-text-yellow[class]:hover {
    color: #F5C644; }
  .md-bg-yellow {
    background-color: #F5C644; }
  .md-h-bg-yellow:hover {
    background-color: #F5C644; }
  .md-bg-yellow-muted {
    background-color: rgba(245, 198, 68, 0.2); }
  .md-text-coral[class] {
    color: #FA5252; }
  .md-h-text-coral[class]:hover {
    color: #FA5252; }
  .md-bg-coral {
    background-color: #FA5252; }
  .md-h-bg-coral:hover {
    background-color: #FA5252; }
  .md-bg-coral-muted {
    background-color: rgba(250, 82, 82, 0.2); }
  .md-text-pink[class] {
    color: #E64980; }
  .md-h-text-pink[class]:hover {
    color: #E64980; }
  .md-bg-pink {
    background-color: #E64980; }
  .md-h-bg-pink:hover {
    background-color: #E64980; }
  .md-bg-pink-muted {
    background-color: rgba(230, 73, 128, 0.2); }
  .md-text-purple[class] {
    color: #BE4BDB; }
  .md-h-text-purple[class]:hover {
    color: #BE4BDB; }
  .md-bg-purple {
    background-color: #BE4BDB; }
  .md-h-bg-purple:hover {
    background-color: #BE4BDB; }
  .md-bg-purple-muted {
    background-color: rgba(190, 75, 219, 0.2); }
  .md-text-teal[class] {
    color: #0B7285; }
  .md-h-text-teal[class]:hover {
    color: #0B7285; }
  .md-bg-teal {
    background-color: #0B7285; }
  .md-h-bg-teal:hover {
    background-color: #0B7285; }
  .md-bg-teal-muted {
    background-color: rgba(11, 114, 133, 0.2); }
  .md-text-turquoise[class] {
    color: #15AABF; }
  .md-h-text-turquoise[class]:hover {
    color: #15AABF; }
  .md-bg-turquoise {
    background-color: #15AABF; }
  .md-h-bg-turquoise:hover {
    background-color: #15AABF; }
  .md-bg-turquoise-muted {
    background-color: rgba(21, 170, 191, 0.2); }
  .md-text-teal-dark[class] {
    color: #0F354D; }
  .md-h-text-teal-dark[class]:hover {
    color: #0F354D; }
  .md-bg-teal-dark {
    background-color: #0F354D; }
  .md-h-bg-teal-dark:hover {
    background-color: #0F354D; }
  .md-bg-teal-dark-muted {
    background-color: rgba(15, 53, 77, 0.2); }
  .md-text-purpleV2[class] {
    color: #6D5FFD; }
  .md-h-text-purpleV2[class]:hover {
    color: #6D5FFD; }
  .md-bg-purpleV2 {
    background-color: #6D5FFD; }
  .md-h-bg-purpleV2:hover {
    background-color: #6D5FFD; }
  .md-bg-purpleV2-muted {
    background-color: rgba(109, 95, 253, 0.2); }
  .md-text-orangeV2[class] {
    color: #FAB005; }
  .md-h-text-orangeV2[class]:hover {
    color: #FAB005; }
  .md-bg-orangeV2 {
    background-color: #FAB005; }
  .md-h-bg-orangeV2:hover {
    background-color: #FAB005; }
  .md-bg-orangeV2-muted {
    background-color: rgba(250, 176, 5, 0.2); }
  .md-text-redV2[class] {
    color: #FF1843; }
  .md-h-text-redV2[class]:hover {
    color: #FF1843; }
  .md-bg-redV2 {
    background-color: #FF1843; }
  .md-h-bg-redV2:hover {
    background-color: #FF1843; }
  .md-bg-redV2-muted {
    background-color: rgba(255, 24, 67, 0.2); }
  .md-text-yellowV2[class] {
    color: rgba(250, 176, 5, 0.6); }
  .md-h-text-yellowV2[class]:hover {
    color: rgba(250, 176, 5, 0.6); }
  .md-bg-yellowV2 {
    background-color: rgba(250, 176, 5, 0.6); }
  .md-h-bg-yellowV2:hover {
    background-color: rgba(250, 176, 5, 0.6); }
  .md-bg-yellowV2-muted {
    background-color: rgba(250, 176, 5, 0.2); }
  .md-text-primary-light[class] {
    color: #6D6F7C; }
  .md-h-text-primary-light[class]:hover {
    color: #6D6F7C; }
  .md-bg-primary-light {
    background-color: #6D6F7C; }
  .md-h-bg-primary-light:hover {
    background-color: #6D6F7C; }
  .md-bg-primary-light-muted {
    background-color: rgba(109, 111, 124, 0.2); }
  .md-text-primary[class] {
    color: #15192D; }
  .md-h-text-primary[class]:hover {
    color: #15192D; }
  .md-bg-primary {
    background-color: #15192D; }
  .md-h-bg-primary:hover {
    background-color: #15192D; }
  .md-bg-primary-muted {
    background-color: rgba(21, 25, 45, 0.2); }
  .md-text-primary-dark[class] {
    color: #080911; }
  .md-h-text-primary-dark[class]:hover {
    color: #080911; }
  .md-bg-primary-dark {
    background-color: #080911; }
  .md-h-bg-primary-dark:hover {
    background-color: #080911; }
  .md-bg-primary-dark-muted {
    background-color: rgba(8, 9, 17, 0.2); }
  .md-bd-0-gray-lightV2 {
    border: 0px solid #F4F6F9; }
  .md-h-bd-0-gray-lightV2:hover {
    border: 0px solid #F4F6F9; }
  .md-bdt-0-gray-lightV2 {
    border-top: 0px solid #F4F6F9; }
  .md-bdr-0-gray-lightV2 {
    border-right: 0px solid #F4F6F9; }
  .md-bdb-0-gray-lightV2 {
    border-bottom: 0px solid #F4F6F9; }
  .md-bdl-0-gray-lightV2 {
    border-left: 0px solid #F4F6F9; }
  .md-bd-0-black {
    border: 0px solid #000000; }
  .md-h-bd-0-black:hover {
    border: 0px solid #000000; }
  .md-bdt-0-black {
    border-top: 0px solid #000000; }
  .md-bdr-0-black {
    border-right: 0px solid #000000; }
  .md-bdb-0-black {
    border-bottom: 0px solid #000000; }
  .md-bdl-0-black {
    border-left: 0px solid #000000; }
  .md-bd-0-white {
    border: 0px solid #ffffff; }
  .md-h-bd-0-white:hover {
    border: 0px solid #ffffff; }
  .md-bdt-0-white {
    border-top: 0px solid #ffffff; }
  .md-bdr-0-white {
    border-right: 0px solid #ffffff; }
  .md-bdb-0-white {
    border-bottom: 0px solid #ffffff; }
  .md-bdl-0-white {
    border-left: 0px solid #ffffff; }
  .md-bd-0-gray-lighter {
    border: 0px solid #e8ebed; }
  .md-h-bd-0-gray-lighter:hover {
    border: 0px solid #e8ebed; }
  .md-bdt-0-gray-lighter {
    border-top: 0px solid #e8ebed; }
  .md-bdr-0-gray-lighter {
    border-right: 0px solid #e8ebed; }
  .md-bdb-0-gray-lighter {
    border-bottom: 0px solid #e8ebed; }
  .md-bdl-0-gray-lighter {
    border-left: 0px solid #e8ebed; }
  .md-bd-0-gray-light {
    border: 0px solid #D9D9D9; }
  .md-h-bd-0-gray-light:hover {
    border: 0px solid #D9D9D9; }
  .md-bdt-0-gray-light {
    border-top: 0px solid #D9D9D9; }
  .md-bdr-0-gray-light {
    border-right: 0px solid #D9D9D9; }
  .md-bdb-0-gray-light {
    border-bottom: 0px solid #D9D9D9; }
  .md-bdl-0-gray-light {
    border-left: 0px solid #D9D9D9; }
  .md-bd-0-gray {
    border: 0px solid #B3B3B3; }
  .md-h-bd-0-gray:hover {
    border: 0px solid #B3B3B3; }
  .md-bdt-0-gray {
    border-top: 0px solid #B3B3B3; }
  .md-bdr-0-gray {
    border-right: 0px solid #B3B3B3; }
  .md-bdb-0-gray {
    border-bottom: 0px solid #B3B3B3; }
  .md-bdl-0-gray {
    border-left: 0px solid #B3B3B3; }
  .md-bd-0-gray-dark {
    border: 0px solid #808080; }
  .md-h-bd-0-gray-dark:hover {
    border: 0px solid #808080; }
  .md-bdt-0-gray-dark {
    border-top: 0px solid #808080; }
  .md-bdr-0-gray-dark {
    border-right: 0px solid #808080; }
  .md-bdb-0-gray-dark {
    border-bottom: 0px solid #808080; }
  .md-bdl-0-gray-dark {
    border-left: 0px solid #808080; }
  .md-bd-0-gray-darker {
    border: 0px solid #4A4A4A; }
  .md-h-bd-0-gray-darker:hover {
    border: 0px solid #4A4A4A; }
  .md-bdt-0-gray-darker {
    border-top: 0px solid #4A4A4A; }
  .md-bdr-0-gray-darker {
    border-right: 0px solid #4A4A4A; }
  .md-bdb-0-gray-darker {
    border-bottom: 0px solid #4A4A4A; }
  .md-bdl-0-gray-darker {
    border-left: 0px solid #4A4A4A; }
  .md-bd-0-alert {
    border: 0px solid #EE1233; }
  .md-h-bd-0-alert:hover {
    border: 0px solid #EE1233; }
  .md-bdt-0-alert {
    border-top: 0px solid #EE1233; }
  .md-bdr-0-alert {
    border-right: 0px solid #EE1233; }
  .md-bdb-0-alert {
    border-bottom: 0px solid #EE1233; }
  .md-bdl-0-alert {
    border-left: 0px solid #EE1233; }
  .md-bd-0-alert-light {
    border: 0px solid #FFEBEB; }
  .md-h-bd-0-alert-light:hover {
    border: 0px solid #FFEBEB; }
  .md-bdt-0-alert-light {
    border-top: 0px solid #FFEBEB; }
  .md-bdr-0-alert-light {
    border-right: 0px solid #FFEBEB; }
  .md-bdb-0-alert-light {
    border-bottom: 0px solid #FFEBEB; }
  .md-bdl-0-alert-light {
    border-left: 0px solid #FFEBEB; }
  .md-bd-0-success {
    border: 0px solid #40C057; }
  .md-h-bd-0-success:hover {
    border: 0px solid #40C057; }
  .md-bdt-0-success {
    border-top: 0px solid #40C057; }
  .md-bdr-0-success {
    border-right: 0px solid #40C057; }
  .md-bdb-0-success {
    border-bottom: 0px solid #40C057; }
  .md-bdl-0-success {
    border-left: 0px solid #40C057; }
  .md-bd-0-success-light {
    border: 0px solid #E6FFEA; }
  .md-h-bd-0-success-light:hover {
    border: 0px solid #E6FFEA; }
  .md-bdt-0-success-light {
    border-top: 0px solid #E6FFEA; }
  .md-bdr-0-success-light {
    border-right: 0px solid #E6FFEA; }
  .md-bdb-0-success-light {
    border-bottom: 0px solid #E6FFEA; }
  .md-bdl-0-success-light {
    border-left: 0px solid #E6FFEA; }
  .md-bd-0-info {
    border: 0px solid #4dadf7; }
  .md-h-bd-0-info:hover {
    border: 0px solid #4dadf7; }
  .md-bdt-0-info {
    border-top: 0px solid #4dadf7; }
  .md-bdr-0-info {
    border-right: 0px solid #4dadf7; }
  .md-bdb-0-info {
    border-bottom: 0px solid #4dadf7; }
  .md-bdl-0-info {
    border-left: 0px solid #4dadf7; }
  .md-bd-0-info-light {
    border: 0px solid #d0eaff; }
  .md-h-bd-0-info-light:hover {
    border: 0px solid #d0eaff; }
  .md-bdt-0-info-light {
    border-top: 0px solid #d0eaff; }
  .md-bdr-0-info-light {
    border-right: 0px solid #d0eaff; }
  .md-bdb-0-info-light {
    border-bottom: 0px solid #d0eaff; }
  .md-bdl-0-info-light {
    border-left: 0px solid #d0eaff; }
  .md-bd-0-orange {
    border: 0px solid #E68F17; }
  .md-h-bd-0-orange:hover {
    border: 0px solid #E68F17; }
  .md-bdt-0-orange {
    border-top: 0px solid #E68F17; }
  .md-bdr-0-orange {
    border-right: 0px solid #E68F17; }
  .md-bdb-0-orange {
    border-bottom: 0px solid #E68F17; }
  .md-bdl-0-orange {
    border-left: 0px solid #E68F17; }
  .md-bd-0-yellow {
    border: 0px solid #F5C644; }
  .md-h-bd-0-yellow:hover {
    border: 0px solid #F5C644; }
  .md-bdt-0-yellow {
    border-top: 0px solid #F5C644; }
  .md-bdr-0-yellow {
    border-right: 0px solid #F5C644; }
  .md-bdb-0-yellow {
    border-bottom: 0px solid #F5C644; }
  .md-bdl-0-yellow {
    border-left: 0px solid #F5C644; }
  .md-bd-0-coral {
    border: 0px solid #FA5252; }
  .md-h-bd-0-coral:hover {
    border: 0px solid #FA5252; }
  .md-bdt-0-coral {
    border-top: 0px solid #FA5252; }
  .md-bdr-0-coral {
    border-right: 0px solid #FA5252; }
  .md-bdb-0-coral {
    border-bottom: 0px solid #FA5252; }
  .md-bdl-0-coral {
    border-left: 0px solid #FA5252; }
  .md-bd-0-pink {
    border: 0px solid #E64980; }
  .md-h-bd-0-pink:hover {
    border: 0px solid #E64980; }
  .md-bdt-0-pink {
    border-top: 0px solid #E64980; }
  .md-bdr-0-pink {
    border-right: 0px solid #E64980; }
  .md-bdb-0-pink {
    border-bottom: 0px solid #E64980; }
  .md-bdl-0-pink {
    border-left: 0px solid #E64980; }
  .md-bd-0-purple {
    border: 0px solid #BE4BDB; }
  .md-h-bd-0-purple:hover {
    border: 0px solid #BE4BDB; }
  .md-bdt-0-purple {
    border-top: 0px solid #BE4BDB; }
  .md-bdr-0-purple {
    border-right: 0px solid #BE4BDB; }
  .md-bdb-0-purple {
    border-bottom: 0px solid #BE4BDB; }
  .md-bdl-0-purple {
    border-left: 0px solid #BE4BDB; }
  .md-bd-0-teal {
    border: 0px solid #0B7285; }
  .md-h-bd-0-teal:hover {
    border: 0px solid #0B7285; }
  .md-bdt-0-teal {
    border-top: 0px solid #0B7285; }
  .md-bdr-0-teal {
    border-right: 0px solid #0B7285; }
  .md-bdb-0-teal {
    border-bottom: 0px solid #0B7285; }
  .md-bdl-0-teal {
    border-left: 0px solid #0B7285; }
  .md-bd-0-turquoise {
    border: 0px solid #15AABF; }
  .md-h-bd-0-turquoise:hover {
    border: 0px solid #15AABF; }
  .md-bdt-0-turquoise {
    border-top: 0px solid #15AABF; }
  .md-bdr-0-turquoise {
    border-right: 0px solid #15AABF; }
  .md-bdb-0-turquoise {
    border-bottom: 0px solid #15AABF; }
  .md-bdl-0-turquoise {
    border-left: 0px solid #15AABF; }
  .md-bd-0-teal-dark {
    border: 0px solid #0F354D; }
  .md-h-bd-0-teal-dark:hover {
    border: 0px solid #0F354D; }
  .md-bdt-0-teal-dark {
    border-top: 0px solid #0F354D; }
  .md-bdr-0-teal-dark {
    border-right: 0px solid #0F354D; }
  .md-bdb-0-teal-dark {
    border-bottom: 0px solid #0F354D; }
  .md-bdl-0-teal-dark {
    border-left: 0px solid #0F354D; }
  .md-bd-0-purpleV2 {
    border: 0px solid #6D5FFD; }
  .md-h-bd-0-purpleV2:hover {
    border: 0px solid #6D5FFD; }
  .md-bdt-0-purpleV2 {
    border-top: 0px solid #6D5FFD; }
  .md-bdr-0-purpleV2 {
    border-right: 0px solid #6D5FFD; }
  .md-bdb-0-purpleV2 {
    border-bottom: 0px solid #6D5FFD; }
  .md-bdl-0-purpleV2 {
    border-left: 0px solid #6D5FFD; }
  .md-bd-0-orangeV2 {
    border: 0px solid #FAB005; }
  .md-h-bd-0-orangeV2:hover {
    border: 0px solid #FAB005; }
  .md-bdt-0-orangeV2 {
    border-top: 0px solid #FAB005; }
  .md-bdr-0-orangeV2 {
    border-right: 0px solid #FAB005; }
  .md-bdb-0-orangeV2 {
    border-bottom: 0px solid #FAB005; }
  .md-bdl-0-orangeV2 {
    border-left: 0px solid #FAB005; }
  .md-bd-0-redV2 {
    border: 0px solid #FF1843; }
  .md-h-bd-0-redV2:hover {
    border: 0px solid #FF1843; }
  .md-bdt-0-redV2 {
    border-top: 0px solid #FF1843; }
  .md-bdr-0-redV2 {
    border-right: 0px solid #FF1843; }
  .md-bdb-0-redV2 {
    border-bottom: 0px solid #FF1843; }
  .md-bdl-0-redV2 {
    border-left: 0px solid #FF1843; }
  .md-bd-0-yellowV2 {
    border: 0px solid rgba(250, 176, 5, 0.6); }
  .md-h-bd-0-yellowV2:hover {
    border: 0px solid rgba(250, 176, 5, 0.6); }
  .md-bdt-0-yellowV2 {
    border-top: 0px solid rgba(250, 176, 5, 0.6); }
  .md-bdr-0-yellowV2 {
    border-right: 0px solid rgba(250, 176, 5, 0.6); }
  .md-bdb-0-yellowV2 {
    border-bottom: 0px solid rgba(250, 176, 5, 0.6); }
  .md-bdl-0-yellowV2 {
    border-left: 0px solid rgba(250, 176, 5, 0.6); }
  .md-bd-0-primary-light {
    border: 0px solid #6D6F7C; }
  .md-h-bd-0-primary-light:hover {
    border: 0px solid #6D6F7C; }
  .md-bdt-0-primary-light {
    border-top: 0px solid #6D6F7C; }
  .md-bdr-0-primary-light {
    border-right: 0px solid #6D6F7C; }
  .md-bdb-0-primary-light {
    border-bottom: 0px solid #6D6F7C; }
  .md-bdl-0-primary-light {
    border-left: 0px solid #6D6F7C; }
  .md-bd-0-primary {
    border: 0px solid #15192D; }
  .md-h-bd-0-primary:hover {
    border: 0px solid #15192D; }
  .md-bdt-0-primary {
    border-top: 0px solid #15192D; }
  .md-bdr-0-primary {
    border-right: 0px solid #15192D; }
  .md-bdb-0-primary {
    border-bottom: 0px solid #15192D; }
  .md-bdl-0-primary {
    border-left: 0px solid #15192D; }
  .md-bd-0-primary-dark {
    border: 0px solid #080911; }
  .md-h-bd-0-primary-dark:hover {
    border: 0px solid #080911; }
  .md-bdt-0-primary-dark {
    border-top: 0px solid #080911; }
  .md-bdr-0-primary-dark {
    border-right: 0px solid #080911; }
  .md-bdb-0-primary-dark {
    border-bottom: 0px solid #080911; }
  .md-bdl-0-primary-dark {
    border-left: 0px solid #080911; }
  .md-bd-1-gray-lightV2 {
    border: 1px solid #F4F6F9; }
  .md-h-bd-1-gray-lightV2:hover {
    border: 1px solid #F4F6F9; }
  .md-bdt-1-gray-lightV2 {
    border-top: 1px solid #F4F6F9; }
  .md-bdr-1-gray-lightV2 {
    border-right: 1px solid #F4F6F9; }
  .md-bdb-1-gray-lightV2 {
    border-bottom: 1px solid #F4F6F9; }
  .md-bdl-1-gray-lightV2 {
    border-left: 1px solid #F4F6F9; }
  .md-bd-1-black {
    border: 1px solid #000000; }
  .md-h-bd-1-black:hover {
    border: 1px solid #000000; }
  .md-bdt-1-black {
    border-top: 1px solid #000000; }
  .md-bdr-1-black {
    border-right: 1px solid #000000; }
  .md-bdb-1-black {
    border-bottom: 1px solid #000000; }
  .md-bdl-1-black {
    border-left: 1px solid #000000; }
  .md-bd-1-white {
    border: 1px solid #ffffff; }
  .md-h-bd-1-white:hover {
    border: 1px solid #ffffff; }
  .md-bdt-1-white {
    border-top: 1px solid #ffffff; }
  .md-bdr-1-white {
    border-right: 1px solid #ffffff; }
  .md-bdb-1-white {
    border-bottom: 1px solid #ffffff; }
  .md-bdl-1-white {
    border-left: 1px solid #ffffff; }
  .md-bd-1-gray-lighter {
    border: 1px solid #e8ebed; }
  .md-h-bd-1-gray-lighter:hover {
    border: 1px solid #e8ebed; }
  .md-bdt-1-gray-lighter {
    border-top: 1px solid #e8ebed; }
  .md-bdr-1-gray-lighter {
    border-right: 1px solid #e8ebed; }
  .md-bdb-1-gray-lighter {
    border-bottom: 1px solid #e8ebed; }
  .md-bdl-1-gray-lighter {
    border-left: 1px solid #e8ebed; }
  .md-bd-1-gray-light {
    border: 1px solid #D9D9D9; }
  .md-h-bd-1-gray-light:hover {
    border: 1px solid #D9D9D9; }
  .md-bdt-1-gray-light {
    border-top: 1px solid #D9D9D9; }
  .md-bdr-1-gray-light {
    border-right: 1px solid #D9D9D9; }
  .md-bdb-1-gray-light {
    border-bottom: 1px solid #D9D9D9; }
  .md-bdl-1-gray-light {
    border-left: 1px solid #D9D9D9; }
  .md-bd-1-gray {
    border: 1px solid #B3B3B3; }
  .md-h-bd-1-gray:hover {
    border: 1px solid #B3B3B3; }
  .md-bdt-1-gray {
    border-top: 1px solid #B3B3B3; }
  .md-bdr-1-gray {
    border-right: 1px solid #B3B3B3; }
  .md-bdb-1-gray {
    border-bottom: 1px solid #B3B3B3; }
  .md-bdl-1-gray {
    border-left: 1px solid #B3B3B3; }
  .md-bd-1-gray-dark {
    border: 1px solid #808080; }
  .md-h-bd-1-gray-dark:hover {
    border: 1px solid #808080; }
  .md-bdt-1-gray-dark {
    border-top: 1px solid #808080; }
  .md-bdr-1-gray-dark {
    border-right: 1px solid #808080; }
  .md-bdb-1-gray-dark {
    border-bottom: 1px solid #808080; }
  .md-bdl-1-gray-dark {
    border-left: 1px solid #808080; }
  .md-bd-1-gray-darker {
    border: 1px solid #4A4A4A; }
  .md-h-bd-1-gray-darker:hover {
    border: 1px solid #4A4A4A; }
  .md-bdt-1-gray-darker {
    border-top: 1px solid #4A4A4A; }
  .md-bdr-1-gray-darker {
    border-right: 1px solid #4A4A4A; }
  .md-bdb-1-gray-darker {
    border-bottom: 1px solid #4A4A4A; }
  .md-bdl-1-gray-darker {
    border-left: 1px solid #4A4A4A; }
  .md-bd-1-alert {
    border: 1px solid #EE1233; }
  .md-h-bd-1-alert:hover {
    border: 1px solid #EE1233; }
  .md-bdt-1-alert {
    border-top: 1px solid #EE1233; }
  .md-bdr-1-alert {
    border-right: 1px solid #EE1233; }
  .md-bdb-1-alert {
    border-bottom: 1px solid #EE1233; }
  .md-bdl-1-alert {
    border-left: 1px solid #EE1233; }
  .md-bd-1-alert-light {
    border: 1px solid #FFEBEB; }
  .md-h-bd-1-alert-light:hover {
    border: 1px solid #FFEBEB; }
  .md-bdt-1-alert-light {
    border-top: 1px solid #FFEBEB; }
  .md-bdr-1-alert-light {
    border-right: 1px solid #FFEBEB; }
  .md-bdb-1-alert-light {
    border-bottom: 1px solid #FFEBEB; }
  .md-bdl-1-alert-light {
    border-left: 1px solid #FFEBEB; }
  .md-bd-1-success {
    border: 1px solid #40C057; }
  .md-h-bd-1-success:hover {
    border: 1px solid #40C057; }
  .md-bdt-1-success {
    border-top: 1px solid #40C057; }
  .md-bdr-1-success {
    border-right: 1px solid #40C057; }
  .md-bdb-1-success {
    border-bottom: 1px solid #40C057; }
  .md-bdl-1-success {
    border-left: 1px solid #40C057; }
  .md-bd-1-success-light {
    border: 1px solid #E6FFEA; }
  .md-h-bd-1-success-light:hover {
    border: 1px solid #E6FFEA; }
  .md-bdt-1-success-light {
    border-top: 1px solid #E6FFEA; }
  .md-bdr-1-success-light {
    border-right: 1px solid #E6FFEA; }
  .md-bdb-1-success-light {
    border-bottom: 1px solid #E6FFEA; }
  .md-bdl-1-success-light {
    border-left: 1px solid #E6FFEA; }
  .md-bd-1-info {
    border: 1px solid #4dadf7; }
  .md-h-bd-1-info:hover {
    border: 1px solid #4dadf7; }
  .md-bdt-1-info {
    border-top: 1px solid #4dadf7; }
  .md-bdr-1-info {
    border-right: 1px solid #4dadf7; }
  .md-bdb-1-info {
    border-bottom: 1px solid #4dadf7; }
  .md-bdl-1-info {
    border-left: 1px solid #4dadf7; }
  .md-bd-1-info-light {
    border: 1px solid #d0eaff; }
  .md-h-bd-1-info-light:hover {
    border: 1px solid #d0eaff; }
  .md-bdt-1-info-light {
    border-top: 1px solid #d0eaff; }
  .md-bdr-1-info-light {
    border-right: 1px solid #d0eaff; }
  .md-bdb-1-info-light {
    border-bottom: 1px solid #d0eaff; }
  .md-bdl-1-info-light {
    border-left: 1px solid #d0eaff; }
  .md-bd-1-orange {
    border: 1px solid #E68F17; }
  .md-h-bd-1-orange:hover {
    border: 1px solid #E68F17; }
  .md-bdt-1-orange {
    border-top: 1px solid #E68F17; }
  .md-bdr-1-orange {
    border-right: 1px solid #E68F17; }
  .md-bdb-1-orange {
    border-bottom: 1px solid #E68F17; }
  .md-bdl-1-orange {
    border-left: 1px solid #E68F17; }
  .md-bd-1-yellow {
    border: 1px solid #F5C644; }
  .md-h-bd-1-yellow:hover {
    border: 1px solid #F5C644; }
  .md-bdt-1-yellow {
    border-top: 1px solid #F5C644; }
  .md-bdr-1-yellow {
    border-right: 1px solid #F5C644; }
  .md-bdb-1-yellow {
    border-bottom: 1px solid #F5C644; }
  .md-bdl-1-yellow {
    border-left: 1px solid #F5C644; }
  .md-bd-1-coral {
    border: 1px solid #FA5252; }
  .md-h-bd-1-coral:hover {
    border: 1px solid #FA5252; }
  .md-bdt-1-coral {
    border-top: 1px solid #FA5252; }
  .md-bdr-1-coral {
    border-right: 1px solid #FA5252; }
  .md-bdb-1-coral {
    border-bottom: 1px solid #FA5252; }
  .md-bdl-1-coral {
    border-left: 1px solid #FA5252; }
  .md-bd-1-pink {
    border: 1px solid #E64980; }
  .md-h-bd-1-pink:hover {
    border: 1px solid #E64980; }
  .md-bdt-1-pink {
    border-top: 1px solid #E64980; }
  .md-bdr-1-pink {
    border-right: 1px solid #E64980; }
  .md-bdb-1-pink {
    border-bottom: 1px solid #E64980; }
  .md-bdl-1-pink {
    border-left: 1px solid #E64980; }
  .md-bd-1-purple {
    border: 1px solid #BE4BDB; }
  .md-h-bd-1-purple:hover {
    border: 1px solid #BE4BDB; }
  .md-bdt-1-purple {
    border-top: 1px solid #BE4BDB; }
  .md-bdr-1-purple {
    border-right: 1px solid #BE4BDB; }
  .md-bdb-1-purple {
    border-bottom: 1px solid #BE4BDB; }
  .md-bdl-1-purple {
    border-left: 1px solid #BE4BDB; }
  .md-bd-1-teal {
    border: 1px solid #0B7285; }
  .md-h-bd-1-teal:hover {
    border: 1px solid #0B7285; }
  .md-bdt-1-teal {
    border-top: 1px solid #0B7285; }
  .md-bdr-1-teal {
    border-right: 1px solid #0B7285; }
  .md-bdb-1-teal {
    border-bottom: 1px solid #0B7285; }
  .md-bdl-1-teal {
    border-left: 1px solid #0B7285; }
  .md-bd-1-turquoise {
    border: 1px solid #15AABF; }
  .md-h-bd-1-turquoise:hover {
    border: 1px solid #15AABF; }
  .md-bdt-1-turquoise {
    border-top: 1px solid #15AABF; }
  .md-bdr-1-turquoise {
    border-right: 1px solid #15AABF; }
  .md-bdb-1-turquoise {
    border-bottom: 1px solid #15AABF; }
  .md-bdl-1-turquoise {
    border-left: 1px solid #15AABF; }
  .md-bd-1-teal-dark {
    border: 1px solid #0F354D; }
  .md-h-bd-1-teal-dark:hover {
    border: 1px solid #0F354D; }
  .md-bdt-1-teal-dark {
    border-top: 1px solid #0F354D; }
  .md-bdr-1-teal-dark {
    border-right: 1px solid #0F354D; }
  .md-bdb-1-teal-dark {
    border-bottom: 1px solid #0F354D; }
  .md-bdl-1-teal-dark {
    border-left: 1px solid #0F354D; }
  .md-bd-1-purpleV2 {
    border: 1px solid #6D5FFD; }
  .md-h-bd-1-purpleV2:hover {
    border: 1px solid #6D5FFD; }
  .md-bdt-1-purpleV2 {
    border-top: 1px solid #6D5FFD; }
  .md-bdr-1-purpleV2 {
    border-right: 1px solid #6D5FFD; }
  .md-bdb-1-purpleV2 {
    border-bottom: 1px solid #6D5FFD; }
  .md-bdl-1-purpleV2 {
    border-left: 1px solid #6D5FFD; }
  .md-bd-1-orangeV2 {
    border: 1px solid #FAB005; }
  .md-h-bd-1-orangeV2:hover {
    border: 1px solid #FAB005; }
  .md-bdt-1-orangeV2 {
    border-top: 1px solid #FAB005; }
  .md-bdr-1-orangeV2 {
    border-right: 1px solid #FAB005; }
  .md-bdb-1-orangeV2 {
    border-bottom: 1px solid #FAB005; }
  .md-bdl-1-orangeV2 {
    border-left: 1px solid #FAB005; }
  .md-bd-1-redV2 {
    border: 1px solid #FF1843; }
  .md-h-bd-1-redV2:hover {
    border: 1px solid #FF1843; }
  .md-bdt-1-redV2 {
    border-top: 1px solid #FF1843; }
  .md-bdr-1-redV2 {
    border-right: 1px solid #FF1843; }
  .md-bdb-1-redV2 {
    border-bottom: 1px solid #FF1843; }
  .md-bdl-1-redV2 {
    border-left: 1px solid #FF1843; }
  .md-bd-1-yellowV2 {
    border: 1px solid rgba(250, 176, 5, 0.6); }
  .md-h-bd-1-yellowV2:hover {
    border: 1px solid rgba(250, 176, 5, 0.6); }
  .md-bdt-1-yellowV2 {
    border-top: 1px solid rgba(250, 176, 5, 0.6); }
  .md-bdr-1-yellowV2 {
    border-right: 1px solid rgba(250, 176, 5, 0.6); }
  .md-bdb-1-yellowV2 {
    border-bottom: 1px solid rgba(250, 176, 5, 0.6); }
  .md-bdl-1-yellowV2 {
    border-left: 1px solid rgba(250, 176, 5, 0.6); }
  .md-bd-1-primary-light {
    border: 1px solid #6D6F7C; }
  .md-h-bd-1-primary-light:hover {
    border: 1px solid #6D6F7C; }
  .md-bdt-1-primary-light {
    border-top: 1px solid #6D6F7C; }
  .md-bdr-1-primary-light {
    border-right: 1px solid #6D6F7C; }
  .md-bdb-1-primary-light {
    border-bottom: 1px solid #6D6F7C; }
  .md-bdl-1-primary-light {
    border-left: 1px solid #6D6F7C; }
  .md-bd-1-primary {
    border: 1px solid #15192D; }
  .md-h-bd-1-primary:hover {
    border: 1px solid #15192D; }
  .md-bdt-1-primary {
    border-top: 1px solid #15192D; }
  .md-bdr-1-primary {
    border-right: 1px solid #15192D; }
  .md-bdb-1-primary {
    border-bottom: 1px solid #15192D; }
  .md-bdl-1-primary {
    border-left: 1px solid #15192D; }
  .md-bd-1-primary-dark {
    border: 1px solid #080911; }
  .md-h-bd-1-primary-dark:hover {
    border: 1px solid #080911; }
  .md-bdt-1-primary-dark {
    border-top: 1px solid #080911; }
  .md-bdr-1-primary-dark {
    border-right: 1px solid #080911; }
  .md-bdb-1-primary-dark {
    border-bottom: 1px solid #080911; }
  .md-bdl-1-primary-dark {
    border-left: 1px solid #080911; }
  .md-bd-2-gray-lightV2 {
    border: 2px solid #F4F6F9; }
  .md-h-bd-2-gray-lightV2:hover {
    border: 2px solid #F4F6F9; }
  .md-bdt-2-gray-lightV2 {
    border-top: 2px solid #F4F6F9; }
  .md-bdr-2-gray-lightV2 {
    border-right: 2px solid #F4F6F9; }
  .md-bdb-2-gray-lightV2 {
    border-bottom: 2px solid #F4F6F9; }
  .md-bdl-2-gray-lightV2 {
    border-left: 2px solid #F4F6F9; }
  .md-bd-2-black {
    border: 2px solid #000000; }
  .md-h-bd-2-black:hover {
    border: 2px solid #000000; }
  .md-bdt-2-black {
    border-top: 2px solid #000000; }
  .md-bdr-2-black {
    border-right: 2px solid #000000; }
  .md-bdb-2-black {
    border-bottom: 2px solid #000000; }
  .md-bdl-2-black {
    border-left: 2px solid #000000; }
  .md-bd-2-white {
    border: 2px solid #ffffff; }
  .md-h-bd-2-white:hover {
    border: 2px solid #ffffff; }
  .md-bdt-2-white {
    border-top: 2px solid #ffffff; }
  .md-bdr-2-white {
    border-right: 2px solid #ffffff; }
  .md-bdb-2-white {
    border-bottom: 2px solid #ffffff; }
  .md-bdl-2-white {
    border-left: 2px solid #ffffff; }
  .md-bd-2-gray-lighter {
    border: 2px solid #e8ebed; }
  .md-h-bd-2-gray-lighter:hover {
    border: 2px solid #e8ebed; }
  .md-bdt-2-gray-lighter {
    border-top: 2px solid #e8ebed; }
  .md-bdr-2-gray-lighter {
    border-right: 2px solid #e8ebed; }
  .md-bdb-2-gray-lighter {
    border-bottom: 2px solid #e8ebed; }
  .md-bdl-2-gray-lighter {
    border-left: 2px solid #e8ebed; }
  .md-bd-2-gray-light {
    border: 2px solid #D9D9D9; }
  .md-h-bd-2-gray-light:hover {
    border: 2px solid #D9D9D9; }
  .md-bdt-2-gray-light {
    border-top: 2px solid #D9D9D9; }
  .md-bdr-2-gray-light {
    border-right: 2px solid #D9D9D9; }
  .md-bdb-2-gray-light {
    border-bottom: 2px solid #D9D9D9; }
  .md-bdl-2-gray-light {
    border-left: 2px solid #D9D9D9; }
  .md-bd-2-gray {
    border: 2px solid #B3B3B3; }
  .md-h-bd-2-gray:hover {
    border: 2px solid #B3B3B3; }
  .md-bdt-2-gray {
    border-top: 2px solid #B3B3B3; }
  .md-bdr-2-gray {
    border-right: 2px solid #B3B3B3; }
  .md-bdb-2-gray {
    border-bottom: 2px solid #B3B3B3; }
  .md-bdl-2-gray {
    border-left: 2px solid #B3B3B3; }
  .md-bd-2-gray-dark {
    border: 2px solid #808080; }
  .md-h-bd-2-gray-dark:hover {
    border: 2px solid #808080; }
  .md-bdt-2-gray-dark {
    border-top: 2px solid #808080; }
  .md-bdr-2-gray-dark {
    border-right: 2px solid #808080; }
  .md-bdb-2-gray-dark {
    border-bottom: 2px solid #808080; }
  .md-bdl-2-gray-dark {
    border-left: 2px solid #808080; }
  .md-bd-2-gray-darker {
    border: 2px solid #4A4A4A; }
  .md-h-bd-2-gray-darker:hover {
    border: 2px solid #4A4A4A; }
  .md-bdt-2-gray-darker {
    border-top: 2px solid #4A4A4A; }
  .md-bdr-2-gray-darker {
    border-right: 2px solid #4A4A4A; }
  .md-bdb-2-gray-darker {
    border-bottom: 2px solid #4A4A4A; }
  .md-bdl-2-gray-darker {
    border-left: 2px solid #4A4A4A; }
  .md-bd-2-alert {
    border: 2px solid #EE1233; }
  .md-h-bd-2-alert:hover {
    border: 2px solid #EE1233; }
  .md-bdt-2-alert {
    border-top: 2px solid #EE1233; }
  .md-bdr-2-alert {
    border-right: 2px solid #EE1233; }
  .md-bdb-2-alert {
    border-bottom: 2px solid #EE1233; }
  .md-bdl-2-alert {
    border-left: 2px solid #EE1233; }
  .md-bd-2-alert-light {
    border: 2px solid #FFEBEB; }
  .md-h-bd-2-alert-light:hover {
    border: 2px solid #FFEBEB; }
  .md-bdt-2-alert-light {
    border-top: 2px solid #FFEBEB; }
  .md-bdr-2-alert-light {
    border-right: 2px solid #FFEBEB; }
  .md-bdb-2-alert-light {
    border-bottom: 2px solid #FFEBEB; }
  .md-bdl-2-alert-light {
    border-left: 2px solid #FFEBEB; }
  .md-bd-2-success {
    border: 2px solid #40C057; }
  .md-h-bd-2-success:hover {
    border: 2px solid #40C057; }
  .md-bdt-2-success {
    border-top: 2px solid #40C057; }
  .md-bdr-2-success {
    border-right: 2px solid #40C057; }
  .md-bdb-2-success {
    border-bottom: 2px solid #40C057; }
  .md-bdl-2-success {
    border-left: 2px solid #40C057; }
  .md-bd-2-success-light {
    border: 2px solid #E6FFEA; }
  .md-h-bd-2-success-light:hover {
    border: 2px solid #E6FFEA; }
  .md-bdt-2-success-light {
    border-top: 2px solid #E6FFEA; }
  .md-bdr-2-success-light {
    border-right: 2px solid #E6FFEA; }
  .md-bdb-2-success-light {
    border-bottom: 2px solid #E6FFEA; }
  .md-bdl-2-success-light {
    border-left: 2px solid #E6FFEA; }
  .md-bd-2-info {
    border: 2px solid #4dadf7; }
  .md-h-bd-2-info:hover {
    border: 2px solid #4dadf7; }
  .md-bdt-2-info {
    border-top: 2px solid #4dadf7; }
  .md-bdr-2-info {
    border-right: 2px solid #4dadf7; }
  .md-bdb-2-info {
    border-bottom: 2px solid #4dadf7; }
  .md-bdl-2-info {
    border-left: 2px solid #4dadf7; }
  .md-bd-2-info-light {
    border: 2px solid #d0eaff; }
  .md-h-bd-2-info-light:hover {
    border: 2px solid #d0eaff; }
  .md-bdt-2-info-light {
    border-top: 2px solid #d0eaff; }
  .md-bdr-2-info-light {
    border-right: 2px solid #d0eaff; }
  .md-bdb-2-info-light {
    border-bottom: 2px solid #d0eaff; }
  .md-bdl-2-info-light {
    border-left: 2px solid #d0eaff; }
  .md-bd-2-orange {
    border: 2px solid #E68F17; }
  .md-h-bd-2-orange:hover {
    border: 2px solid #E68F17; }
  .md-bdt-2-orange {
    border-top: 2px solid #E68F17; }
  .md-bdr-2-orange {
    border-right: 2px solid #E68F17; }
  .md-bdb-2-orange {
    border-bottom: 2px solid #E68F17; }
  .md-bdl-2-orange {
    border-left: 2px solid #E68F17; }
  .md-bd-2-yellow {
    border: 2px solid #F5C644; }
  .md-h-bd-2-yellow:hover {
    border: 2px solid #F5C644; }
  .md-bdt-2-yellow {
    border-top: 2px solid #F5C644; }
  .md-bdr-2-yellow {
    border-right: 2px solid #F5C644; }
  .md-bdb-2-yellow {
    border-bottom: 2px solid #F5C644; }
  .md-bdl-2-yellow {
    border-left: 2px solid #F5C644; }
  .md-bd-2-coral {
    border: 2px solid #FA5252; }
  .md-h-bd-2-coral:hover {
    border: 2px solid #FA5252; }
  .md-bdt-2-coral {
    border-top: 2px solid #FA5252; }
  .md-bdr-2-coral {
    border-right: 2px solid #FA5252; }
  .md-bdb-2-coral {
    border-bottom: 2px solid #FA5252; }
  .md-bdl-2-coral {
    border-left: 2px solid #FA5252; }
  .md-bd-2-pink {
    border: 2px solid #E64980; }
  .md-h-bd-2-pink:hover {
    border: 2px solid #E64980; }
  .md-bdt-2-pink {
    border-top: 2px solid #E64980; }
  .md-bdr-2-pink {
    border-right: 2px solid #E64980; }
  .md-bdb-2-pink {
    border-bottom: 2px solid #E64980; }
  .md-bdl-2-pink {
    border-left: 2px solid #E64980; }
  .md-bd-2-purple {
    border: 2px solid #BE4BDB; }
  .md-h-bd-2-purple:hover {
    border: 2px solid #BE4BDB; }
  .md-bdt-2-purple {
    border-top: 2px solid #BE4BDB; }
  .md-bdr-2-purple {
    border-right: 2px solid #BE4BDB; }
  .md-bdb-2-purple {
    border-bottom: 2px solid #BE4BDB; }
  .md-bdl-2-purple {
    border-left: 2px solid #BE4BDB; }
  .md-bd-2-teal {
    border: 2px solid #0B7285; }
  .md-h-bd-2-teal:hover {
    border: 2px solid #0B7285; }
  .md-bdt-2-teal {
    border-top: 2px solid #0B7285; }
  .md-bdr-2-teal {
    border-right: 2px solid #0B7285; }
  .md-bdb-2-teal {
    border-bottom: 2px solid #0B7285; }
  .md-bdl-2-teal {
    border-left: 2px solid #0B7285; }
  .md-bd-2-turquoise {
    border: 2px solid #15AABF; }
  .md-h-bd-2-turquoise:hover {
    border: 2px solid #15AABF; }
  .md-bdt-2-turquoise {
    border-top: 2px solid #15AABF; }
  .md-bdr-2-turquoise {
    border-right: 2px solid #15AABF; }
  .md-bdb-2-turquoise {
    border-bottom: 2px solid #15AABF; }
  .md-bdl-2-turquoise {
    border-left: 2px solid #15AABF; }
  .md-bd-2-teal-dark {
    border: 2px solid #0F354D; }
  .md-h-bd-2-teal-dark:hover {
    border: 2px solid #0F354D; }
  .md-bdt-2-teal-dark {
    border-top: 2px solid #0F354D; }
  .md-bdr-2-teal-dark {
    border-right: 2px solid #0F354D; }
  .md-bdb-2-teal-dark {
    border-bottom: 2px solid #0F354D; }
  .md-bdl-2-teal-dark {
    border-left: 2px solid #0F354D; }
  .md-bd-2-purpleV2 {
    border: 2px solid #6D5FFD; }
  .md-h-bd-2-purpleV2:hover {
    border: 2px solid #6D5FFD; }
  .md-bdt-2-purpleV2 {
    border-top: 2px solid #6D5FFD; }
  .md-bdr-2-purpleV2 {
    border-right: 2px solid #6D5FFD; }
  .md-bdb-2-purpleV2 {
    border-bottom: 2px solid #6D5FFD; }
  .md-bdl-2-purpleV2 {
    border-left: 2px solid #6D5FFD; }
  .md-bd-2-orangeV2 {
    border: 2px solid #FAB005; }
  .md-h-bd-2-orangeV2:hover {
    border: 2px solid #FAB005; }
  .md-bdt-2-orangeV2 {
    border-top: 2px solid #FAB005; }
  .md-bdr-2-orangeV2 {
    border-right: 2px solid #FAB005; }
  .md-bdb-2-orangeV2 {
    border-bottom: 2px solid #FAB005; }
  .md-bdl-2-orangeV2 {
    border-left: 2px solid #FAB005; }
  .md-bd-2-redV2 {
    border: 2px solid #FF1843; }
  .md-h-bd-2-redV2:hover {
    border: 2px solid #FF1843; }
  .md-bdt-2-redV2 {
    border-top: 2px solid #FF1843; }
  .md-bdr-2-redV2 {
    border-right: 2px solid #FF1843; }
  .md-bdb-2-redV2 {
    border-bottom: 2px solid #FF1843; }
  .md-bdl-2-redV2 {
    border-left: 2px solid #FF1843; }
  .md-bd-2-yellowV2 {
    border: 2px solid rgba(250, 176, 5, 0.6); }
  .md-h-bd-2-yellowV2:hover {
    border: 2px solid rgba(250, 176, 5, 0.6); }
  .md-bdt-2-yellowV2 {
    border-top: 2px solid rgba(250, 176, 5, 0.6); }
  .md-bdr-2-yellowV2 {
    border-right: 2px solid rgba(250, 176, 5, 0.6); }
  .md-bdb-2-yellowV2 {
    border-bottom: 2px solid rgba(250, 176, 5, 0.6); }
  .md-bdl-2-yellowV2 {
    border-left: 2px solid rgba(250, 176, 5, 0.6); }
  .md-bd-2-primary-light {
    border: 2px solid #6D6F7C; }
  .md-h-bd-2-primary-light:hover {
    border: 2px solid #6D6F7C; }
  .md-bdt-2-primary-light {
    border-top: 2px solid #6D6F7C; }
  .md-bdr-2-primary-light {
    border-right: 2px solid #6D6F7C; }
  .md-bdb-2-primary-light {
    border-bottom: 2px solid #6D6F7C; }
  .md-bdl-2-primary-light {
    border-left: 2px solid #6D6F7C; }
  .md-bd-2-primary {
    border: 2px solid #15192D; }
  .md-h-bd-2-primary:hover {
    border: 2px solid #15192D; }
  .md-bdt-2-primary {
    border-top: 2px solid #15192D; }
  .md-bdr-2-primary {
    border-right: 2px solid #15192D; }
  .md-bdb-2-primary {
    border-bottom: 2px solid #15192D; }
  .md-bdl-2-primary {
    border-left: 2px solid #15192D; }
  .md-bd-2-primary-dark {
    border: 2px solid #080911; }
  .md-h-bd-2-primary-dark:hover {
    border: 2px solid #080911; }
  .md-bdt-2-primary-dark {
    border-top: 2px solid #080911; }
  .md-bdr-2-primary-dark {
    border-right: 2px solid #080911; }
  .md-bdb-2-primary-dark {
    border-bottom: 2px solid #080911; }
  .md-bdl-2-primary-dark {
    border-left: 2px solid #080911; }
  .md-bd-3-gray-lightV2 {
    border: 3px solid #F4F6F9; }
  .md-h-bd-3-gray-lightV2:hover {
    border: 3px solid #F4F6F9; }
  .md-bdt-3-gray-lightV2 {
    border-top: 3px solid #F4F6F9; }
  .md-bdr-3-gray-lightV2 {
    border-right: 3px solid #F4F6F9; }
  .md-bdb-3-gray-lightV2 {
    border-bottom: 3px solid #F4F6F9; }
  .md-bdl-3-gray-lightV2 {
    border-left: 3px solid #F4F6F9; }
  .md-bd-3-black {
    border: 3px solid #000000; }
  .md-h-bd-3-black:hover {
    border: 3px solid #000000; }
  .md-bdt-3-black {
    border-top: 3px solid #000000; }
  .md-bdr-3-black {
    border-right: 3px solid #000000; }
  .md-bdb-3-black {
    border-bottom: 3px solid #000000; }
  .md-bdl-3-black {
    border-left: 3px solid #000000; }
  .md-bd-3-white {
    border: 3px solid #ffffff; }
  .md-h-bd-3-white:hover {
    border: 3px solid #ffffff; }
  .md-bdt-3-white {
    border-top: 3px solid #ffffff; }
  .md-bdr-3-white {
    border-right: 3px solid #ffffff; }
  .md-bdb-3-white {
    border-bottom: 3px solid #ffffff; }
  .md-bdl-3-white {
    border-left: 3px solid #ffffff; }
  .md-bd-3-gray-lighter {
    border: 3px solid #e8ebed; }
  .md-h-bd-3-gray-lighter:hover {
    border: 3px solid #e8ebed; }
  .md-bdt-3-gray-lighter {
    border-top: 3px solid #e8ebed; }
  .md-bdr-3-gray-lighter {
    border-right: 3px solid #e8ebed; }
  .md-bdb-3-gray-lighter {
    border-bottom: 3px solid #e8ebed; }
  .md-bdl-3-gray-lighter {
    border-left: 3px solid #e8ebed; }
  .md-bd-3-gray-light {
    border: 3px solid #D9D9D9; }
  .md-h-bd-3-gray-light:hover {
    border: 3px solid #D9D9D9; }
  .md-bdt-3-gray-light {
    border-top: 3px solid #D9D9D9; }
  .md-bdr-3-gray-light {
    border-right: 3px solid #D9D9D9; }
  .md-bdb-3-gray-light {
    border-bottom: 3px solid #D9D9D9; }
  .md-bdl-3-gray-light {
    border-left: 3px solid #D9D9D9; }
  .md-bd-3-gray {
    border: 3px solid #B3B3B3; }
  .md-h-bd-3-gray:hover {
    border: 3px solid #B3B3B3; }
  .md-bdt-3-gray {
    border-top: 3px solid #B3B3B3; }
  .md-bdr-3-gray {
    border-right: 3px solid #B3B3B3; }
  .md-bdb-3-gray {
    border-bottom: 3px solid #B3B3B3; }
  .md-bdl-3-gray {
    border-left: 3px solid #B3B3B3; }
  .md-bd-3-gray-dark {
    border: 3px solid #808080; }
  .md-h-bd-3-gray-dark:hover {
    border: 3px solid #808080; }
  .md-bdt-3-gray-dark {
    border-top: 3px solid #808080; }
  .md-bdr-3-gray-dark {
    border-right: 3px solid #808080; }
  .md-bdb-3-gray-dark {
    border-bottom: 3px solid #808080; }
  .md-bdl-3-gray-dark {
    border-left: 3px solid #808080; }
  .md-bd-3-gray-darker {
    border: 3px solid #4A4A4A; }
  .md-h-bd-3-gray-darker:hover {
    border: 3px solid #4A4A4A; }
  .md-bdt-3-gray-darker {
    border-top: 3px solid #4A4A4A; }
  .md-bdr-3-gray-darker {
    border-right: 3px solid #4A4A4A; }
  .md-bdb-3-gray-darker {
    border-bottom: 3px solid #4A4A4A; }
  .md-bdl-3-gray-darker {
    border-left: 3px solid #4A4A4A; }
  .md-bd-3-alert {
    border: 3px solid #EE1233; }
  .md-h-bd-3-alert:hover {
    border: 3px solid #EE1233; }
  .md-bdt-3-alert {
    border-top: 3px solid #EE1233; }
  .md-bdr-3-alert {
    border-right: 3px solid #EE1233; }
  .md-bdb-3-alert {
    border-bottom: 3px solid #EE1233; }
  .md-bdl-3-alert {
    border-left: 3px solid #EE1233; }
  .md-bd-3-alert-light {
    border: 3px solid #FFEBEB; }
  .md-h-bd-3-alert-light:hover {
    border: 3px solid #FFEBEB; }
  .md-bdt-3-alert-light {
    border-top: 3px solid #FFEBEB; }
  .md-bdr-3-alert-light {
    border-right: 3px solid #FFEBEB; }
  .md-bdb-3-alert-light {
    border-bottom: 3px solid #FFEBEB; }
  .md-bdl-3-alert-light {
    border-left: 3px solid #FFEBEB; }
  .md-bd-3-success {
    border: 3px solid #40C057; }
  .md-h-bd-3-success:hover {
    border: 3px solid #40C057; }
  .md-bdt-3-success {
    border-top: 3px solid #40C057; }
  .md-bdr-3-success {
    border-right: 3px solid #40C057; }
  .md-bdb-3-success {
    border-bottom: 3px solid #40C057; }
  .md-bdl-3-success {
    border-left: 3px solid #40C057; }
  .md-bd-3-success-light {
    border: 3px solid #E6FFEA; }
  .md-h-bd-3-success-light:hover {
    border: 3px solid #E6FFEA; }
  .md-bdt-3-success-light {
    border-top: 3px solid #E6FFEA; }
  .md-bdr-3-success-light {
    border-right: 3px solid #E6FFEA; }
  .md-bdb-3-success-light {
    border-bottom: 3px solid #E6FFEA; }
  .md-bdl-3-success-light {
    border-left: 3px solid #E6FFEA; }
  .md-bd-3-info {
    border: 3px solid #4dadf7; }
  .md-h-bd-3-info:hover {
    border: 3px solid #4dadf7; }
  .md-bdt-3-info {
    border-top: 3px solid #4dadf7; }
  .md-bdr-3-info {
    border-right: 3px solid #4dadf7; }
  .md-bdb-3-info {
    border-bottom: 3px solid #4dadf7; }
  .md-bdl-3-info {
    border-left: 3px solid #4dadf7; }
  .md-bd-3-info-light {
    border: 3px solid #d0eaff; }
  .md-h-bd-3-info-light:hover {
    border: 3px solid #d0eaff; }
  .md-bdt-3-info-light {
    border-top: 3px solid #d0eaff; }
  .md-bdr-3-info-light {
    border-right: 3px solid #d0eaff; }
  .md-bdb-3-info-light {
    border-bottom: 3px solid #d0eaff; }
  .md-bdl-3-info-light {
    border-left: 3px solid #d0eaff; }
  .md-bd-3-orange {
    border: 3px solid #E68F17; }
  .md-h-bd-3-orange:hover {
    border: 3px solid #E68F17; }
  .md-bdt-3-orange {
    border-top: 3px solid #E68F17; }
  .md-bdr-3-orange {
    border-right: 3px solid #E68F17; }
  .md-bdb-3-orange {
    border-bottom: 3px solid #E68F17; }
  .md-bdl-3-orange {
    border-left: 3px solid #E68F17; }
  .md-bd-3-yellow {
    border: 3px solid #F5C644; }
  .md-h-bd-3-yellow:hover {
    border: 3px solid #F5C644; }
  .md-bdt-3-yellow {
    border-top: 3px solid #F5C644; }
  .md-bdr-3-yellow {
    border-right: 3px solid #F5C644; }
  .md-bdb-3-yellow {
    border-bottom: 3px solid #F5C644; }
  .md-bdl-3-yellow {
    border-left: 3px solid #F5C644; }
  .md-bd-3-coral {
    border: 3px solid #FA5252; }
  .md-h-bd-3-coral:hover {
    border: 3px solid #FA5252; }
  .md-bdt-3-coral {
    border-top: 3px solid #FA5252; }
  .md-bdr-3-coral {
    border-right: 3px solid #FA5252; }
  .md-bdb-3-coral {
    border-bottom: 3px solid #FA5252; }
  .md-bdl-3-coral {
    border-left: 3px solid #FA5252; }
  .md-bd-3-pink {
    border: 3px solid #E64980; }
  .md-h-bd-3-pink:hover {
    border: 3px solid #E64980; }
  .md-bdt-3-pink {
    border-top: 3px solid #E64980; }
  .md-bdr-3-pink {
    border-right: 3px solid #E64980; }
  .md-bdb-3-pink {
    border-bottom: 3px solid #E64980; }
  .md-bdl-3-pink {
    border-left: 3px solid #E64980; }
  .md-bd-3-purple {
    border: 3px solid #BE4BDB; }
  .md-h-bd-3-purple:hover {
    border: 3px solid #BE4BDB; }
  .md-bdt-3-purple {
    border-top: 3px solid #BE4BDB; }
  .md-bdr-3-purple {
    border-right: 3px solid #BE4BDB; }
  .md-bdb-3-purple {
    border-bottom: 3px solid #BE4BDB; }
  .md-bdl-3-purple {
    border-left: 3px solid #BE4BDB; }
  .md-bd-3-teal {
    border: 3px solid #0B7285; }
  .md-h-bd-3-teal:hover {
    border: 3px solid #0B7285; }
  .md-bdt-3-teal {
    border-top: 3px solid #0B7285; }
  .md-bdr-3-teal {
    border-right: 3px solid #0B7285; }
  .md-bdb-3-teal {
    border-bottom: 3px solid #0B7285; }
  .md-bdl-3-teal {
    border-left: 3px solid #0B7285; }
  .md-bd-3-turquoise {
    border: 3px solid #15AABF; }
  .md-h-bd-3-turquoise:hover {
    border: 3px solid #15AABF; }
  .md-bdt-3-turquoise {
    border-top: 3px solid #15AABF; }
  .md-bdr-3-turquoise {
    border-right: 3px solid #15AABF; }
  .md-bdb-3-turquoise {
    border-bottom: 3px solid #15AABF; }
  .md-bdl-3-turquoise {
    border-left: 3px solid #15AABF; }
  .md-bd-3-teal-dark {
    border: 3px solid #0F354D; }
  .md-h-bd-3-teal-dark:hover {
    border: 3px solid #0F354D; }
  .md-bdt-3-teal-dark {
    border-top: 3px solid #0F354D; }
  .md-bdr-3-teal-dark {
    border-right: 3px solid #0F354D; }
  .md-bdb-3-teal-dark {
    border-bottom: 3px solid #0F354D; }
  .md-bdl-3-teal-dark {
    border-left: 3px solid #0F354D; }
  .md-bd-3-purpleV2 {
    border: 3px solid #6D5FFD; }
  .md-h-bd-3-purpleV2:hover {
    border: 3px solid #6D5FFD; }
  .md-bdt-3-purpleV2 {
    border-top: 3px solid #6D5FFD; }
  .md-bdr-3-purpleV2 {
    border-right: 3px solid #6D5FFD; }
  .md-bdb-3-purpleV2 {
    border-bottom: 3px solid #6D5FFD; }
  .md-bdl-3-purpleV2 {
    border-left: 3px solid #6D5FFD; }
  .md-bd-3-orangeV2 {
    border: 3px solid #FAB005; }
  .md-h-bd-3-orangeV2:hover {
    border: 3px solid #FAB005; }
  .md-bdt-3-orangeV2 {
    border-top: 3px solid #FAB005; }
  .md-bdr-3-orangeV2 {
    border-right: 3px solid #FAB005; }
  .md-bdb-3-orangeV2 {
    border-bottom: 3px solid #FAB005; }
  .md-bdl-3-orangeV2 {
    border-left: 3px solid #FAB005; }
  .md-bd-3-redV2 {
    border: 3px solid #FF1843; }
  .md-h-bd-3-redV2:hover {
    border: 3px solid #FF1843; }
  .md-bdt-3-redV2 {
    border-top: 3px solid #FF1843; }
  .md-bdr-3-redV2 {
    border-right: 3px solid #FF1843; }
  .md-bdb-3-redV2 {
    border-bottom: 3px solid #FF1843; }
  .md-bdl-3-redV2 {
    border-left: 3px solid #FF1843; }
  .md-bd-3-yellowV2 {
    border: 3px solid rgba(250, 176, 5, 0.6); }
  .md-h-bd-3-yellowV2:hover {
    border: 3px solid rgba(250, 176, 5, 0.6); }
  .md-bdt-3-yellowV2 {
    border-top: 3px solid rgba(250, 176, 5, 0.6); }
  .md-bdr-3-yellowV2 {
    border-right: 3px solid rgba(250, 176, 5, 0.6); }
  .md-bdb-3-yellowV2 {
    border-bottom: 3px solid rgba(250, 176, 5, 0.6); }
  .md-bdl-3-yellowV2 {
    border-left: 3px solid rgba(250, 176, 5, 0.6); }
  .md-bd-3-primary-light {
    border: 3px solid #6D6F7C; }
  .md-h-bd-3-primary-light:hover {
    border: 3px solid #6D6F7C; }
  .md-bdt-3-primary-light {
    border-top: 3px solid #6D6F7C; }
  .md-bdr-3-primary-light {
    border-right: 3px solid #6D6F7C; }
  .md-bdb-3-primary-light {
    border-bottom: 3px solid #6D6F7C; }
  .md-bdl-3-primary-light {
    border-left: 3px solid #6D6F7C; }
  .md-bd-3-primary {
    border: 3px solid #15192D; }
  .md-h-bd-3-primary:hover {
    border: 3px solid #15192D; }
  .md-bdt-3-primary {
    border-top: 3px solid #15192D; }
  .md-bdr-3-primary {
    border-right: 3px solid #15192D; }
  .md-bdb-3-primary {
    border-bottom: 3px solid #15192D; }
  .md-bdl-3-primary {
    border-left: 3px solid #15192D; }
  .md-bd-3-primary-dark {
    border: 3px solid #080911; }
  .md-h-bd-3-primary-dark:hover {
    border: 3px solid #080911; }
  .md-bdt-3-primary-dark {
    border-top: 3px solid #080911; }
  .md-bdr-3-primary-dark {
    border-right: 3px solid #080911; }
  .md-bdb-3-primary-dark {
    border-bottom: 3px solid #080911; }
  .md-bdl-3-primary-dark {
    border-left: 3px solid #080911; }
  .md-bd-4-gray-lightV2 {
    border: 4px solid #F4F6F9; }
  .md-h-bd-4-gray-lightV2:hover {
    border: 4px solid #F4F6F9; }
  .md-bdt-4-gray-lightV2 {
    border-top: 4px solid #F4F6F9; }
  .md-bdr-4-gray-lightV2 {
    border-right: 4px solid #F4F6F9; }
  .md-bdb-4-gray-lightV2 {
    border-bottom: 4px solid #F4F6F9; }
  .md-bdl-4-gray-lightV2 {
    border-left: 4px solid #F4F6F9; }
  .md-bd-4-black {
    border: 4px solid #000000; }
  .md-h-bd-4-black:hover {
    border: 4px solid #000000; }
  .md-bdt-4-black {
    border-top: 4px solid #000000; }
  .md-bdr-4-black {
    border-right: 4px solid #000000; }
  .md-bdb-4-black {
    border-bottom: 4px solid #000000; }
  .md-bdl-4-black {
    border-left: 4px solid #000000; }
  .md-bd-4-white {
    border: 4px solid #ffffff; }
  .md-h-bd-4-white:hover {
    border: 4px solid #ffffff; }
  .md-bdt-4-white {
    border-top: 4px solid #ffffff; }
  .md-bdr-4-white {
    border-right: 4px solid #ffffff; }
  .md-bdb-4-white {
    border-bottom: 4px solid #ffffff; }
  .md-bdl-4-white {
    border-left: 4px solid #ffffff; }
  .md-bd-4-gray-lighter {
    border: 4px solid #e8ebed; }
  .md-h-bd-4-gray-lighter:hover {
    border: 4px solid #e8ebed; }
  .md-bdt-4-gray-lighter {
    border-top: 4px solid #e8ebed; }
  .md-bdr-4-gray-lighter {
    border-right: 4px solid #e8ebed; }
  .md-bdb-4-gray-lighter {
    border-bottom: 4px solid #e8ebed; }
  .md-bdl-4-gray-lighter {
    border-left: 4px solid #e8ebed; }
  .md-bd-4-gray-light {
    border: 4px solid #D9D9D9; }
  .md-h-bd-4-gray-light:hover {
    border: 4px solid #D9D9D9; }
  .md-bdt-4-gray-light {
    border-top: 4px solid #D9D9D9; }
  .md-bdr-4-gray-light {
    border-right: 4px solid #D9D9D9; }
  .md-bdb-4-gray-light {
    border-bottom: 4px solid #D9D9D9; }
  .md-bdl-4-gray-light {
    border-left: 4px solid #D9D9D9; }
  .md-bd-4-gray {
    border: 4px solid #B3B3B3; }
  .md-h-bd-4-gray:hover {
    border: 4px solid #B3B3B3; }
  .md-bdt-4-gray {
    border-top: 4px solid #B3B3B3; }
  .md-bdr-4-gray {
    border-right: 4px solid #B3B3B3; }
  .md-bdb-4-gray {
    border-bottom: 4px solid #B3B3B3; }
  .md-bdl-4-gray {
    border-left: 4px solid #B3B3B3; }
  .md-bd-4-gray-dark {
    border: 4px solid #808080; }
  .md-h-bd-4-gray-dark:hover {
    border: 4px solid #808080; }
  .md-bdt-4-gray-dark {
    border-top: 4px solid #808080; }
  .md-bdr-4-gray-dark {
    border-right: 4px solid #808080; }
  .md-bdb-4-gray-dark {
    border-bottom: 4px solid #808080; }
  .md-bdl-4-gray-dark {
    border-left: 4px solid #808080; }
  .md-bd-4-gray-darker {
    border: 4px solid #4A4A4A; }
  .md-h-bd-4-gray-darker:hover {
    border: 4px solid #4A4A4A; }
  .md-bdt-4-gray-darker {
    border-top: 4px solid #4A4A4A; }
  .md-bdr-4-gray-darker {
    border-right: 4px solid #4A4A4A; }
  .md-bdb-4-gray-darker {
    border-bottom: 4px solid #4A4A4A; }
  .md-bdl-4-gray-darker {
    border-left: 4px solid #4A4A4A; }
  .md-bd-4-alert {
    border: 4px solid #EE1233; }
  .md-h-bd-4-alert:hover {
    border: 4px solid #EE1233; }
  .md-bdt-4-alert {
    border-top: 4px solid #EE1233; }
  .md-bdr-4-alert {
    border-right: 4px solid #EE1233; }
  .md-bdb-4-alert {
    border-bottom: 4px solid #EE1233; }
  .md-bdl-4-alert {
    border-left: 4px solid #EE1233; }
  .md-bd-4-alert-light {
    border: 4px solid #FFEBEB; }
  .md-h-bd-4-alert-light:hover {
    border: 4px solid #FFEBEB; }
  .md-bdt-4-alert-light {
    border-top: 4px solid #FFEBEB; }
  .md-bdr-4-alert-light {
    border-right: 4px solid #FFEBEB; }
  .md-bdb-4-alert-light {
    border-bottom: 4px solid #FFEBEB; }
  .md-bdl-4-alert-light {
    border-left: 4px solid #FFEBEB; }
  .md-bd-4-success {
    border: 4px solid #40C057; }
  .md-h-bd-4-success:hover {
    border: 4px solid #40C057; }
  .md-bdt-4-success {
    border-top: 4px solid #40C057; }
  .md-bdr-4-success {
    border-right: 4px solid #40C057; }
  .md-bdb-4-success {
    border-bottom: 4px solid #40C057; }
  .md-bdl-4-success {
    border-left: 4px solid #40C057; }
  .md-bd-4-success-light {
    border: 4px solid #E6FFEA; }
  .md-h-bd-4-success-light:hover {
    border: 4px solid #E6FFEA; }
  .md-bdt-4-success-light {
    border-top: 4px solid #E6FFEA; }
  .md-bdr-4-success-light {
    border-right: 4px solid #E6FFEA; }
  .md-bdb-4-success-light {
    border-bottom: 4px solid #E6FFEA; }
  .md-bdl-4-success-light {
    border-left: 4px solid #E6FFEA; }
  .md-bd-4-info {
    border: 4px solid #4dadf7; }
  .md-h-bd-4-info:hover {
    border: 4px solid #4dadf7; }
  .md-bdt-4-info {
    border-top: 4px solid #4dadf7; }
  .md-bdr-4-info {
    border-right: 4px solid #4dadf7; }
  .md-bdb-4-info {
    border-bottom: 4px solid #4dadf7; }
  .md-bdl-4-info {
    border-left: 4px solid #4dadf7; }
  .md-bd-4-info-light {
    border: 4px solid #d0eaff; }
  .md-h-bd-4-info-light:hover {
    border: 4px solid #d0eaff; }
  .md-bdt-4-info-light {
    border-top: 4px solid #d0eaff; }
  .md-bdr-4-info-light {
    border-right: 4px solid #d0eaff; }
  .md-bdb-4-info-light {
    border-bottom: 4px solid #d0eaff; }
  .md-bdl-4-info-light {
    border-left: 4px solid #d0eaff; }
  .md-bd-4-orange {
    border: 4px solid #E68F17; }
  .md-h-bd-4-orange:hover {
    border: 4px solid #E68F17; }
  .md-bdt-4-orange {
    border-top: 4px solid #E68F17; }
  .md-bdr-4-orange {
    border-right: 4px solid #E68F17; }
  .md-bdb-4-orange {
    border-bottom: 4px solid #E68F17; }
  .md-bdl-4-orange {
    border-left: 4px solid #E68F17; }
  .md-bd-4-yellow {
    border: 4px solid #F5C644; }
  .md-h-bd-4-yellow:hover {
    border: 4px solid #F5C644; }
  .md-bdt-4-yellow {
    border-top: 4px solid #F5C644; }
  .md-bdr-4-yellow {
    border-right: 4px solid #F5C644; }
  .md-bdb-4-yellow {
    border-bottom: 4px solid #F5C644; }
  .md-bdl-4-yellow {
    border-left: 4px solid #F5C644; }
  .md-bd-4-coral {
    border: 4px solid #FA5252; }
  .md-h-bd-4-coral:hover {
    border: 4px solid #FA5252; }
  .md-bdt-4-coral {
    border-top: 4px solid #FA5252; }
  .md-bdr-4-coral {
    border-right: 4px solid #FA5252; }
  .md-bdb-4-coral {
    border-bottom: 4px solid #FA5252; }
  .md-bdl-4-coral {
    border-left: 4px solid #FA5252; }
  .md-bd-4-pink {
    border: 4px solid #E64980; }
  .md-h-bd-4-pink:hover {
    border: 4px solid #E64980; }
  .md-bdt-4-pink {
    border-top: 4px solid #E64980; }
  .md-bdr-4-pink {
    border-right: 4px solid #E64980; }
  .md-bdb-4-pink {
    border-bottom: 4px solid #E64980; }
  .md-bdl-4-pink {
    border-left: 4px solid #E64980; }
  .md-bd-4-purple {
    border: 4px solid #BE4BDB; }
  .md-h-bd-4-purple:hover {
    border: 4px solid #BE4BDB; }
  .md-bdt-4-purple {
    border-top: 4px solid #BE4BDB; }
  .md-bdr-4-purple {
    border-right: 4px solid #BE4BDB; }
  .md-bdb-4-purple {
    border-bottom: 4px solid #BE4BDB; }
  .md-bdl-4-purple {
    border-left: 4px solid #BE4BDB; }
  .md-bd-4-teal {
    border: 4px solid #0B7285; }
  .md-h-bd-4-teal:hover {
    border: 4px solid #0B7285; }
  .md-bdt-4-teal {
    border-top: 4px solid #0B7285; }
  .md-bdr-4-teal {
    border-right: 4px solid #0B7285; }
  .md-bdb-4-teal {
    border-bottom: 4px solid #0B7285; }
  .md-bdl-4-teal {
    border-left: 4px solid #0B7285; }
  .md-bd-4-turquoise {
    border: 4px solid #15AABF; }
  .md-h-bd-4-turquoise:hover {
    border: 4px solid #15AABF; }
  .md-bdt-4-turquoise {
    border-top: 4px solid #15AABF; }
  .md-bdr-4-turquoise {
    border-right: 4px solid #15AABF; }
  .md-bdb-4-turquoise {
    border-bottom: 4px solid #15AABF; }
  .md-bdl-4-turquoise {
    border-left: 4px solid #15AABF; }
  .md-bd-4-teal-dark {
    border: 4px solid #0F354D; }
  .md-h-bd-4-teal-dark:hover {
    border: 4px solid #0F354D; }
  .md-bdt-4-teal-dark {
    border-top: 4px solid #0F354D; }
  .md-bdr-4-teal-dark {
    border-right: 4px solid #0F354D; }
  .md-bdb-4-teal-dark {
    border-bottom: 4px solid #0F354D; }
  .md-bdl-4-teal-dark {
    border-left: 4px solid #0F354D; }
  .md-bd-4-purpleV2 {
    border: 4px solid #6D5FFD; }
  .md-h-bd-4-purpleV2:hover {
    border: 4px solid #6D5FFD; }
  .md-bdt-4-purpleV2 {
    border-top: 4px solid #6D5FFD; }
  .md-bdr-4-purpleV2 {
    border-right: 4px solid #6D5FFD; }
  .md-bdb-4-purpleV2 {
    border-bottom: 4px solid #6D5FFD; }
  .md-bdl-4-purpleV2 {
    border-left: 4px solid #6D5FFD; }
  .md-bd-4-orangeV2 {
    border: 4px solid #FAB005; }
  .md-h-bd-4-orangeV2:hover {
    border: 4px solid #FAB005; }
  .md-bdt-4-orangeV2 {
    border-top: 4px solid #FAB005; }
  .md-bdr-4-orangeV2 {
    border-right: 4px solid #FAB005; }
  .md-bdb-4-orangeV2 {
    border-bottom: 4px solid #FAB005; }
  .md-bdl-4-orangeV2 {
    border-left: 4px solid #FAB005; }
  .md-bd-4-redV2 {
    border: 4px solid #FF1843; }
  .md-h-bd-4-redV2:hover {
    border: 4px solid #FF1843; }
  .md-bdt-4-redV2 {
    border-top: 4px solid #FF1843; }
  .md-bdr-4-redV2 {
    border-right: 4px solid #FF1843; }
  .md-bdb-4-redV2 {
    border-bottom: 4px solid #FF1843; }
  .md-bdl-4-redV2 {
    border-left: 4px solid #FF1843; }
  .md-bd-4-yellowV2 {
    border: 4px solid rgba(250, 176, 5, 0.6); }
  .md-h-bd-4-yellowV2:hover {
    border: 4px solid rgba(250, 176, 5, 0.6); }
  .md-bdt-4-yellowV2 {
    border-top: 4px solid rgba(250, 176, 5, 0.6); }
  .md-bdr-4-yellowV2 {
    border-right: 4px solid rgba(250, 176, 5, 0.6); }
  .md-bdb-4-yellowV2 {
    border-bottom: 4px solid rgba(250, 176, 5, 0.6); }
  .md-bdl-4-yellowV2 {
    border-left: 4px solid rgba(250, 176, 5, 0.6); }
  .md-bd-4-primary-light {
    border: 4px solid #6D6F7C; }
  .md-h-bd-4-primary-light:hover {
    border: 4px solid #6D6F7C; }
  .md-bdt-4-primary-light {
    border-top: 4px solid #6D6F7C; }
  .md-bdr-4-primary-light {
    border-right: 4px solid #6D6F7C; }
  .md-bdb-4-primary-light {
    border-bottom: 4px solid #6D6F7C; }
  .md-bdl-4-primary-light {
    border-left: 4px solid #6D6F7C; }
  .md-bd-4-primary {
    border: 4px solid #15192D; }
  .md-h-bd-4-primary:hover {
    border: 4px solid #15192D; }
  .md-bdt-4-primary {
    border-top: 4px solid #15192D; }
  .md-bdr-4-primary {
    border-right: 4px solid #15192D; }
  .md-bdb-4-primary {
    border-bottom: 4px solid #15192D; }
  .md-bdl-4-primary {
    border-left: 4px solid #15192D; }
  .md-bd-4-primary-dark {
    border: 4px solid #080911; }
  .md-h-bd-4-primary-dark:hover {
    border: 4px solid #080911; }
  .md-bdt-4-primary-dark {
    border-top: 4px solid #080911; }
  .md-bdr-4-primary-dark {
    border-right: 4px solid #080911; }
  .md-bdb-4-primary-dark {
    border-bottom: 4px solid #080911; }
  .md-bdl-4-primary-dark {
    border-left: 4px solid #080911; }
  .md-bd-radius-0 {
    border-radius: 0px; }
  .md-bd-radius-1 {
    border-radius: 1px; }
  .md-bd-radius-2 {
    border-radius: 2px; }
  .md-bd-radius-3 {
    border-radius: 3px; }
  .md-bd-radius-4 {
    border-radius: 4px; }
  .md-bd-radius-5 {
    border-radius: 5px; }
  .md-bd-radius-6 {
    border-radius: 6px; }
  .md-bd-radius-7 {
    border-radius: 7px; }
  .md-bd-radius-8 {
    border-radius: 8px; }
  .md-bd-radius-9 {
    border-radius: 9px; }
  .md-bd-radius-10 {
    border-radius: 10px; }
  .md-bd-radius-11 {
    border-radius: 11px; }
  .md-bd-radius-12 {
    border-radius: 12px; }
  .md-bd-radius-13 {
    border-radius: 13px; }
  .md-bd-radius-14 {
    border-radius: 14px; }
  .md-bd-radius-15 {
    border-radius: 15px; }
  .md-bd-radius-16 {
    border-radius: 16px; }
  .md-bd-radius-17 {
    border-radius: 17px; }
  .md-bd-radius-18 {
    border-radius: 18px; }
  .md-bd-radius-19 {
    border-radius: 19px; }
  .md-bd-radius-20 {
    border-radius: 20px; }
  .md-bd-radius-21 {
    border-radius: 21px; }
  .md-bd-radius-22 {
    border-radius: 22px; }
  .md-bd-radius-23 {
    border-radius: 23px; }
  .md-bd-radius-24 {
    border-radius: 24px; }
  .md-bd-radius-25 {
    border-radius: 25px; }
  .md-bd-radius-26 {
    border-radius: 26px; }
  .md-bd-radius-27 {
    border-radius: 27px; }
  .md-bd-radius-28 {
    border-radius: 28px; }
  .md-bd-radius-29 {
    border-radius: 29px; }
  .md-bd-radius-30 {
    border-radius: 30px; }
  .md-bd-radius-31 {
    border-radius: 31px; }
  .md-bd-radius-32 {
    border-radius: 32px; }
  .md-bd-radius-33 {
    border-radius: 33px; }
  .md-bd-radius-34 {
    border-radius: 34px; }
  .md-bd-radius-35 {
    border-radius: 35px; }
  .md-bd-radius-36 {
    border-radius: 36px; }
  .md-bd-radius-37 {
    border-radius: 37px; }
  .md-bd-radius-38 {
    border-radius: 38px; }
  .md-bd-radius-39 {
    border-radius: 39px; }
  .md-bd-radius-40 {
    border-radius: 40px; }
  .md-bd-radius-41 {
    border-radius: 41px; }
  .md-bd-radius-42 {
    border-radius: 42px; }
  .md-bd-radius-43 {
    border-radius: 43px; }
  .md-bd-radius-44 {
    border-radius: 44px; }
  .md-bd-radius-45 {
    border-radius: 45px; }
  .md-bd-radius-46 {
    border-radius: 46px; }
  .md-bd-radius-47 {
    border-radius: 47px; }
  .md-bd-radius-48 {
    border-radius: 48px; }
  .md-bd-radius-49 {
    border-radius: 49px; }
  .md-bd-radius-50 {
    border-radius: 50px; }
  .md-muted {
    opacity: 0.4; }
  .md-uppercase {
    text-transform: uppercase; }
  .md-capitalize {
    text-transform: capitalize; }
  .md-ovf-hidden {
    overflow: hidden; }
  .md-ovf-scroll {
    overflow: scroll; }
  .md-bd-radius {
    border-radius: 4px; }
  .md-bdtl-radius {
    border-top-left-radius: 4px; }
  .md-bdtr-radius {
    border-top-right-radius: 4px; }
  .md-bdbl-radius {
    border-bottom-left-radius: 4px; }
  .md-bdbr-radius {
    border-bottom-right-radius: 4px; }
  .md-no-radius {
    border-radius: 0; }
  .md-round {
    border-radius: 50%; } }

@media (min-width: 1025px) {
  .lg-mg-auto {
    margin: auto; }
  .lg-mgv-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .lg-mgh-auto {
    margin-right: auto;
    margin-left: auto; }
  .lg-mgt-auto {
    margin-top: auto; }
  .lg-mgr-auto {
    margin-right: auto; }
  .lg-mgb-auto {
    margin-bottom: auto; }
  .lg-mgl-auto {
    margin-left: auto; }
  .lg-mg-6 {
    margin: 12rem; }
  .lg-mgv-6 {
    margin-top: 12rem;
    margin-bottom: 12rem; }
  .lg-mgh-6 {
    margin-right: 12rem;
    margin-left: 12rem; }
  .lg-mgt-6 {
    margin-top: 12rem; }
  .lg-mgr-6 {
    margin-right: 12rem; }
  .lg-mgb-6 {
    margin-bottom: 12rem; }
  .lg-mgl-6 {
    margin-left: 12rem; }
  .lg-pg-6 {
    padding: 12rem; }
  .lg-pgv-6 {
    padding-top: 12rem;
    padding-bottom: 12rem; }
  .lg-pgh-6 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .lg-pgt-6 {
    padding-top: 12rem; }
  .lg-pgr-6 {
    padding-right: 12rem; }
  .lg-pgb-6 {
    padding-bottom: 12rem; }
  .lg-pgl-6 {
    padding-left: 12rem; }
  .lg-mg-5_5 {
    margin: 11rem; }
  .lg-mgv-5_5 {
    margin-top: 11rem;
    margin-bottom: 11rem; }
  .lg-mgh-5_5 {
    margin-right: 11rem;
    margin-left: 11rem; }
  .lg-mgt-5_5 {
    margin-top: 11rem; }
  .lg-mgr-5_5 {
    margin-right: 11rem; }
  .lg-mgb-5_5 {
    margin-bottom: 11rem; }
  .lg-mgl-5_5 {
    margin-left: 11rem; }
  .lg-pg-5_5 {
    padding: 11rem; }
  .lg-pgv-5_5 {
    padding-top: 11rem;
    padding-bottom: 11rem; }
  .lg-pgh-5_5 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .lg-pgt-5_5 {
    padding-top: 11rem; }
  .lg-pgr-5_5 {
    padding-right: 11rem; }
  .lg-pgb-5_5 {
    padding-bottom: 11rem; }
  .lg-pgl-5_5 {
    padding-left: 11rem; }
  .lg-mg-5 {
    margin: 10rem; }
  .lg-mgv-5 {
    margin-top: 10rem;
    margin-bottom: 10rem; }
  .lg-mgh-5 {
    margin-right: 10rem;
    margin-left: 10rem; }
  .lg-mgt-5 {
    margin-top: 10rem; }
  .lg-mgr-5 {
    margin-right: 10rem; }
  .lg-mgb-5 {
    margin-bottom: 10rem; }
  .lg-mgl-5 {
    margin-left: 10rem; }
  .lg-pg-5 {
    padding: 10rem; }
  .lg-pgv-5 {
    padding-top: 10rem;
    padding-bottom: 10rem; }
  .lg-pgh-5 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .lg-pgt-5 {
    padding-top: 10rem; }
  .lg-pgr-5 {
    padding-right: 10rem; }
  .lg-pgb-5 {
    padding-bottom: 10rem; }
  .lg-pgl-5 {
    padding-left: 10rem; }
  .lg-mg-4 {
    margin: 8rem; }
  .lg-mgv-4 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .lg-mgh-4 {
    margin-right: 8rem;
    margin-left: 8rem; }
  .lg-mgt-4 {
    margin-top: 8rem; }
  .lg-mgr-4 {
    margin-right: 8rem; }
  .lg-mgb-4 {
    margin-bottom: 8rem; }
  .lg-mgl-4 {
    margin-left: 8rem; }
  .lg-pg-4 {
    padding: 8rem; }
  .lg-pgv-4 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .lg-pgh-4 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .lg-pgt-4 {
    padding-top: 8rem; }
  .lg-pgr-4 {
    padding-right: 8rem; }
  .lg-pgb-4 {
    padding-bottom: 8rem; }
  .lg-pgl-4 {
    padding-left: 8rem; }
  .lg-mg-3 {
    margin: 6rem; }
  .lg-mgv-3 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .lg-mgh-3 {
    margin-right: 6rem;
    margin-left: 6rem; }
  .lg-mgt-3 {
    margin-top: 6rem; }
  .lg-mgr-3 {
    margin-right: 6rem; }
  .lg-mgb-3 {
    margin-bottom: 6rem; }
  .lg-mgl-3 {
    margin-left: 6rem; }
  .lg-pg-3 {
    padding: 6rem; }
  .lg-pgv-3 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .lg-pgh-3 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .lg-pgt-3 {
    padding-top: 6rem; }
  .lg-pgr-3 {
    padding-right: 6rem; }
  .lg-pgb-3 {
    padding-bottom: 6rem; }
  .lg-pgl-3 {
    padding-left: 6rem; }
  .lg-mg-2_5 {
    margin: 5rem; }
  .lg-mgv-2_5 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .lg-mgh-2_5 {
    margin-right: 5rem;
    margin-left: 5rem; }
  .lg-mgt-2_5 {
    margin-top: 5rem; }
  .lg-mgr-2_5 {
    margin-right: 5rem; }
  .lg-mgb-2_5 {
    margin-bottom: 5rem; }
  .lg-mgl-2_5 {
    margin-left: 5rem; }
  .lg-pg-2_5 {
    padding: 5rem; }
  .lg-pgv-2_5 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .lg-pgh-2_5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .lg-pgt-2_5 {
    padding-top: 5rem; }
  .lg-pgr-2_5 {
    padding-right: 5rem; }
  .lg-pgb-2_5 {
    padding-bottom: 5rem; }
  .lg-pgl-2_5 {
    padding-left: 5rem; }
  .lg-mg-2 {
    margin: 4rem; }
  .lg-mgv-2 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .lg-mgh-2 {
    margin-right: 4rem;
    margin-left: 4rem; }
  .lg-mgt-2 {
    margin-top: 4rem; }
  .lg-mgr-2 {
    margin-right: 4rem; }
  .lg-mgb-2 {
    margin-bottom: 4rem; }
  .lg-mgl-2 {
    margin-left: 4rem; }
  .lg-pg-2 {
    padding: 4rem; }
  .lg-pgv-2 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .lg-pgh-2 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .lg-pgt-2 {
    padding-top: 4rem; }
  .lg-pgr-2 {
    padding-right: 4rem; }
  .lg-pgb-2 {
    padding-bottom: 4rem; }
  .lg-pgl-2 {
    padding-left: 4rem; }
  .lg-mg-1_75 {
    margin: 3.5rem; }
  .lg-mgv-1_75 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .lg-mgh-1_75 {
    margin-right: 3.5rem;
    margin-left: 3.5rem; }
  .lg-mgt-1_75 {
    margin-top: 3.5rem; }
  .lg-mgr-1_75 {
    margin-right: 3.5rem; }
  .lg-mgb-1_75 {
    margin-bottom: 3.5rem; }
  .lg-mgl-1_75 {
    margin-left: 3.5rem; }
  .lg-pg-1_75 {
    padding: 3.5rem; }
  .lg-pgv-1_75 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .lg-pgh-1_75 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .lg-pgt-1_75 {
    padding-top: 3.5rem; }
  .lg-pgr-1_75 {
    padding-right: 3.5rem; }
  .lg-pgb-1_75 {
    padding-bottom: 3.5rem; }
  .lg-pgl-1_75 {
    padding-left: 3.5rem; }
  .lg-mg-1_5 {
    margin: 3rem; }
  .lg-mgv-1_5 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .lg-mgh-1_5 {
    margin-right: 3rem;
    margin-left: 3rem; }
  .lg-mgt-1_5 {
    margin-top: 3rem; }
  .lg-mgr-1_5 {
    margin-right: 3rem; }
  .lg-mgb-1_5 {
    margin-bottom: 3rem; }
  .lg-mgl-1_5 {
    margin-left: 3rem; }
  .lg-pg-1_5 {
    padding: 3rem; }
  .lg-pgv-1_5 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .lg-pgh-1_5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .lg-pgt-1_5 {
    padding-top: 3rem; }
  .lg-pgr-1_5 {
    padding-right: 3rem; }
  .lg-pgb-1_5 {
    padding-bottom: 3rem; }
  .lg-pgl-1_5 {
    padding-left: 3rem; }
  .lg-mg-1_25 {
    margin: 2.5rem; }
  .lg-mgv-1_25 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .lg-mgh-1_25 {
    margin-right: 2.5rem;
    margin-left: 2.5rem; }
  .lg-mgt-1_25 {
    margin-top: 2.5rem; }
  .lg-mgr-1_25 {
    margin-right: 2.5rem; }
  .lg-mgb-1_25 {
    margin-bottom: 2.5rem; }
  .lg-mgl-1_25 {
    margin-left: 2.5rem; }
  .lg-pg-1_25 {
    padding: 2.5rem; }
  .lg-pgv-1_25 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .lg-pgh-1_25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .lg-pgt-1_25 {
    padding-top: 2.5rem; }
  .lg-pgr-1_25 {
    padding-right: 2.5rem; }
  .lg-pgb-1_25 {
    padding-bottom: 2.5rem; }
  .lg-pgl-1_25 {
    padding-left: 2.5rem; }
  .lg-mg-1 {
    margin: 2rem; }
  .lg-mgv-1 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .lg-mgh-1 {
    margin-right: 2rem;
    margin-left: 2rem; }
  .lg-mgt-1 {
    margin-top: 2rem; }
  .lg-mgr-1 {
    margin-right: 2rem; }
  .lg-mgb-1 {
    margin-bottom: 2rem; }
  .lg-mgl-1 {
    margin-left: 2rem; }
  .lg-pg-1 {
    padding: 2rem; }
  .lg-pgv-1 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .lg-pgh-1 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .lg-pgt-1 {
    padding-top: 2rem; }
  .lg-pgr-1 {
    padding-right: 2rem; }
  .lg-pgb-1 {
    padding-bottom: 2rem; }
  .lg-pgl-1 {
    padding-left: 2rem; }
  .lg-mg-0_75 {
    margin: 1.5rem; }
  .lg-mgv-0_75 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .lg-mgh-0_75 {
    margin-right: 1.5rem;
    margin-left: 1.5rem; }
  .lg-mgt-0_75 {
    margin-top: 1.5rem; }
  .lg-mgr-0_75 {
    margin-right: 1.5rem; }
  .lg-mgb-0_75 {
    margin-bottom: 1.5rem; }
  .lg-mgl-0_75 {
    margin-left: 1.5rem; }
  .lg-pg-0_75 {
    padding: 1.5rem; }
  .lg-pgv-0_75 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .lg-pgh-0_75 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .lg-pgt-0_75 {
    padding-top: 1.5rem; }
  .lg-pgr-0_75 {
    padding-right: 1.5rem; }
  .lg-pgb-0_75 {
    padding-bottom: 1.5rem; }
  .lg-pgl-0_75 {
    padding-left: 1.5rem; }
  .lg-mg-0_5 {
    margin: 1rem; }
  .lg-mgv-0_5 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .lg-mgh-0_5 {
    margin-right: 1rem;
    margin-left: 1rem; }
  .lg-mgt-0_5 {
    margin-top: 1rem; }
  .lg-mgr-0_5 {
    margin-right: 1rem; }
  .lg-mgb-0_5 {
    margin-bottom: 1rem; }
  .lg-mgl-0_5 {
    margin-left: 1rem; }
  .lg-pg-0_5 {
    padding: 1rem; }
  .lg-pgv-0_5 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .lg-pgh-0_5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .lg-pgt-0_5 {
    padding-top: 1rem; }
  .lg-pgr-0_5 {
    padding-right: 1rem; }
  .lg-pgb-0_5 {
    padding-bottom: 1rem; }
  .lg-pgl-0_5 {
    padding-left: 1rem; }
  .lg-mg-0_25 {
    margin: 0.5rem; }
  .lg-mgv-0_25 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .lg-mgh-0_25 {
    margin-right: 0.5rem;
    margin-left: 0.5rem; }
  .lg-mgt-0_25 {
    margin-top: 0.5rem; }
  .lg-mgr-0_25 {
    margin-right: 0.5rem; }
  .lg-mgb-0_25 {
    margin-bottom: 0.5rem; }
  .lg-mgl-0_25 {
    margin-left: 0.5rem; }
  .lg-pg-0_25 {
    padding: 0.5rem; }
  .lg-pgv-0_25 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .lg-pgh-0_25 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .lg-pgt-0_25 {
    padding-top: 0.5rem; }
  .lg-pgr-0_25 {
    padding-right: 0.5rem; }
  .lg-pgb-0_25 {
    padding-bottom: 0.5rem; }
  .lg-pgl-0_25 {
    padding-left: 0.5rem; }
  .lg-mg-0 {
    margin: 0rem; }
  .lg-mgv-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .lg-mgh-0 {
    margin-right: 0rem;
    margin-left: 0rem; }
  .lg-mgt-0 {
    margin-top: 0rem; }
  .lg-mgr-0 {
    margin-right: 0rem; }
  .lg-mgb-0 {
    margin-bottom: 0rem; }
  .lg-mgl-0 {
    margin-left: 0rem; }
  .lg-pg-0 {
    padding: 0rem; }
  .lg-pgv-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .lg-pgh-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important; }
  .lg-pgt-0 {
    padding-top: 0rem; }
  .lg-pgr-0 {
    padding-right: 0rem; }
  .lg-pgb-0 {
    padding-bottom: 0rem; }
  .lg-pgl-0 {
    padding-left: 0rem; }
  .lg-text-left {
    text-align: left; }
  .lg-text-center {
    text-align: center; }
  .lg-text-right {
    text-align: right; }
  .lg-break-word {
    word-wrap: break-word; }
  .lg-word-normal {
    word-wrap: normal; }
  .lg-lh-1_425 {
    line-height: 1.425; }
  .lg-lh-1_2 {
    line-height: 1.2; }
  .lg-lh-1_1 {
    line-height: 1.1; }
  .lg-lh-1 {
    line-height: 1; }
  .lg-lh-0_75 {
    line-height: 0.75; }
  .lg-fz-100 {
    font-size: 10rem; }
  .lg-fz-36 {
    font-size: 3.6rem; }
  .lg-fz-34 {
    font-size: 3.4rem; }
  .lg-fz-32 {
    font-size: 3.2rem; }
  .lg-fz-28 {
    font-size: 2.8rem; }
  .lg-fz-26 {
    font-size: 2.6rem; }
  .lg-fz-24 {
    font-size: 2.4rem; }
  .lg-fz-22 {
    font-size: 2.2rem; }
  .lg-fz-21 {
    font-size: 2.1rem; }
  .lg-fz-18 {
    font-size: 1.8rem; }
  .lg-fz-16 {
    font-size: 1.6rem; }
  .lg-fz-14 {
    font-size: 1.4rem; }
  .lg-fz-13 {
    font-size: 1.3rem; }
  .lg-fz-12 {
    font-size: 1.2rem; }
  .lg-fz-11 {
    font-size: 1.1rem; }
  .lg-fz-10 {
    font-size: 1rem; }
  .lg-fw-700 {
    font-weight: 700; }
  .lg-fw-600 {
    font-weight: 600; }
  .lg-fw-400 {
    font-weight: 400; }
  .lg-fw-300 {
    font-weight: 300; }
  .lg-text-gray-lightV2[class] {
    color: #F4F6F9; }
  .lg-h-text-gray-lightV2[class]:hover {
    color: #F4F6F9; }
  .lg-bg-gray-lightV2 {
    background-color: #F4F6F9; }
  .lg-h-bg-gray-lightV2:hover {
    background-color: #F4F6F9; }
  .lg-bg-gray-lightV2-muted {
    background-color: rgba(244, 246, 249, 0.2); }
  .lg-text-black[class] {
    color: #000000; }
  .lg-h-text-black[class]:hover {
    color: #000000; }
  .lg-bg-black {
    background-color: #000000; }
  .lg-h-bg-black:hover {
    background-color: #000000; }
  .lg-bg-black-muted {
    background-color: rgba(0, 0, 0, 0.2); }
  .lg-text-white[class] {
    color: #ffffff; }
  .lg-h-text-white[class]:hover {
    color: #ffffff; }
  .lg-bg-white {
    background-color: #ffffff; }
  .lg-h-bg-white:hover {
    background-color: #ffffff; }
  .lg-bg-white-muted {
    background-color: rgba(255, 255, 255, 0.2); }
  .lg-text-gray-lighter[class] {
    color: #e8ebed; }
  .lg-h-text-gray-lighter[class]:hover {
    color: #e8ebed; }
  .lg-bg-gray-lighter {
    background-color: #e8ebed; }
  .lg-h-bg-gray-lighter:hover {
    background-color: #e8ebed; }
  .lg-bg-gray-lighter-muted {
    background-color: rgba(232, 235, 237, 0.2); }
  .lg-text-gray-light[class] {
    color: #D9D9D9; }
  .lg-h-text-gray-light[class]:hover {
    color: #D9D9D9; }
  .lg-bg-gray-light {
    background-color: #D9D9D9; }
  .lg-h-bg-gray-light:hover {
    background-color: #D9D9D9; }
  .lg-bg-gray-light-muted {
    background-color: rgba(217, 217, 217, 0.2); }
  .lg-text-gray[class] {
    color: #B3B3B3; }
  .lg-h-text-gray[class]:hover {
    color: #B3B3B3; }
  .lg-bg-gray {
    background-color: #B3B3B3; }
  .lg-h-bg-gray:hover {
    background-color: #B3B3B3; }
  .lg-bg-gray-muted {
    background-color: rgba(179, 179, 179, 0.2); }
  .lg-text-gray-dark[class] {
    color: #808080; }
  .lg-h-text-gray-dark[class]:hover {
    color: #808080; }
  .lg-bg-gray-dark {
    background-color: #808080; }
  .lg-h-bg-gray-dark:hover {
    background-color: #808080; }
  .lg-bg-gray-dark-muted {
    background-color: rgba(128, 128, 128, 0.2); }
  .lg-text-gray-darker[class] {
    color: #4A4A4A; }
  .lg-h-text-gray-darker[class]:hover {
    color: #4A4A4A; }
  .lg-bg-gray-darker {
    background-color: #4A4A4A; }
  .lg-h-bg-gray-darker:hover {
    background-color: #4A4A4A; }
  .lg-bg-gray-darker-muted {
    background-color: rgba(74, 74, 74, 0.2); }
  .lg-text-alert[class] {
    color: #EE1233; }
  .lg-h-text-alert[class]:hover {
    color: #EE1233; }
  .lg-bg-alert {
    background-color: #EE1233; }
  .lg-h-bg-alert:hover {
    background-color: #EE1233; }
  .lg-bg-alert-muted {
    background-color: rgba(238, 18, 51, 0.2); }
  .lg-text-alert-light[class] {
    color: #FFEBEB; }
  .lg-h-text-alert-light[class]:hover {
    color: #FFEBEB; }
  .lg-bg-alert-light {
    background-color: #FFEBEB; }
  .lg-h-bg-alert-light:hover {
    background-color: #FFEBEB; }
  .lg-bg-alert-light-muted {
    background-color: rgba(255, 235, 235, 0.2); }
  .lg-text-success[class] {
    color: #40C057; }
  .lg-h-text-success[class]:hover {
    color: #40C057; }
  .lg-bg-success {
    background-color: #40C057; }
  .lg-h-bg-success:hover {
    background-color: #40C057; }
  .lg-bg-success-muted {
    background-color: rgba(64, 192, 87, 0.2); }
  .lg-text-success-light[class] {
    color: #E6FFEA; }
  .lg-h-text-success-light[class]:hover {
    color: #E6FFEA; }
  .lg-bg-success-light {
    background-color: #E6FFEA; }
  .lg-h-bg-success-light:hover {
    background-color: #E6FFEA; }
  .lg-bg-success-light-muted {
    background-color: rgba(230, 255, 234, 0.2); }
  .lg-text-info[class] {
    color: #4dadf7; }
  .lg-h-text-info[class]:hover {
    color: #4dadf7; }
  .lg-bg-info {
    background-color: #4dadf7; }
  .lg-h-bg-info:hover {
    background-color: #4dadf7; }
  .lg-bg-info-muted {
    background-color: rgba(77, 173, 247, 0.2); }
  .lg-text-info-light[class] {
    color: #d0eaff; }
  .lg-h-text-info-light[class]:hover {
    color: #d0eaff; }
  .lg-bg-info-light {
    background-color: #d0eaff; }
  .lg-h-bg-info-light:hover {
    background-color: #d0eaff; }
  .lg-bg-info-light-muted {
    background-color: rgba(208, 234, 255, 0.2); }
  .lg-text-orange[class] {
    color: #E68F17; }
  .lg-h-text-orange[class]:hover {
    color: #E68F17; }
  .lg-bg-orange {
    background-color: #E68F17; }
  .lg-h-bg-orange:hover {
    background-color: #E68F17; }
  .lg-bg-orange-muted {
    background-color: rgba(230, 143, 23, 0.2); }
  .lg-text-yellow[class] {
    color: #F5C644; }
  .lg-h-text-yellow[class]:hover {
    color: #F5C644; }
  .lg-bg-yellow {
    background-color: #F5C644; }
  .lg-h-bg-yellow:hover {
    background-color: #F5C644; }
  .lg-bg-yellow-muted {
    background-color: rgba(245, 198, 68, 0.2); }
  .lg-text-coral[class] {
    color: #FA5252; }
  .lg-h-text-coral[class]:hover {
    color: #FA5252; }
  .lg-bg-coral {
    background-color: #FA5252; }
  .lg-h-bg-coral:hover {
    background-color: #FA5252; }
  .lg-bg-coral-muted {
    background-color: rgba(250, 82, 82, 0.2); }
  .lg-text-pink[class] {
    color: #E64980; }
  .lg-h-text-pink[class]:hover {
    color: #E64980; }
  .lg-bg-pink {
    background-color: #E64980; }
  .lg-h-bg-pink:hover {
    background-color: #E64980; }
  .lg-bg-pink-muted {
    background-color: rgba(230, 73, 128, 0.2); }
  .lg-text-purple[class] {
    color: #BE4BDB; }
  .lg-h-text-purple[class]:hover {
    color: #BE4BDB; }
  .lg-bg-purple {
    background-color: #BE4BDB; }
  .lg-h-bg-purple:hover {
    background-color: #BE4BDB; }
  .lg-bg-purple-muted {
    background-color: rgba(190, 75, 219, 0.2); }
  .lg-text-teal[class] {
    color: #0B7285; }
  .lg-h-text-teal[class]:hover {
    color: #0B7285; }
  .lg-bg-teal {
    background-color: #0B7285; }
  .lg-h-bg-teal:hover {
    background-color: #0B7285; }
  .lg-bg-teal-muted {
    background-color: rgba(11, 114, 133, 0.2); }
  .lg-text-turquoise[class] {
    color: #15AABF; }
  .lg-h-text-turquoise[class]:hover {
    color: #15AABF; }
  .lg-bg-turquoise {
    background-color: #15AABF; }
  .lg-h-bg-turquoise:hover {
    background-color: #15AABF; }
  .lg-bg-turquoise-muted {
    background-color: rgba(21, 170, 191, 0.2); }
  .lg-text-teal-dark[class] {
    color: #0F354D; }
  .lg-h-text-teal-dark[class]:hover {
    color: #0F354D; }
  .lg-bg-teal-dark {
    background-color: #0F354D; }
  .lg-h-bg-teal-dark:hover {
    background-color: #0F354D; }
  .lg-bg-teal-dark-muted {
    background-color: rgba(15, 53, 77, 0.2); }
  .lg-text-purpleV2[class] {
    color: #6D5FFD; }
  .lg-h-text-purpleV2[class]:hover {
    color: #6D5FFD; }
  .lg-bg-purpleV2 {
    background-color: #6D5FFD; }
  .lg-h-bg-purpleV2:hover {
    background-color: #6D5FFD; }
  .lg-bg-purpleV2-muted {
    background-color: rgba(109, 95, 253, 0.2); }
  .lg-text-orangeV2[class] {
    color: #FAB005; }
  .lg-h-text-orangeV2[class]:hover {
    color: #FAB005; }
  .lg-bg-orangeV2 {
    background-color: #FAB005; }
  .lg-h-bg-orangeV2:hover {
    background-color: #FAB005; }
  .lg-bg-orangeV2-muted {
    background-color: rgba(250, 176, 5, 0.2); }
  .lg-text-redV2[class] {
    color: #FF1843; }
  .lg-h-text-redV2[class]:hover {
    color: #FF1843; }
  .lg-bg-redV2 {
    background-color: #FF1843; }
  .lg-h-bg-redV2:hover {
    background-color: #FF1843; }
  .lg-bg-redV2-muted {
    background-color: rgba(255, 24, 67, 0.2); }
  .lg-text-yellowV2[class] {
    color: rgba(250, 176, 5, 0.6); }
  .lg-h-text-yellowV2[class]:hover {
    color: rgba(250, 176, 5, 0.6); }
  .lg-bg-yellowV2 {
    background-color: rgba(250, 176, 5, 0.6); }
  .lg-h-bg-yellowV2:hover {
    background-color: rgba(250, 176, 5, 0.6); }
  .lg-bg-yellowV2-muted {
    background-color: rgba(250, 176, 5, 0.2); }
  .lg-text-primary-light[class] {
    color: #6D6F7C; }
  .lg-h-text-primary-light[class]:hover {
    color: #6D6F7C; }
  .lg-bg-primary-light {
    background-color: #6D6F7C; }
  .lg-h-bg-primary-light:hover {
    background-color: #6D6F7C; }
  .lg-bg-primary-light-muted {
    background-color: rgba(109, 111, 124, 0.2); }
  .lg-text-primary[class] {
    color: #15192D; }
  .lg-h-text-primary[class]:hover {
    color: #15192D; }
  .lg-bg-primary {
    background-color: #15192D; }
  .lg-h-bg-primary:hover {
    background-color: #15192D; }
  .lg-bg-primary-muted {
    background-color: rgba(21, 25, 45, 0.2); }
  .lg-text-primary-dark[class] {
    color: #080911; }
  .lg-h-text-primary-dark[class]:hover {
    color: #080911; }
  .lg-bg-primary-dark {
    background-color: #080911; }
  .lg-h-bg-primary-dark:hover {
    background-color: #080911; }
  .lg-bg-primary-dark-muted {
    background-color: rgba(8, 9, 17, 0.2); }
  .lg-bd-0-gray-lightV2 {
    border: 0px solid #F4F6F9; }
  .lg-h-bd-0-gray-lightV2:hover {
    border: 0px solid #F4F6F9; }
  .lg-bdt-0-gray-lightV2 {
    border-top: 0px solid #F4F6F9; }
  .lg-bdr-0-gray-lightV2 {
    border-right: 0px solid #F4F6F9; }
  .lg-bdb-0-gray-lightV2 {
    border-bottom: 0px solid #F4F6F9; }
  .lg-bdl-0-gray-lightV2 {
    border-left: 0px solid #F4F6F9; }
  .lg-bd-0-black {
    border: 0px solid #000000; }
  .lg-h-bd-0-black:hover {
    border: 0px solid #000000; }
  .lg-bdt-0-black {
    border-top: 0px solid #000000; }
  .lg-bdr-0-black {
    border-right: 0px solid #000000; }
  .lg-bdb-0-black {
    border-bottom: 0px solid #000000; }
  .lg-bdl-0-black {
    border-left: 0px solid #000000; }
  .lg-bd-0-white {
    border: 0px solid #ffffff; }
  .lg-h-bd-0-white:hover {
    border: 0px solid #ffffff; }
  .lg-bdt-0-white {
    border-top: 0px solid #ffffff; }
  .lg-bdr-0-white {
    border-right: 0px solid #ffffff; }
  .lg-bdb-0-white {
    border-bottom: 0px solid #ffffff; }
  .lg-bdl-0-white {
    border-left: 0px solid #ffffff; }
  .lg-bd-0-gray-lighter {
    border: 0px solid #e8ebed; }
  .lg-h-bd-0-gray-lighter:hover {
    border: 0px solid #e8ebed; }
  .lg-bdt-0-gray-lighter {
    border-top: 0px solid #e8ebed; }
  .lg-bdr-0-gray-lighter {
    border-right: 0px solid #e8ebed; }
  .lg-bdb-0-gray-lighter {
    border-bottom: 0px solid #e8ebed; }
  .lg-bdl-0-gray-lighter {
    border-left: 0px solid #e8ebed; }
  .lg-bd-0-gray-light {
    border: 0px solid #D9D9D9; }
  .lg-h-bd-0-gray-light:hover {
    border: 0px solid #D9D9D9; }
  .lg-bdt-0-gray-light {
    border-top: 0px solid #D9D9D9; }
  .lg-bdr-0-gray-light {
    border-right: 0px solid #D9D9D9; }
  .lg-bdb-0-gray-light {
    border-bottom: 0px solid #D9D9D9; }
  .lg-bdl-0-gray-light {
    border-left: 0px solid #D9D9D9; }
  .lg-bd-0-gray {
    border: 0px solid #B3B3B3; }
  .lg-h-bd-0-gray:hover {
    border: 0px solid #B3B3B3; }
  .lg-bdt-0-gray {
    border-top: 0px solid #B3B3B3; }
  .lg-bdr-0-gray {
    border-right: 0px solid #B3B3B3; }
  .lg-bdb-0-gray {
    border-bottom: 0px solid #B3B3B3; }
  .lg-bdl-0-gray {
    border-left: 0px solid #B3B3B3; }
  .lg-bd-0-gray-dark {
    border: 0px solid #808080; }
  .lg-h-bd-0-gray-dark:hover {
    border: 0px solid #808080; }
  .lg-bdt-0-gray-dark {
    border-top: 0px solid #808080; }
  .lg-bdr-0-gray-dark {
    border-right: 0px solid #808080; }
  .lg-bdb-0-gray-dark {
    border-bottom: 0px solid #808080; }
  .lg-bdl-0-gray-dark {
    border-left: 0px solid #808080; }
  .lg-bd-0-gray-darker {
    border: 0px solid #4A4A4A; }
  .lg-h-bd-0-gray-darker:hover {
    border: 0px solid #4A4A4A; }
  .lg-bdt-0-gray-darker {
    border-top: 0px solid #4A4A4A; }
  .lg-bdr-0-gray-darker {
    border-right: 0px solid #4A4A4A; }
  .lg-bdb-0-gray-darker {
    border-bottom: 0px solid #4A4A4A; }
  .lg-bdl-0-gray-darker {
    border-left: 0px solid #4A4A4A; }
  .lg-bd-0-alert {
    border: 0px solid #EE1233; }
  .lg-h-bd-0-alert:hover {
    border: 0px solid #EE1233; }
  .lg-bdt-0-alert {
    border-top: 0px solid #EE1233; }
  .lg-bdr-0-alert {
    border-right: 0px solid #EE1233; }
  .lg-bdb-0-alert {
    border-bottom: 0px solid #EE1233; }
  .lg-bdl-0-alert {
    border-left: 0px solid #EE1233; }
  .lg-bd-0-alert-light {
    border: 0px solid #FFEBEB; }
  .lg-h-bd-0-alert-light:hover {
    border: 0px solid #FFEBEB; }
  .lg-bdt-0-alert-light {
    border-top: 0px solid #FFEBEB; }
  .lg-bdr-0-alert-light {
    border-right: 0px solid #FFEBEB; }
  .lg-bdb-0-alert-light {
    border-bottom: 0px solid #FFEBEB; }
  .lg-bdl-0-alert-light {
    border-left: 0px solid #FFEBEB; }
  .lg-bd-0-success {
    border: 0px solid #40C057; }
  .lg-h-bd-0-success:hover {
    border: 0px solid #40C057; }
  .lg-bdt-0-success {
    border-top: 0px solid #40C057; }
  .lg-bdr-0-success {
    border-right: 0px solid #40C057; }
  .lg-bdb-0-success {
    border-bottom: 0px solid #40C057; }
  .lg-bdl-0-success {
    border-left: 0px solid #40C057; }
  .lg-bd-0-success-light {
    border: 0px solid #E6FFEA; }
  .lg-h-bd-0-success-light:hover {
    border: 0px solid #E6FFEA; }
  .lg-bdt-0-success-light {
    border-top: 0px solid #E6FFEA; }
  .lg-bdr-0-success-light {
    border-right: 0px solid #E6FFEA; }
  .lg-bdb-0-success-light {
    border-bottom: 0px solid #E6FFEA; }
  .lg-bdl-0-success-light {
    border-left: 0px solid #E6FFEA; }
  .lg-bd-0-info {
    border: 0px solid #4dadf7; }
  .lg-h-bd-0-info:hover {
    border: 0px solid #4dadf7; }
  .lg-bdt-0-info {
    border-top: 0px solid #4dadf7; }
  .lg-bdr-0-info {
    border-right: 0px solid #4dadf7; }
  .lg-bdb-0-info {
    border-bottom: 0px solid #4dadf7; }
  .lg-bdl-0-info {
    border-left: 0px solid #4dadf7; }
  .lg-bd-0-info-light {
    border: 0px solid #d0eaff; }
  .lg-h-bd-0-info-light:hover {
    border: 0px solid #d0eaff; }
  .lg-bdt-0-info-light {
    border-top: 0px solid #d0eaff; }
  .lg-bdr-0-info-light {
    border-right: 0px solid #d0eaff; }
  .lg-bdb-0-info-light {
    border-bottom: 0px solid #d0eaff; }
  .lg-bdl-0-info-light {
    border-left: 0px solid #d0eaff; }
  .lg-bd-0-orange {
    border: 0px solid #E68F17; }
  .lg-h-bd-0-orange:hover {
    border: 0px solid #E68F17; }
  .lg-bdt-0-orange {
    border-top: 0px solid #E68F17; }
  .lg-bdr-0-orange {
    border-right: 0px solid #E68F17; }
  .lg-bdb-0-orange {
    border-bottom: 0px solid #E68F17; }
  .lg-bdl-0-orange {
    border-left: 0px solid #E68F17; }
  .lg-bd-0-yellow {
    border: 0px solid #F5C644; }
  .lg-h-bd-0-yellow:hover {
    border: 0px solid #F5C644; }
  .lg-bdt-0-yellow {
    border-top: 0px solid #F5C644; }
  .lg-bdr-0-yellow {
    border-right: 0px solid #F5C644; }
  .lg-bdb-0-yellow {
    border-bottom: 0px solid #F5C644; }
  .lg-bdl-0-yellow {
    border-left: 0px solid #F5C644; }
  .lg-bd-0-coral {
    border: 0px solid #FA5252; }
  .lg-h-bd-0-coral:hover {
    border: 0px solid #FA5252; }
  .lg-bdt-0-coral {
    border-top: 0px solid #FA5252; }
  .lg-bdr-0-coral {
    border-right: 0px solid #FA5252; }
  .lg-bdb-0-coral {
    border-bottom: 0px solid #FA5252; }
  .lg-bdl-0-coral {
    border-left: 0px solid #FA5252; }
  .lg-bd-0-pink {
    border: 0px solid #E64980; }
  .lg-h-bd-0-pink:hover {
    border: 0px solid #E64980; }
  .lg-bdt-0-pink {
    border-top: 0px solid #E64980; }
  .lg-bdr-0-pink {
    border-right: 0px solid #E64980; }
  .lg-bdb-0-pink {
    border-bottom: 0px solid #E64980; }
  .lg-bdl-0-pink {
    border-left: 0px solid #E64980; }
  .lg-bd-0-purple {
    border: 0px solid #BE4BDB; }
  .lg-h-bd-0-purple:hover {
    border: 0px solid #BE4BDB; }
  .lg-bdt-0-purple {
    border-top: 0px solid #BE4BDB; }
  .lg-bdr-0-purple {
    border-right: 0px solid #BE4BDB; }
  .lg-bdb-0-purple {
    border-bottom: 0px solid #BE4BDB; }
  .lg-bdl-0-purple {
    border-left: 0px solid #BE4BDB; }
  .lg-bd-0-teal {
    border: 0px solid #0B7285; }
  .lg-h-bd-0-teal:hover {
    border: 0px solid #0B7285; }
  .lg-bdt-0-teal {
    border-top: 0px solid #0B7285; }
  .lg-bdr-0-teal {
    border-right: 0px solid #0B7285; }
  .lg-bdb-0-teal {
    border-bottom: 0px solid #0B7285; }
  .lg-bdl-0-teal {
    border-left: 0px solid #0B7285; }
  .lg-bd-0-turquoise {
    border: 0px solid #15AABF; }
  .lg-h-bd-0-turquoise:hover {
    border: 0px solid #15AABF; }
  .lg-bdt-0-turquoise {
    border-top: 0px solid #15AABF; }
  .lg-bdr-0-turquoise {
    border-right: 0px solid #15AABF; }
  .lg-bdb-0-turquoise {
    border-bottom: 0px solid #15AABF; }
  .lg-bdl-0-turquoise {
    border-left: 0px solid #15AABF; }
  .lg-bd-0-teal-dark {
    border: 0px solid #0F354D; }
  .lg-h-bd-0-teal-dark:hover {
    border: 0px solid #0F354D; }
  .lg-bdt-0-teal-dark {
    border-top: 0px solid #0F354D; }
  .lg-bdr-0-teal-dark {
    border-right: 0px solid #0F354D; }
  .lg-bdb-0-teal-dark {
    border-bottom: 0px solid #0F354D; }
  .lg-bdl-0-teal-dark {
    border-left: 0px solid #0F354D; }
  .lg-bd-0-purpleV2 {
    border: 0px solid #6D5FFD; }
  .lg-h-bd-0-purpleV2:hover {
    border: 0px solid #6D5FFD; }
  .lg-bdt-0-purpleV2 {
    border-top: 0px solid #6D5FFD; }
  .lg-bdr-0-purpleV2 {
    border-right: 0px solid #6D5FFD; }
  .lg-bdb-0-purpleV2 {
    border-bottom: 0px solid #6D5FFD; }
  .lg-bdl-0-purpleV2 {
    border-left: 0px solid #6D5FFD; }
  .lg-bd-0-orangeV2 {
    border: 0px solid #FAB005; }
  .lg-h-bd-0-orangeV2:hover {
    border: 0px solid #FAB005; }
  .lg-bdt-0-orangeV2 {
    border-top: 0px solid #FAB005; }
  .lg-bdr-0-orangeV2 {
    border-right: 0px solid #FAB005; }
  .lg-bdb-0-orangeV2 {
    border-bottom: 0px solid #FAB005; }
  .lg-bdl-0-orangeV2 {
    border-left: 0px solid #FAB005; }
  .lg-bd-0-redV2 {
    border: 0px solid #FF1843; }
  .lg-h-bd-0-redV2:hover {
    border: 0px solid #FF1843; }
  .lg-bdt-0-redV2 {
    border-top: 0px solid #FF1843; }
  .lg-bdr-0-redV2 {
    border-right: 0px solid #FF1843; }
  .lg-bdb-0-redV2 {
    border-bottom: 0px solid #FF1843; }
  .lg-bdl-0-redV2 {
    border-left: 0px solid #FF1843; }
  .lg-bd-0-yellowV2 {
    border: 0px solid rgba(250, 176, 5, 0.6); }
  .lg-h-bd-0-yellowV2:hover {
    border: 0px solid rgba(250, 176, 5, 0.6); }
  .lg-bdt-0-yellowV2 {
    border-top: 0px solid rgba(250, 176, 5, 0.6); }
  .lg-bdr-0-yellowV2 {
    border-right: 0px solid rgba(250, 176, 5, 0.6); }
  .lg-bdb-0-yellowV2 {
    border-bottom: 0px solid rgba(250, 176, 5, 0.6); }
  .lg-bdl-0-yellowV2 {
    border-left: 0px solid rgba(250, 176, 5, 0.6); }
  .lg-bd-0-primary-light {
    border: 0px solid #6D6F7C; }
  .lg-h-bd-0-primary-light:hover {
    border: 0px solid #6D6F7C; }
  .lg-bdt-0-primary-light {
    border-top: 0px solid #6D6F7C; }
  .lg-bdr-0-primary-light {
    border-right: 0px solid #6D6F7C; }
  .lg-bdb-0-primary-light {
    border-bottom: 0px solid #6D6F7C; }
  .lg-bdl-0-primary-light {
    border-left: 0px solid #6D6F7C; }
  .lg-bd-0-primary {
    border: 0px solid #15192D; }
  .lg-h-bd-0-primary:hover {
    border: 0px solid #15192D; }
  .lg-bdt-0-primary {
    border-top: 0px solid #15192D; }
  .lg-bdr-0-primary {
    border-right: 0px solid #15192D; }
  .lg-bdb-0-primary {
    border-bottom: 0px solid #15192D; }
  .lg-bdl-0-primary {
    border-left: 0px solid #15192D; }
  .lg-bd-0-primary-dark {
    border: 0px solid #080911; }
  .lg-h-bd-0-primary-dark:hover {
    border: 0px solid #080911; }
  .lg-bdt-0-primary-dark {
    border-top: 0px solid #080911; }
  .lg-bdr-0-primary-dark {
    border-right: 0px solid #080911; }
  .lg-bdb-0-primary-dark {
    border-bottom: 0px solid #080911; }
  .lg-bdl-0-primary-dark {
    border-left: 0px solid #080911; }
  .lg-bd-1-gray-lightV2 {
    border: 1px solid #F4F6F9; }
  .lg-h-bd-1-gray-lightV2:hover {
    border: 1px solid #F4F6F9; }
  .lg-bdt-1-gray-lightV2 {
    border-top: 1px solid #F4F6F9; }
  .lg-bdr-1-gray-lightV2 {
    border-right: 1px solid #F4F6F9; }
  .lg-bdb-1-gray-lightV2 {
    border-bottom: 1px solid #F4F6F9; }
  .lg-bdl-1-gray-lightV2 {
    border-left: 1px solid #F4F6F9; }
  .lg-bd-1-black {
    border: 1px solid #000000; }
  .lg-h-bd-1-black:hover {
    border: 1px solid #000000; }
  .lg-bdt-1-black {
    border-top: 1px solid #000000; }
  .lg-bdr-1-black {
    border-right: 1px solid #000000; }
  .lg-bdb-1-black {
    border-bottom: 1px solid #000000; }
  .lg-bdl-1-black {
    border-left: 1px solid #000000; }
  .lg-bd-1-white {
    border: 1px solid #ffffff; }
  .lg-h-bd-1-white:hover {
    border: 1px solid #ffffff; }
  .lg-bdt-1-white {
    border-top: 1px solid #ffffff; }
  .lg-bdr-1-white {
    border-right: 1px solid #ffffff; }
  .lg-bdb-1-white {
    border-bottom: 1px solid #ffffff; }
  .lg-bdl-1-white {
    border-left: 1px solid #ffffff; }
  .lg-bd-1-gray-lighter {
    border: 1px solid #e8ebed; }
  .lg-h-bd-1-gray-lighter:hover {
    border: 1px solid #e8ebed; }
  .lg-bdt-1-gray-lighter {
    border-top: 1px solid #e8ebed; }
  .lg-bdr-1-gray-lighter {
    border-right: 1px solid #e8ebed; }
  .lg-bdb-1-gray-lighter {
    border-bottom: 1px solid #e8ebed; }
  .lg-bdl-1-gray-lighter {
    border-left: 1px solid #e8ebed; }
  .lg-bd-1-gray-light {
    border: 1px solid #D9D9D9; }
  .lg-h-bd-1-gray-light:hover {
    border: 1px solid #D9D9D9; }
  .lg-bdt-1-gray-light {
    border-top: 1px solid #D9D9D9; }
  .lg-bdr-1-gray-light {
    border-right: 1px solid #D9D9D9; }
  .lg-bdb-1-gray-light {
    border-bottom: 1px solid #D9D9D9; }
  .lg-bdl-1-gray-light {
    border-left: 1px solid #D9D9D9; }
  .lg-bd-1-gray {
    border: 1px solid #B3B3B3; }
  .lg-h-bd-1-gray:hover {
    border: 1px solid #B3B3B3; }
  .lg-bdt-1-gray {
    border-top: 1px solid #B3B3B3; }
  .lg-bdr-1-gray {
    border-right: 1px solid #B3B3B3; }
  .lg-bdb-1-gray {
    border-bottom: 1px solid #B3B3B3; }
  .lg-bdl-1-gray {
    border-left: 1px solid #B3B3B3; }
  .lg-bd-1-gray-dark {
    border: 1px solid #808080; }
  .lg-h-bd-1-gray-dark:hover {
    border: 1px solid #808080; }
  .lg-bdt-1-gray-dark {
    border-top: 1px solid #808080; }
  .lg-bdr-1-gray-dark {
    border-right: 1px solid #808080; }
  .lg-bdb-1-gray-dark {
    border-bottom: 1px solid #808080; }
  .lg-bdl-1-gray-dark {
    border-left: 1px solid #808080; }
  .lg-bd-1-gray-darker {
    border: 1px solid #4A4A4A; }
  .lg-h-bd-1-gray-darker:hover {
    border: 1px solid #4A4A4A; }
  .lg-bdt-1-gray-darker {
    border-top: 1px solid #4A4A4A; }
  .lg-bdr-1-gray-darker {
    border-right: 1px solid #4A4A4A; }
  .lg-bdb-1-gray-darker {
    border-bottom: 1px solid #4A4A4A; }
  .lg-bdl-1-gray-darker {
    border-left: 1px solid #4A4A4A; }
  .lg-bd-1-alert {
    border: 1px solid #EE1233; }
  .lg-h-bd-1-alert:hover {
    border: 1px solid #EE1233; }
  .lg-bdt-1-alert {
    border-top: 1px solid #EE1233; }
  .lg-bdr-1-alert {
    border-right: 1px solid #EE1233; }
  .lg-bdb-1-alert {
    border-bottom: 1px solid #EE1233; }
  .lg-bdl-1-alert {
    border-left: 1px solid #EE1233; }
  .lg-bd-1-alert-light {
    border: 1px solid #FFEBEB; }
  .lg-h-bd-1-alert-light:hover {
    border: 1px solid #FFEBEB; }
  .lg-bdt-1-alert-light {
    border-top: 1px solid #FFEBEB; }
  .lg-bdr-1-alert-light {
    border-right: 1px solid #FFEBEB; }
  .lg-bdb-1-alert-light {
    border-bottom: 1px solid #FFEBEB; }
  .lg-bdl-1-alert-light {
    border-left: 1px solid #FFEBEB; }
  .lg-bd-1-success {
    border: 1px solid #40C057; }
  .lg-h-bd-1-success:hover {
    border: 1px solid #40C057; }
  .lg-bdt-1-success {
    border-top: 1px solid #40C057; }
  .lg-bdr-1-success {
    border-right: 1px solid #40C057; }
  .lg-bdb-1-success {
    border-bottom: 1px solid #40C057; }
  .lg-bdl-1-success {
    border-left: 1px solid #40C057; }
  .lg-bd-1-success-light {
    border: 1px solid #E6FFEA; }
  .lg-h-bd-1-success-light:hover {
    border: 1px solid #E6FFEA; }
  .lg-bdt-1-success-light {
    border-top: 1px solid #E6FFEA; }
  .lg-bdr-1-success-light {
    border-right: 1px solid #E6FFEA; }
  .lg-bdb-1-success-light {
    border-bottom: 1px solid #E6FFEA; }
  .lg-bdl-1-success-light {
    border-left: 1px solid #E6FFEA; }
  .lg-bd-1-info {
    border: 1px solid #4dadf7; }
  .lg-h-bd-1-info:hover {
    border: 1px solid #4dadf7; }
  .lg-bdt-1-info {
    border-top: 1px solid #4dadf7; }
  .lg-bdr-1-info {
    border-right: 1px solid #4dadf7; }
  .lg-bdb-1-info {
    border-bottom: 1px solid #4dadf7; }
  .lg-bdl-1-info {
    border-left: 1px solid #4dadf7; }
  .lg-bd-1-info-light {
    border: 1px solid #d0eaff; }
  .lg-h-bd-1-info-light:hover {
    border: 1px solid #d0eaff; }
  .lg-bdt-1-info-light {
    border-top: 1px solid #d0eaff; }
  .lg-bdr-1-info-light {
    border-right: 1px solid #d0eaff; }
  .lg-bdb-1-info-light {
    border-bottom: 1px solid #d0eaff; }
  .lg-bdl-1-info-light {
    border-left: 1px solid #d0eaff; }
  .lg-bd-1-orange {
    border: 1px solid #E68F17; }
  .lg-h-bd-1-orange:hover {
    border: 1px solid #E68F17; }
  .lg-bdt-1-orange {
    border-top: 1px solid #E68F17; }
  .lg-bdr-1-orange {
    border-right: 1px solid #E68F17; }
  .lg-bdb-1-orange {
    border-bottom: 1px solid #E68F17; }
  .lg-bdl-1-orange {
    border-left: 1px solid #E68F17; }
  .lg-bd-1-yellow {
    border: 1px solid #F5C644; }
  .lg-h-bd-1-yellow:hover {
    border: 1px solid #F5C644; }
  .lg-bdt-1-yellow {
    border-top: 1px solid #F5C644; }
  .lg-bdr-1-yellow {
    border-right: 1px solid #F5C644; }
  .lg-bdb-1-yellow {
    border-bottom: 1px solid #F5C644; }
  .lg-bdl-1-yellow {
    border-left: 1px solid #F5C644; }
  .lg-bd-1-coral {
    border: 1px solid #FA5252; }
  .lg-h-bd-1-coral:hover {
    border: 1px solid #FA5252; }
  .lg-bdt-1-coral {
    border-top: 1px solid #FA5252; }
  .lg-bdr-1-coral {
    border-right: 1px solid #FA5252; }
  .lg-bdb-1-coral {
    border-bottom: 1px solid #FA5252; }
  .lg-bdl-1-coral {
    border-left: 1px solid #FA5252; }
  .lg-bd-1-pink {
    border: 1px solid #E64980; }
  .lg-h-bd-1-pink:hover {
    border: 1px solid #E64980; }
  .lg-bdt-1-pink {
    border-top: 1px solid #E64980; }
  .lg-bdr-1-pink {
    border-right: 1px solid #E64980; }
  .lg-bdb-1-pink {
    border-bottom: 1px solid #E64980; }
  .lg-bdl-1-pink {
    border-left: 1px solid #E64980; }
  .lg-bd-1-purple {
    border: 1px solid #BE4BDB; }
  .lg-h-bd-1-purple:hover {
    border: 1px solid #BE4BDB; }
  .lg-bdt-1-purple {
    border-top: 1px solid #BE4BDB; }
  .lg-bdr-1-purple {
    border-right: 1px solid #BE4BDB; }
  .lg-bdb-1-purple {
    border-bottom: 1px solid #BE4BDB; }
  .lg-bdl-1-purple {
    border-left: 1px solid #BE4BDB; }
  .lg-bd-1-teal {
    border: 1px solid #0B7285; }
  .lg-h-bd-1-teal:hover {
    border: 1px solid #0B7285; }
  .lg-bdt-1-teal {
    border-top: 1px solid #0B7285; }
  .lg-bdr-1-teal {
    border-right: 1px solid #0B7285; }
  .lg-bdb-1-teal {
    border-bottom: 1px solid #0B7285; }
  .lg-bdl-1-teal {
    border-left: 1px solid #0B7285; }
  .lg-bd-1-turquoise {
    border: 1px solid #15AABF; }
  .lg-h-bd-1-turquoise:hover {
    border: 1px solid #15AABF; }
  .lg-bdt-1-turquoise {
    border-top: 1px solid #15AABF; }
  .lg-bdr-1-turquoise {
    border-right: 1px solid #15AABF; }
  .lg-bdb-1-turquoise {
    border-bottom: 1px solid #15AABF; }
  .lg-bdl-1-turquoise {
    border-left: 1px solid #15AABF; }
  .lg-bd-1-teal-dark {
    border: 1px solid #0F354D; }
  .lg-h-bd-1-teal-dark:hover {
    border: 1px solid #0F354D; }
  .lg-bdt-1-teal-dark {
    border-top: 1px solid #0F354D; }
  .lg-bdr-1-teal-dark {
    border-right: 1px solid #0F354D; }
  .lg-bdb-1-teal-dark {
    border-bottom: 1px solid #0F354D; }
  .lg-bdl-1-teal-dark {
    border-left: 1px solid #0F354D; }
  .lg-bd-1-purpleV2 {
    border: 1px solid #6D5FFD; }
  .lg-h-bd-1-purpleV2:hover {
    border: 1px solid #6D5FFD; }
  .lg-bdt-1-purpleV2 {
    border-top: 1px solid #6D5FFD; }
  .lg-bdr-1-purpleV2 {
    border-right: 1px solid #6D5FFD; }
  .lg-bdb-1-purpleV2 {
    border-bottom: 1px solid #6D5FFD; }
  .lg-bdl-1-purpleV2 {
    border-left: 1px solid #6D5FFD; }
  .lg-bd-1-orangeV2 {
    border: 1px solid #FAB005; }
  .lg-h-bd-1-orangeV2:hover {
    border: 1px solid #FAB005; }
  .lg-bdt-1-orangeV2 {
    border-top: 1px solid #FAB005; }
  .lg-bdr-1-orangeV2 {
    border-right: 1px solid #FAB005; }
  .lg-bdb-1-orangeV2 {
    border-bottom: 1px solid #FAB005; }
  .lg-bdl-1-orangeV2 {
    border-left: 1px solid #FAB005; }
  .lg-bd-1-redV2 {
    border: 1px solid #FF1843; }
  .lg-h-bd-1-redV2:hover {
    border: 1px solid #FF1843; }
  .lg-bdt-1-redV2 {
    border-top: 1px solid #FF1843; }
  .lg-bdr-1-redV2 {
    border-right: 1px solid #FF1843; }
  .lg-bdb-1-redV2 {
    border-bottom: 1px solid #FF1843; }
  .lg-bdl-1-redV2 {
    border-left: 1px solid #FF1843; }
  .lg-bd-1-yellowV2 {
    border: 1px solid rgba(250, 176, 5, 0.6); }
  .lg-h-bd-1-yellowV2:hover {
    border: 1px solid rgba(250, 176, 5, 0.6); }
  .lg-bdt-1-yellowV2 {
    border-top: 1px solid rgba(250, 176, 5, 0.6); }
  .lg-bdr-1-yellowV2 {
    border-right: 1px solid rgba(250, 176, 5, 0.6); }
  .lg-bdb-1-yellowV2 {
    border-bottom: 1px solid rgba(250, 176, 5, 0.6); }
  .lg-bdl-1-yellowV2 {
    border-left: 1px solid rgba(250, 176, 5, 0.6); }
  .lg-bd-1-primary-light {
    border: 1px solid #6D6F7C; }
  .lg-h-bd-1-primary-light:hover {
    border: 1px solid #6D6F7C; }
  .lg-bdt-1-primary-light {
    border-top: 1px solid #6D6F7C; }
  .lg-bdr-1-primary-light {
    border-right: 1px solid #6D6F7C; }
  .lg-bdb-1-primary-light {
    border-bottom: 1px solid #6D6F7C; }
  .lg-bdl-1-primary-light {
    border-left: 1px solid #6D6F7C; }
  .lg-bd-1-primary {
    border: 1px solid #15192D; }
  .lg-h-bd-1-primary:hover {
    border: 1px solid #15192D; }
  .lg-bdt-1-primary {
    border-top: 1px solid #15192D; }
  .lg-bdr-1-primary {
    border-right: 1px solid #15192D; }
  .lg-bdb-1-primary {
    border-bottom: 1px solid #15192D; }
  .lg-bdl-1-primary {
    border-left: 1px solid #15192D; }
  .lg-bd-1-primary-dark {
    border: 1px solid #080911; }
  .lg-h-bd-1-primary-dark:hover {
    border: 1px solid #080911; }
  .lg-bdt-1-primary-dark {
    border-top: 1px solid #080911; }
  .lg-bdr-1-primary-dark {
    border-right: 1px solid #080911; }
  .lg-bdb-1-primary-dark {
    border-bottom: 1px solid #080911; }
  .lg-bdl-1-primary-dark {
    border-left: 1px solid #080911; }
  .lg-bd-2-gray-lightV2 {
    border: 2px solid #F4F6F9; }
  .lg-h-bd-2-gray-lightV2:hover {
    border: 2px solid #F4F6F9; }
  .lg-bdt-2-gray-lightV2 {
    border-top: 2px solid #F4F6F9; }
  .lg-bdr-2-gray-lightV2 {
    border-right: 2px solid #F4F6F9; }
  .lg-bdb-2-gray-lightV2 {
    border-bottom: 2px solid #F4F6F9; }
  .lg-bdl-2-gray-lightV2 {
    border-left: 2px solid #F4F6F9; }
  .lg-bd-2-black {
    border: 2px solid #000000; }
  .lg-h-bd-2-black:hover {
    border: 2px solid #000000; }
  .lg-bdt-2-black {
    border-top: 2px solid #000000; }
  .lg-bdr-2-black {
    border-right: 2px solid #000000; }
  .lg-bdb-2-black {
    border-bottom: 2px solid #000000; }
  .lg-bdl-2-black {
    border-left: 2px solid #000000; }
  .lg-bd-2-white {
    border: 2px solid #ffffff; }
  .lg-h-bd-2-white:hover {
    border: 2px solid #ffffff; }
  .lg-bdt-2-white {
    border-top: 2px solid #ffffff; }
  .lg-bdr-2-white {
    border-right: 2px solid #ffffff; }
  .lg-bdb-2-white {
    border-bottom: 2px solid #ffffff; }
  .lg-bdl-2-white {
    border-left: 2px solid #ffffff; }
  .lg-bd-2-gray-lighter {
    border: 2px solid #e8ebed; }
  .lg-h-bd-2-gray-lighter:hover {
    border: 2px solid #e8ebed; }
  .lg-bdt-2-gray-lighter {
    border-top: 2px solid #e8ebed; }
  .lg-bdr-2-gray-lighter {
    border-right: 2px solid #e8ebed; }
  .lg-bdb-2-gray-lighter {
    border-bottom: 2px solid #e8ebed; }
  .lg-bdl-2-gray-lighter {
    border-left: 2px solid #e8ebed; }
  .lg-bd-2-gray-light {
    border: 2px solid #D9D9D9; }
  .lg-h-bd-2-gray-light:hover {
    border: 2px solid #D9D9D9; }
  .lg-bdt-2-gray-light {
    border-top: 2px solid #D9D9D9; }
  .lg-bdr-2-gray-light {
    border-right: 2px solid #D9D9D9; }
  .lg-bdb-2-gray-light {
    border-bottom: 2px solid #D9D9D9; }
  .lg-bdl-2-gray-light {
    border-left: 2px solid #D9D9D9; }
  .lg-bd-2-gray {
    border: 2px solid #B3B3B3; }
  .lg-h-bd-2-gray:hover {
    border: 2px solid #B3B3B3; }
  .lg-bdt-2-gray {
    border-top: 2px solid #B3B3B3; }
  .lg-bdr-2-gray {
    border-right: 2px solid #B3B3B3; }
  .lg-bdb-2-gray {
    border-bottom: 2px solid #B3B3B3; }
  .lg-bdl-2-gray {
    border-left: 2px solid #B3B3B3; }
  .lg-bd-2-gray-dark {
    border: 2px solid #808080; }
  .lg-h-bd-2-gray-dark:hover {
    border: 2px solid #808080; }
  .lg-bdt-2-gray-dark {
    border-top: 2px solid #808080; }
  .lg-bdr-2-gray-dark {
    border-right: 2px solid #808080; }
  .lg-bdb-2-gray-dark {
    border-bottom: 2px solid #808080; }
  .lg-bdl-2-gray-dark {
    border-left: 2px solid #808080; }
  .lg-bd-2-gray-darker {
    border: 2px solid #4A4A4A; }
  .lg-h-bd-2-gray-darker:hover {
    border: 2px solid #4A4A4A; }
  .lg-bdt-2-gray-darker {
    border-top: 2px solid #4A4A4A; }
  .lg-bdr-2-gray-darker {
    border-right: 2px solid #4A4A4A; }
  .lg-bdb-2-gray-darker {
    border-bottom: 2px solid #4A4A4A; }
  .lg-bdl-2-gray-darker {
    border-left: 2px solid #4A4A4A; }
  .lg-bd-2-alert {
    border: 2px solid #EE1233; }
  .lg-h-bd-2-alert:hover {
    border: 2px solid #EE1233; }
  .lg-bdt-2-alert {
    border-top: 2px solid #EE1233; }
  .lg-bdr-2-alert {
    border-right: 2px solid #EE1233; }
  .lg-bdb-2-alert {
    border-bottom: 2px solid #EE1233; }
  .lg-bdl-2-alert {
    border-left: 2px solid #EE1233; }
  .lg-bd-2-alert-light {
    border: 2px solid #FFEBEB; }
  .lg-h-bd-2-alert-light:hover {
    border: 2px solid #FFEBEB; }
  .lg-bdt-2-alert-light {
    border-top: 2px solid #FFEBEB; }
  .lg-bdr-2-alert-light {
    border-right: 2px solid #FFEBEB; }
  .lg-bdb-2-alert-light {
    border-bottom: 2px solid #FFEBEB; }
  .lg-bdl-2-alert-light {
    border-left: 2px solid #FFEBEB; }
  .lg-bd-2-success {
    border: 2px solid #40C057; }
  .lg-h-bd-2-success:hover {
    border: 2px solid #40C057; }
  .lg-bdt-2-success {
    border-top: 2px solid #40C057; }
  .lg-bdr-2-success {
    border-right: 2px solid #40C057; }
  .lg-bdb-2-success {
    border-bottom: 2px solid #40C057; }
  .lg-bdl-2-success {
    border-left: 2px solid #40C057; }
  .lg-bd-2-success-light {
    border: 2px solid #E6FFEA; }
  .lg-h-bd-2-success-light:hover {
    border: 2px solid #E6FFEA; }
  .lg-bdt-2-success-light {
    border-top: 2px solid #E6FFEA; }
  .lg-bdr-2-success-light {
    border-right: 2px solid #E6FFEA; }
  .lg-bdb-2-success-light {
    border-bottom: 2px solid #E6FFEA; }
  .lg-bdl-2-success-light {
    border-left: 2px solid #E6FFEA; }
  .lg-bd-2-info {
    border: 2px solid #4dadf7; }
  .lg-h-bd-2-info:hover {
    border: 2px solid #4dadf7; }
  .lg-bdt-2-info {
    border-top: 2px solid #4dadf7; }
  .lg-bdr-2-info {
    border-right: 2px solid #4dadf7; }
  .lg-bdb-2-info {
    border-bottom: 2px solid #4dadf7; }
  .lg-bdl-2-info {
    border-left: 2px solid #4dadf7; }
  .lg-bd-2-info-light {
    border: 2px solid #d0eaff; }
  .lg-h-bd-2-info-light:hover {
    border: 2px solid #d0eaff; }
  .lg-bdt-2-info-light {
    border-top: 2px solid #d0eaff; }
  .lg-bdr-2-info-light {
    border-right: 2px solid #d0eaff; }
  .lg-bdb-2-info-light {
    border-bottom: 2px solid #d0eaff; }
  .lg-bdl-2-info-light {
    border-left: 2px solid #d0eaff; }
  .lg-bd-2-orange {
    border: 2px solid #E68F17; }
  .lg-h-bd-2-orange:hover {
    border: 2px solid #E68F17; }
  .lg-bdt-2-orange {
    border-top: 2px solid #E68F17; }
  .lg-bdr-2-orange {
    border-right: 2px solid #E68F17; }
  .lg-bdb-2-orange {
    border-bottom: 2px solid #E68F17; }
  .lg-bdl-2-orange {
    border-left: 2px solid #E68F17; }
  .lg-bd-2-yellow {
    border: 2px solid #F5C644; }
  .lg-h-bd-2-yellow:hover {
    border: 2px solid #F5C644; }
  .lg-bdt-2-yellow {
    border-top: 2px solid #F5C644; }
  .lg-bdr-2-yellow {
    border-right: 2px solid #F5C644; }
  .lg-bdb-2-yellow {
    border-bottom: 2px solid #F5C644; }
  .lg-bdl-2-yellow {
    border-left: 2px solid #F5C644; }
  .lg-bd-2-coral {
    border: 2px solid #FA5252; }
  .lg-h-bd-2-coral:hover {
    border: 2px solid #FA5252; }
  .lg-bdt-2-coral {
    border-top: 2px solid #FA5252; }
  .lg-bdr-2-coral {
    border-right: 2px solid #FA5252; }
  .lg-bdb-2-coral {
    border-bottom: 2px solid #FA5252; }
  .lg-bdl-2-coral {
    border-left: 2px solid #FA5252; }
  .lg-bd-2-pink {
    border: 2px solid #E64980; }
  .lg-h-bd-2-pink:hover {
    border: 2px solid #E64980; }
  .lg-bdt-2-pink {
    border-top: 2px solid #E64980; }
  .lg-bdr-2-pink {
    border-right: 2px solid #E64980; }
  .lg-bdb-2-pink {
    border-bottom: 2px solid #E64980; }
  .lg-bdl-2-pink {
    border-left: 2px solid #E64980; }
  .lg-bd-2-purple {
    border: 2px solid #BE4BDB; }
  .lg-h-bd-2-purple:hover {
    border: 2px solid #BE4BDB; }
  .lg-bdt-2-purple {
    border-top: 2px solid #BE4BDB; }
  .lg-bdr-2-purple {
    border-right: 2px solid #BE4BDB; }
  .lg-bdb-2-purple {
    border-bottom: 2px solid #BE4BDB; }
  .lg-bdl-2-purple {
    border-left: 2px solid #BE4BDB; }
  .lg-bd-2-teal {
    border: 2px solid #0B7285; }
  .lg-h-bd-2-teal:hover {
    border: 2px solid #0B7285; }
  .lg-bdt-2-teal {
    border-top: 2px solid #0B7285; }
  .lg-bdr-2-teal {
    border-right: 2px solid #0B7285; }
  .lg-bdb-2-teal {
    border-bottom: 2px solid #0B7285; }
  .lg-bdl-2-teal {
    border-left: 2px solid #0B7285; }
  .lg-bd-2-turquoise {
    border: 2px solid #15AABF; }
  .lg-h-bd-2-turquoise:hover {
    border: 2px solid #15AABF; }
  .lg-bdt-2-turquoise {
    border-top: 2px solid #15AABF; }
  .lg-bdr-2-turquoise {
    border-right: 2px solid #15AABF; }
  .lg-bdb-2-turquoise {
    border-bottom: 2px solid #15AABF; }
  .lg-bdl-2-turquoise {
    border-left: 2px solid #15AABF; }
  .lg-bd-2-teal-dark {
    border: 2px solid #0F354D; }
  .lg-h-bd-2-teal-dark:hover {
    border: 2px solid #0F354D; }
  .lg-bdt-2-teal-dark {
    border-top: 2px solid #0F354D; }
  .lg-bdr-2-teal-dark {
    border-right: 2px solid #0F354D; }
  .lg-bdb-2-teal-dark {
    border-bottom: 2px solid #0F354D; }
  .lg-bdl-2-teal-dark {
    border-left: 2px solid #0F354D; }
  .lg-bd-2-purpleV2 {
    border: 2px solid #6D5FFD; }
  .lg-h-bd-2-purpleV2:hover {
    border: 2px solid #6D5FFD; }
  .lg-bdt-2-purpleV2 {
    border-top: 2px solid #6D5FFD; }
  .lg-bdr-2-purpleV2 {
    border-right: 2px solid #6D5FFD; }
  .lg-bdb-2-purpleV2 {
    border-bottom: 2px solid #6D5FFD; }
  .lg-bdl-2-purpleV2 {
    border-left: 2px solid #6D5FFD; }
  .lg-bd-2-orangeV2 {
    border: 2px solid #FAB005; }
  .lg-h-bd-2-orangeV2:hover {
    border: 2px solid #FAB005; }
  .lg-bdt-2-orangeV2 {
    border-top: 2px solid #FAB005; }
  .lg-bdr-2-orangeV2 {
    border-right: 2px solid #FAB005; }
  .lg-bdb-2-orangeV2 {
    border-bottom: 2px solid #FAB005; }
  .lg-bdl-2-orangeV2 {
    border-left: 2px solid #FAB005; }
  .lg-bd-2-redV2 {
    border: 2px solid #FF1843; }
  .lg-h-bd-2-redV2:hover {
    border: 2px solid #FF1843; }
  .lg-bdt-2-redV2 {
    border-top: 2px solid #FF1843; }
  .lg-bdr-2-redV2 {
    border-right: 2px solid #FF1843; }
  .lg-bdb-2-redV2 {
    border-bottom: 2px solid #FF1843; }
  .lg-bdl-2-redV2 {
    border-left: 2px solid #FF1843; }
  .lg-bd-2-yellowV2 {
    border: 2px solid rgba(250, 176, 5, 0.6); }
  .lg-h-bd-2-yellowV2:hover {
    border: 2px solid rgba(250, 176, 5, 0.6); }
  .lg-bdt-2-yellowV2 {
    border-top: 2px solid rgba(250, 176, 5, 0.6); }
  .lg-bdr-2-yellowV2 {
    border-right: 2px solid rgba(250, 176, 5, 0.6); }
  .lg-bdb-2-yellowV2 {
    border-bottom: 2px solid rgba(250, 176, 5, 0.6); }
  .lg-bdl-2-yellowV2 {
    border-left: 2px solid rgba(250, 176, 5, 0.6); }
  .lg-bd-2-primary-light {
    border: 2px solid #6D6F7C; }
  .lg-h-bd-2-primary-light:hover {
    border: 2px solid #6D6F7C; }
  .lg-bdt-2-primary-light {
    border-top: 2px solid #6D6F7C; }
  .lg-bdr-2-primary-light {
    border-right: 2px solid #6D6F7C; }
  .lg-bdb-2-primary-light {
    border-bottom: 2px solid #6D6F7C; }
  .lg-bdl-2-primary-light {
    border-left: 2px solid #6D6F7C; }
  .lg-bd-2-primary {
    border: 2px solid #15192D; }
  .lg-h-bd-2-primary:hover {
    border: 2px solid #15192D; }
  .lg-bdt-2-primary {
    border-top: 2px solid #15192D; }
  .lg-bdr-2-primary {
    border-right: 2px solid #15192D; }
  .lg-bdb-2-primary {
    border-bottom: 2px solid #15192D; }
  .lg-bdl-2-primary {
    border-left: 2px solid #15192D; }
  .lg-bd-2-primary-dark {
    border: 2px solid #080911; }
  .lg-h-bd-2-primary-dark:hover {
    border: 2px solid #080911; }
  .lg-bdt-2-primary-dark {
    border-top: 2px solid #080911; }
  .lg-bdr-2-primary-dark {
    border-right: 2px solid #080911; }
  .lg-bdb-2-primary-dark {
    border-bottom: 2px solid #080911; }
  .lg-bdl-2-primary-dark {
    border-left: 2px solid #080911; }
  .lg-bd-3-gray-lightV2 {
    border: 3px solid #F4F6F9; }
  .lg-h-bd-3-gray-lightV2:hover {
    border: 3px solid #F4F6F9; }
  .lg-bdt-3-gray-lightV2 {
    border-top: 3px solid #F4F6F9; }
  .lg-bdr-3-gray-lightV2 {
    border-right: 3px solid #F4F6F9; }
  .lg-bdb-3-gray-lightV2 {
    border-bottom: 3px solid #F4F6F9; }
  .lg-bdl-3-gray-lightV2 {
    border-left: 3px solid #F4F6F9; }
  .lg-bd-3-black {
    border: 3px solid #000000; }
  .lg-h-bd-3-black:hover {
    border: 3px solid #000000; }
  .lg-bdt-3-black {
    border-top: 3px solid #000000; }
  .lg-bdr-3-black {
    border-right: 3px solid #000000; }
  .lg-bdb-3-black {
    border-bottom: 3px solid #000000; }
  .lg-bdl-3-black {
    border-left: 3px solid #000000; }
  .lg-bd-3-white {
    border: 3px solid #ffffff; }
  .lg-h-bd-3-white:hover {
    border: 3px solid #ffffff; }
  .lg-bdt-3-white {
    border-top: 3px solid #ffffff; }
  .lg-bdr-3-white {
    border-right: 3px solid #ffffff; }
  .lg-bdb-3-white {
    border-bottom: 3px solid #ffffff; }
  .lg-bdl-3-white {
    border-left: 3px solid #ffffff; }
  .lg-bd-3-gray-lighter {
    border: 3px solid #e8ebed; }
  .lg-h-bd-3-gray-lighter:hover {
    border: 3px solid #e8ebed; }
  .lg-bdt-3-gray-lighter {
    border-top: 3px solid #e8ebed; }
  .lg-bdr-3-gray-lighter {
    border-right: 3px solid #e8ebed; }
  .lg-bdb-3-gray-lighter {
    border-bottom: 3px solid #e8ebed; }
  .lg-bdl-3-gray-lighter {
    border-left: 3px solid #e8ebed; }
  .lg-bd-3-gray-light {
    border: 3px solid #D9D9D9; }
  .lg-h-bd-3-gray-light:hover {
    border: 3px solid #D9D9D9; }
  .lg-bdt-3-gray-light {
    border-top: 3px solid #D9D9D9; }
  .lg-bdr-3-gray-light {
    border-right: 3px solid #D9D9D9; }
  .lg-bdb-3-gray-light {
    border-bottom: 3px solid #D9D9D9; }
  .lg-bdl-3-gray-light {
    border-left: 3px solid #D9D9D9; }
  .lg-bd-3-gray {
    border: 3px solid #B3B3B3; }
  .lg-h-bd-3-gray:hover {
    border: 3px solid #B3B3B3; }
  .lg-bdt-3-gray {
    border-top: 3px solid #B3B3B3; }
  .lg-bdr-3-gray {
    border-right: 3px solid #B3B3B3; }
  .lg-bdb-3-gray {
    border-bottom: 3px solid #B3B3B3; }
  .lg-bdl-3-gray {
    border-left: 3px solid #B3B3B3; }
  .lg-bd-3-gray-dark {
    border: 3px solid #808080; }
  .lg-h-bd-3-gray-dark:hover {
    border: 3px solid #808080; }
  .lg-bdt-3-gray-dark {
    border-top: 3px solid #808080; }
  .lg-bdr-3-gray-dark {
    border-right: 3px solid #808080; }
  .lg-bdb-3-gray-dark {
    border-bottom: 3px solid #808080; }
  .lg-bdl-3-gray-dark {
    border-left: 3px solid #808080; }
  .lg-bd-3-gray-darker {
    border: 3px solid #4A4A4A; }
  .lg-h-bd-3-gray-darker:hover {
    border: 3px solid #4A4A4A; }
  .lg-bdt-3-gray-darker {
    border-top: 3px solid #4A4A4A; }
  .lg-bdr-3-gray-darker {
    border-right: 3px solid #4A4A4A; }
  .lg-bdb-3-gray-darker {
    border-bottom: 3px solid #4A4A4A; }
  .lg-bdl-3-gray-darker {
    border-left: 3px solid #4A4A4A; }
  .lg-bd-3-alert {
    border: 3px solid #EE1233; }
  .lg-h-bd-3-alert:hover {
    border: 3px solid #EE1233; }
  .lg-bdt-3-alert {
    border-top: 3px solid #EE1233; }
  .lg-bdr-3-alert {
    border-right: 3px solid #EE1233; }
  .lg-bdb-3-alert {
    border-bottom: 3px solid #EE1233; }
  .lg-bdl-3-alert {
    border-left: 3px solid #EE1233; }
  .lg-bd-3-alert-light {
    border: 3px solid #FFEBEB; }
  .lg-h-bd-3-alert-light:hover {
    border: 3px solid #FFEBEB; }
  .lg-bdt-3-alert-light {
    border-top: 3px solid #FFEBEB; }
  .lg-bdr-3-alert-light {
    border-right: 3px solid #FFEBEB; }
  .lg-bdb-3-alert-light {
    border-bottom: 3px solid #FFEBEB; }
  .lg-bdl-3-alert-light {
    border-left: 3px solid #FFEBEB; }
  .lg-bd-3-success {
    border: 3px solid #40C057; }
  .lg-h-bd-3-success:hover {
    border: 3px solid #40C057; }
  .lg-bdt-3-success {
    border-top: 3px solid #40C057; }
  .lg-bdr-3-success {
    border-right: 3px solid #40C057; }
  .lg-bdb-3-success {
    border-bottom: 3px solid #40C057; }
  .lg-bdl-3-success {
    border-left: 3px solid #40C057; }
  .lg-bd-3-success-light {
    border: 3px solid #E6FFEA; }
  .lg-h-bd-3-success-light:hover {
    border: 3px solid #E6FFEA; }
  .lg-bdt-3-success-light {
    border-top: 3px solid #E6FFEA; }
  .lg-bdr-3-success-light {
    border-right: 3px solid #E6FFEA; }
  .lg-bdb-3-success-light {
    border-bottom: 3px solid #E6FFEA; }
  .lg-bdl-3-success-light {
    border-left: 3px solid #E6FFEA; }
  .lg-bd-3-info {
    border: 3px solid #4dadf7; }
  .lg-h-bd-3-info:hover {
    border: 3px solid #4dadf7; }
  .lg-bdt-3-info {
    border-top: 3px solid #4dadf7; }
  .lg-bdr-3-info {
    border-right: 3px solid #4dadf7; }
  .lg-bdb-3-info {
    border-bottom: 3px solid #4dadf7; }
  .lg-bdl-3-info {
    border-left: 3px solid #4dadf7; }
  .lg-bd-3-info-light {
    border: 3px solid #d0eaff; }
  .lg-h-bd-3-info-light:hover {
    border: 3px solid #d0eaff; }
  .lg-bdt-3-info-light {
    border-top: 3px solid #d0eaff; }
  .lg-bdr-3-info-light {
    border-right: 3px solid #d0eaff; }
  .lg-bdb-3-info-light {
    border-bottom: 3px solid #d0eaff; }
  .lg-bdl-3-info-light {
    border-left: 3px solid #d0eaff; }
  .lg-bd-3-orange {
    border: 3px solid #E68F17; }
  .lg-h-bd-3-orange:hover {
    border: 3px solid #E68F17; }
  .lg-bdt-3-orange {
    border-top: 3px solid #E68F17; }
  .lg-bdr-3-orange {
    border-right: 3px solid #E68F17; }
  .lg-bdb-3-orange {
    border-bottom: 3px solid #E68F17; }
  .lg-bdl-3-orange {
    border-left: 3px solid #E68F17; }
  .lg-bd-3-yellow {
    border: 3px solid #F5C644; }
  .lg-h-bd-3-yellow:hover {
    border: 3px solid #F5C644; }
  .lg-bdt-3-yellow {
    border-top: 3px solid #F5C644; }
  .lg-bdr-3-yellow {
    border-right: 3px solid #F5C644; }
  .lg-bdb-3-yellow {
    border-bottom: 3px solid #F5C644; }
  .lg-bdl-3-yellow {
    border-left: 3px solid #F5C644; }
  .lg-bd-3-coral {
    border: 3px solid #FA5252; }
  .lg-h-bd-3-coral:hover {
    border: 3px solid #FA5252; }
  .lg-bdt-3-coral {
    border-top: 3px solid #FA5252; }
  .lg-bdr-3-coral {
    border-right: 3px solid #FA5252; }
  .lg-bdb-3-coral {
    border-bottom: 3px solid #FA5252; }
  .lg-bdl-3-coral {
    border-left: 3px solid #FA5252; }
  .lg-bd-3-pink {
    border: 3px solid #E64980; }
  .lg-h-bd-3-pink:hover {
    border: 3px solid #E64980; }
  .lg-bdt-3-pink {
    border-top: 3px solid #E64980; }
  .lg-bdr-3-pink {
    border-right: 3px solid #E64980; }
  .lg-bdb-3-pink {
    border-bottom: 3px solid #E64980; }
  .lg-bdl-3-pink {
    border-left: 3px solid #E64980; }
  .lg-bd-3-purple {
    border: 3px solid #BE4BDB; }
  .lg-h-bd-3-purple:hover {
    border: 3px solid #BE4BDB; }
  .lg-bdt-3-purple {
    border-top: 3px solid #BE4BDB; }
  .lg-bdr-3-purple {
    border-right: 3px solid #BE4BDB; }
  .lg-bdb-3-purple {
    border-bottom: 3px solid #BE4BDB; }
  .lg-bdl-3-purple {
    border-left: 3px solid #BE4BDB; }
  .lg-bd-3-teal {
    border: 3px solid #0B7285; }
  .lg-h-bd-3-teal:hover {
    border: 3px solid #0B7285; }
  .lg-bdt-3-teal {
    border-top: 3px solid #0B7285; }
  .lg-bdr-3-teal {
    border-right: 3px solid #0B7285; }
  .lg-bdb-3-teal {
    border-bottom: 3px solid #0B7285; }
  .lg-bdl-3-teal {
    border-left: 3px solid #0B7285; }
  .lg-bd-3-turquoise {
    border: 3px solid #15AABF; }
  .lg-h-bd-3-turquoise:hover {
    border: 3px solid #15AABF; }
  .lg-bdt-3-turquoise {
    border-top: 3px solid #15AABF; }
  .lg-bdr-3-turquoise {
    border-right: 3px solid #15AABF; }
  .lg-bdb-3-turquoise {
    border-bottom: 3px solid #15AABF; }
  .lg-bdl-3-turquoise {
    border-left: 3px solid #15AABF; }
  .lg-bd-3-teal-dark {
    border: 3px solid #0F354D; }
  .lg-h-bd-3-teal-dark:hover {
    border: 3px solid #0F354D; }
  .lg-bdt-3-teal-dark {
    border-top: 3px solid #0F354D; }
  .lg-bdr-3-teal-dark {
    border-right: 3px solid #0F354D; }
  .lg-bdb-3-teal-dark {
    border-bottom: 3px solid #0F354D; }
  .lg-bdl-3-teal-dark {
    border-left: 3px solid #0F354D; }
  .lg-bd-3-purpleV2 {
    border: 3px solid #6D5FFD; }
  .lg-h-bd-3-purpleV2:hover {
    border: 3px solid #6D5FFD; }
  .lg-bdt-3-purpleV2 {
    border-top: 3px solid #6D5FFD; }
  .lg-bdr-3-purpleV2 {
    border-right: 3px solid #6D5FFD; }
  .lg-bdb-3-purpleV2 {
    border-bottom: 3px solid #6D5FFD; }
  .lg-bdl-3-purpleV2 {
    border-left: 3px solid #6D5FFD; }
  .lg-bd-3-orangeV2 {
    border: 3px solid #FAB005; }
  .lg-h-bd-3-orangeV2:hover {
    border: 3px solid #FAB005; }
  .lg-bdt-3-orangeV2 {
    border-top: 3px solid #FAB005; }
  .lg-bdr-3-orangeV2 {
    border-right: 3px solid #FAB005; }
  .lg-bdb-3-orangeV2 {
    border-bottom: 3px solid #FAB005; }
  .lg-bdl-3-orangeV2 {
    border-left: 3px solid #FAB005; }
  .lg-bd-3-redV2 {
    border: 3px solid #FF1843; }
  .lg-h-bd-3-redV2:hover {
    border: 3px solid #FF1843; }
  .lg-bdt-3-redV2 {
    border-top: 3px solid #FF1843; }
  .lg-bdr-3-redV2 {
    border-right: 3px solid #FF1843; }
  .lg-bdb-3-redV2 {
    border-bottom: 3px solid #FF1843; }
  .lg-bdl-3-redV2 {
    border-left: 3px solid #FF1843; }
  .lg-bd-3-yellowV2 {
    border: 3px solid rgba(250, 176, 5, 0.6); }
  .lg-h-bd-3-yellowV2:hover {
    border: 3px solid rgba(250, 176, 5, 0.6); }
  .lg-bdt-3-yellowV2 {
    border-top: 3px solid rgba(250, 176, 5, 0.6); }
  .lg-bdr-3-yellowV2 {
    border-right: 3px solid rgba(250, 176, 5, 0.6); }
  .lg-bdb-3-yellowV2 {
    border-bottom: 3px solid rgba(250, 176, 5, 0.6); }
  .lg-bdl-3-yellowV2 {
    border-left: 3px solid rgba(250, 176, 5, 0.6); }
  .lg-bd-3-primary-light {
    border: 3px solid #6D6F7C; }
  .lg-h-bd-3-primary-light:hover {
    border: 3px solid #6D6F7C; }
  .lg-bdt-3-primary-light {
    border-top: 3px solid #6D6F7C; }
  .lg-bdr-3-primary-light {
    border-right: 3px solid #6D6F7C; }
  .lg-bdb-3-primary-light {
    border-bottom: 3px solid #6D6F7C; }
  .lg-bdl-3-primary-light {
    border-left: 3px solid #6D6F7C; }
  .lg-bd-3-primary {
    border: 3px solid #15192D; }
  .lg-h-bd-3-primary:hover {
    border: 3px solid #15192D; }
  .lg-bdt-3-primary {
    border-top: 3px solid #15192D; }
  .lg-bdr-3-primary {
    border-right: 3px solid #15192D; }
  .lg-bdb-3-primary {
    border-bottom: 3px solid #15192D; }
  .lg-bdl-3-primary {
    border-left: 3px solid #15192D; }
  .lg-bd-3-primary-dark {
    border: 3px solid #080911; }
  .lg-h-bd-3-primary-dark:hover {
    border: 3px solid #080911; }
  .lg-bdt-3-primary-dark {
    border-top: 3px solid #080911; }
  .lg-bdr-3-primary-dark {
    border-right: 3px solid #080911; }
  .lg-bdb-3-primary-dark {
    border-bottom: 3px solid #080911; }
  .lg-bdl-3-primary-dark {
    border-left: 3px solid #080911; }
  .lg-bd-4-gray-lightV2 {
    border: 4px solid #F4F6F9; }
  .lg-h-bd-4-gray-lightV2:hover {
    border: 4px solid #F4F6F9; }
  .lg-bdt-4-gray-lightV2 {
    border-top: 4px solid #F4F6F9; }
  .lg-bdr-4-gray-lightV2 {
    border-right: 4px solid #F4F6F9; }
  .lg-bdb-4-gray-lightV2 {
    border-bottom: 4px solid #F4F6F9; }
  .lg-bdl-4-gray-lightV2 {
    border-left: 4px solid #F4F6F9; }
  .lg-bd-4-black {
    border: 4px solid #000000; }
  .lg-h-bd-4-black:hover {
    border: 4px solid #000000; }
  .lg-bdt-4-black {
    border-top: 4px solid #000000; }
  .lg-bdr-4-black {
    border-right: 4px solid #000000; }
  .lg-bdb-4-black {
    border-bottom: 4px solid #000000; }
  .lg-bdl-4-black {
    border-left: 4px solid #000000; }
  .lg-bd-4-white {
    border: 4px solid #ffffff; }
  .lg-h-bd-4-white:hover {
    border: 4px solid #ffffff; }
  .lg-bdt-4-white {
    border-top: 4px solid #ffffff; }
  .lg-bdr-4-white {
    border-right: 4px solid #ffffff; }
  .lg-bdb-4-white {
    border-bottom: 4px solid #ffffff; }
  .lg-bdl-4-white {
    border-left: 4px solid #ffffff; }
  .lg-bd-4-gray-lighter {
    border: 4px solid #e8ebed; }
  .lg-h-bd-4-gray-lighter:hover {
    border: 4px solid #e8ebed; }
  .lg-bdt-4-gray-lighter {
    border-top: 4px solid #e8ebed; }
  .lg-bdr-4-gray-lighter {
    border-right: 4px solid #e8ebed; }
  .lg-bdb-4-gray-lighter {
    border-bottom: 4px solid #e8ebed; }
  .lg-bdl-4-gray-lighter {
    border-left: 4px solid #e8ebed; }
  .lg-bd-4-gray-light {
    border: 4px solid #D9D9D9; }
  .lg-h-bd-4-gray-light:hover {
    border: 4px solid #D9D9D9; }
  .lg-bdt-4-gray-light {
    border-top: 4px solid #D9D9D9; }
  .lg-bdr-4-gray-light {
    border-right: 4px solid #D9D9D9; }
  .lg-bdb-4-gray-light {
    border-bottom: 4px solid #D9D9D9; }
  .lg-bdl-4-gray-light {
    border-left: 4px solid #D9D9D9; }
  .lg-bd-4-gray {
    border: 4px solid #B3B3B3; }
  .lg-h-bd-4-gray:hover {
    border: 4px solid #B3B3B3; }
  .lg-bdt-4-gray {
    border-top: 4px solid #B3B3B3; }
  .lg-bdr-4-gray {
    border-right: 4px solid #B3B3B3; }
  .lg-bdb-4-gray {
    border-bottom: 4px solid #B3B3B3; }
  .lg-bdl-4-gray {
    border-left: 4px solid #B3B3B3; }
  .lg-bd-4-gray-dark {
    border: 4px solid #808080; }
  .lg-h-bd-4-gray-dark:hover {
    border: 4px solid #808080; }
  .lg-bdt-4-gray-dark {
    border-top: 4px solid #808080; }
  .lg-bdr-4-gray-dark {
    border-right: 4px solid #808080; }
  .lg-bdb-4-gray-dark {
    border-bottom: 4px solid #808080; }
  .lg-bdl-4-gray-dark {
    border-left: 4px solid #808080; }
  .lg-bd-4-gray-darker {
    border: 4px solid #4A4A4A; }
  .lg-h-bd-4-gray-darker:hover {
    border: 4px solid #4A4A4A; }
  .lg-bdt-4-gray-darker {
    border-top: 4px solid #4A4A4A; }
  .lg-bdr-4-gray-darker {
    border-right: 4px solid #4A4A4A; }
  .lg-bdb-4-gray-darker {
    border-bottom: 4px solid #4A4A4A; }
  .lg-bdl-4-gray-darker {
    border-left: 4px solid #4A4A4A; }
  .lg-bd-4-alert {
    border: 4px solid #EE1233; }
  .lg-h-bd-4-alert:hover {
    border: 4px solid #EE1233; }
  .lg-bdt-4-alert {
    border-top: 4px solid #EE1233; }
  .lg-bdr-4-alert {
    border-right: 4px solid #EE1233; }
  .lg-bdb-4-alert {
    border-bottom: 4px solid #EE1233; }
  .lg-bdl-4-alert {
    border-left: 4px solid #EE1233; }
  .lg-bd-4-alert-light {
    border: 4px solid #FFEBEB; }
  .lg-h-bd-4-alert-light:hover {
    border: 4px solid #FFEBEB; }
  .lg-bdt-4-alert-light {
    border-top: 4px solid #FFEBEB; }
  .lg-bdr-4-alert-light {
    border-right: 4px solid #FFEBEB; }
  .lg-bdb-4-alert-light {
    border-bottom: 4px solid #FFEBEB; }
  .lg-bdl-4-alert-light {
    border-left: 4px solid #FFEBEB; }
  .lg-bd-4-success {
    border: 4px solid #40C057; }
  .lg-h-bd-4-success:hover {
    border: 4px solid #40C057; }
  .lg-bdt-4-success {
    border-top: 4px solid #40C057; }
  .lg-bdr-4-success {
    border-right: 4px solid #40C057; }
  .lg-bdb-4-success {
    border-bottom: 4px solid #40C057; }
  .lg-bdl-4-success {
    border-left: 4px solid #40C057; }
  .lg-bd-4-success-light {
    border: 4px solid #E6FFEA; }
  .lg-h-bd-4-success-light:hover {
    border: 4px solid #E6FFEA; }
  .lg-bdt-4-success-light {
    border-top: 4px solid #E6FFEA; }
  .lg-bdr-4-success-light {
    border-right: 4px solid #E6FFEA; }
  .lg-bdb-4-success-light {
    border-bottom: 4px solid #E6FFEA; }
  .lg-bdl-4-success-light {
    border-left: 4px solid #E6FFEA; }
  .lg-bd-4-info {
    border: 4px solid #4dadf7; }
  .lg-h-bd-4-info:hover {
    border: 4px solid #4dadf7; }
  .lg-bdt-4-info {
    border-top: 4px solid #4dadf7; }
  .lg-bdr-4-info {
    border-right: 4px solid #4dadf7; }
  .lg-bdb-4-info {
    border-bottom: 4px solid #4dadf7; }
  .lg-bdl-4-info {
    border-left: 4px solid #4dadf7; }
  .lg-bd-4-info-light {
    border: 4px solid #d0eaff; }
  .lg-h-bd-4-info-light:hover {
    border: 4px solid #d0eaff; }
  .lg-bdt-4-info-light {
    border-top: 4px solid #d0eaff; }
  .lg-bdr-4-info-light {
    border-right: 4px solid #d0eaff; }
  .lg-bdb-4-info-light {
    border-bottom: 4px solid #d0eaff; }
  .lg-bdl-4-info-light {
    border-left: 4px solid #d0eaff; }
  .lg-bd-4-orange {
    border: 4px solid #E68F17; }
  .lg-h-bd-4-orange:hover {
    border: 4px solid #E68F17; }
  .lg-bdt-4-orange {
    border-top: 4px solid #E68F17; }
  .lg-bdr-4-orange {
    border-right: 4px solid #E68F17; }
  .lg-bdb-4-orange {
    border-bottom: 4px solid #E68F17; }
  .lg-bdl-4-orange {
    border-left: 4px solid #E68F17; }
  .lg-bd-4-yellow {
    border: 4px solid #F5C644; }
  .lg-h-bd-4-yellow:hover {
    border: 4px solid #F5C644; }
  .lg-bdt-4-yellow {
    border-top: 4px solid #F5C644; }
  .lg-bdr-4-yellow {
    border-right: 4px solid #F5C644; }
  .lg-bdb-4-yellow {
    border-bottom: 4px solid #F5C644; }
  .lg-bdl-4-yellow {
    border-left: 4px solid #F5C644; }
  .lg-bd-4-coral {
    border: 4px solid #FA5252; }
  .lg-h-bd-4-coral:hover {
    border: 4px solid #FA5252; }
  .lg-bdt-4-coral {
    border-top: 4px solid #FA5252; }
  .lg-bdr-4-coral {
    border-right: 4px solid #FA5252; }
  .lg-bdb-4-coral {
    border-bottom: 4px solid #FA5252; }
  .lg-bdl-4-coral {
    border-left: 4px solid #FA5252; }
  .lg-bd-4-pink {
    border: 4px solid #E64980; }
  .lg-h-bd-4-pink:hover {
    border: 4px solid #E64980; }
  .lg-bdt-4-pink {
    border-top: 4px solid #E64980; }
  .lg-bdr-4-pink {
    border-right: 4px solid #E64980; }
  .lg-bdb-4-pink {
    border-bottom: 4px solid #E64980; }
  .lg-bdl-4-pink {
    border-left: 4px solid #E64980; }
  .lg-bd-4-purple {
    border: 4px solid #BE4BDB; }
  .lg-h-bd-4-purple:hover {
    border: 4px solid #BE4BDB; }
  .lg-bdt-4-purple {
    border-top: 4px solid #BE4BDB; }
  .lg-bdr-4-purple {
    border-right: 4px solid #BE4BDB; }
  .lg-bdb-4-purple {
    border-bottom: 4px solid #BE4BDB; }
  .lg-bdl-4-purple {
    border-left: 4px solid #BE4BDB; }
  .lg-bd-4-teal {
    border: 4px solid #0B7285; }
  .lg-h-bd-4-teal:hover {
    border: 4px solid #0B7285; }
  .lg-bdt-4-teal {
    border-top: 4px solid #0B7285; }
  .lg-bdr-4-teal {
    border-right: 4px solid #0B7285; }
  .lg-bdb-4-teal {
    border-bottom: 4px solid #0B7285; }
  .lg-bdl-4-teal {
    border-left: 4px solid #0B7285; }
  .lg-bd-4-turquoise {
    border: 4px solid #15AABF; }
  .lg-h-bd-4-turquoise:hover {
    border: 4px solid #15AABF; }
  .lg-bdt-4-turquoise {
    border-top: 4px solid #15AABF; }
  .lg-bdr-4-turquoise {
    border-right: 4px solid #15AABF; }
  .lg-bdb-4-turquoise {
    border-bottom: 4px solid #15AABF; }
  .lg-bdl-4-turquoise {
    border-left: 4px solid #15AABF; }
  .lg-bd-4-teal-dark {
    border: 4px solid #0F354D; }
  .lg-h-bd-4-teal-dark:hover {
    border: 4px solid #0F354D; }
  .lg-bdt-4-teal-dark {
    border-top: 4px solid #0F354D; }
  .lg-bdr-4-teal-dark {
    border-right: 4px solid #0F354D; }
  .lg-bdb-4-teal-dark {
    border-bottom: 4px solid #0F354D; }
  .lg-bdl-4-teal-dark {
    border-left: 4px solid #0F354D; }
  .lg-bd-4-purpleV2 {
    border: 4px solid #6D5FFD; }
  .lg-h-bd-4-purpleV2:hover {
    border: 4px solid #6D5FFD; }
  .lg-bdt-4-purpleV2 {
    border-top: 4px solid #6D5FFD; }
  .lg-bdr-4-purpleV2 {
    border-right: 4px solid #6D5FFD; }
  .lg-bdb-4-purpleV2 {
    border-bottom: 4px solid #6D5FFD; }
  .lg-bdl-4-purpleV2 {
    border-left: 4px solid #6D5FFD; }
  .lg-bd-4-orangeV2 {
    border: 4px solid #FAB005; }
  .lg-h-bd-4-orangeV2:hover {
    border: 4px solid #FAB005; }
  .lg-bdt-4-orangeV2 {
    border-top: 4px solid #FAB005; }
  .lg-bdr-4-orangeV2 {
    border-right: 4px solid #FAB005; }
  .lg-bdb-4-orangeV2 {
    border-bottom: 4px solid #FAB005; }
  .lg-bdl-4-orangeV2 {
    border-left: 4px solid #FAB005; }
  .lg-bd-4-redV2 {
    border: 4px solid #FF1843; }
  .lg-h-bd-4-redV2:hover {
    border: 4px solid #FF1843; }
  .lg-bdt-4-redV2 {
    border-top: 4px solid #FF1843; }
  .lg-bdr-4-redV2 {
    border-right: 4px solid #FF1843; }
  .lg-bdb-4-redV2 {
    border-bottom: 4px solid #FF1843; }
  .lg-bdl-4-redV2 {
    border-left: 4px solid #FF1843; }
  .lg-bd-4-yellowV2 {
    border: 4px solid rgba(250, 176, 5, 0.6); }
  .lg-h-bd-4-yellowV2:hover {
    border: 4px solid rgba(250, 176, 5, 0.6); }
  .lg-bdt-4-yellowV2 {
    border-top: 4px solid rgba(250, 176, 5, 0.6); }
  .lg-bdr-4-yellowV2 {
    border-right: 4px solid rgba(250, 176, 5, 0.6); }
  .lg-bdb-4-yellowV2 {
    border-bottom: 4px solid rgba(250, 176, 5, 0.6); }
  .lg-bdl-4-yellowV2 {
    border-left: 4px solid rgba(250, 176, 5, 0.6); }
  .lg-bd-4-primary-light {
    border: 4px solid #6D6F7C; }
  .lg-h-bd-4-primary-light:hover {
    border: 4px solid #6D6F7C; }
  .lg-bdt-4-primary-light {
    border-top: 4px solid #6D6F7C; }
  .lg-bdr-4-primary-light {
    border-right: 4px solid #6D6F7C; }
  .lg-bdb-4-primary-light {
    border-bottom: 4px solid #6D6F7C; }
  .lg-bdl-4-primary-light {
    border-left: 4px solid #6D6F7C; }
  .lg-bd-4-primary {
    border: 4px solid #15192D; }
  .lg-h-bd-4-primary:hover {
    border: 4px solid #15192D; }
  .lg-bdt-4-primary {
    border-top: 4px solid #15192D; }
  .lg-bdr-4-primary {
    border-right: 4px solid #15192D; }
  .lg-bdb-4-primary {
    border-bottom: 4px solid #15192D; }
  .lg-bdl-4-primary {
    border-left: 4px solid #15192D; }
  .lg-bd-4-primary-dark {
    border: 4px solid #080911; }
  .lg-h-bd-4-primary-dark:hover {
    border: 4px solid #080911; }
  .lg-bdt-4-primary-dark {
    border-top: 4px solid #080911; }
  .lg-bdr-4-primary-dark {
    border-right: 4px solid #080911; }
  .lg-bdb-4-primary-dark {
    border-bottom: 4px solid #080911; }
  .lg-bdl-4-primary-dark {
    border-left: 4px solid #080911; }
  .lg-bd-radius-0 {
    border-radius: 0px; }
  .lg-bd-radius-1 {
    border-radius: 1px; }
  .lg-bd-radius-2 {
    border-radius: 2px; }
  .lg-bd-radius-3 {
    border-radius: 3px; }
  .lg-bd-radius-4 {
    border-radius: 4px; }
  .lg-bd-radius-5 {
    border-radius: 5px; }
  .lg-bd-radius-6 {
    border-radius: 6px; }
  .lg-bd-radius-7 {
    border-radius: 7px; }
  .lg-bd-radius-8 {
    border-radius: 8px; }
  .lg-bd-radius-9 {
    border-radius: 9px; }
  .lg-bd-radius-10 {
    border-radius: 10px; }
  .lg-bd-radius-11 {
    border-radius: 11px; }
  .lg-bd-radius-12 {
    border-radius: 12px; }
  .lg-bd-radius-13 {
    border-radius: 13px; }
  .lg-bd-radius-14 {
    border-radius: 14px; }
  .lg-bd-radius-15 {
    border-radius: 15px; }
  .lg-bd-radius-16 {
    border-radius: 16px; }
  .lg-bd-radius-17 {
    border-radius: 17px; }
  .lg-bd-radius-18 {
    border-radius: 18px; }
  .lg-bd-radius-19 {
    border-radius: 19px; }
  .lg-bd-radius-20 {
    border-radius: 20px; }
  .lg-bd-radius-21 {
    border-radius: 21px; }
  .lg-bd-radius-22 {
    border-radius: 22px; }
  .lg-bd-radius-23 {
    border-radius: 23px; }
  .lg-bd-radius-24 {
    border-radius: 24px; }
  .lg-bd-radius-25 {
    border-radius: 25px; }
  .lg-bd-radius-26 {
    border-radius: 26px; }
  .lg-bd-radius-27 {
    border-radius: 27px; }
  .lg-bd-radius-28 {
    border-radius: 28px; }
  .lg-bd-radius-29 {
    border-radius: 29px; }
  .lg-bd-radius-30 {
    border-radius: 30px; }
  .lg-bd-radius-31 {
    border-radius: 31px; }
  .lg-bd-radius-32 {
    border-radius: 32px; }
  .lg-bd-radius-33 {
    border-radius: 33px; }
  .lg-bd-radius-34 {
    border-radius: 34px; }
  .lg-bd-radius-35 {
    border-radius: 35px; }
  .lg-bd-radius-36 {
    border-radius: 36px; }
  .lg-bd-radius-37 {
    border-radius: 37px; }
  .lg-bd-radius-38 {
    border-radius: 38px; }
  .lg-bd-radius-39 {
    border-radius: 39px; }
  .lg-bd-radius-40 {
    border-radius: 40px; }
  .lg-bd-radius-41 {
    border-radius: 41px; }
  .lg-bd-radius-42 {
    border-radius: 42px; }
  .lg-bd-radius-43 {
    border-radius: 43px; }
  .lg-bd-radius-44 {
    border-radius: 44px; }
  .lg-bd-radius-45 {
    border-radius: 45px; }
  .lg-bd-radius-46 {
    border-radius: 46px; }
  .lg-bd-radius-47 {
    border-radius: 47px; }
  .lg-bd-radius-48 {
    border-radius: 48px; }
  .lg-bd-radius-49 {
    border-radius: 49px; }
  .lg-bd-radius-50 {
    border-radius: 50px; }
  .lg-muted {
    opacity: 0.4; }
  .lg-uppercase {
    text-transform: uppercase; }
  .lg-capitalize {
    text-transform: capitalize; }
  .lg-ovf-hidden {
    overflow: hidden; }
  .lg-ovf-scroll {
    overflow: scroll; }
  .lg-bd-radius {
    border-radius: 4px; }
  .lg-bdtl-radius {
    border-top-left-radius: 4px; }
  .lg-bdtr-radius {
    border-top-right-radius: 4px; }
  .lg-bdbl-radius {
    border-bottom-left-radius: 4px; }
  .lg-bdbr-radius {
    border-bottom-right-radius: 4px; }
  .lg-no-radius {
    border-radius: 0; }
  .lg-round {
    border-radius: 50%; } }

.ratio-1_1 {
  position: relative; }
  .ratio-1_1 > *:first-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .ratio-1_1:before {
    display: block;
    content: "";
    padding-bottom: 100%; }

.relative {
  position: relative; }

.rotate-15 {
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg); }

.rotate-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.h-rotate-15:hover {
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg); }

.h-rotate-45:hover {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.h-rotate-90:hover {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.h-rotate-180:hover {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.h-rotate-270:hover {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.anim-all {
  transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1); }

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important; }

.shadow__long {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16) !important; }

.shadowV2 {
  box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.08) !important; }

.rounded {
  border-radius: 50%; }

.curp {
  cursor: pointer; }

.pointer-events-none {
  pointer-events: none; }

.hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.user-select-none {
  -webkit-user-select: none;
          user-select: none; }

svg {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%; }

.icon-bg {
  display: block;
  background-repeat: no-repeat;
  background-size: 100%; }

.icon-bg.arrow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpolygon fill='%2315192D' transform='translate(-500.000000, -1685.000000)' points='510 1685 508.2375 1686.7625 515.2125 1693.75 500 1693.75 500 1696.25 515.2125 1696.25 508.2375 1703.2375 510 1705 520 1695'/%3E %3C/svg%3E"); }

/*---
title:   Icons
name:    icons
---

```example:html
<div>
  <svg class="icon big"><use xlink:href="#icon-account" /></svg>
  <svg class="icon big"><use xlink:href="#icon-arrow_down" /></svg>
  <svg class="icon big"><use xlink:href="#icon-arrow_left_slider" /></svg>
  <svg class="icon big"><use xlink:href="#icon-arrow_right_slider" /></svg>
  <svg class="icon big"><use xlink:href="#icon-cart" /></svg>
</div>

<div>
  <svg class="icon small alert"><use xlink:href="#icon-close" /></svg>
  <svg class="icon warning"><use xlink:href="#icon-in_progress" /></svg>
  <svg class="icon big info"><use xlink:href="#icon-phone" /></svg>
  <svg class="icon big success"><use xlink:href="#icon-favoris" /></svg>
</div>

<div>
  <svg class="icon big gray-lighter"><use xlink:href="#icon-close" /></svg>
  <svg class="icon big gray-light"><use xlink:href="#icon-close" /></svg>
  <svg class="icon big gray"><use xlink:href="#icon-close" /></svg>
  <svg class="icon big gray-dark"><use xlink:href="#icon-close" /></svg>
  <svg class="icon big gray-darker"><use xlink:href="#icon-close" /></svg>
</div>

<div>
  <span class="icon-bg close-primary small"></span>
  <span class="icon-bg close-secondary"></span>
  <span class="icon-bg close-alert big"></span>
</div>
```
*/
button {
  background: none;
  border: none; }

.button {
  padding: 1rem 3.3rem 1.1rem;
  transition: background 250ms cubic-bezier(0.55, 0, 0.1, 1), border 250ms cubic-bezier(0.55, 0, 0.1, 1), color 250ms cubic-bezier(0.55, 0, 0.1, 1), opacity 250ms cubic-bezier(0.55, 0, 0.1, 1);
  text-decoration: none;
  cursor: pointer;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.14;
  box-shadow: none;
  border-radius: 0;
  text-align: center;
  border-radius: 4rem;
  /*---
  title:   Size
  name:    size-button
  section: Buttons
  ---

  Button styles can be applied to any element (excepted `.tertiary` wich can't be used with self-closing HTML attributes like input).
  Typically you'll want to use either a `<button>` or an `<a>` element:

  ```example:html
  <input type="submit" class="button primary small" value="Button cta" />
  <button class="button primary">Button primary</button>
  <a class="button primary large" href="#x">Button secondary</a>
  ```
  */
  /*---
  title:   Colors
  name:    colors-button
  section: Buttons
  ---

  Button styles can be applied to any element (excepted `.tertiary` wich can't be used with self-closing HTML attributes like input).
  Typically you'll want to use either a `<button>` or an `<a>` element:

  ```example:html
  <input type="submit" class="button cta" value="Button cta" />
  <button class="button primary">Button primary</button>
  <a class="button secondary" href="#x">Button secondary</a>
  <button class="button tertiary">Button tertiary</button>
  ```
  */
  /*---
  title:   Disabled
  name:    disabled-button
  section: Buttons
  ---

  Buttons can also be used as call to action:

  ```example:html
  <button class="button cta disabled">Button cta</button>
  <button class="button primary disabled">Button primary</button>
  <a class="button secondary disabled" href="#x">Button secondary</a>
  <a class="button tertiary disabled" href="#x">Button tertiary</a>
  ```
  */ }
  .button.with-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .button.with-icon svg {
      width: 1.2rem;
      height: 1.2rem; }
      .button.with-icon svg + span {
        margin-left: 1rem; }
  .button.small {
    font-size: 1.2rem;
    line-height: 1.333333;
    padding: 0.8rem 2rem;
    border-radius: 3.2rem; }
    .button.small.with-icon svg {
      width: 1.2rem;
      height: 1.2rem; }
      .button.small.with-icon svg + span {
        margin-left: 1rem; }
  .button.large {
    padding: 1.5rem 4rem 1.7rem;
    border-radius: 4.8rem; }
    .button.large.with-icon {
      padding: 1.2rem 2rem; }
      .button.large.with-icon svg {
        width: 2.4rem;
        height: 2.4rem; }
        .button.large.with-icon svg + span {
          margin-left: 1.2rem; }
  .button.primary {
    color: #ffffff;
    border: 1px solid #15192D;
    background: #15192D; }
    .button.primary.light {
      color: #15192D;
      border: 1px solid #6D6F7C;
      background: #6D6F7C; }
    .button.primary.white {
      color: #15192D;
      border: 1px solid #ffffff;
      background: #ffffff; }
    .button.primary:not(.disabled):not([disabled]):hover, .button.primary:not(.disabled):not([disabled]):focus, .button.primary:not(.disabled):not([disabled]):active {
      color: #ffffff;
      border-color: #080911;
      background: #080911; }
  .button.cancel {
    border-color: transparent;
    background: transparent;
    color: #EE1233; }
    .button.cancel:not(.disabled):not([disabled]):hover, .button.cancel:not(.disabled):not([disabled]):focus, .button.cancel:not(.disabled):not([disabled]):active {
      color: #ffffff;
      border-color: #B3B3B3;
      background: #B3B3B3; }
  .button.outline {
    background: #ffffff;
    border: 1px solid #D9D9D9;
    color: #000000; }
    .button.outline:not(.disabled):not([disabled]):hover, .button.outline:not(.disabled):not([disabled]):focus, .button.outline:not(.disabled):not([disabled]):active {
      border-color: #15192D;
      color: #15192D; }
    .button.outline.gray-dark {
      background: #ffffff;
      border: 1px solid #D9D9D9;
      color: #000000; }
      .button.outline.gray-dark:not(.disabled):not([disabled]):hover, .button.outline.gray-dark:not(.disabled):not([disabled]):focus, .button.outline.gray-dark:not(.disabled):not([disabled]):active {
        border-color: #808080;
        color: #ffffff;
        background: #808080; }
    .button.outline.primary {
      background: #ffffff;
      border: 1px solid #15192D;
      color: #15192D; }
      .button.outline.primary:not(.disabled):not([disabled]):hover, .button.outline.primary:not(.disabled):not([disabled]):focus, .button.outline.primary:not(.disabled):not([disabled]):active {
        border-color: #15192D;
        background: #15192D;
        color: #ffffff; }
  .button.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .button.disabled, .button[disabled] {
    opacity: 0.4;
    text-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
    cursor: default; }

.button-group {
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch; }
  .button-group.primary {
    --color: #15192D; }
  .button-group.gray {
    --color: #B3B3B3; }
  .button-group.gray-dark {
    --color: #808080; }
  .button-group.gray-darker {
    --color: #4A4A4A; }
  .button-group .button {
    color: var(--color);
    border: 1px solid var(--color); }
    .button-group .button.active {
      background: var(--color);
      color: #ffffff; }
  .button-group .button:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none; }
  .button-group .button:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none; }

label {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem; }
  label span {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 1.4rem;
    padding: 0;
    line-height: 1;
    color: #4A4A4A; }
    label span[data-optionnal]:after {
      content: 'Optionnel';
      font-size: 1rem;
      line-height: 1.2rem;
      color: #B3B3B3; }
  label > input,
  label > select,
  label > textarea {
    margin-top: 0.5rem;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.2;
    padding-top: 0.5rem;
    padding-bottom: 1rem; }
  label.error {
    position: relative;
    color: #EE1233; }
    label.error:before {
      content: '';
      position: absolute;
      top: 6.1rem;
      left: 2rem;
      display: block;
      height: 0;
      border-bottom: 1rem solid rgba(238, 18, 51, 0.2);
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent; }
    label.error:after {
      content: attr(data-error);
      position: relative;
      background: rgba(238, 18, 51, 0.2);
      color: #EE1233;
      font-size: 1rem;
      line-height: 1;
      padding: 1rem 1rem;
      border-radius: 1.5rem; }
    label.error > input,
    label.error > select,
    label.error > textarea {
      border-color: #EE1233;
      margin-bottom: 1.5rem; }
  label abbr {
    display: none; }
  label.dark input {
    border: none;
    background-color: #080911; }
  label.dark input::-webkit-input-placeholder {
    color: #ffffff; }

/*---
title:   Input
name:    form-input
order: 2
section: Form
---

```example:html
<label for="input-error" class="error" data-error="Error message">
  Input label
  <input type="text" name="input" value="Content added" id="input-error">
</label>
<label for="input">
  Input label
  <input type="text" name="input" value="" id="input" placeholder="Input placeholder">
</label>
<label for="input-value">
  Input label
  <input type="text" name="input" value="Content added" id="input-value">
</label>
<label for="input-disabled">
  Input label
  <input type="text" name="input" value="I am disabled" id="input-disabled" disabled>
</label>
```
*/
input,
textarea,
select {
  width: 100%;
  position: relative;
  border: none;
  border-bottom: 1px solid #B3B3B3;
  background: transparent;
  -webkit-appearance: none;
          appearance: none;
  font-size: 1.3rem;
  line-height: 1.425;
  font-weight: 400;
  padding: 1.2rem 0;
  color: #000000;
  border-radius: 0;
  transition: border 250ms cubic-bezier(0.55, 0, 0.1, 1); }
  input:not(:disabled):hover, input:not(:read-only):hover,
  textarea:not(:disabled):hover,
  textarea:not(:read-only):hover,
  select:not(:disabled):hover,
  select:not(:read-only):hover {
    border-color: #808080; }
  input:not(:disabled):focus, input:not(:read-only):focus,
  textarea:not(:disabled):focus,
  textarea:not(:read-only):focus,
  select:not(:disabled):focus,
  select:not(:read-only):focus {
    border-color: #4A4A4A; }
  input:disabled, input:not(select):read-only,
  textarea:disabled,
  textarea:not(select):read-only,
  select:disabled,
  select:not(select):read-only {
    background: rgba(232, 235, 237, 0.5);
    color: #808080; }
  input[aria-invalid="true"],
  textarea[aria-invalid="true"],
  select[aria-invalid="true"] {
    border-color: #EE1233; }

input {
  line-height: 4.1rem;
  padding-top: 0;
  padding-bottom: 0; }

textarea {
  line-height: 1.425; }

.phoneInput input {
  font-size: 1.8rem; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-size: 1.3rem;
  line-height: normal !important;
  color: #808080;
  vertical-align: middle; }

input::placeholder,
input::-webkit-input-placeholder,
textarea::placeholder,
textarea::-webkit-input-placeholder {
  font-size: 1.3rem;
  line-height: normal !important;
  color: #808080;
  vertical-align: middle; }

input::-webkit-input-placeholder {
  line-height: 4.1rem; }

input::placeholder,
input::-webkit-input-placeholder {
  line-height: 4.1rem; }

input.quantity {
  text-align: center;
  width: 6.5rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 54px;
  padding: 0;
  margin: 0 auto; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/*---
title:   Select
name:    form-select
section: form
---

```example:html
<label for="select1">
  Select label
  <select class="" name="select" id="select1">
    <option value="1">Select option</option>
    <option value="2">Select option</option>
    <option value="3">Select option</option>
    <option value="4">Select option</option>
    <option value="5">Select option</option>
  </select>
</label>
<label for="select2">
  Select label
  <select class="" name="select" id="select2" disabled>
    <option value="1">Select option</option>
    <option value="2">Select option</option>
    <option value="3">Select option</option>
    <option value="4">Select option</option>
    <option value="5">Select option</option>
  </select>
</label>
```
*/
select {
  margin: 0;
  padding: 0.2rem 2.5rem 0.2rem 0.5rem;
  color: #000000;
  border: none;
  border-radius: 0;
  font-size: 1.8rem;
  border-bottom: 1px solid #D9D9D9;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 16' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill='#B3B3B3' transform='translate(-254.000000, -1687.000000)' d='M268,1697.64663 L278.889276,1687.1931 C279.15807,1686.93051 279.601892,1686.93676 279.876937,1687.2056 L281.789749,1689.07498 C282.064794,1689.34382 282.071045,1689.78147 281.802251,1690.04406 L268.506333,1702.81087 C268.36881,1702.94842 268.18128,1703.01094 268,1702.99844 C267.812469,1703.00469 267.63119,1702.94217 267.493667,1702.81087 L254.197749,1690.04406 C253.928955,1689.78147 253.935206,1689.34382 254.210251,1689.07498 L256.123063,1687.2056 C256.398108,1686.93676 256.84193,1686.93051 257.110724,1687.1931 L268,1697.64663 Z'%3E%3C/path%3E %3C/svg%3E");
  background-position: calc(100% - 0.5rem) 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  cursor: pointer;
  transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1); }
  select:not(:disabled):hover {
    border-bottom-color: #808080;
    box-shadow: 0 1px 0 #808080; }
  select::-ms-expand {
    display: none; }
  select:disabled {
    opacity: 1;
    padding: 0.2rem 0.2rem;
    cursor: default; }
  select.small {
    background-position: calc(100% - 1.2rem) 50%;
    padding: 0.5rem 4rem 0.5rem 2rem; }

/*---
title:   Textarea
name:    form-textarea
section: form
---

```example:html
<label for="textarea">
  Textarea label
  <textarea name="textarea" id="textarea" placeholder="Textarea placeholder"></textarea>
</label>
```
*/
textarea {
  resize: vertical;
  min-height: 16rem; }

/*---
title:   Input group
name:    form-input-group
section: form
---

```example:html
<label for="input-group" class="input-group">
  Group input
  <div class="input-group--inner">
    <input type="text" name="input-group" id="input-group" value="" placeholder="input placeholder" id="input-group">
    <button type="submit" name="button" class="button secondary">OK</button>
  </div>
</label>
<label for="input-group-disabled" class="input-group">
  Group input disabled
  <div class="input-group--inner">
    <input type="text" name="input-group" id="input-group-disabled" value="" placeholder="input placeholder" id="input-group" disabled>
    <button type="submit" name="button" class="button secondary disabled">OK</button>
  </div>
</label>

<label for="input-group_search" class="input-group search">
  <div class="input-group--inner">
    <input type="text" name="input-group" value="" placeholder="Rechercher un produit" id="input-group_search">
    <button type="submit" name="button" class="button secondary">
      <svg class="icon"><use xlink:href="#icon-search" /></svg>
    </button>
  </div>
</label>
```
*/
.input-group {
  position: relative; }
  .input-group > input {
    margin-top: 0;
    padding: 0 6rem 0 2.2rem;
    border-radius: 2rem;
    line-height: 4.1rem;
    border: 1px solid #B3B3B3; }
    .input-group > input:focus + button {
      color: #4A4A4A; }
  .input-group button {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    right: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: transparent;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    color: #B3B3B3;
    transition: color 250ms cubic-bezier(0.55, 0, 0.1, 1); }
  .input-group.dark input,
  .input-group.dark button {
    color: #ffffff; }
  .input-group.dark input:focus + button {
    color: #B3B3B3; }

/*---
title:   Input radio
name:    form-input-radio
section: form
---

```example:html
<div class="">
  <input type="radio" name="radio" value="1" id="radio1">
  <label for="radio1">
    Radio option
  </label>
</div>
<div class="">
  <input type="radio" name="radio" value="2" id="radio2" checked>
  <label for="radio2">
    Radio option
  </label>
</div>
<div class="">
  <input type="radio" name="radio-disabled" value="2" id="radio2" disabled>
  <label for="radio2">
    Radio option
  </label>
</div>
<div class="">
  <input type="radio" name="radio-disabled" value="3" id="radio3" checked disabled>
  <label for="radio3">
    Radio option
  </label>
</div>
```
*/
input[type="radio"] {
  display: none; }
  input[type="radio"] + label {
    position: relative;
    display: block;
    padding: 1rem 1rem 1rem 4rem;
    border: 1px solid #e8ebed;
    border-radius: 0.4rem;
    cursor: pointer;
    min-height: 4rem;
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    -webkit-user-select: none;
            user-select: none;
    color: #4A4A4A;
    transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1); }
    input[type="radio"] + label:before {
      content: '';
      position: absolute;
      left: 1rem;
      top: 2rem;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background: #D9D9D9;
      border: 1px solid #D9D9D9;
      transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1); }
    input[type="radio"] + label:after {
      content: '';
      position: absolute;
      left: 1.4rem;
      top: 2rem;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 4px;
      background: #808080;
      opacity: 0;
      transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1); }
  input[type="radio"]:checked + label {
    border: 2px solid #808080;
    padding: 0.9rem 0.9rem 0.9rem 3.9rem; }
    input[type="radio"]:checked + label:after {
      opacity: 1; }
  input[type="radio"]:disabled + label {
    opacity: 0.4;
    color: #808080;
    cursor: default; }
    input[type="radio"]:disabled + label:before {
      background: #D9D9D9; }

/*---
title:   Input checkbox
name:    form-input-checkbox
section: form
---

```example:html
<div class="">
  <input type="checkbox" name="checkbox" value="1" id="checkbox1">
  <label for="checkbox1">
    Checkbox option
  </label>
</div>
<div class="">
  <input type="checkbox" name="checkbox" value="2" id="checkbox2" checked>
  <label for="checkbox2">
    Checkbox option
  </label>
</div>
<div class="">
  <input type="checkbox" name="checkbox" value="3" id="checkbox3" disabled>
  <label for="checkbox3">
    Checkbox option
  </label>
</div>
<div class="">
  <input type="checkbox" name="checkbox" value="4" id="checkbox4" checked disabled>
  <label for="checkbox4">
    Checkbox option
  </label>
</div>
```
*/
input[type="checkbox"] {
  display: none; }
  input[type="checkbox"] + label {
    position: relative;
    display: block;
    padding: 1rem 1rem 1rem 4rem;
    border: 1px solid #e8ebed;
    border-radius: 0.4rem;
    cursor: pointer;
    min-height: 4rem;
    height: 100%;
    font-size: 1.4rem;
    line-height: 2rem;
    -webkit-user-select: none;
            user-select: none;
    color: #4A4A4A;
    transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1); }
    input[type="checkbox"] + label:before {
      content: '';
      position: absolute;
      left: 1rem;
      top: 1.2rem;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 2px;
      background: #D9D9D9;
      border: 1px solid #D9D9D9;
      transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1); }
    input[type="checkbox"] + label:after {
      display: none; }
    input[type="checkbox"] + label svg {
      position: absolute;
      left: 1.2rem;
      top: 2rem;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 1.2rem;
      opacity: 0;
      transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1); }
  input[type="checkbox"]:checked + label {
    border: 2px solid #808080;
    padding: 0.9rem 0.9rem 0.9rem 3.9rem; }
    input[type="checkbox"]:checked + label svg {
      opacity: 1; }
  input[type="checkbox"]:disabled + label {
    opacity: 0.4;
    color: #808080;
    cursor: default; }
    input[type="checkbox"]:disabled + label:before {
      background: #D9D9D9; }
  input[type="checkbox"][id*="toggle-"]:not(:checked) ~ .toggle,
  input[type="checkbox"][id*="toggle-"]:checked ~ .hide-when-toggle {
    display: none; }

label[for*="toggle-"] {
  cursor: pointer; }

input.checkbox-switch + label {
  width: 4rem;
  flex: 0 0 4rem;
  height: 2rem;
  border-radius: 2rem;
  padding: .3rem !important;
  min-height: inherit;
  position: relative;
  background-color: #e8ebed;
  border: none !important;
  transition: 250ms cubic-bezier(0.55, 0, 0.1, 1); }
  input.checkbox-switch + label:before {
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid #e8ebed;
    background-color: #808080;
    transition: 250ms cubic-bezier(0.55, 0, 0.1, 1); }

input.checkbox-switch:checked + label {
  background-color: #40C057; }
  input.checkbox-switch:checked + label:before {
    border-color: #40C057;
    background-color: white;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }

.alert-box {
  position: relative;
  padding: 1.5rem;
  max-height: 20rem;
  border-radius: 4px;
  border: 1px solid;
  transition: max-height 250ms cubic-bezier(0.55, 0, 0.1, 1) 125ms, padding 250ms cubic-bezier(0.55, 0, 0.1, 1) 125ms, margin 250ms cubic-bezier(0.55, 0, 0.1, 1) 125ms;
  /*---
  title:   Alert
  name:    alert
  ---

  Alert can have different colors:

  ```example:html
  <div class="alert-box success">
    <p class="no-margin">This is a success alert</p>
    <a href="#x" class="alert-box--close"><svg class="icon small black"><use xlink:href="#icon-close" /></svg></a>
  </div>
  <div class="alert-box warning">
    <p class="no-margin">This is a warning alert</p>
    <a href="#x" class="alert-box--close"><svg class="icon small gray-darker"><use xlink:href="#icon-close" /></svg></a>
  </div>
  <div class="alert-box info">
    <p class="no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    <a href="#x" class="alert-box--close"><svg class="icon small white"><use xlink:href="#icon-close" /></svg></a>
  </div>
  <div class="alert-box alert">
    <p class="no-margin">This is an alert alert ¯\_(ツ)_/¯</p>
    <a href="#x" class="alert-box--close"><svg class="icon small white"><use xlink:href="#icon-close" /></svg></a>
  </div>
  ```
  */ }
  .alert-box.success {
    background: #40C057;
    color: #ffffff; }
  .alert-box.warning {
    color: #000000; }
  .alert-box.info {
    background: rgba(77, 173, 247, 0.2);
    border-color: #4dadf7; }
  .alert-box.alert {
    background: #EE1233;
    color: #ffffff; }
  .alert-box .alert-box--close {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 1.5rem;
    right: 2rem;
    background-size: 66%;
    cursor: pointer;
    transition: opacity 250ms cubic-bezier(0.55, 0, 0.1, 1); }
  .alert-box > * {
    transition: opacity 250ms cubic-bezier(0.55, 0, 0.1, 1);
    transition-delay: 125ms; }
  .alert-box.remove {
    overflow: hidden;
    max-height: 0;
    padding: 0 7rem 0 4rem;
    margin-top: 0;
    margin-bottom: 0; }
    .alert-box.remove > * {
      opacity: 0; }

@media (max-width: 640px) {
  .table-scroll {
    position: relative; }
    .table-scroll:before, .table-scroll:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 2rem; }
    .table-scroll:before {
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0));
      left: 0; }
    .table-scroll:after {
      background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0));
      right: 0; }
    .table-scroll .table-scroll--container {
      display: block;
      overflow-x: auto;
      white-space: nowrap; }
      .table-scroll .table-scroll--container table {
        display: initial;
        padding: 0 2rem; } }

table {
  border: none;
  border-collapse: collapse; }
  table:not(.no-margin) {
    margin-bottom: 4rem; }
  table tbody tr td {
    padding: 1rem 0; }
    table tbody tr td.no-wrap {
      white-space: nowrap; }
    table tbody tr td:not(:last-of-type) {
      padding-right: 1rem; }
  table tbody tr:not(:last-of-type) {
    border-bottom: 1px solid #D9D9D9; }
  table tfoot,
  table thead {
    border-bottom: 1px solid #D9D9D9; }
    table tfoot tr,
    table thead tr {
      font-size: 14px;
      line-height: 14px;
      color: #4A4A4A;
      text-align: left; }
      table tfoot tr th,
      table thead tr th {
        padding: 1rem 0;
        font-weight: 700; }
        table tfoot tr th:not(:last-of-type),
        table thead tr th:not(:last-of-type) {
          padding-right: 1rem; }
      table tfoot tr th,
      table thead tr th {
        padding: 1rem 0;
        font-weight: 700; }
  table.stripped {
    border: none;
    /*---
title:   Table stripped
name:    table-stripped
section: Table
---

Tables can be stripped

```example:html
<table class="stripped">
  <tr>
    <td>Col 1</td>
    <td><a href="#x">Col 2</a></td>
  </tr>
  <tr>
    <td>Col 1</td>
    <td>Col 2</td>
  </tr>
  <tr>
    <td>Col 1</td>
    <td>Col 2</td>
  </tr>
  <tr>
    <td>Col 1</td>
    <td>Col 2</td>
  </tr>
  <tr>
    <td>Col 1</td>
    <td>Col 2</td>
  </tr>
  <tr>
    <td>Col 1</td>
    <td>Col 2</td>
  </tr>
</table>
```
*/ }
    table.stripped tbody tr:nth-of-type(odd) {
      background: #e8ebed; }
    table.stripped tbody tr td:first-child {
      padding: 1.5rem 2rem 1.5rem 2rem; }
    table.stripped tbody tr td:not(:first-child):not(:last-child) {
      padding: 1.5rem 2rem; }
    table.stripped tbody tr td:last-child {
      padding: 1.5rem 2rem 1.5rem 2rem;
      font-weight: 700; }
    @media (min-width: 641px) {
      table.stripped tbody tr td:first-child {
        padding: 1.5rem 0 1.5rem 4rem; }
      table.stripped tbody tr td:not(:first-child):not(:last-child) {
        padding: 1.5rem 0; }
      table.stripped tbody tr td:last-child {
        padding: 1.5rem 4rem 1.5rem 0; } }
    table.stripped tbody tr:not(:last-of-type) {
      border: none; }
  @media (max-width: 640px) {
    table.scroll {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      padding: 0 2rem;
      position: relative; }
      table.scroll:before, table.scroll:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 2rem; }
      table.scroll:before {
        background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0));
        left: 0; }
      table.scroll:after {
        background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0));
        right: 0; }
    table.responsive thead {
      display: none; }
    table.responsive td,
    table.responsive th {
      padding: 0; }
    table.responsive tbody tr {
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      width: 100%; }
      table.responsive tbody tr td {
        text-align: left;
        padding: 0.5rem 0; }
        table.responsive tbody tr td[data-header] {
          display: flex;
          flex-direction: column; }
          table.responsive tbody tr td[data-header]:before {
            display: block;
            content: attr(data-header);
            text-align: left;
            font-weight: 700; }
    table.responsive.cart tbody tr {
      position: relative;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1.5rem 1.5rem 3rem 1.5rem; }
      table.responsive.cart tbody tr td:first-of-type {
        flex: 0 0 100%;
        padding: 0; }
        table.responsive.cart tbody tr td:first-of-type + td {
          justify-content: center;
          align-items: center; }
      table.responsive.cart tbody tr td:not(:first-of-type) {
        flex: 0 0 33%;
        max-width: 33%;
        padding-top: 2rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3rem; }
      table.responsive.cart tbody tr td[data-header]:before {
        color: #808080;
        font-size: 9px;
        line-height: 10px;
        text-transform: uppercase;
        margin-bottom: 1rem; }
      table.responsive.cart tbody tr td:nth-of-type(2) {
        padding-top: 2rem; }
      table.responsive.cart tbody tr .cart-item__remove {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #e8ebed;
        border-top: 1px solid #D9D9D9; }
        table.responsive.cart tbody tr .cart-item__remove a {
          width: 100%; } }
  table.responsive.cart tbody tr td {
    transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1); }
    table.responsive.cart tbody tr td > * {
      transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
      overflow: hidden;
      display: block;
      max-height: 15rem; }
  table.responsive.cart tbody tr.delete td {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    table.responsive.cart tbody tr.delete td > * {
      max-height: 0;
      opacity: 0;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important; }

table.quotes__account {
  border-collapse: collapse; }
  table.quotes__account thead tr,
  table.quotes__account tbody tr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: none; }
    table.quotes__account thead tr td,
    table.quotes__account thead tr th,
    table.quotes__account tbody tr td,
    table.quotes__account tbody tr th {
      text-align: left; }
      table.quotes__account thead tr td:not(:first-of-type):not(:last-of-type),
      table.quotes__account thead tr th:not(:first-of-type):not(:last-of-type),
      table.quotes__account tbody tr td:not(:first-of-type):not(:last-of-type),
      table.quotes__account tbody tr th:not(:first-of-type):not(:last-of-type) {
        padding: 3rem 0rem 3rem 1rem !important; }
      table.quotes__account thead tr td:first-of-type,
      table.quotes__account thead tr th:first-of-type,
      table.quotes__account tbody tr td:first-of-type,
      table.quotes__account tbody tr th:first-of-type {
        padding: 3rem 1rem 3rem 3rem !important; }
      table.quotes__account thead tr td:last-of-type,
      table.quotes__account thead tr th:last-of-type,
      table.quotes__account tbody tr td:last-of-type,
      table.quotes__account tbody tr th:last-of-type {
        padding: 3rem 3rem 3rem 1rem !important; }
      table.quotes__account thead tr td:nth-child(1),
      table.quotes__account thead tr th:nth-child(1),
      table.quotes__account tbody tr td:nth-child(1),
      table.quotes__account tbody tr th:nth-child(1) {
        width: 20%;
        flex: 0 0 20%;
        max-width: 20%; }
      table.quotes__account thead tr td:nth-child(2),
      table.quotes__account thead tr th:nth-child(2),
      table.quotes__account tbody tr td:nth-child(2),
      table.quotes__account tbody tr th:nth-child(2) {
        width: 14%;
        flex: 0 0 14%;
        max-width: 14%; }
      table.quotes__account thead tr td:nth-child(3),
      table.quotes__account thead tr th:nth-child(3),
      table.quotes__account tbody tr td:nth-child(3),
      table.quotes__account tbody tr th:nth-child(3) {
        width: 15%;
        flex: 0 0 15%;
        max-width: 15%; }
      table.quotes__account thead tr td:nth-child(4),
      table.quotes__account thead tr th:nth-child(4),
      table.quotes__account tbody tr td:nth-child(4),
      table.quotes__account tbody tr th:nth-child(4) {
        width: 13%;
        flex: 0 0 13%;
        max-width: 13%; }
      table.quotes__account thead tr td:nth-child(5),
      table.quotes__account thead tr th:nth-child(5),
      table.quotes__account tbody tr td:nth-child(5),
      table.quotes__account tbody tr th:nth-child(5) {
        width: 18%;
        flex: 0 0 18%;
        max-width: 18%; }
      table.quotes__account thead tr td:nth-child(6),
      table.quotes__account thead tr th:nth-child(6),
      table.quotes__account tbody tr td:nth-child(6),
      table.quotes__account tbody tr th:nth-child(6) {
        width: 20%;
        flex: 0 0 20%;
        max-width: 20%; }
    table.quotes__account thead tr td.sub,
    table.quotes__account tbody tr td.sub {
      flex: 0 0 100%;
      padding: 0; }
      table.quotes__account thead tr td.sub div,
      table.quotes__account tbody tr td.sub div {
        background: #e8ebed;
        padding: 2rem; }
  @media (min-width: 641px) and (max-width: 1024px) {
    table.quotes__account thead tr td:nth-child(1),
    table.quotes__account thead tr th:nth-child(1),
    table.quotes__account tbody tr td:nth-child(1),
    table.quotes__account tbody tr th:nth-child(1) {
      width: 22%;
      flex: 0 0 22%;
      max-width: 22%; }
    table.quotes__account thead tr td:nth-child(2),
    table.quotes__account thead tr th:nth-child(2),
    table.quotes__account tbody tr td:nth-child(2),
    table.quotes__account tbody tr th:nth-child(2) {
      width: 18%;
      flex: 0 0 18%;
      max-width: 18%; }
    table.quotes__account thead tr td:nth-child(3),
    table.quotes__account thead tr th:nth-child(3),
    table.quotes__account tbody tr td:nth-child(3),
    table.quotes__account tbody tr th:nth-child(3) {
      width: 17%;
      flex: 0 0 17%;
      max-width: 17%; }
    table.quotes__account thead tr td:nth-child(4),
    table.quotes__account thead tr th:nth-child(4),
    table.quotes__account tbody tr td:nth-child(4),
    table.quotes__account tbody tr th:nth-child(4) {
      width: 16%;
      flex: 0 0 16%;
      max-width: 16%; }
    table.quotes__account thead tr td:nth-child(5),
    table.quotes__account thead tr th:nth-child(5),
    table.quotes__account tbody tr td:nth-child(5),
    table.quotes__account tbody tr th:nth-child(5) {
      display: none; }
    table.quotes__account thead tr td:nth-child(6),
    table.quotes__account thead tr th:nth-child(6),
    table.quotes__account tbody tr td:nth-child(6),
    table.quotes__account tbody tr th:nth-child(6) {
      width: 27%;
      flex: 0 0 27%;
      max-width: 27%; }
    table.quotes__account thead tr td:nth-child(5),
    table.quotes__account thead tr th:nth-child(5),
    table.quotes__account tbody tr td:nth-child(5),
    table.quotes__account tbody tr th:nth-child(5) {
      display: none; }
    table.quotes__account thead tr td:last-of-type,
    table.quotes__account thead tr th:last-of-type,
    table.quotes__account tbody tr td:last-of-type,
    table.quotes__account tbody tr th:last-of-type {
      padding-right: 1rem; } }
  @media (max-width: 640px) {
    table.quotes__account > thead > tr,
    table.quotes__account > tbody > tr {
      flex-direction: row; }
      table.quotes__account > thead > tr > td:not(:first-of-type):not(:last-of-type),
      table.quotes__account > thead > tr > th:not(:first-of-type):not(:last-of-type),
      table.quotes__account > tbody > tr > td:not(:first-of-type):not(:last-of-type),
      table.quotes__account > tbody > tr > th:not(:first-of-type):not(:last-of-type) {
        padding: 1rem !important; }
      table.quotes__account > thead > tr > td:first-of-type,
      table.quotes__account > thead > tr > th:first-of-type,
      table.quotes__account > tbody > tr > td:first-of-type,
      table.quotes__account > tbody > tr > th:first-of-type {
        padding: 1rem !important; }
      table.quotes__account > thead > tr > td:last-of-type,
      table.quotes__account > thead > tr > th:last-of-type,
      table.quotes__account > tbody > tr > td:last-of-type,
      table.quotes__account > tbody > tr > th:last-of-type {
        padding: 1rem !important; }
      table.quotes__account > thead > tr > td:nth-child(1),
      table.quotes__account > thead > tr > th:nth-child(1),
      table.quotes__account > tbody > tr > td:nth-child(1),
      table.quotes__account > tbody > tr > th:nth-child(1) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      table.quotes__account > thead > tr > td:nth-child(2),
      table.quotes__account > thead > tr > th:nth-child(2),
      table.quotes__account > tbody > tr > td:nth-child(2),
      table.quotes__account > tbody > tr > th:nth-child(2) {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      table.quotes__account > thead > tr > td:nth-child(3),
      table.quotes__account > thead > tr > th:nth-child(3),
      table.quotes__account > tbody > tr > td:nth-child(3),
      table.quotes__account > tbody > tr > th:nth-child(3) {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      table.quotes__account > thead > tr > td:nth-child(4),
      table.quotes__account > thead > tr > th:nth-child(4),
      table.quotes__account > tbody > tr > td:nth-child(4),
      table.quotes__account > tbody > tr > th:nth-child(4) {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      table.quotes__account > thead > tr > td:nth-child(5),
      table.quotes__account > thead > tr > th:nth-child(5),
      table.quotes__account > tbody > tr > td:nth-child(5),
      table.quotes__account > tbody > tr > th:nth-child(5) {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%; }
      table.quotes__account > thead > tr > td:nth-child(6),
      table.quotes__account > thead > tr > th:nth-child(6),
      table.quotes__account > tbody > tr > td:nth-child(6),
      table.quotes__account > tbody > tr > th:nth-child(6) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%; }
      table.quotes__account > thead > tr > td.sub div,
      table.quotes__account > thead > tr > th.sub div,
      table.quotes__account > tbody > tr > td.sub div,
      table.quotes__account > tbody > tr > th.sub div {
        padding: 0.5rem 2rem; }
      table.quotes__account > thead > tr > td.sub:before,
      table.quotes__account > thead > tr > th.sub:before,
      table.quotes__account > tbody > tr > td.sub:before,
      table.quotes__account > tbody > tr > th.sub:before {
        display: none; }
      table.quotes__account > thead > tr > td.sub table tbody tr,
      table.quotes__account > thead > tr > th.sub table tbody tr,
      table.quotes__account > tbody > tr > td.sub table tbody tr,
      table.quotes__account > tbody > tr > th.sub table tbody tr {
        display: flex;
        flex-direction: row;
        padding: 0 !important; }
        table.quotes__account > thead > tr > td.sub table tbody tr td,
        table.quotes__account > thead > tr > th.sub table tbody tr td,
        table.quotes__account > tbody > tr > td.sub table tbody tr td,
        table.quotes__account > tbody > tr > th.sub table tbody tr td {
          width: 50%;
          max-width: 50%;
          flex: 0 0 50%;
          padding-top: 1rem !important;
          padding-bottom: 1rem !important; }
          table.quotes__account > thead > tr > td.sub table tbody tr td:first-of-type,
          table.quotes__account > thead > tr > th.sub table tbody tr td:first-of-type,
          table.quotes__account > tbody > tr > td.sub table tbody tr td:first-of-type,
          table.quotes__account > tbody > tr > th.sub table tbody tr td:first-of-type {
            padding-left: 0 !important; }
          table.quotes__account > thead > tr > td.sub table tbody tr td:last-of-type,
          table.quotes__account > thead > tr > th.sub table tbody tr td:last-of-type,
          table.quotes__account > tbody > tr > td.sub table tbody tr td:last-of-type,
          table.quotes__account > tbody > tr > th.sub table tbody tr td:last-of-type {
            padding-right: 0 !important; } }

table.quote__status {
  border: none;
  margin-bottom: 0; }
  table.quote__status thead tr th {
    color: #4A4A4A;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    padding: 1rem !important; }
  table.quote__status tbody tr {
    border: none !important;
    color: #4A4A4A;
    font-size: 1.3rem;
    line-height: 1.15; }
    table.quote__status tbody tr td {
      padding: 1rem !important; }
  table.quote__status tbody tr + tr {
    border-top: 1px solid #B3B3B3 !important; }
  @media (max-width: 640px) {
    table.quote__status tbody tr {
      padding: 1rem 0;
      display: flex;
      flex-direction: row; }
      table.quote__status tbody tr td {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%; } }
